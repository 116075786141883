import { Component, OnInit, Pipe, ViewChild, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { AccountsService, AdsAccountDto } from '../services/accounts.service';
import { FeatureFlagService } from '../services/feature-flag.service';
import { Policy, PolicyResultSet, RbacService } from '../services/rbac.service';
import { ToasterNotificationService } from '../services/toasterNotification.service';
import { UserGroupsService, UsersService } from '../services/users.service';
import { ConfirmationDialogComponent } from '../shared/dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'ads-app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {
  accounts: AdsAccountDto[];
  accountSearchControl: FormControl = new FormControl();
  allValue = 'all';
  dataSource: MatTableDataSource<PolicyResultSet>;
  emptyMessage: string;
  filteredAccountOptions: AdsAccountDto[];
  headers: string[];
  search: Subject<string> = new Subject();
  selectedAccount: string = this.allValue;
  showPolicies = false;
  emails = new Map<string, string>();
  loaded = false;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private accountsService: AccountsService,
    private featureFlagService: FeatureFlagService, public dialog: MatDialog,
    private rbacService: RbacService,
    private toasterService: ToasterNotificationService,
    private usersService: UsersService) {
    this.headers = ['account', 'workspace', 'object', 'subject', 'role', 'deleted'];
  }

  ngOnInit(): void {
    this.accountSearchControl.valueChanges.pipe(debounceTime(500)).subscribe(selectedAccount => {
      this.getPoliciesByAccount(selectedAccount);
    });

    this.getAccounts();

    this.featureFlagService.permissionTabEnabled.then(treatment => {
      if (treatment === 'on') {
        this.showPolicies = true;
      }
    });
  }

  getAccounts() {
    this.accountsService.searchAccounts().subscribe(accounts => {
      this.accounts = accounts;
      this.selectedAccount = this.allValue;
      this.getPoliciesByAccount();
      this.loaded = true;
    },
      error => {
        this.toasterService.showWarnToaster(error.detail);
      });
  }

  getPoliciesByAccount(selectedAccount?: any) {
    if (!selectedAccount || selectedAccount?.accountName === this.allValue) {
      this.rbacService.searchPolicies().subscribe(async returnedPolicies => {
        const policies = await this.setupPolicyDisplay(returnedPolicies.results);
        this.emails = await this.getEmails(policies);
        this.dataSource = new MatTableDataSource(policies);
        this.dataSource.sort = this.sort;
      },
        error => {
          this.getPoliciesError(error);
        });
    }
    else {
      this.rbacService.searchPolicies(selectedAccount).subscribe(async returnedPolicies => {
        const policies = await this.setupPolicyDisplay(returnedPolicies.results);
        this.emails = await this.getEmails(policies);
        this.dataSource = new MatTableDataSource(policies);
        this.dataSource.sort = this.sort;
      },
        error => {
          this.getPoliciesError(error);
        });
    }

    if (!this.dataSource?.data || this.dataSource.data.length < 1) {
      this.emptyMessage = 'No policies in selected account.';
    }
  }

  async getEmails(policies: any[]) {
    let emailMap = new Map<string, string>();
    for(let i = 0; i < policies.length; i++) {
      const isSecurityGroup = policies[i].subject.type === 'Security Group' ? true : false;
      const policySubject = policies[i].subject.id.split('/');
      const userId = policySubject[policySubject.length - 1];
      console.log(policies[i].subject.accountId);

      if(!isSecurityGroup && policies[i].subject.accountId) {
        this.usersService.getUser(policies[i].subject.accountId, userId).subscribe(user => {
          emailMap.set(policies[i].policyId, user.emailAddress);
        });
      }
    }
    return emailMap;
  }

  getEmailFromMap(policy) {
    let email = this.emails.get(policy.policyId);

    return email;
  }

  async setupPolicyDisplay(policies: Policy[]) {
    let formattedPolicies = [];

    for (let i = 0; i < policies.length; i++) {
      const accountName = this.getAccountName(policies[i]);
      const isSecurityGroup = policies[i].subject.type === 'Security Group' ? true : false;

      let role = policies[i].role.replace('role:', '');
      role = role.charAt(0).toUpperCase() + role.slice(1);

      formattedPolicies[i] = {
        policyId: policies[i].id,
        account: accountName,
        workspace: policies[i].workspace,
        subject: policies[i].subject,
        isSecurityGroup,
        object: policies[i].resource,
        role
      };
    }

    formattedPolicies = this.alphabetizeList(formattedPolicies, 'account');

    return formattedPolicies;
  }

  getAccountName(policy) {
    let accountName = '';

    if (policy.accountId && this.accounts) {
      const accountHolder = this.accounts.filter(account => account.accountId === policy.accountId);
      if (accountHolder?.length > 0) {
        accountName = accountHolder[0].accountName;
      }
    }
    else {
      accountName = '*';
    }

    return accountName;
  }

  alphabetizeList(unsortedList, property) {
    return unsortedList.sort((a, b) => a[property].localeCompare(b[property]));
  }

  getPoliciesError(error) {
    error.status === 403 ? this.toasterService.showWarnToaster('You do not have access to this resource')
      : this.toasterService.showWarnToaster(error.detail);
    if (this.dataSource?.data) {
      this.dataSource.data.length = 0;
    }
  }

  deletePolicy(policy: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure you want to delete this policy?',
        buttonText: {
          ok: 'Delete',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.rbacService.deletePolicy(policy.policyId)
          .subscribe({
            next: (resp: any) => {
              this.getPoliciesByAccount();
              this.toasterService.showInfoToaster('Policy successfully deleted.');
            },
            error: (err) => {
              this.toasterService.showWarnToaster(err.detail);
            }
          });
      }
    });
  }
}

@Pipe({ name: 'getAccessName' })
export class AccessName implements PipeTransform {
  constructor(private toasterService: ToasterNotificationService,
    private userGroupService: UserGroupsService,
    private userService: UsersService) { }

  transform(policy) {
    if (policy.isSecurityGroup) {
      return this.userGroupService.userGroup(policy.subject.id, policy.subject.accountId)
        .pipe(map(userGroup => {
          if (userGroup && userGroup.groupName) {
            return userGroup.groupName;
          }
          return '';
        },
          error => {
            this.toasterService.showWarnToaster(error.detail);
            return '';
          }));
    }
    else {
      const policySubject = policy.subject.id.split('/');
      const userId = policySubject[policySubject.length - 1];

      return this.userService.getUser(policy.subject.accountId, userId)
        .pipe(map(user => {
          if (user && user.displayName) {
            return user.displayName;
          }
          else {
            return '';
          }
        },
          error => {
            this.toasterService.showWarnToaster(error.detail);
            return '';
          }));
    }
  }
}
