import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BreadcrumbService } from 'angular-crumbs';
import { AdsAccountDto, AccountsService } from '../../services/accounts.service';
import {
  UsersService,
  UserGroupsService,
  UserGroupResponse,
  AppUserDto,
  UsersGroupingListResponse,
  Operation,
  AppUserDtoPagedListDto
} from '../../services/users.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ToasterNotificationService } from '../../services/toasterNotification.service';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'ads-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.scss']
})
export class UserGroupsComponent implements OnInit {

  @ViewChild('userGroupName') userGroupName: ElementRef;

  userGroupForm: FormGroup;
  private accountId: number;
  private userGroupId: number;
  private newUserGroup: boolean;
  public account: AdsAccountDto;
  public userGroup: UserGroupResponse = new UserGroupResponse();
  private search: Subject<string> = new Subject();
  groupUsersData: MatTableDataSource<{}>;
  availableUsersRawData: AppUserDto[];
  availableUsersData: MatTableDataSource<{}>;
  availableUsersSearchTerm = '';
  availableUsersEmptyData = true;
  availableUsersBaseMessage = 'To see user information, please begin typing a username';
  availableUsersEmptyMessage: string = this.availableUsersBaseMessage;
  userGroupFormNameControl = 'userGroupName';

  usersInGroup: AppUserDto[] = [];
  userIdsInGroup: string[] = [];

  userIdsToAdd: string[] = [];
  userIdsToRemove: string[] = [];

  availableUsersColumnsToDisplay: string[] = ['displayName', 'emailAddress', 'action'];
  addedUserColumnsToDisplay: string[] = ['displayName', 'emailAddress', 'action'];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private toasterService: ToasterNotificationService,
    private userService: UsersService,
    private userGroupService: UserGroupsService,
    private accountService: AccountsService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog) {

    this.accountId = Number.parseInt(this.route.parent.snapshot.params.id, 10);

    this.newUserGroup = this.route.snapshot.params.userGroupId === undefined ? true : false;

    this.userGroupId = this.newUserGroup ? undefined : Number.parseInt(this.route.snapshot.params.userGroupId, 10);

    this.userGroupForm = this.formBuilder.group({
      userGroupName: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.search.pipe(debounceTime(500)).subscribe(searchTextValue => {
      this.searchUsers(searchTextValue);
    });

    this.accountService.account(this.accountId).subscribe(
      ((accountResponse: AdsAccountDto) => {
        this.account = accountResponse;
        if (this.account === undefined) {
          this.router.navigateByUrl('/accounts');
        }
        this.breadcrumbService.changeBreadcrumb(this.route.snapshot, this.account.accountName);
      }),
      error => {
        this.router.navigateByUrl('/accounts');
      });


    if (this.userGroupId !== undefined) {
      this.userGroupService.userGroup(this.userGroupId, this.accountId).subscribe(r => {

        this.userGroup.groupName = r.groupName;
        this.userGroup.userGroupId = r.userGroupId;

        this.userGroupService.usersInGroup(this.accountId, this.userGroupId).subscribe(
          ((userGroupResponse: UsersGroupingListResponse[]) => {
            for (const user of userGroupResponse) {
              const modifiedUser: AppUserDto = new AppUserDto();
              modifiedUser.displayName = user.name;
              modifiedUser.emailAddress = user.userEmail;
              modifiedUser.userId = user.b2cUserId;

              this.usersInGroup.push(modifiedUser);
            }
            this.groupUsersData = new MatTableDataSource(this.usersInGroup);
            if (this.userGroup.groupName !== undefined) {
              this.userGroupForm.controls[this.userGroupFormNameControl].setValue(this.userGroup.groupName);
            }
          }),
          error => {
            this.navigateToAccount();
          });
      },
        err => {
          console.error(err);
          this.navigateToAccount();
        });

    } else {
      // this.userGroup.users = [];
      this.groupUsersData = new MatTableDataSource();
    }
  }

  searchTriggered(filterValue: any) {
    this.availableUsersSearchTerm = filterValue;
    this.search.next(filterValue);
  }

  searchUsers(filterValue: any) {
    if (filterValue !== '') {
      this.userService.getUsersByAccount(this.accountId, filterValue).subscribe((resp: AppUserDtoPagedListDto) => {
        this.availableUsersRawData = resp.items;

        this.refreshAvailableUsersData();

        this.availableUsersEmptyMessage = 'No results match search term ' + filterValue;
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
    } else {
      this.availableUsersEmptyData = true;
      this.availableUsersEmptyMessage = this.availableUsersBaseMessage;
    }
  }

  onSubmit() {
    // Create an array of user GUID to send back
    for (const user of this.usersInGroup) {
      this.userIdsInGroup.push(user.userId);
    }

    this.userGroupForm.markAllAsTouched();

    if (this.userGroupForm.invalid) {
      this.userGroupName.nativeElement.focus();
      return;
    }

    if (this.newUserGroup) {
      this.userGroup.groupName = this.userGroupForm.value.userGroupName;
      this.userGroup.accountId = this.accountId;


      this.userGroupService.createUserGroup(this.accountId, this.userGroup).subscribe(g => {
        this.toasterService.showInfoToaster('User group created!');
        this.userGroupService.addUsersToGroup(this.accountId, g.userGroupId, this.userIdsInGroup).subscribe(a => {
          this.router.navigate([`${g.userGroupId}`], { relativeTo: this.route });
        });
      },
      (error) => {
        this.toasterService.showWarnToaster(error.detail);
      });
    } else {
      this.userGroup.groupName = this.userGroupForm.value.userGroupName;

      const userGroupPatch: Operation[] = [
        new Operation({ op: 'replace', path: '/groupName', value: this.userGroup.groupName}),
      ];

      this.userGroupService.updateUserGroup(this.accountId, this.userGroupId, userGroupPatch).subscribe(u => {
        this.toasterService.showInfoToaster('User group updated!');
        if (this.userIdsToAdd.length > 0) {
          this.userGroupService.addUsersToGroup(this.accountId, this.userGroupId, this.userIdsToAdd).subscribe(a => {
            a.errors.forEach(e => {
              this.toasterService.showWarnToaster(e.error);
              this.removeUser(this.usersInGroup.find(f => f.userId === e.userId));
            });
          });
        }
        if (this.userIdsToRemove.length > 0) {
          this.userGroupService.removeUsersFromGroup(this.accountId, this.userGroupId, this.userIdsToRemove).subscribe(r => {
          });
        }
      });
    }
  }

  refreshAvailableUsersData() {
    let filteredData = this.availableUsersRawData;

    if (this.usersInGroup !== undefined) {
      for (const groupUser of this.usersInGroup) {
        if (filteredData !== undefined) {
          filteredData = filteredData.filter(item => item.userId !== groupUser.userId);
        }
      }
    }
    this.availableUsersData = new MatTableDataSource(filteredData);

    this.availableUsersEmptyData = this.availableUsersData.filteredData.length === 0;
  }

  removeUser(user: AppUserDto) {

    // Keep Track of Users to Remove
    if (!this.userIdsToRemove.includes(user.userId)) {
      this.userIdsToRemove.push(user.userId);
    }
    // Ensure that the arrays for removing and adding are mutually exclusive
    if (this.userIdsToAdd.includes(user.userId)) {
      this.userIdsToAdd = this.userIdsToAdd.filter(item => item !== user.userId);
    }

    this.usersInGroup = this.usersInGroup.filter(item => item.userId !== user.userId);
    this.groupUsersData = new MatTableDataSource(this.usersInGroup);
    this.refreshAvailableUsersData();
  }

  addUser(user: AppUserDto) {

    // Keep Track of Users to Add
    if (!this.userIdsToAdd.includes(user.userId)) {
      this.userIdsToAdd.push(user.userId);
    }
    // Ensure that the arrays for removing and adding are mutually exclusive
    if (this.userIdsToRemove.includes(user.userId)) {
      this.userIdsToRemove = this.userIdsToRemove.filter(item => item !== user.userId);
    }

    // Add to right column
    this.usersInGroup.push(user);
    this.groupUsersData = new MatTableDataSource(this.usersInGroup);

    // Refresh left column
    this.refreshAvailableUsersData();
  }

  navigateToAccount() {
    this.router.navigateByUrl(`/accounts/view/${this.account.accountId}/account-details`);
  }

  onDeleteUserGroupClick() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure you want to delete this user group?',
        message: 'This action is irreversible.',
        buttonText: {
          ok: 'Delete',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userGroupService.deleteUserGroup(this.accountId, this.userGroup.userGroupId).subscribe(u => {
            this.toasterService.showInfoToaster('User group deleted!');
            this.navigateToAccount();
        });
      }
    });
  }

  canDeactivate(): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent>;
    let config = {
      data: {
        message: 'You may have unsaved changes. Are you sure you want to navigate away?'
      }
    }

    if(this.userGroupForm.dirty) {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, config);

      return dialogRef.afterClosed().pipe(map(result => result === true));
    } else {
      return of(true);
    }
  }
}

export class UsersDeactivateGuard implements CanDeactivate<UserGroupsComponent> {
  canDeactivate(component: UserGroupsComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return component.canDeactivate();
  }
  
}