<div class="my-3">
  <mat-card>
    <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
      Manage Users
      <div>
        <button id='usr-usersauditlogs' [routerLink]="['/auditing']" [queryParams]="{resourceType: 'ADSUsersUser'}" mat-button color="secondary">
          View Audit Logs
        </button>
        <button id='usr-addnewuserbutton' [routerLink]="['/users/add']" mat-button color="primary">
          Add New User
        </button>
      </div>
    </mat-card-title>
    <mat-card-subtitle>
      Manage users for the SCV portal
    </mat-card-subtitle>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div id="leftcolumn">
        <label>Search Users</label>
        <mat-form-field>
          <input id='usr-searchtext' name="searchText" matInput (keyup)="searchTriggered($event.target.value)" maxlength="50" #searchText />
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-content class="table-container">
      <table mat-table [dataSource]="userData" matSort class="user-component-table" [hidden]="emptyData">
        <ng-container matColumnDef="accountName">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Account</th>
          <td mat-cell *matCellDef="let user">{{ user.accountName }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let user">{{ user.email }}</td>
        </ng-container>

        <ng-container matColumnDef="displayName">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Display Name</th>
          <td mat-cell *matCellDef="let user">{{ user.displayName }}</td>
        </ng-container>

        <ng-container matColumnDef="lastSignInDate">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Last Logged In
          <mat-icon #tooltip="matTooltip" matTooltip="Recent logins can take up to 10 minutes to be processed. Logins are only updated
                    once per hour while users have active sessions.">info</mat-icon></th>
          <td mat-cell *matCellDef="let user"><span matTooltip="{{ user.lastSignInDate }}" matTooltipPosition="below">{{ user.lastSignInDate | dateAgo: '--' }}</span></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row class="hover" (click)="onUserRowSelect(row)" *matRowDef="let row; columns: columnsToDisplay">
        </tr>
      </table>
      <div class="load-more" *ngIf="skipToken !== null && skipToken !== undefined">
        <button mat-button color="primary" id='usr-loadmoredata' (click)='loadMoreResults()'>
          Load More
        </button>
      </div>
      <div *ngIf="emptyData">
        <label class="sm-note">{{emptyMessage}}</label>
      </div>
    </mat-card-content>
  </mat-card>
</div>
