<mat-card>
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between">
    <mat-card-title>Installer Apps</mat-card-title>
    <div>
      <button mat-button color="primary" (click)="chooseNumberOfActivations()">Generate App Activation Codes</button>
    </div>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div fxFill fxLayoutAlign="right">
      <!-- <mat-checkbox [(ngModel)]="active" >Active</mat-checkbox> -->
      <mat-button-toggle-group name="activeGroup" #group="matButtonToggleGroup" [value]="filterState" (change)="activeChanged(group.value)">
        <mat-button-toggle color="primary" value="ReadyForActivation">Available</mat-button-toggle>
        <mat-button-toggle value="InUse">In Use</mat-button-toggle>
        <mat-button-toggle value="Disabled">Disabled</mat-button-toggle>
        <mat-button-toggle value="">All</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div fxFill fxLayoutAlign="center" *ngIf="isLoading">
      <h3>Loading...</h3>
    </div>
    <div fxFill fxLayoutAlign="center" *ngIf="emptyData">
      <h3>No Installer Apps found. Try changing the filters above.</h3>
    </div>
    <div fxFill fxLayout="column" fxLayoutAlign="center" *ngIf="errorLoading">
      <h3 fxFlexAlign="center">Error Getting Installer Apps</h3>
      <div fxFlexAlign="center">
        <button mat-button color="primary" (click)="retry()">Retry</button>
      </div>
    </div>
    <table mat-table [dataSource]="userData" matSort class="user-component-table"
      *ngIf="!emptyData && !isLoading && !errorLoading">
      <ng-container matColumnDef="companyName">
        <th class="table-header" mat-header-cell *matHeaderCellDef>Company Name</th>
        <td mat-cell *matCellDef="let user">{{ user.companyName }}</td>
      </ng-container>
      <ng-container matColumnDef="companyCode">
        <th class="table-header" mat-header-cell *matHeaderCellDef>Company Code</th>
        <td mat-cell *matCellDef="let user">{{ user.companyCode }}</td>
      </ng-container>
      <ng-container matColumnDef="activationCode">
        <th class="table-header" mat-header-cell *matHeaderCellDef>Activation Code</th>
        <td mat-cell *matCellDef="let user">{{ user.activationCode }}</td>
      </ng-container>
      <ng-container matColumnDef="createdOn">
        <th class="table-header" mat-header-cell *matHeaderCellDef>Created</th>
        <td mat-cell *matCellDef="let user"><span #tooltip="matTooltip" [matTooltip]="user.createdOn" title="{{user.lastLoggedIn}}">{{ user.createdOn | dateAgo }}</span></td>
      </ng-container>
      <ng-container matColumnDef="lastLoggedIn">
        <th class="table-header" mat-header-cell *matHeaderCellDef>Last Logged In <mat-icon #tooltip="matTooltip" matTooltip="Recent logins can take up to 10 minutes to be processed. Logins are only updated once per hour while users have active sessions.">info</mat-icon></th>
        <td mat-cell *matCellDef="let user"><span #tooltip="matTooltip" [matTooltip]="user.lastLoggedIn">{{ user.lastLoggedIn | dateAgo:'-' }}</span></td>
      </ng-container>
      <ng-container matColumnDef="chips">
        <th class="table-header" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
          <mat-chip-list>
            <mat-chip color="warn" *ngIf="!user.accountEnabled && !user.accountInUse">Disabled</mat-chip>
            <mat-chip color="primary" *ngIf="user.accountEnabled && !user.accountInUse">Available</mat-chip>
            <mat-chip color="accent" *ngIf="user.accountInUse">In Use</mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th class="table-header align-right" mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let user" class="align-right"><button *ngIf="user.accountInUse || (user.accountEnabled && !user.accountInUse)" mat-button color="warn" (click)="disable(user)">Disable</button></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
    </table>
  </mat-card-content>
</mat-card>
