<div>
  <div *ngIf="ready">
    <mat-checkbox *ngFor="let type of eventOptionsArray" style="padding-right: 2rem" [name]="type.type"
      [(ngModel)]="type.options.visible"
      (ngModelChange)="updateMarkers()"
      >
      {{type.type}}
      <img [src]="type.options.icon" style="height: 1.2rem">
    </mat-checkbox>

    <mat-checkbox (change)="showLOIs($event.checked)" style="float: right">
      Show LOIs
    </mat-checkbox>
</div>
  <google-map id="map" #map height="600px" width="100%" [options]="mapOptions">
    <map-marker #marker="mapMarker"
      *ngFor="let m of markers"
      [position]="{ lat: m.latitude, lng: m.longitude }"
      [options]="{ icon: m.options.icon, visible: m.options.visible }"
      (mapClick)="onMarkerClick($event, marker, m)"
    ></map-marker>
    <map-info-window #mapInfoWindow>
      <div *ngIf="infoWindowMarker">
        <div><b>{{infoWindowMarker.eventType}}</b></div>
        <div>{{infoWindowMarker.time.toISOString()}}</div>
        <pre style="max-height: 200px; overflow: scroll">{{infoWindowMarker.source | json}}</pre>
      </div>
    </map-info-window>
  </google-map>
</div>
