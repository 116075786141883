import { Component, OnInit, AfterViewInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NewAccount, AccountsService, AdsAccountDto } from '../../services/accounts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'angular-crumbs';
import { ToasterNotificationService } from '../../services/toasterNotification.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'ads-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss']
})
export class AccountFormComponent implements OnInit, AfterViewInit {
  accountForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private breadcrumbService: BreadcrumbService,
              private toasterService: ToasterNotificationService,
              private accountService: AccountsService,
              private renderer: Renderer2,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.accountForm = this.formBuilder.group({
      sapAccountNumber: ['', [
        Validators.required,
        Validators.min(1),
        Validators.max(999999999)
      ]],
      accountName: ['', Validators.required]
    });
    this.breadcrumbService.changeBreadcrumb(this.route.snapshot, 'Add');
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#af-accountname');
    element.focus();
    this.cdr.detectChanges();
  }

  onSubmit() {
    this.accountForm.markAllAsTouched();

    if (this.accountForm.invalid) {
      return;
    }

    const account = new NewAccount();
    account.accountName = this.accountForm.value.accountName;
    account.sapAccountNumber = this.accountForm.value.sapAccountNumber;

    this.accountService.create(account).subscribe((acc: AdsAccountDto) => {
      this.router.navigate([`/accounts/view/${acc.accountId}`]);
    },
      err => {
        this.toasterService.showWarnToaster(err.detail);
      }
    );

  }
}
