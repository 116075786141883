<div *ngIf="this.assetId && this.searchable === 'false'">
    <mat-chip-list class="notSearchable" *ngIf="this.workspaces.length > 0" #chipList aria-label="Workspaces">
      <mat-chip
      *ngFor="let workspace of workspacesDisplayable" 
      [selectable]="false"
      (click)="chipClick()">
        {{workspace.displayName}}
      </mat-chip>
    </mat-chip-list>
    <p *ngIf="this.workspaces.length == 0">No workspaces for this asset.</p>
  </div>
  
  <mat-form-field *ngIf="this.assetId && this.searchable == 'true'">
    <input 
    placeholder="Add Workspace" 
    [formControl]="workspaceCtrl" 
    #workspaceInput 
    [matAutocomplete]="auto"
    [matChipInputFor]="chipList" 
    [matChipInputSeparatorKeyCodes]="separatorKeyCodes" 
    (matChipInputTokenEnd)="onWorkspaceAdd($event)">
    
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let workspace of filteredWorkspaces | async" [value]="workspace.id">
        {{workspace.displayName}} [{{workspace.id}}]
      </mat-option>
    </mat-autocomplete>
  
    <mat-chip-list #chipList aria-label="Workspaces">
      <mat-chip class="searchableChips"
      *ngFor="let workspace of workspacesDisplayable" 
      [selectable]="true" 
      [removable]="true" 
      (removed)="remove(workspace)"
      (click)="chipClick()">
        {{workspace.displayName}}
        <mat-icon matChipRemove>highlight_off</mat-icon>
      </mat-chip>
    </mat-chip-list>
    </mat-form-field>
  
    <mat-form-field *ngIf="this.groupId">
      <input 
      placeholder="Add Workspace" 
      [formControl]="workspaceCtrl" 
      #workspaceInput 
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList" 
      [matChipInputSeparatorKeyCodes]="separatorKeyCodes" 
      (matChipInputTokenEnd)="onWorkspaceAdd($event)">
  
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let workspace of filteredWorkspaces | async" [value]="workspace.id">
          {{workspace.displayName}} [{{workspace.id}}]
        </mat-option>
      </mat-autocomplete>
  
      <mat-chip-list #chipList aria-label="Workspaces">
        <mat-chip class="searchable"
        *ngFor="let workspace of workspacesDisplayable" 
        [selectable]="true" 
        [removable]="true" 
        (removed)="remove(workspace)"
        (click)="chipClick()">
          {{workspace.displayName}}
          <mat-icon matChipRemove>highlight_off</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-form-field>