import { Component, OnInit, AfterViewInit, Renderer2, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Subject} from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UsersService, AppUserDtoPagedListDto } from '../services/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { ToasterNotificationService } from '../services/toasterNotification.service';
import { AdsAccountDto } from '../services/accounts.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'ads-manage-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {
  accountId: number;
  accounts: AdsAccountDto[];
  baseMessage: string;
  columnsToDisplay: string[] = [
    'accountName',
    'email',
    'displayName',
    'lastSignInDate'
  ];
  emptyData: boolean;
  emptyMessage: string;
  filterValue: string;
  private search: Subject<string> = new Subject();
  skipToken: string;
  userData: MatTableDataSource<{}>;
  
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterNotificationService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
    this.search.pipe(debounceTime(500)).subscribe(searchTextValue => {
      this.searchUsers(searchTextValue);
    });

    this.emptyData = true;
    this.skipToken = null;
    this.baseMessage = 'To see users please begin typing the user\'s display name';
    this.emptyMessage = this.baseMessage;
    this.accountId = this.route.snapshot.params.accountId;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#usr-searchtext');
    element.focus();
    this.cdr.detectChanges();
  }

  searchTriggered(filterValue: any) {
    this.filterValue = filterValue;

    if (this.filterValue === '') {
      this.userData.data = [];
    }

    this.search.next(filterValue);
  }

  searchUsers(filterValue?: string) {
    this.filterValue = filterValue;
    if (this.filterValue !== '') {
      this.userService.getUsers(this.filterValue).subscribe((resp: AppUserDtoPagedListDto) => {
        const userData = resp.items.map((u: any) => ({
          id: u.userId,
          accountId: u.accountId,
          accountName: u.accountName,
          givenName: u.firstName,
          surname: u.lastName,
          email: u.emailAddress,
          displayName: u.displayName,
          lastSignInDate: u.lastSignInDate,
        }));
        this.skipToken = resp.skipToken;
        this.userData = new MatTableDataSource(userData);
        this.userData.sort = this.sort;

        this.emptyData = this.userData.filteredData.length === 0;
        this.emptyMessage = 'No results match search term ' + this.filterValue;
      },
      error => {
        this.toasterService.showWarnToaster(error.detail);
      });
    } else {
      this.emptyData = true;
      this.emptyMessage = this.baseMessage;
      this.skipToken = null;
    }
  }

  loadMoreResults() {
    if (this.skipToken !== null) {
      this.userService.getUsers(this.filterValue, this.skipToken).subscribe((resp: AppUserDtoPagedListDto) => {
        const newData = resp.items.map((u: any) => ({
          id: u.userId,
          accountId: u.accountId,
          accountName: u.accountName,
          givenName: u.firstName,
          surname: u.lastName,
          email: u.emailAddress,
          displayName: u.displayName,
          lastSignInDate: u.lastSignInDate,
        }));
        this.skipToken = resp.skipToken;
        const combinedData = this.userData.data.concat(newData);
        this.userData = new MatTableDataSource(combinedData);
        this.emptyData = this.userData.filteredData.length === 0;
        this.emptyMessage = 'No results match search term ' + this.filterValue;
      },
      error => {
        this.toasterService.showWarnToaster(error.detail);
      });
    }
  }

  onUserRowSelect(event) {
    this.router.navigate([`accounts/${event.accountId}/users/${event.id}`], {
      relativeTo: this.route.parent.parent,
    });
  }
}
