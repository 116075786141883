import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AssetResponse, AssetsV3Service } from 'src/app/services/assets-v3.service';
import { RailSightService } from 'src/app/services/railsight.service';
import { AssetsService, TripCLMEventModel, TripDeviceLocationEventModel, TripEventModel, TripGeoMetrixTripEventModel, TripSummaryModel } from 'src/app/services/shipments-v2.service';
import { createTripEventViewModels } from '../../TripEventViewModelFactory';
import { TripEventViewModel } from '../../types';

let defaultEventQueryStartDate = new Date();
defaultEventQueryStartDate.setDate(defaultEventQueryStartDate.getDate() - 90);

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.page.html',
  styleUrls: ['./asset-details.page.scss']
})
export class AssetDetailsPage implements OnInit {
  assetId: string;
  asset: AssetResponse;
  events: TripEventViewModel[];

  ready: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private assetV3Service: AssetsV3Service,
    private shipmentAssetsService: AssetsService,
    private railsightService: RailSightService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.assetId = this.route.snapshot.params.assetId;

    const assetPromise = this.assetV3Service.getAsset(this.assetId).toPromise();
    const tripsPromise = this.getTripsPage();
    const eventsPromise = tripsPromise
      .then(trips => {
        let startDate = defaultEventQueryStartDate;

        if (trips.length > 0) {
          startDate = trips[trips.length - 1].startDateTime;
        }

        return this.getEvents(startDate)
      });

    Promise.all([
      assetPromise,
      tripsPromise,
      eventsPromise
    ]).then(([asset, trips, events]) => {
      this.asset = asset;
      this.events = createTripEventViewModels(asset, events, trips, this.railsightService);

      this.ready = true;
    })
  }

  async getTripsPage() {
    const tripsResponse = await this.shipmentAssetsService.getAssetTrips(this.assetId, null, 100).toPromise();

    return tripsResponse.items
  }

  async getEvents(startDate: Date): Promise<TripEventModel[]> {
    let events: TripEventModel[] = [];
    let continuationToken: string | null = null;

    do {
      const eventsResponse = await this.shipmentAssetsService.getAssetEvents(this.assetId, continuationToken, startDate, 1000).toPromise();
      events = events.concat(eventsResponse.items);
      continuationToken = eventsResponse.continuationToken;
    }
    while (continuationToken);

    events = events.sort((a, b) => a.eventDateTime.getTime() - b.eventDateTime.getTime());

    return events;
  }
}
