<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    Assets (PaaS)

    <div>
      <button mat-button color="primary" (click)="onGetOrCreateClick()">Get Or Create Asset</button>
      <a [routerLink]="['/auditing']" [queryParams]="{resourceType: 'ADSAssetsv3Asset'}" mat-button color="primary">
        View Audit Logs
      </a>
      <a [routerLink]="['/assets-paas/accounts']" mat-button color="primary">
        Switch to Account Assets
      </a>
    </div>
  </mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div id="leftcolumn">
      <label>Search Assets by Name</label>
      <mat-form-field>
        <input matInput (keyup)="onSearchKeyup($event)" maxlength="50" #searchText />
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-content>
    <ag-grid-angular style="width: 100%; height: 570px;" class="ag-theme-alpine"
      [columnDefs]="columnDefs" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
    <label class="sm-note">Click on a row to view details on the asset</label>
  </mat-card-content>
</mat-card>
