import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DevicesGroupsService, IDefaultGroup, IDevicesGroups, IDevicesGroupsResponse } from 'src/app/services/devices-groups.service';
import { DeviceGroupResponse } from 'src/app/services/devices.service';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { AddDeviceGroupComponent } from './add-device-group/add-device-group.component';
import { ChangesConfirmationPopupComponent } from './changes-confirmation-popup/changes-confirmation-popup.component';
import { columnDefs, defaultColDef } from './devices-groups.grid';
import { settingTooltipUp } from './devices-groups.tooltip';
import { MoveDeviceGroupComponent } from './move-device-group/move-device-group.component';

@Component({
  selector: 'app-account-devices-groups-tab',
  templateUrl: './account-devices-groups-tab.component.html',
  styleUrls: ['./account-devices-groups-tab.component.scss']
})
export class AccountDevicesGroupsTabComponent implements OnInit {
  private search: Subject<string> = new Subject();
  defaultColDef = defaultColDef;
  columnDefs = columnDefs;
  private gridApi: GridApi;
  defaultGroupSelected: string;
  counter: string;
  totalRegisters: number;
  devices: DeviceGroupResponse[];

  rowData!: IDevicesGroups[];
  private accountId: number;

  constructor(
    private route: ActivatedRoute,
    private toasterService: ToasterNotificationService,
    private router: Router,
    private devicesGroupsService: DevicesGroupsService,
    public dialog: MatDialog
  ) {
    this.route.parent.url.subscribe((urlPath) => {
      let accountId = 0;
      if (urlPath.length > 1) {
        accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
      }

      if (isNaN(accountId)) {
        this.router.navigateByUrl('[/accounts]');
      }
      this.accountId = accountId;
    });
  }

  ngOnInit(): void {
    this.getDevicesGroups(this.accountId);
    this.getDefaultGroup(this.accountId);
    this.search.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      this.gridApi.setQuickFilter(value);
    });
    this.devices = this.route.snapshot.data['items'];
  }

  searchDevicesGroupTriggered(filterValue: any) {
    this.search.next(filterValue);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    params.api.addEventListener('filterChanged', () => {
      this.counter = params.api.isAnyFilterPresent()
        ? `${params.api.getDisplayedRowCount()} of ${this.totalRegisters} shown`
        : `${this.totalRegisters}`;
    });
    settingTooltipUp();
  }

  getDevicesGroups(accountId: number) {
    this.devicesGroupsService
      .getDevicesGroups(accountId, 'all')
      .toPromise()
      .then((response: IDevicesGroupsResponse) => {
        this.rowData = response.results;
        this.totalRegisters = response.count;
        this.counter = response.count + '';
      })
      .catch((error) => this.toasterService.showWarnToaster(error));
  }

  getDefaultGroup(accountId: number) {
    this.devicesGroupsService
      .getDefaultGroup(accountId, 'all')
      .toPromise()
      .then((response: IDefaultGroup) => {
        this.defaultGroupSelected = response.defaultGroupId;
      })
      .catch((error) => this.toasterService.showWarnToaster(error));
  }

  onDefaultGroupChanged(event: MatSelectChange) {
    this.defaultGroupSelected = event.value;
  }

  openAddDeviceGroupDialog() {
    const defaultGroup = this.rowData.find((group) => group.id === this.defaultGroupSelected);
    const dialogRef = this.dialog.open(AddDeviceGroupComponent, {
      data: {
        accountId: this.accountId,
        devices: this.devices,
        defaultGroupName: defaultGroup?.name,
        groups: this.rowData
      },
      panelClass: 'add-device-group-dialog',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== null && result !== undefined) {
        this.getDevicesGroups(this.accountId);
      }
    });
  }

  openMoveDevicesDialog() {
    const dialogRef = this.dialog.open(MoveDeviceGroupComponent, {
      data: { accountId: this.accountId, devices: this.devices, groups: this.rowData },
      panelClass: 'add-device-group-dialog',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== null && result !== undefined) {
        this.getDevicesGroups(this.accountId);
      }
    });
  }

  confirmDefaultGropChange() {
    const groupSelected = this.rowData.find((group) => group.id === this.defaultGroupSelected);
    const dialogRef = this.dialog.open(ChangesConfirmationPopupComponent, {
      data: {
        popUpTitle: 'Information',
        popUpDescription: `From now the new devices will be automatically added to ${groupSelected?.name}.
        <br> <br> Do you really want to change it ?`
      },
      width: '330px',
      panelClass: 'changes-confirmation-popup',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((action: 'accept' | null) => {
      if (action === 'accept') {
        //Call the backend end point
        this.devicesGroupsService.postDefaultGroup(this.accountId, 'all', this.defaultGroupSelected).subscribe(
          (response) => this.toasterService.showInfoToaster('Default group changed successfully'),
          (error) => this.toasterService.showWarnToaster(error?.error?.detail || error?.error?.title || error)
        );
      }
    });
  }
}
