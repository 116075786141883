import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { environment } from '@env/environment';
import { AgGridModule } from 'ag-grid-angular';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxMaskModule } from 'ngx-mask';
import {MatRadioModule} from '@angular/material/radio';
import {
  AccountsService,
  ACCOUNTS_API_BASE_URL,
  InstallerLocationsService,
  InstallersService,
  WorkspacesService
} from '../services/accounts.service';
import { AssetsV3Service, AccountWorkspacesV3Service, ASSETS_V3_API_BASE_URL } from '../services/assets-v3.service';
import { AssetGroupsService, AssetRailcarService, AssetsService,  ASSETS_API_BASE_URL } from '../services/assets.service';
import { DeviceGroupsService, DevicesService, DEVICES_API_BASE_URL } from '../services/devices.service';
import { RailSightService, RAILSIGHT_API_BASE_URL } from '../services/railsight.service';
import { Rsrd2Service, RSRD2_API_BASE_URL } from '../services/rsrd2.service';
import { InstallerAppAccountsService, USERS_API_BASE_URL } from '../services/users.service';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';
import { SharedModule } from '../shared/shared.module';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountFormComponent } from './account-form/account-form.component';
import { AccountViewComponent } from './account-view/account-view.component';
import { AccountAssetsTabComponent } from './account-view/tabs/account-assets-tab/account-assets-tab.component';
import { AccountDetailsTabComponent } from './account-view/tabs/account-details-tab/account-details-tab.component';
import { AssetsGroupsTableComponent } from './account-view/tabs/account-details-tab/assets-groups-table/assets-groups-table.component';
import { DeviceGroupsTableComponent } from './account-view/tabs/account-details-tab/device-groups-table/device-groups-table.component';
import { UserGroupsTableComponent } from './account-view/tabs/account-details-tab/user-groups-table/user-groups-table.component';
import { AccountDevicesTabComponent } from './account-view/tabs/account-devices-tab/account-devices-tab.component';
import {
  AccountInstallersTabComponent,
  DialogSelectQuantityComponent
} from './account-view/tabs/account-installers-tab/account-installers-tab.component';
import { AccountRailSightTabComponent } from './account-view/tabs/account-railsight-tab/account-railsight-tab.component';
// tslint:disable-next-line: max-line-length
import { AgGridRailSightCLMEventsComponent } from './account-view/tabs/account-railsight-tab/ag-grid-railsight-clm-events/ag-grid-railsight-clm-events.component';
// tslint:disable-next-line: max-line-length
import { AgGridRailSightFleetAssetsComponent } from './account-view/tabs/account-railsight-tab/ag-grid-railsight-fleet-assets/ag-grid-railsight-fleet-assets.component';
import { AccountRSRD2TabComponent } from './account-view/tabs/account-rsrd2-tab/account-rsrd2-tab.component';
import { AgGridRSRD2Component } from './account-view/tabs/account-rsrd2-tab/ag-grid/ag-grid.component';
import { AccountUsersTabComponent } from './account-view/tabs/account-users-tab/account-users-tab.component';
import { AccountWorkspacesTabComponent } from './account-view/tabs/account-workspaces-tab/account-workspaces-tab.component';
import { AccountSharingTabComponent } from './account-view/tabs/account-sharing-tab/account-sharing-tab.component';
import { WorkspaceNewComponent } from './account-view/tabs/account-workspaces-tab/workspace-new/workspace-new.component';
import { WorkspaceViewComponent } from './account-view/tabs/account-workspaces-tab/workspace-view/workspace-view.component';
import { SharingViewComponent } from './account-view/tabs/account-sharing-tab/sharing-view/sharing-view.component';
import { AccountsRoutingModule } from './accounts-routing.module';
import { AccountsComponent } from './accounts.component';
import { AssetGroupUnassignedComponent } from './asset-groups-unassigned/asset-groups-unassigned.component';
import { AssetGroupsComponent, AssetsDeactivateGuard } from './asset-groups/asset-groups.component';
import { DeviceGroupEditComponent, DevicesDeactivateGuard } from './device-groups/device-group.component';
import { AddInstallerLocationsComponent } from './installer-locations/add-installer-locations/add-installer-locations.component';
import { EditInstallerLocationsComponent } from './installer-locations/edit-installer-locations/edit-installer-locations.component';
import { InstallerLocationsComponent } from './installer-locations/installer-locations.component';
import { UserGroupsComponent, UsersDeactivateGuard } from './user-groups/user-groups.component';
import { AccountDevicesGroupsTabComponent } from './account-view/tabs/account-devices-groups-tab/account-devices-groups-tab.component';
import { DevicesGroupsService, DEVICES_GROUPS_API_BASE_URL } from '../services/devices-groups.service';
import { SHIPMENTS_V2_API_BASE_URL, TripsService } from '../services/shipments-v2.service';
import { AddDeviceGroupComponent } from './account-view/tabs/account-devices-groups-tab/add-device-group/add-device-group.component';
import { DeviceValidatorDirective } from './account-view/tabs/account-devices-groups-tab/device-validator.directive';
import { DevicesSelectionComponent } from './account-view/tabs/account-devices-groups-tab/devices-selection/devices-selection.component';
import { ChangesConfirmationPopupComponent } from './account-view/tabs/account-devices-groups-tab/changes-confirmation-popup/changes-confirmation-popup.component';
import { MoveDeviceGroupComponent } from './account-view/tabs/account-devices-groups-tab/move-device-group/move-device-group.component';
import { ShareToAccountDialogComponent } from './account-view/tabs/account-sharing-tab/share-to-account-dialog-component/share-to-account-dialog.component'
import { ShareAssetsDialogComponent } from './account-view/tabs/account-sharing-tab/sharing-view/share-assets-dialog-component/share-assets-dialog.component'


@NgModule({
  imports: [
    CommonModule,
    AccountsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CommonUiSharedModule,
    NgxMaskModule,
    AgGridModule.withComponents([]),
    NgxJsonViewerModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTableModule,
    HighchartsChartModule,
    MatRadioModule
  ],
  declarations: [
    AccountsComponent,
    AccountFormComponent,
    AccountEditComponent,
    AccountViewComponent,
    AccountDevicesTabComponent,
    AccountAssetsTabComponent,
    AccountUsersTabComponent,
    AccountInstallersTabComponent,
    AccountDetailsTabComponent,
    DeviceGroupEditComponent,
    DeviceGroupsTableComponent,
    AssetsGroupsTableComponent,
    AssetGroupsComponent,
    DialogSelectQuantityComponent,
    UserGroupsTableComponent,
    UserGroupsComponent,
    AccountRSRD2TabComponent,
    AgGridRSRD2Component,
    AccountRailSightTabComponent,
    AgGridRailSightCLMEventsComponent,
    AgGridRailSightFleetAssetsComponent,
    AssetGroupUnassignedComponent,
    AccountWorkspacesTabComponent,
    AccountSharingTabComponent,
    WorkspaceNewComponent,
    WorkspaceViewComponent,
    SharingViewComponent,
    InstallerLocationsComponent,
    AddInstallerLocationsComponent,
    EditInstallerLocationsComponent,
    AccountDevicesGroupsTabComponent,
    AddDeviceGroupComponent,
    DeviceValidatorDirective,
    DevicesSelectionComponent,
    ChangesConfirmationPopupComponent,
    MoveDeviceGroupComponent,
    ShareToAccountDialogComponent,
    ShareAssetsDialogComponent
  ],
  entryComponents: [DialogSelectQuantityComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AccountsService,
    { provide: ACCOUNTS_API_BASE_URL, useValue: environment.accountsBaseUrl },
    AssetRailcarService,
    WorkspacesService,
    { provide: ACCOUNTS_API_BASE_URL, useValue: environment.accountsBaseUrl },
    AssetsService,
    { provide: ASSETS_API_BASE_URL, useValue: environment.assetsBaseUrl },
    AccountWorkspacesV3Service,
    AssetsV3Service,
    { provide: ASSETS_V3_API_BASE_URL, useValue: environment.assetsV3BaseUrl },
    AssetGroupsService,
    DevicesService,
    { provide: DEVICES_API_BASE_URL, useValue: environment.devicesBaseUrl },
    DevicesGroupsService,
    { provide: DEVICES_GROUPS_API_BASE_URL, useValue: environment.devicesBaseUrl },
    DeviceGroupsService,
    { provide: DEVICES_API_BASE_URL, useValue: environment.devicesBaseUrl },
    InstallerAppAccountsService,
    { provide: USERS_API_BASE_URL, useValue: environment.usersBaseUrl },
    InstallersService,
    { provide: ACCOUNTS_API_BASE_URL, useValue: environment.accountsBaseUrl },
    InstallerLocationsService,
    { provide: ACCOUNTS_API_BASE_URL, useValue: environment.accountsBaseUrl },
    Rsrd2Service,
    { provide: RSRD2_API_BASE_URL, useValue: environment.rsrd2BaseUrl },
    RailSightService,
    { provide: RAILSIGHT_API_BASE_URL, useValue: environment.railsightBaseUrl },
    TripsService,
    { provide: SHIPMENTS_V2_API_BASE_URL, useValue: environment.shipmentsV2BaseUrl },
    DevicesDeactivateGuard,
    AssetsDeactivateGuard,
    UsersDeactivateGuard
  ]
})
export class AccountsModule {}
