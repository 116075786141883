import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AccountWorkspaceAssetsDeviceV3Service, ReplaceDeviceDto } from '../../services/assets-v3.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToasterNotificationService } from '../../services/toasterNotification.service';
import { FeatureFlagService } from '../../services/feature-flag.service';

@Component({
  selector: 'ads-message-dialog',
  templateUrl: 'account-asset-replace-device.component.html',
  styleUrls: ['./account-asset-replace-device.component.scss']
})
export class AccountAssetReplaceDeviceComponent implements OnInit {
  assetReplaceDevice: FormGroup;
  accountId: number;
  assetId: string;
  newDeviceCtrl = new FormControl();
  bogieIQDeviceActive = false;
  deviceToReplace: string;
  installedDevices: string[];
  allowReplace = false;
  public replaceDeviceDto: ReplaceDeviceDto = new ReplaceDeviceDto();

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private accountWorkspaceAssetsDeviceV3Service: AccountWorkspaceAssetsDeviceV3Service,
    private formBuilder: FormBuilder,
    private toasterService: ToasterNotificationService,
    private featureFlagService: FeatureFlagService,
    private dialogRef: MatDialogRef<AccountAssetReplaceDeviceComponent>) {
      if (data) {
        this.accountId = data.accountId;
        this.assetId = data.assetId;
        this.installedDevices = data.installedDevices;
      }
  }

  ngOnInit() {
    this.assetReplaceDevice = this.formBuilder.group({
      oldDeviceSerialNumber: "",
      location: null,
      side: null
    });

    this.featureFlagService.assetsPaaSDeviceInstallsEnabled.then((treatment) => {
      this.allowReplace = treatment === 'on';
    });
  }

  deviceIsBogieIq() {
    return this.newDeviceCtrl.value != null && this.newDeviceCtrl.value.toString().startsWith('B');
  }

  onSubmit() {
    if(!this.allowReplace) {
      this.toasterService.showWarnToaster("Device install feature not enabled");
    }
    this.replaceDeviceDto.newDeviceSerialNumber = this.newDeviceCtrl.value;
    this.replaceDeviceDto.oldDeviceSerialNumber = this.deviceToReplace;
    this.replaceDeviceDto.location = this.assetReplaceDevice.value.location;
    this.replaceDeviceDto.side = this.assetReplaceDevice.value.side;
    this.accountWorkspaceAssetsDeviceV3Service.replaceDevice(this.accountId, "all", this.assetId, "Ops Portal", this.replaceDeviceDto)
      .subscribe(() => {
        this.dialogRef.close(true);
      },
      error => {
        this.handleErrorResponse(error);
      }
    );
  }

  handleErrorResponse(error) {
    var errorResponse = JSON.parse(error.response);
    var errorResponseToDisplay = "";
    if(errorResponse.errors != null) {
      errorResponseToDisplay = errorResponse.errors;
    } else{
      errorResponseToDisplay = errorResponse == null || errorResponse == undefined ? error.detail : errorResponse;
    }
    this.toasterService.showWarnToaster(JSON.stringify(errorResponseToDisplay));
  }

  cancel(): void {
      this.dialogRef.close(false);
  }
}
