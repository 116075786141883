<mat-card>
  <mat-card-title>
    Edit User
  </mat-card-title>
  <mat-card-subtitle>
    Edit SCV user settings
  </mat-card-subtitle>
  <mat-card-content>
    <form [formGroup]="editUserForm" (ngSubmit)="onSave()">
      <div class="example-container">
        <mat-checkbox id="user-bypassMfa" formControlName="bypassMfa" [(ngModel)]="user.bypassMfa">Bypass MFA</mat-checkbox>
        
        <mat-form-field>
          <input id='usr-firstname' matInput formControlName="firstName" [(ngModel)]="user.firstName" maxlength="100"
            placeholder="First Name" required>
          <ads-control-messages [control]='editUserForm.get("firstName")'></ads-control-messages>
        </mat-form-field>

        <mat-form-field>
          <input id='usr-lastname' matInput formControlName="lastName" [(ngModel)]="user.lastName" maxlength="100"
            placeholder="Last Name" required>
          <ads-control-messages [control]='editUserForm.get("lastName")'></ads-control-messages>
        </mat-form-field>

        <ads-account-select-search id='usr-account' ngDefaultControl placeholder="Account"
          [formControl]="accountControl" [allowEmptySelection]="false">
        </ads-account-select-search>

        <ads-pages-and-features id='usr-features' ngDefaultControl [pagesFeaturesControl]="featuresControl"
          [roleType]="1" placeholder="Features">
        </ads-pages-and-features>

        <ads-pages-and-features id='usr-pages' ngDefaultControl [pagesFeaturesControl]="pagesControl" [roleType]="2"
          placeholder="Pages">
        </ads-pages-and-features>

        <mat-form-field>
          <input id='usr-mobilePhone' matInput formControlName="mobilePhone" [(ngModel)]="user.mobilePhone"
            placeholder="Mobile Phone">
        </mat-form-field>

        <mat-form-field>
          <input id='usr-dateDisplayFormat' matInput formControlName="dateDisplayFormat"
            [(ngModel)]="user.dateDisplayFormat" placeholder="Date Display Format">
        </mat-form-field>

        <mat-form-field>
          <input id='usr-dateTimeDisplayFormat' matInput formControlName="dateTimeDisplayFormat"
            [(ngModel)]="user.dateTimeDisplayFormat" placeholder="Date Time Display Format">
        </mat-form-field>

        <mat-form-field>
          <input id='usr-lengthUnitOfMeasure' matInput formControlName="lengthUnitOfMeasure"
            [(ngModel)]="user.lengthUnitOfMeasure" placeholder="Length Unit of Measure">
        </mat-form-field>

        <mat-form-field>
          <input id='usr-tankVolumeUnitOfMeasure' matInput formControlName="tankVolumeUnitOfMeasure"
            [(ngModel)]="user.tankVolumeUnitOfMeasure" placeholder="Tank Volume Unit of Measure">
        </mat-form-field>

        <mat-form-field>
          <input id='usr-loadingDimensionsUnitOfMeasure' matInput formControlName="loadingDimensionsUnitOfMeasure"
            [(ngModel)]="user.loadingDimensionsUnitOfMeasure" placeholder="Loading Dimensions Unit of Measure">
        </mat-form-field>

        <mat-form-field>
          <input id='usr-weightUnitOfMeasure' matInput formControlName="weightUnitOfMeasure"
            [(ngModel)]="user.weightUnitOfMeasure" placeholder="Weight Unit of Measure">
        </mat-form-field>

        <mat-form-field>
          <input id='usr-jobTitle' matInput formControlName="jobTitle" [(ngModel)]="user.jobTitle"
            placeholder="Job Title">
        </mat-form-field>

      </div>
    </form>
  </mat-card-content>
  <mat-card-actions align="end">
    <button id='usr-cancelbutton' type="button" mat-stroked-button adsBackbutton>Cancel</button>
    <button id='usr-savebutton' mat-flat-button color="primary" (click)="onSave()">Save</button>
  </mat-card-actions>
</mat-card>