import { Component, OnInit, ViewChild, Input, OnDestroy, AfterViewInit, forwardRef, SimpleChanges, OnChanges } from '@angular/core';
import { DeviceSearchDto, DevicesService } from '../../services/devices.service';
import { FormControl, NG_VALIDATORS, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReplaySubject, Subject, Observable } from 'rxjs';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { takeUntil } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ads-device-select-search',
  templateUrl: './device-select-search.component.html',
  styleUrls: ['./device-select-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeviceSelectSearchComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DeviceSelectSearchComponent),
      multi: true
    }
  ]
})
export class DeviceSelectSearchComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit, ControlValueAccessor {
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @Input() accountId = 0;
  @Input() label: string;

  public deviceSearchControl: FormControl = new FormControl();
  protected devices: DeviceSearchDto[];
  public filteredDevices: ReplaySubject<DeviceSearchDto[]> = new ReplaySubject<DeviceSearchDto[]>(1);
  protected onDestroy = new Subject<void>();
  public disableSelect = true;
  public placeholder: string;
  private searchAccountId: number;
  private apiSearchValue: string = '';

  constructor(private devicesService: DevicesService) {

  }

  ngOnInit() {
    if (this.accountId > 0) {
      this.searchDevices('');
    } else {
      this.placeholder = 'Please select an Owner Account before selecting a Device';
    }

    // listen for search field value changes
    this.deviceSearchControl.valueChanges
      .pipe(debounceTime(500))
      .pipe(takeUntil(this.onDestroy))
      .subscribe((resp) => {
        this.filterDevices(resp);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.accountId > 0) {
      this.searchDevices('');

      if (this.searchAccountId !== undefined && this.accountId !== this.searchAccountId) {
        this.singleSelect.value = '';
        this.onChange(0);
        this.onTouched();
      }
    }
  }

  searchDevices(searchValue: string) {
    this.devicesService.unattached(this.accountId, 'all', searchValue).subscribe(devices => {
      this.searchAccountId = this.accountId;

      this.devices = [
        ...devices
      ];

      // Occasionally the user's current selection will already be in the list if they recently
      // unattached the same one. Check for this case and if it's not there, add the asset's
      // selected device
      // KMS 25 NOV 2020
      if (this.singleSelect.value && !this.devices.find(x => x.serialNumber === this.singleSelect.value)) {
        this.devices.push(new DeviceSearchDto({ serialNumber: this.singleSelect.value }));
      }

      // The Unattach Option
      this.devices.push(new DeviceSearchDto());

      this.filteredDevices.next(this.devices.slice());

      if (searchValue === '') {
        if (devices.length > 0) {
          this.disableSelect = false;
          this.placeholder = 'No Installed Device';
        } else {
          this.disableSelect = true;
          this.placeholder = 'No Unattached Devices';
        }
      }
    });
  }

  onSelectionChanged(event: MatSelectChange) {
    this.onChange(event.value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(obj: any): void {
    if (obj) {
      this.singleSelect.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  validate({ serialNumber }: DeviceSearchDto) {
    return serialNumber !== '';
  }

  ngAfterViewInit() { }

  onSelectionChange(deviceId: number) { }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  protected filterDevices(searchValue: string) {
    if (!this.devices) {
      return;
    }

    if (!searchValue && this.apiSearchValue === '') {
      this.filteredDevices.next(this.devices.slice());
      return;
    } else if(!searchValue && this.apiSearchValue !== '') {
      this.apiSearchValue = '';
      this.searchDevices('');
    }

    var matchingDevices = 0;

    // filter the devices
    this.filteredDevices.next(
      this.devices.filter(device => {
        if (!device.serialNumber) {
          return false;
        }

        if(device.serialNumber.toLowerCase().startsWith(searchValue.toLocaleLowerCase()))
        {
          matchingDevices++;
          return true;
        } else {
          return false;
        }
      })
    );

    if(!matchingDevices) {
      this.apiSearchValue = searchValue;
      this.searchDevices(searchValue);
    }
  }
}
