/*
  This service looks like it was generated, then manually modified, and (bonus) looks like it was even built to be coupled to a grid on a page.

*/

import { Injectable, InjectionToken, Inject, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponseBase, HttpResponse } from '@angular/common/http';
import { Observable, Subject, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';

export const RAILSIGHT_API_BASE_URL = new InjectionToken<string>('RAILSIGHT_API_BASE_URL');

export interface IRailSightService {
  checkFileValid(file: any);
  getAccountAssets(accountId?: number);
  getAccountFleets(accountId?: number);
  getAccountReceiverFleets(accountId?: number);
  getAccountFleet(accountId?: number, fleetName?: string);
  getAccountReceiverFleet(accountId?: number, fleetName?: string);
  getAccountFleetAssets(accountId?: number, fleetName?: string);
  getFleetTypes();
  getHistoricalRailSightCLMEvents(accountId?: number, fleetName?: string, startDate?: Date, endDate?: Date);
  getHistoricalRailSightWaybillEvents(accountId?: number, fleetName?: string, startDate?: Date, endDate?: Date);
  getLatestRailSightCLMEvents(accountId?: number, fleetName?: string);
  getCLMById(accountId: number, id: number): Observable<any>;
  getWaybillById(accountId: number, id: string): Observable<any>;
  getSasToken();
  processFiles(fileNames: string[]);
  getStatus(fileName?: string);
  getWaybill(accountId?: number, asset?: string, waybillDate?: string, createdDate?: string): Observable<ArrayBuffer>;
  addAccountFleet(accountId?: number, fleetName?: string, fleetType?: string);
  addAccountReceiverFleet(accountId?: number, fleetName?: string, fleetNameIsa?: string, fleetNameGs?: string, fleetType?: string);
  updateAccountFleet(accountId?: number, fleetName?: string, body?: Operation[] | undefined);
  updateAccountReceiverFleet(accountId?: number, fleetName?: string, fleetNameIsa?: string,
    fleetNameGs?: string, body?: Operation[] | undefined);
  updateAccountFleetCLU(accountId?: number, fleetName?: string);
  uploadAccountFleetCLU(accountId?: number);
  waybillDates(query?: string | null | undefined, accountId?: number | null | undefined,
    fleetName?: string | null | undefined, pageNumber?: number | undefined,
    pageSize?: number | undefined);
  emitOnDownloadButtonSelected(change: null);
  emitOnUploadCLUButtonSelected(change: null);
  emitOnResetAssetsGridSelected(change: null);
  emitOnSelectedRowsSelected(change: null);
  emitOnUnselectedRowsSelected(change: null);
  emitOnAllRowsSelected(change: null);
  yymmddhh();
}

@Injectable({
  providedIn: 'root'
})
export class RailSightService implements IRailSightService {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  // Currently selected fleet
  accountFleet;

  // Assets assigned to the currently selected fleet
  accountFleetAssetNames: string[];

  // Assets that have been selected to be included in the fleet
  selectedFleetAssetNames: string[];

  exportEnabled = true;

  private triggerDownload = new Subject<null>();
  public triggerDownload$: Observable<null> = this.triggerDownload;

  private triggerResetAssetsGrid = new Subject<null>();
  public triggerResetAssetsGrid$: Observable<null> = this.triggerResetAssetsGrid;

  private triggerSaveSelectedAssets = new Subject<null>();
  public triggerSaveSelectedAssets$: Observable<null> = this.triggerSaveSelectedAssets;

  private triggerSelectedRowsToggle = new Subject<null>();
  public triggerSelectedRowsToggle$: Observable<null> = this.triggerSelectedRowsToggle;

  private triggerUnselectedRowsToggle = new Subject<null>();
  public triggerUnselectedRowsToggle$: Observable<null> = this.triggerUnselectedRowsToggle;

  private triggerAllRowsToggle = new Subject<null>();
  public triggerAllRowsToggle$: Observable<null> = this.triggerAllRowsToggle;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(RAILSIGHT_API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : '';
    this.accountFleetAssetNames = [];
  }

  getAccountAssets(accountId?: number) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('The parameter \'accountId\' must be defined.');
    }

    let url = this.baseUrl + '/api/v2/fleet/accountassets';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams()
        .set('accountId', accountId.toString()),
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('GET', url, options);
  }

  getAccountFleets(accountId?: number) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('The parameter \'accountId\' must be defined.');
    }

    let url = this.baseUrl + '/api/v2/fleet/accountfleets';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams()
        .set('accountId', accountId.toString()),
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('GET', url, options);
  }

  getAccountReceiverFleets(accountId?: number) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('The parameter \'accountId\' must be defined.');
    }

    let url = this.baseUrl + '/api/v2/fleet/accountreceiverfleets';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams()
        .set('accountId', accountId.toString()),
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('GET', url, options);
  }

  getAccountFleet(accountId?: number, fleetName?: string) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('The parameter \'accountId\' must be defined.');
    }

    if (fleetName === undefined || fleetName === null) {
      return _observableThrow('The parameter \'fleetName\' must be defined.');
    }

    let url = this.baseUrl + '/api/v2/fleet/accountfleet';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams()
        .set('accountId', accountId.toString())
        .set('fleetName', fleetName),
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('GET', url, options);
  }

  getAccountReceiverFleet(accountId?: number, fleetName?: string, fleetNameIsa?: string, fleetNameGs?: string) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('The parameter \'accountId\' must be defined.');
    }

    if (fleetName === undefined || fleetName === null) {
      return _observableThrow('The parameter \'fleetName\' must be defined.');
    }

    if (fleetNameIsa === undefined || fleetNameIsa === null) {
      return _observableThrow('The parameter \'fleetNameIsa\' must be defined.');
    }

    if (fleetNameGs === undefined || fleetNameGs === null) {
      return _observableThrow('The parameter \'fleetNameGs\' must be defined.');
    }

    let url = this.baseUrl + '/api/v2/fleet/accountreceiverfleet';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams()
        .set('accountId', accountId.toString())
        .set('fleetName', fleetName)
        .set('fleetNameIsa', fleetNameIsa)
        .set('fleetNameGs', fleetNameGs),
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('GET', url, options);
  }

  getAccountFleetAssets(accountId?: number, fleetName?: string) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('The parameter \'accountId\' must be defined.');
    }

    if (fleetName === undefined || fleetName === null) {
      return _observableThrow('The parameter \'fleetName\' must be defined.');
    }

    let url = this.baseUrl + '/api/v2/fleet/accountfleetassets';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams()
        .set('accountId', accountId.toString())
        .set('fleetName', fleetName),
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('GET', url, options);
  }

  getFleetTypes() {

    let url = this.baseUrl + '/api/v2/fleet/fleettypes';
    url = url.replace(/[?&]$/, '');

    return this.http.request('GET', url);
  }

  addAccountFleet(accountId?: number, fleetName?: string, fleetType?: string) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('\'accountId\' must be defined.');
    }
    if (fleetName === undefined || fleetName === null) {
      return _observableThrow('\'fleetName\' must be defined.');
    }
    if (fleetType === undefined || fleetType === null) {
      return _observableThrow('\'fleetType\' must be defined.');
    }

    // Create new fleet
    this.accountFleet = {
      accountId,
      fleetName,
      fleetType,
      clu: ''
    };

    // Call the API to save the fleet
    let url = this.baseUrl + '/api/v2/fleet/accountfleet';
    url = url.replace(/[?&]$/, '');

    const content = JSON.stringify(this.accountFleet);

    const options: any = {
      body: content,
      observe: 'response',
      responseType: 'text',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    return this.http.request('POST', url, options);
  }

  addAccountReceiverFleet(accountId?: number, fleetName?: string, fleetNameIsa?: string, fleetNameGs?: string, fleetType?: string) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('\'accountId\' must be defined.');
    }
    if (fleetName === undefined || fleetName === null) {
      return _observableThrow('\'fleetName\' must be defined.');
    }
    if (fleetNameIsa === undefined || fleetNameIsa === null) {
      return _observableThrow('\'fleetNameIsa\' must be defined.');
    }
    if (fleetNameGs === undefined || fleetNameGs === null) {
      return _observableThrow('\'fleetNameGs\' must be defined.');
    }
    if (fleetType === undefined || fleetType === null) {
      return _observableThrow('\'fleetType\' must be defined.');
    }

    // Create new fleet
    const accountReceiverFleet = {
      accountId,
      receiver: fleetName,
      senderISA06: fleetNameIsa,
      senderGS: fleetNameGs,
      fleetType
    };

    // Call the API to save the fleet
    let url = this.baseUrl + '/api/v2/fleet/accountreceiverfleet';
    url = url.replace(/[?&]$/, '');

    const content = JSON.stringify(accountReceiverFleet);

    const options: any = {
      body: content,
      observe: 'response',
      responseType: 'text',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    return this.http.request('POST', url, options);
  }

  updateAccountFleet(accountId?: number, fleetName?: string, body?: Operation[] | undefined) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('\'accountId\' must be defined.');
    }
    if (fleetName === undefined || fleetName === null) {
      return _observableThrow('\'fleetName\' must be defined.');
    }

    // Call the API to save the fleet
    let url = this.baseUrl + '/api/v2/fleet/accountfleet';
    url = url.replace(/[?&]$/, '');

    const content = JSON.stringify(body);

    const options: any = {
      params: new HttpParams()
        .set('accountId', accountId.toString())
        .set('fleetName', fleetName),
      body: content,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json-patch+json',
        Accept: 'application/json'
      })
    };

    return this.http.request('PATCH', url, options);
  }

  updateAccountReceiverFleet(accountId?: number, fleetName?: string,
    fleetNameIsa?: string, fleetNameGs?: string, body?: Operation[] | undefined) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('\'accountId\' must be defined.');
    }
    if (fleetName === undefined || fleetName === null) {
      return _observableThrow('\'fleetName\' must be defined.');
    }
    if (fleetNameIsa === undefined || fleetNameIsa === null) {
      return _observableThrow('\'fleetNameIsa\' must be defined.');
    }
    if (fleetNameGs === undefined || fleetNameGs === null) {
      return _observableThrow('\'fleetNameGs\' must be defined.');
    }

    // Call the API to save the fleet
    let url = this.baseUrl + '/api/v2/fleet/accountreceiverfleet';
    url = url.replace(/[?&]$/, '');

    const content = JSON.stringify(body);

    const options: any = {
      params: new HttpParams()
        .set('accountId', accountId.toString())
        .set('fleetName', fleetName)
        .set('fleetNameIsa', fleetNameIsa)
        .set('fleetNameGs', fleetNameGs),
      body: content,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json-patch+json',
        Accept: 'application/json'
      })
    };

    return this.http.request('PATCH', url, options);
  }

  updateAccountFleetCLU(accountId?: number, fleetName?: string) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('\'accountId\' must be defined.');
    }
    if (fleetName === undefined || fleetName === null) {
      return _observableThrow('\'fleetName\' must be defined.');
    }

    // Create new CLU for selected assets
    this.accountFleet.clu = 'CLU TO RRDC ' + fleetName.padEnd(9) + '\n';

    // Add selected assets to CLU
    this.selectedFleetAssetNames.forEach(selected => {
      this.accountFleet.clu += 'P' + selected + ' \n';
    });

    // Add any deselected assets to CLU for deletion
    this.accountFleetAssetNames.forEach(existing => {
      if (!this.selectedFleetAssetNames.includes(existing)) {
        this.accountFleet.clu += 'D' + existing + ' \n';
      }
    });

    // End the CLU
    this.accountFleet.clu += 'EOM\n';

    const accountFleetPatch: Operation[] = [
      new Operation({ op: 'replace', path: '/CLU', value: this.accountFleet.clu })
    ];

    // Call the API to save the new fleet
    let url = this.baseUrl + '/api/v2/fleet/accountfleet';
    url = url.replace(/[?&]$/, '');

    const content = JSON.stringify(accountFleetPatch);

    const options: any = {
      params: new HttpParams()
        .set('accountId', accountId.toString())
        .set('fleetName', fleetName),
      body: content,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json-patch+json',
        Accept: 'application/json'
      })
    };

    return this.http.request('PATCH', url, options);
  }

  uploadAccountFleetCLU(accountId?: number) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('\'accountId\' must be defined.');
    }

    if (this.accountFleet.clu === undefined || this.accountFleet.clu === null) {
      return _observableThrow('\'clu\' has not been created.');
    }

    // Create the CLU request
    const cluRequest = new CLURequestDto({
      accountId,
      clu: ''
    });

    cluRequest.clu = this.accountFleet.clu;

    // Call the API to upload the CLU request
    let url = this.baseUrl + '/api/v2/railsightevent/clurequest';
    url = url.replace(/[?&]$/, '');

    const content = JSON.stringify(cluRequest);

    const options: any = {
      body: content,
      observe: 'response',
      responseType: 'text',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    return this.http.request('POST', url, options);
  }

  getHistoricalRailSightCLMEvents(accountId?: number, fleetName?: string, startDate?: Date, endDate?: Date) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('The parameter \'accountId\' must be defined.');
    }

    if (fleetName === undefined || fleetName === null) {
      return _observableThrow('The parameter \'fleetName\' must be defined.');
    }

    if (startDate === undefined || startDate === null) {
      return _observableThrow('The parameter \'startDate\' must be defined.');
    }

    if (endDate === undefined || endDate === null) {
      endDate = new Date();
    }

    let url = this.baseUrl + '/api/v2/railsightevent/historicalclmevents';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams()
        .set('accountId', accountId.toString())
        .set('fleetName', fleetName)
        .set('startDate', startDate.toJSON())
        .set('endDate', endDate.toJSON()),
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('GET', url, options);
  }

  getLatestRailSightCLMEvents(accountId?: number, fleetName?: string) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('The parameter \'accountId\' must be defined.');
    }

    if (fleetName === undefined || fleetName === null) {
      return _observableThrow('The parameter \'fleetName\' must be defined.');
    }

    let url = this.baseUrl + '/api/v2/railsightevent/latestclmevents';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams()
        .set('accountId', accountId.toString())
        .set('fleetName', fleetName),
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('GET', url, options);
  }

  getWaybill(accountId?: number, asset?: string, waybillDate?: string, createdDate?: string): Observable<ArrayBuffer> {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('The parameter \'accountId\' must be defined.');
    }

    if (asset === undefined || asset === null) {
      return _observableThrow('The parameter \'asset\' must be defined.');
    }

    if (waybillDate === undefined || waybillDate === null) {
      return _observableThrow('The parameter \'waybillDate\' must be defined.');
    }

    if (createdDate === undefined || createdDate === null) {
      return _observableThrow('The parameter \'createdDate\' must be defined.');
    }

    let url = this.baseUrl + '/api/v2/waybill/waybillforassetbydate';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams()
        .set('accountId', accountId.toString())
        .set('asset', asset)
        .set('waybillDate', waybillDate)
        .set('createdDate', createdDate),
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('GET', url, options);
  }

  getCLMById(accountId: number, id: number): Observable<any> {

    let url = this.baseUrl + `/api/v2/railsightevent/CLMbyId?accountId=${accountId}&id=${id}`;

    return (this.http.get(url));
  }

  getWaybillById(accountId: number, id: string): Observable<any> {

    let url = this.baseUrl + `/api/v2/waybill/WaybillById?accountId=${accountId}&id=${id}`;

    return (this.http.get<{ items: any[] }>(url)
      .map(x => x.items[0])
    );
  }

  /**
   * @param query (optional)
   * @param accountId (optional)
   * @param isActive (optional)
   * @param pageNumber (optional)
   * @param pageSize (optional)
   * @return Success
   */
  waybillDates(query?: string | null | undefined, accountId?: number | null | undefined,
    fleetName?: string | null | undefined, pageNumber?: number | undefined,
    pageSize?: number | undefined): Observable<WaybillDateDtoWaybillDatePagedListDto> {

    let url = this.baseUrl + '/api/v2/waybill/waybilldatesforaccountassets?';
    if (query !== undefined && query !== null) {
      url += 'query=' + encodeURIComponent('' + query) + '&';
    }
    if (accountId === undefined || accountId === null) {
      return _observableThrow('The parameter \'accountId\' must be defined.');
    } else {
      url += 'accountId=' + encodeURIComponent('' + accountId) + '&';
    }
    if (fleetName) {
      url += 'fleetName=' + encodeURIComponent('' + fleetName) + '&';
    }
    if (pageNumber === null) {
      return _observableThrow('The parameter \'pageNumber\' cannot be null.');
    } else if (pageNumber !== undefined) {
      url += 'pageNumber=' + encodeURIComponent('' + pageNumber) + '&';
    }
    if (pageSize === null) {
      return _observableThrow('The parameter \'pageSize\' cannot be null.');
    } else if (pageSize !== undefined) {
      url += 'pageSize=' + encodeURIComponent('' + pageSize) + '&';
    }
    url = url.replace(/[?&]$/, '');

    const options: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('get', url, options).pipe(_observableMergeMap((response: any) => {
      return this.processWaybillDates(response);
    })).pipe(_observableCatch((response: any) => {
      if (response instanceof HttpResponseBase) {
        try {
          return this.processWaybillDates(response as any);
        } catch (e) {
          return _observableThrow(e) as any as Observable<WaybillDateDtoWaybillDatePagedListDto>;
        }
      } else {
        return _observableThrow(response) as any as Observable<WaybillDateDtoWaybillDatePagedListDto>;
      }
    }));
  }

  protected processWaybillDates(response: HttpResponseBase): Observable<WaybillDateDtoWaybillDatePagedListDto> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (response as any).error instanceof Blob ? (response as any).error : undefined;

    const headers: any = {};
    if (response.headers) {
      for (const key of response.headers.keys()) {
        headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(responseText => {
        let result200: any = null;
        const resultData200 = responseText === '' ? null : JSON.parse(responseText, this.jsonParseReviver);
        result200 = WaybillDateDtoWaybillDatePagedListDto.fromJS(resultData200);
        return _observableOf(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(responseText => {
        return throwException('An unexpected server error occurred.', status, responseText, headers);
      }));
    }
    return _observableOf<WaybillDateDtoWaybillDatePagedListDto>(null as any);
  }

  getHistoricalRailSightWaybillEvents(accountId?: number, fleetName?: string, startDate?: Date, endDate?: Date) {
    if (accountId === undefined || accountId === null) {
      return _observableThrow('The parameter \'accountId\' must be defined.');
    }

    if (fleetName === undefined || fleetName === null) {
      return _observableThrow('The parameter \'fleetName\' must be defined.');
    }

    if (startDate === undefined || startDate === null) {
      return _observableThrow('The parameter \'startDate\' must be defined.');
    }

    if (endDate === undefined || endDate === null) {
      endDate = new Date();
    }

    let url = this.baseUrl + '/api/v2/waybill/historicalwaybillevents';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams()
        .set('accountId', accountId.toString())
        .set('fleetName', fleetName)
        .set('startDate', startDate.toJSON())
        .set('endDate', endDate.toJSON()),
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('GET', url, options);
  }

  // Check file validity
  checkFileValid(file: any) {
    const validExtensions: string[] = ['bol', 'csv', 'hbol', 'txt'];
    let result = false;

    validExtensions.forEach(ext => {
      if (file.name.endsWith(ext)) {
        result = true;
      }
    });

    return result;
  }

  // Sas token for blob storage
  getSasToken(): Observable<string> {
    let url = this.baseUrl + '/api/v2/railsightevent/importeventstoken';
    url = url.replace(/[?&]$/, '');

    return this.http.get<string>(url);
  }

  processFiles(fileNames: string[]) {
    if (fileNames === undefined || fileNames === null) {
      return _observableThrow('The parameter \'fileNames\' must be defined.');
    }

    // return _observableOf('200');
    let url = this.baseUrl + '/api/v2/railsightevent/importeventfiles';
    url = url.replace(/[?&]$/, '');

    const content = JSON.stringify({fileNames});

    const options: any = {
      body: content,
      observe: 'response',
      responseType: 'text',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    return this.http.request('POST', url, options);
  }

  getStatus(fileName?: string) {
    if (fileName === undefined || fileName === null) {
      throw new Error('The parameter \'fileName\' must be defined.');
    }

    let url = this.baseUrl + '/api/v2/railsightevent/importstatus';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams().set('filename', fileName),
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('GET', url, options);
  }

  // Handles Download button logic
  emitOnDownloadButtonSelected(change: null = null) {
    this.triggerDownload.next(change);
  }

  // Handles Save button get selected assets logic
  emitOnUploadCLUButtonSelected(change: null = null) {
    this.triggerSaveSelectedAssets.next(change);
  }

  // Handles Reset button logic for Assets grid
  emitOnResetAssetsGridSelected(change: null = null) {
    this.triggerResetAssetsGrid.next(change);
  }

  // Handles Toggle selected rows logic
  emitOnSelectedRowsSelected(change: null = null) {
    this.triggerSelectedRowsToggle.next(change);
  }

  // Handles Toggle selected rows logic
  emitOnUnselectedRowsSelected(change: null = null) {
    this.triggerUnselectedRowsToggle.next(change);
  }

  // Handles Toggle selected rows logic
  emitOnAllRowsSelected(change: null = null) {
    this.triggerAllRowsToggle.next(change);
  }

  yymmddhh() {
    const now = new Date();
    const y = now.getFullYear() % 100;
    const m = now.getMonth() + 1;
    const d = now.getDate();
    const h = now.getHours();
    const mm = m < 10 ? '0' + m : m;
    const dd = d < 10 ? '0' + d : d;
    const hh = h < 10 ? '0' + h : h;
    return '' + y + mm + dd + hh;
  }
}

export class AccountAssetDto implements IAccountAssetDto {

  constructor(data?: IAccountAssetDto) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (this as any)[property] = (data as any)[property];
        }
      }
    }
  }
  selected?: boolean;
  assetId?: number;
  asset?: string | undefined;
  assetType?: string | undefined;
  deviceId?: number;
  owner?: string | undefined;
  operator?: string | undefined;

  static fromJS(data: any): AccountAssetDto {
    data = typeof data === 'object' ? data : {};
    const result = new AccountAssetDto();
    result.init(data);
    return result;
  }

  init(data?: any) {
    if (data) {
      this.selected = data.selected;
      this.assetId = data.assetId;
      this.asset = data.asset;
      this.assetType = data.assetType;
      this.deviceId = data.deviceId;
      this.owner = data.owner;
      this.operator = data.operator;
    }
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data.selected = this.selected;
    data.assetId = this.assetId;
    data.asset = this.asset;
    data.assetType = this.assetType;
    data.deviceId = this.deviceId;
    data.owner = this.owner;
    data.operator = this.operator;
    return data;
  }
}

export interface IAccountAssetDto {
  selected?: boolean;
  assetId?: number;
  asset?: string | undefined;
  assetType?: string | undefined;
  deviceId?: number;
  owner?: string | undefined;
  operator?: string | undefined;
}

export class AccountFleetDto implements IAccountFleetDto {

  constructor(data?: IAccountFleetDto) {
    if (data && data instanceof AccountFleetDto) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (this as any)[property] = (data as any)[property];
        }
      }
    }
  }
  accountId?: number;
  fleetName?: string | undefined;
  fleetNameIsa?: string | undefined;
  fleetNameGs?: string | undefined;
  fleetType?: string | undefined;
  clu?: string | undefined;

  static fromJS(data: any): AccountFleetDto {
    data = typeof data === 'object' ? data : {};
    const result = new AccountFleetDto();
    result.init(data);
    return result;
  }

  init(data?: any) {
    if (data) {
      this.accountId = data.accountId;
      this.fleetName = data.fleetName;
      this.fleetNameIsa = data.fleetNameIsa;
      this.fleetNameGs = data.fleetNameGs;
      this.fleetType = data.fleetType;
      this.clu = data.clu;
    }
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data.accountId = this.accountId;
    data.fleetName = this.fleetName;
    data.fleetNameIsa = this.fleetNameIsa;
    data.fleetNameGs = this.fleetNameGs;
    data.fleetType = this.fleetType;
    data.clu = this.clu;
    return data;
  }
}

export interface IAccountFleetDto {
  accountId?: number;
  fleetName?: string | undefined;
  fleetNameIsa?: string | undefined;
  fleetNameGs?: string | undefined;
  fleetType?: string | undefined;
  clu?: string | undefined;
}

export class AccountReceiverFleetDto implements IAccountReceiverFleetDto {

  constructor(data?: IAccountReceiverFleetDto) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (this as any)[property] = (data as any)[property];
        }
      }
    }
  }
  accountId?: number;
  receiver?: string | undefined;
  senderISA06?: string | undefined;
  senderGS?: string | undefined;
  fleetType?: string | undefined;

  static fromJS(data: any): AccountReceiverFleetDto {
    data = typeof data === 'object' ? data : {};
    const result = new AccountReceiverFleetDto();
    result.init(data);
    return result;
  }

  init(data?: any) {
    if (data) {
      this.accountId = data.accountId;
      this.receiver = data.fleetName;
      this.senderISA06 = data.fleetNameIsa;
      this.senderGS = data.fleetNameGs;
      this.fleetType = data.fleetType;
    }
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data.accountId = this.accountId;
    data.receiver = this.receiver;
    data.senderISA06 = this.senderISA06;
    data.senderGS = this.senderGS;
    data.fleetType = this.fleetType;
    return data;
  }
}

export interface IAccountReceiverFleetDto {
  accountId?: number;
  receiver?: string | undefined;
  senderISA06?: string | undefined;
  senderGS?: string | undefined;
  fleetType?: string | undefined;
}

export class FleetTypeDto implements IFleetTypeDto {

  constructor(data?: IFleetTypeDto) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (this as any)[property] = (data as any)[property];
        }
      }
    }
  }
  type?: string | undefined;
  description?: string | undefined;

  static fromJS(data: any): AccountFleetDto {
    data = typeof data === 'object' ? data : {};
    const result = new AccountFleetDto();
    result.init(data);
    return result;
  }

  init(data?: any) {
    if (data) {
      this.type = data.type;
      this.description = data.description;
    }
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data.type = this.type;
    data.description = this.description;
    return data;
  }
}

export interface IFleetTypeDto {
  type?: string | undefined;
  description?: string | undefined;
}

export class CLURequestDto implements ICLURequestDto {

  constructor(data?: ICLURequestDto) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (this as any)[property] = (data as any)[property];
        }
      }
    }
  }
  accountId?: number;
  clu?: string | undefined;

  static fromJS(data: any): CLURequestDto {
    data = typeof data === 'object' ? data : {};
    const result = new CLURequestDto();
    result.init(data);
    return result;
  }

  init(data?: any) {
    if (data) {
      this.accountId = data.accountId;
      this.clu = data.clu;
    }
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data.accountId = this.accountId;
    data.clu = this.clu;
    return data;
  }
}

export interface ICLURequestDto {
  accountId?: number;
  clu?: string | undefined;
}

export class WaybillDateDto implements IWaybillDateDto {

  constructor(data?: IWaybillDateDto) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (this as any)[property] = (data as any)[property];
        }
      }
    }
  }
  asset?: string;
  billOfLadingNumber?: string;
  createdDate?: string;
  waybillDate?: string;
  waybillDateUtc?: string;

  static fromJS(data: any): WaybillDateDto {
    data = typeof data === 'object' ? data : {};
    const result = new WaybillDateDto();
    result.init(data);
    return result;
  }

  init(data?: any) {
    if (data) {
      this.asset = data.asset;
      this.billOfLadingNumber = data.billOfLadingNumber;
      this.createdDate = data.createdDate;
      this.waybillDate = data.waybillDate;
      this.waybillDateUtc = data.waybillDateUtc;
    }
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data.asset = this.asset;
    data.billOfLadingNumber = this.billOfLadingNumber;
    data.createdDate = this.createdDate;
    data.waybillDate = this.waybillDate;
    data.waybillDateUtc = this.waybillDateUtc;
    return data;
  }
}

export interface IWaybillDateDto {
  asset?: string;
  billOfLadingNumber?: string;
  createdDate?: string;
  waybillDate?: string;
  waybillDateUtc?: string;
}

export class WaybillDateDtoWaybillDatePagedListDto implements IWaybillDateDtoWaybillDatePagedListDto {

  constructor(data?: IWaybillDateDtoWaybillDatePagedListDto) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (this as any)[property] = (data as any)[property];
        }
      }
    }
  }
  totalOperatorAssets?: number;
  totalOwnerAssets?: number;
  count?: number;
  total?: number;
  items?: WaybillDateDto[] | undefined;

  static fromJS(data: any): WaybillDateDtoWaybillDatePagedListDto {
    data = typeof data === 'object' ? data : {};
    const result = new WaybillDateDtoWaybillDatePagedListDto();
    result.init(data);
    return result;
  }

  init(data?: any) {
    if (data) {
      this.totalOperatorAssets = data.totalOperatorAssets;
      this.totalOwnerAssets = data.totalOwnerAssets;
      this.count = data.count;
      this.total = data.total;
      if (Array.isArray(data.items)) {
        this.items = [] as any;
        for (const item of data.items) {
          this.items.push(WaybillDateDto.fromJS(item));
        }
      }
    }
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data.totalOperatorAssets = this.totalOperatorAssets;
    data.totalOwnerAssets = this.totalOwnerAssets;
    data.count = this.count;
    data.total = this.total;
    if (Array.isArray(this.items)) {
      data.items = [];
      for (const item of this.items) {
        data.items.push(item.toJSON());
      }
    }
    return data;
  }
}

export interface IWaybillDateDtoWaybillDatePagedListDto {
  totalOperatorAssets?: number;
  totalOwnerAssets?: number;
  count?: number;
  total?: number;
  items?: WaybillDateDto[] | undefined;
}

export class ApiException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any; };
  result: any;

  constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isApiException = true;

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true;
  }
}

function throwException(message: string, status: number, response: string,
  headers: { [key: string]: any; }, result?: any): Observable<any> {
  if (result !== null && result !== undefined) {
    return _observableThrow(result);
  } else {
    return _observableThrow(new ApiException(message, status, response, headers, null));
  }
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next('');
      observer.complete();
    } else {
      const reader = new FileReader();
      reader.onload = event => {
        observer.next((event.target as any).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}

export class Operation implements IOperation {

  constructor(data?: IOperation) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (this as any)[property] = (data as any)[property];
        }
      }
    }
  }

  op?: string;
  value?: any | undefined;
  path?: string;

  static fromJS(data: any): Operation {
    data = typeof data === 'object' ? data : {};
    const result = new Operation();
    result.init(data);
    return result;
  }

  init(data?: any) {
    if (data) {
      this.op = data.op;
      this.value = data.value;
      this.path = data.path;
    }
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data.op = this.op;
    data.value = this.value;
    data.path = this.path;
    return data;
  }
}

export interface IOperation {
  op?: string;
  value?: any | undefined;
  path?: string;
}

export class ProblemDetails implements IProblemDetails {

  constructor(data?: IProblemDetails) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (this as any)[property] = (data as any)[property];
        }
      }
    }
  }

  type?: string;
  title?: string;
  status?: number | undefined;
  detail?: string;
  instance?: string;

  static fromJS(data: any): ProblemDetails {
    data = typeof data === 'object' ? data : {};
    const result = new ProblemDetails();
    result.init(data);
    return result;
  }

  init(data?: any) {
    if (data) {
      this.type = data.type;
      this.title = data.title;
      this.status = data.status;
      this.detail = data.detail;
      this.instance = data.instance;
    }
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data.type = this.type;
    data.title = this.title;
    data.status = this.status;
    data.detail = this.detail;
    data.instance = this.instance;
    return data;
  }
}

export interface IProblemDetails {
  type?: string;
  title?: string;
  status?: number | undefined;
  detail?: string;
  instance?: string;
}

/* Fleet types */
export enum FleetType {
  CLM = 'CLM',
  Geo = 'Geo',
  Waybill = 'Waybill',
  Sender = 'Sender'
}

/* Tab labels */
export enum TabLabel {
  CLM = 'CLM',
  Status = 'Status',
  Waybill = 'Waybill'
}
