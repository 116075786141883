<mat-card>
  <div [hidden]="isDeactivate">
    <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
      Share Assets
    </mat-card-title>
  </div>
  <div [hidden]="!isDeactivate">
    <mat-card-title fxLayout="row" ng-show="" fxLayoutAlign="space-between">
      Deactivate Assets
    </mat-card-title>
  </div>
  <mat-slide-toggle id='share-slider' [checked]="isDeactivate" (toggleChange)="toggleDeactivate($event)">
    Deactivate Assets
  </mat-slide-toggle>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class="account-dropdown">
      <ads-account-select-search #accountSelect id='sourceAccountId' ngDefaultControl placeholder="Source Account">
      </ads-account-select-search>
    </div>
    <div [hidden]="!isDeactivate" class="account-dropdown">
      <ads-account-select-search #targetAccountSelect id='targetAccountId' ngDefaultControl placeholder="Target Account">
      </ads-account-select-search>
    </div>
    <div class="workspace-dropdown">
      <ads-workspace-select #workspaceSelect accountId="{{sourceAccountId}}"></ads-workspace-select>
    </div>
    <div class="names-search" id="leftcolumn">
      <mat-form-field>
        <input placeholder="Search Assets by Name" matInput (keyup)="onSearchKeyup($event)" maxlength="50" #searchText />
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-content>
    <ag-grid-angular
      style="width: 100%; height: 570px;"
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      [rowSelection]="'multiple'"
      [suppressRowClickSelection]="true"
      (rowSelected)="onRowSelected($event)"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </mat-card-content>
  <div [hidden]="isDeactivate">
    <mat-card-actions align="end" >
      <button id="af-createbutton" mat-flat-button color="primary" (click)="shareAssetsClick(true)" type="submit" [disabled]="sourceAccountId==0">Share By Workspace</button>
      <button id="af-createbutton" mat-flat-button color="primary" (click)="shareAssetsClick(false)" type="submit" [disabled]="!enableSelectedAssets">Share Selected</button>
    </mat-card-actions>
  </div>
  <div [hidden]="!isDeactivate">
    <mat-card-actions align="end">
      <button id="af-createbutton" mat-flat-button color="primary" (click)="deactivateAssetsClick()" type="submit" [disabled]="!enableSelectedAssets">Deactivate</button>
    </mat-card-actions>
  </div>
</mat-card>
