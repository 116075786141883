import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'ads-pages-and-features',
  templateUrl: './pages-and-features.component.html',
  styleUrls: ['./pages-and-features.component.scss']
})
export class PagesAndFeaturesComponent implements OnInit {
  @Input() roleType: number;
  @Input() pagesFeaturesControl: FormControl;
  @Input() placeholder: string;

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  public dataSource: any[];

  constructor() {
  }

  ngOnInit() {
    switch (this.roleType) {
      case ClaimType.Features: {
        this.dataSource = this.EnumToDictionary(FeatureTypes).sort((a, b) => a.name.localeCompare(b.name));
        break;
      }
      case ClaimType.Pages: {
        this.dataSource = this.EnumToDictionary(PageTypes).sort((a, b) => a.name.localeCompare(b.name));
        break;
      }
      default: {
      }
    }
  }

  private EnumToDictionary(claimType: typeof PageTypes | typeof FeatureTypes): any[] {
    // TypeScript will 'double' map the enum in the emitted object, so it can be accessed both by key and value.
    // https://stackoverflow.com/questions/43100718/typescript-enum-to-object-array
    const StringIsNumber = value => isNaN(Number(value)) === false;
    return Object.keys(claimType)
      .filter(StringIsNumber)
      .map(key => ({ name: claimType[key], id: parseInt(key, 10) }));
  }
}

export class PagesAndFeaturesUtils {
  static CsvToEnumValueArray(value: string, typeValue: typeof PageTypes | typeof FeatureTypes): any[] {
    if (!value) {
      return [];
    } else {
      const result = value.split(',').map(i => {
        const item = typeValue[parseInt(i, 10)];
        return item ? item : `missing value [${i}]`;
      });

      return result;
    }
  }

  static CsvToArray(valueCsv: string): Array<number> {
    if (!valueCsv) {
      return new Array<number>();
    } else {
      return valueCsv.split(',').map(Number);
    }
  }

  static ArrayToCsv(valueArray: Array<number>): string {
    if (!valueArray) {
      return '';
    } else {
      return valueArray.join(',');
    }
  }
}

export enum ClaimType {
  Features = 1,
  Pages = 2
}

export enum FeatureTypes {
  'Anonymize Data' = 0,
  'Auto Refresh' = 1,
  'Saved Filters' = 2,
  'RSRD2' = 3,
  'ETA Change Destinations' = 4,
  'Admin' = 99,
}

export enum PageTypes {
  'Ess_Track' = 1,
  'Ess_Trace' = 2,
  'Ess_Mileage' = 3,
  'Ess_Dwell' = 4,
  'Ess_Arrivals & Departures' = 5,
  'Plus_Cycle' = 6,
  'Plus_CycleHistory' = 7,
  'Plus_Route' = 8,
  'Plus_ETA' = 9,
  'Adv_Securement' = 10,
  'Adv_Temperature' = 11,
  'Adv_LoadStatus' = 12,
  'Fleet Management' = 13,
  'User Management' = 14,
  'Fleet Health' = 15,
  'Geofence Editor' = 16,
  'Impact' = 17,
  'RSRD2' = 18,
  'UMLER' = 19,
  'Device' = 20,
  'Lease' = 21,
  'Location Management' = 22,
  'Railcar Management' = 23,
  'Manage Reports and Views' = 24,
  'Companies' = 25,
  'Completed Trips' = 26,
  'BogieIQ' = 27,
  'System Assignment Management' = 28,
  'Manage Unknown Assets' = 29,
  'Locations of Interest' = 30,
  'Lease_Dashboard' = 31,
  'Active Global Trips' = 32,
  'Manage Railcar OneVue' = 33,
  'Wheel And Brake Health' = 34,
  'Asset_Health_Events' = 35,
  'Admin Reports' = 99
}
