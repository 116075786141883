import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationsRoutingModule } from './locations-routing.module';
import { LocationsComponent } from './locations.component';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { AddLocationDialogComponent } from './add-location-dialog/add-location-dialog.component';
import { LocationService, GEO_API_BASE_URL } from '../services/geo.service';
import { environment } from '@env/environment';
import { SharedModule } from '../shared/shared.module';
import { LocationCacheService } from '../services/location-cache.service';

@NgModule({
  declarations: [LocationsComponent, AddLocationDialogComponent],
  imports: [CommonModule, LocationsRoutingModule, CommonUiSharedModule, GoogleMapsModule, SharedModule],
  exports: [LocationsComponent],
  providers: [
    LocationService,
    {
      provide: GEO_API_BASE_URL,
      useValue: environment.geoBaseUrl
    },
    LocationCacheService
  ]
})
export class LocationsModule {}
