import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Observable, Subject } from 'rxjs';
import { WorkspacesService, WorkspaceDto } from '../../services/accounts.service';
import { ToasterNotificationService } from '../../services/toasterNotification.service';
import { Router, ActivatedRoute  } from '@angular/router';

@Component({
  selector: 'ads-workspace-select',
  templateUrl: './workspace-select.component.html',
  styleUrls: ['./workspace-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WorkspaceSelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => WorkspaceSelectComponent),
      multi: true
    }
  ]
})

export class WorkspaceSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @Input() accountId: number;
  @Input() allowEmptySelection: boolean;
  @Input() control: any;
  @Input() isRequired = true;
  @Input() placeholder: string;

  // Empty option will display as All. Returns empty accountID.
  @Input() allOption: boolean;

  protected workspaceAsync: Observable<WorkspaceDto[]>;
  protected onDestroy = new Subject<void>();
  workspaces: WorkspaceDto[];
  workspacesLoaded = new Subject<boolean>();


  onChange: any = () => { };
  onTouched: any = () => { };
  workspaceSelected: string;

  constructor(private workspacesService: WorkspacesService, private toasterService: ToasterNotificationService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.workspacesLoaded.next(false);

    this.activatedRoute.queryParams.subscribe(params => {
      if(params.workspace === undefined)
      {
        this.workspaceSelected = 'all';
      } else {
        this.workspaceSelected = params.workspace;
      }
    });

    this.setupWorkspaces();
  }

  onSelectionChanged(event: MatSelectChange) {
    this.workspaceSelected = event.value;
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { workspace: event.value },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    this.onChange(event.value);
    this.onTouched();
  }

  writeValue(obj: any): void {
    if (obj) {
      this.singleSelect.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate({ id }: WorkspaceDto) {
    if (!this.allowEmptySelection && id === '') {
      return false;
    }
    return id != '';
  }

  setupWorkspaces() {
    const allWorkspace = new WorkspaceDto();

    allWorkspace.accountId = this.accountId;
    allWorkspace.id = 'all';
    allWorkspace.displayName = 'All';

    this.workspacesService.workspaces(this.accountId).subscribe(workspaces => {
      const sortedResults = this.alphabetizeList(workspaces, 'displayName');
      this.workspaces = [
        allWorkspace,
        ...sortedResults
      ];
      this.workspacesLoaded.next(true);

    },
    error => {
      this.toasterService.showWarnToaster(error.detail);
    });
  }

  alphabetizeList(unsortedList, property) {
    return unsortedList.sort((a, b) => a[property].localeCompare(b[property]));
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
