<mat-card>
  <mat-card-title id="assetmgmt">Asset Management</mat-card-title>
  <mat-card-subtitle class="event-subtitle">RailSight Events</mat-card-subtitle>
  <mat-tab-group (selectedTabChange)="onTabClick($event)">
    <mat-tab label="Fleet">
      <mat-card>
        <mat-card-content>
          <section fxLayout="row wrap" fxLayoutAlign="space-between end">
            <div fxLayoutAlign="left end" fxLayoutGap="10px">
              <mat-card-subtitle class="event-subtitle">RailSight Fleet Configuration</mat-card-subtitle>
              <mat-icon class="event-subtitle" #tooltip="matTooltip" matTooltipPosition="right" 
                matTooltip="Selected assets will be included in the fleet
                        Deselected assets will be removed from the fleet
                        Reset will undo any unsaved changes
                        Upload will save and submit the updated fleet">info</mat-icon>
            </div>
            <div fxFlex="1 1 auto"></div>
            <div class="event-subtitle" fxLayoutAlign="right end">
              <a href="https://www.railinc.com/loa2/" color="primary">Railinc LOA</a>
            </div>
          </section>
          <section fxLayout="row" fxLayoutAlign="space-between end">
            <div fxLayout="row" fxLayoutAlign="left end" fxLayoutGap="20px">
              <mat-form-field>
                <mat-label class="form-field-label">Fleets</mat-label>
                <mat-select [(value)]="fleetOption" (selectionChange)="onRowSelectionChangedFleets($event)">
                  <mat-option *ngFor="let fleet of fleets" [value]="fleet" [compareWith]="fleetComparison">
                    {{fleet.fleetName}} - {{fleet.fleetType}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="1 1 auto"></div>
          </section>
          <form [formGroup]="editAccountFleetForm"
            (ngSubmit)="onAddAccountFleet(editAccountFleetForm.controls.fleetNameCtrl.value,
              editAccountFleetForm.controls.fleetNameIsaCtrl.value, editAccountFleetForm.controls.fleetNameGsCtrl.value)">
            <mat-card-content>
              <section fxLayout="row wrap" fxLayoutAlign="space-between end">
                <div fxLayout="row" fxLayoutAlign="left end" fxLayoutGap="20px">
                  <mat-form-field>
                    <mat-label class="form-field-label">Fleet Type</mat-label>
                    <mat-select [(value)]="fleetTypeOption" (selectionChange)="onRowSelectionChangedFleetTypes($event)"
                    [formControl]="editAccountFleetForm.get('fleetTypeCtrl')">
                      <mat-option *ngFor="let fleetType of fleetTypes" [value]="fleetType.type">{{fleetType.type}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>                  
                  <div fxFlexAlign="center">
                    <mat-icon #tooltip="matTooltip" matTooltipPosition="right" 
                      matTooltip={{fleetTypeTooltip}}>info</mat-icon>
                  </div>
                  <mat-form-field class="form-field">
                    <mat-label class="form-field-label">{{fleetNameLabel}}</mat-label>
                    <input [value]="fleetName" id="rs-fleetname" matInput formControlName="fleetNameCtrl"
                      required />
                    <ads-control-messages [control]="editAccountFleetForm.get('fleetNameCtrl')"></ads-control-messages>
                  </mat-form-field>
                  <mat-form-field class="form-field">
                    <mat-label class="form-field-label">{{fleetNameIsaLabel}}</mat-label>
                    <input [(value)]="fleetNameIsa" id="rs-fleetnameisa" matInput formControlName="fleetNameIsaCtrl"
                    [ngClass]="{'hidden': editAccountFleetForm.controls.fleetNameIsaCtrl.disabled}"/>
                    <ads-control-messages [control]="editAccountFleetForm.get('fleetNameIsaCtrl')"></ads-control-messages>
                  </mat-form-field>
                  <mat-form-field class="form-field">
                    <mat-label class="form-field-label">{{fleetNameGsLabel}}</mat-label>
                    <input [(value)]="fleetNameGs" id="rs-fleetnamegs" matInput formControlName="fleetNameGsCtrl"
                    [ngClass]="{'hidden': editAccountFleetForm.controls.fleetNameGsCtrl.disabled}"/>
                    <ads-control-messages [control]="editAccountFleetForm.get('fleetNameGsCtrl')"></ads-control-messages>
                  </mat-form-field>
                  <div fxFlex="1 1 auto"></div>
                </div>
              </section>    
              <section fxLayout="row" fxLayoutAlign="space-between end">
                <div fxLayout="row" fxLayoutAlign="left end">
                  <button id="rs-addbutton" class="button-sm" type="submit"
                    [disabled]="!editAccountFleetForm.valid">
                    Add
                  </button>
                  <button id="rs-updatebutton" class="button-sm" type="button"
                    [disabled]="!editAccountFleetForm.valid || fleetTypeOption == 'Sender'" 
                    (click)="onUpdateAccountFleet()">
                    Update
                  </button>
                  <div fxFlex="1 1 auto"></div>                    
                </div>
              </section>          
            </mat-card-content>
          </form>
          <mat-card-content>
            <section *ngIf="fleetAssetsReady" fxLayout="row wrap" fxLayoutAlign="space-between end">
              <div fxLayout="row" fxLayoutAlign="left end">
                <mat-form-field>
                  <mat-label class="form-field-label">Filter Assets</mat-label>
                  <mat-select [(value)]="assetOption" (selectionChange)="onRowSelectionChangedFleetAssets($event)">
                    <mat-option value="all" selected> All Assets </mat-option>
                    <mat-option value="selected"> Selected Assets </mat-option>
                    <mat-option value="unselected"> Unselected Assets </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="1 1 auto"></div>
              <div fxLayout="row" fxLayoutAlign="right end">
                <button id="rs-resetbutton" class="button-sm" type="button" (click)="resetAssetsGrid()"
                  [disabled]="!(accountAssetsReady && fleetAssetsReady)">
                  Reset
                </button>
                <button id="rs-uploadbutton" class="button-sm" type="button" (click)="onUploadCLU()"
                  [disabled]="!editAccountFleetForm.valid || !(accountAssetsReady && fleetAssetsReady)">
                  Upload
                </button>
              </div>
            </section>
            <div *ngIf="fleetAssetsReady" class="aggrid-height">
              <ads-ag-grid-railsight-fleet-assets class="aggrid-height" [body]="gridDataAssets.body"
                [header]="gridDataAssets.header" (updateAccountFleetCLU)="updateAccountFleetCLU()">
              </ads-ag-grid-railsight-fleet-assets>
            </div>
            <div *ngIf="fleetAssetsLoading" class="center">
              <span class="refresh-text">Retrieving data please wait&nbsp;&nbsp;</span>
              <mat-spinner class="mat-spinner-color" diameter="20"></mat-spinner>
            </div>
          </mat-card-content>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="Status" [disabled]="!(disableTabs())">
      <mat-card-content>
        <section fxLayout="column" fxLayoutAlign="space-between start">
          <mat-card-subtitle class="event-subtitle">RailSight Fleet Status</mat-card-subtitle>
          <mat-card-subtitle class="event-text">Date of Last CLM Event: {{ latestClmDate }}</mat-card-subtitle>
          <mat-card-subtitle class="event-text">Date of Last Waybill: {{ latestWaybillDate }}</mat-card-subtitle>
          <highcharts-chart [Highcharts]="Highcharts" [options]="weekClmChartOptions" [(update)]="updateClmChart"
            class="highcharts">
          </highcharts-chart>
          <highcharts-chart [Highcharts]="Highcharts" [options]="weekWaybillChartOptions"
            [(update)]="updateWaybillChart" class="highcharts">
          </highcharts-chart>
        </section>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="CLM" [disabled]="!(disableTabs())">
      <mat-card>
        <mat-card-content>
          <section fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <mat-card-subtitle fxFlexOrder="1" class="event-subtitle">RailSight Event Details</mat-card-subtitle>
            <mat-card-subtitle fxFlexOrder="2" class="event-subtitle"> Last Event: {{ lastEventDays }}
            </mat-card-subtitle>
            <button fxFlexOrder="3" [disabled]="!railsightService.exportEnabled" class="button-sm"
              (click)="downloadToExcel()">
              Export
            </button>
          </section>
          <section fxLayout="row wrap" fxLayoutAlign="space-between end">
            <div fxLayout="row" fxLayoutAlign="left end">
              <mat-form-field>
                <mat-label>Filter Events</mat-label>
                <mat-select [(value)]="clmEventOption" (selectionChange)="onRowSelectionChangedCLMEvents($event)">
                  <mat-option value="latest" selected> Latest Events </mat-option>
                  <mat-option value="historical"> Historical Events </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div [hidden]="!(clmEventOption == 'historical')" layout="row">
              <mat-form-field appearance="standard" floatLabel="always" [style.width.px]="225">
                <mat-label>From</mat-label>
                <input matInput [matDatepicker]="startDatePicker" placeholder="MM/DD/YYYY" [(ngModel)]="startDate"
                  (dateChange)="dateChanged('start', $event)" [min]="minDate" />
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="standard" floatLabel="always" [style.width.px]="225">
                <mat-label>To</mat-label>
                <input matInput [matDatepicker]="endDatePicker" placeholder="MM/DD/YYYY" [(ngModel)]="endDate"
                  (dateChange)="dateChanged('end', $event)" [min]="startDate" />
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div [hidden]="!(clmEventOption == 'historical')" layout="row">
              <button id="rs-submithistoricaleventsrequestbutton" class="button-sm" type="button"
                (click)="getHistoricalRailSightCLMEvents()">
                Submit
              </button>
            </div>
          </section>
          <div class="aggrid-height">
            <ads-ag-grid-railsight-clm-events class="aggrid-height" [body]="gridDataEvents.body"
              [header]="gridDataEvents.header"></ads-ag-grid-railsight-clm-events>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="Waybill" [disabled]="!(disableTabs())">
      <mat-card>
        <mat-card-content>
          <section fxLayout="row wrap" fxLayoutAlign="left end" fxLayoutGap="10px">
            <div fxLayoutAlign="left end" fxLayoutGap="10px">
              <mat-card-subtitle class="event-subtitle">Waybill Details</mat-card-subtitle>
              <mat-icon class="event-subtitle" *ngIf="waybillDateData.filteredData.length == 0" 
              #tooltip="matTooltip" matTooltipPosition="right"
                matTooltip="If you would like to subscribe to Waybills,
                          a request to enable Full 417 distribution
                          must be submitted to a Railinc Support Specialist">info</mat-icon>
            </div>
            <div fxFlex="1 1 auto"></div>
          </section>
        </mat-card-content>
        <mat-divider *ngIf="showWaybill"></mat-divider>
        <mat-card-content *ngIf="showWaybill">
          <button class="button-lg" (click)="showWaybill = false">Close Waybill</button>
          <div>
            <ngx-json-viewer (click)="triggerJSONChangeDetection()" [json]="waybillObject" [expanded]="true">
            </ngx-json-viewer>
          </div>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div>
            <label>Search Assets</label>
            <mat-form-field>
              <mat-label></mat-label>
              <input id="ac-asset-searchtext" name="searchText" matInput (keyup)="searchTriggered($event.target.value)"
                maxlength="50" #searchText placeholder="Asset name or number" />
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div fxLayoutAlign="left end" fxLayoutGap="10px">
            <mat-checkbox name="showAllWaybillFleets" color="primary" class="event-subtitle" 
            (change)="onChangeShowAllWaybillFleets($event)" [(ngModel)]="showAllWaybillFleets">All Fleets</mat-checkbox> 
            <div fxFlex="1 1 auto"></div>
            <div class="action-wrapper">
              <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                (page)="updatePaginator($event)">
              </mat-paginator>
            </div>
          </div>
          <table mat-table [dataSource]="waybillDateData" class="constant-col"
            *ngIf="waybillDateData.filteredData.length > 0">
            <ng-container matColumnDef="asset">
              <th class="table-header" mat-header-cell *matHeaderCellDef>Asset Name</th>
              <td mat-cell *matCellDef="let waybill">{{ waybill.asset }}</td>
            </ng-container>
            <ng-container matColumnDef="billOfLadingNumber">
              <th class="table-header" mat-header-cell *matHeaderCellDef>Bill Of Lading Number</th>
              <td mat-cell *matCellDef="let waybill">{{ waybill.billOfLadingNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="waybillDate">
              <th class="table-header" mat-header-cell *matHeaderCellDef>Waybill Date</th>
              <td mat-cell *matCellDef="let waybill">{{ waybill.waybillDate }}</td>
            </ng-container>
            <ng-container matColumnDef="waybillDateUtc">
              <th class="table-header" mat-header-cell *matHeaderCellDef>Waybill Date UTC</th>
              <td mat-cell *matCellDef="let waybill">{{ waybill.waybillDateUtc }}</td>
            </ng-container>
            <ng-container matColumnDef="createdDate">
              <th class="table-header" mat-header-cell *matHeaderCellDef>Created Date</th>
              <td mat-cell *matCellDef="let waybill">{{ waybill.createdDate }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row class="hover" (click)="onRowClick(row)" *matRowDef="let row; columns: columnsToDisplay"></tr>
          </table>
          <div *ngIf="waybillDateData.filteredData.length === 0">
            <label class="sm-note">{{ emptyMessage }}</label>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="Import" [disabled]="!(disableTabs())">
      <ng-template matTabContent>
        <mat-card>
          <mat-card-content>
            <section fxLayout="row wrap" fxLayoutAlign="left end" fxLayoutGap="10px">
              <mat-card-subtitle class="event-subtitle">Import Historical Events</mat-card-subtitle>
            </section>
          </mat-card-content>
          <mat-divider></mat-divider>
          <mat-card-content>
            <section fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="10px">
              <ads-file-upload [destType]="'blob'" [service]="railsightService"></ads-file-upload>
            </section>
          </mat-card-content>
        </mat-card>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-card>