import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountAssetResponse, AccountWorkspaceAssetsV3Service } from 'src/app/services/assets-v3.service';
import { AccountTripModel, AccountTripsService } from 'src/app/services/shipments-v2.service';

@Component({
  selector: 'account-trip-details',
  templateUrl: './account-trip-details.page.html',
  styleUrls: ['./account-trip-details.page.scss']
})
export class AccountTripDetailsPage implements OnInit {
  trip: AccountTripModel | null = null;
  asset: AccountAssetResponse | null = null;

  constructor(
    private tripService: AccountTripsService,
    private accountAssetV3Service: AccountWorkspaceAssetsV3Service,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    const accountId = this.route.snapshot.params.accountId;
    const tripId = this.route.snapshot.params.tripId;

    const tripPromise = this.tripService.getAccountTrip(accountId, 'all', tripId).toPromise();
    const assetPromise = tripPromise.then(trip => this.accountAssetV3Service.getAssetInWorkspace(accountId, 'all', trip.assetId).toPromise());

    tripPromise.then(trip => {
      this.trip = trip;
    });

    assetPromise
      .then(asset => {
        this.asset = asset;
      })
      .catch(err => {
        this.asset = null;
        console.log('Asset not found');
      });
  }

}
