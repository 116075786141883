<div class="my-3">
  <mat-card>
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="space-between">
          <div>
            <button id="aws-add-workspaces" mat-button color="primary" (click)="shareToAccount()">Add Accounts</button>
          </div>
      </div>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-content class="table-container">
      <table mat-table matSort [dataSource]="dataSource" class="user-component-table" [hidden]="dataSource?.filteredData?.length < 1">
        <ng-container matColumnDef="id">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Account</th>
          <td mat-cell *matCellDef="let account">
            <a id= "account"  [routerLink]="[account.accountId]">
              {{account.accountName}}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="displayName">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Shared Assets</th>
          <td mat-cell *matCellDef="let account">
            <a id= "account"  [routerLink]="[account.accountId]">
              {{account.sharedAssets}}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="deleted">
          <th mat-header-cell *matHeaderCellDef class="table-header"></th>
          <td mat-cell *matCellDef="let account">
            <button mat-button (click)="removeAccountShare(account)">
              <mat-icon class="red-icon">clear</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headers; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: headers;">
        </tr>
      </table>
      <div *ngIf="dataSource?.filteredData?.length < 1">
        <label class="sm-note">{{emptyMessage}}</label>
      </div>
    </mat-card-content>
  </mat-card>
</div>
