import { Component, OnInit, AfterViewInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UsersService, Operation, AppUserDto } from '../../services/users.service';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'angular-crumbs';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { PagesAndFeaturesUtils } from 'src/app/shared/pages-and-features/pages-and-features.component';

@Component({
  selector: 'ads-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, AfterViewInit {
  editUserForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    accountControl: [''],
    featuresControl: [''],
    pagesControl: [''],
    mobilePhone: [''],
    dateDisplayFormat: [''],
    dateTimeDisplayFormat: [''],
    lengthUnitOfMeasure: [''],
    tankVolumeUnitOfMeasure: [''],
    loadingDimensionsUnitOfMeasure: [''],
    weightUnitOfMeasure: [''],
    jobTitle: [''],
    bypassMfa: ['']
  });

  accountControl = new FormControl();
  featuresControl = new FormControl();
  pagesControl = new FormControl();
  bypassMfaControl = new FormControl(false);

  public user: any = {
    firstName: '',
    lastName: '',
    account: '',
    pages: '',
    features: '',
    mobilePhone: '',
    dateDisplayFormat: '',
    dateTimeDisplayFormat: '',
    lengthUnitOfMeasure: '',
    tankVolumeUnitOfMeasure: '',
    loadingDimensionsUnitOfMeasure: '',
    weightUnitOfMeasure: '',
    jobTitle: '',
    bypassMfa: ''
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UsersService,
    private breadcrumbService: BreadcrumbService,
    private toasterService: ToasterNotificationService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
    this.userId = this.route.snapshot.params.id;
    this.accountId = this.route.snapshot.params.accountId;
  }

  private userId: string;
  private accountId: number;

  ngOnInit() {
    this.userService.getUser(this.accountId, this.userId).subscribe((resp: AppUserDto) => {
      this.user = { ...resp };
      this.breadcrumbService.changeBreadcrumb(
        this.route.snapshot,
        this.user.displayName
      );
      this.accountControl.setValue(parseInt(this.user.accountId));
      this.featuresControl.setValue(PagesAndFeaturesUtils.CsvToArray(this.user.features));
      this.pagesControl.setValue(PagesAndFeaturesUtils.CsvToArray(this.user.pages));
      this.bypassMfaControl.setValue(this.user.bypassMfa);
    });
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#usr-firstname');
    element.focus();
    this.cdr.detectChanges();
  }

  onSave() {
    if (this.editUserForm.invalid) {
      return;
    }
    
    const userPatch: Operation[] = [
      new Operation({ op: 'replace', path: '/firstName', value: this.user.firstName }),
      new Operation({ op: 'replace', path: '/lastName', value: this.user.lastName }),
      new Operation({ op: 'replace', path: '/accountId', value: this.accountControl.value }),
      new Operation({ op: 'replace', path: '/pages', value: PagesAndFeaturesUtils.ArrayToCsv(this.pagesControl.value) }),
      new Operation({ op: 'replace', path: '/features', value: PagesAndFeaturesUtils.ArrayToCsv(this.featuresControl.value) }),
      new Operation({ op: 'replace', path: '/mobilePhone', value: this.user.mobilePhone }),
      new Operation({ op: 'replace', path: '/dateDisplayFormat', value: this.user.dateDisplayFormat }),
      new Operation({ op: 'replace', path: '/dateTimeDisplayFormat', value: this.user.dateTimeDisplayFormat }),
      new Operation({ op: 'replace', path: '/lengthUnitOfMeasure', value: this.user.lengthUnitOfMeasure }),
      new Operation({ op: 'replace', path: '/tankVolumeUnitOfMeasure', value: this.user.tankVolumeUnitOfMeasure }),
      new Operation({ op: 'replace', path: '/loadingDimensionsUnitOfMeasure', value: this.user.loadingDimensionsUnitOfMeasure }),
      new Operation({ op: 'replace', path: '/weightUnitOfMeasure', value: this.user.weightUnitOfMeasure }),
      new Operation({ op: 'replace', path: '/jobTitle', value: this.user.jobTitle }),
      new Operation({ op: 'replace', path: '/bypassMfa', value: this.user.bypassMfa }),
    ];

    this.userService.updateUser(this.accountId, this.userId, userPatch).subscribe(
      (resp: any) => {
        this.router.navigate([`/accounts/${this.accountControl.value}/users/${this.userId}`]);
      },
      err => {
        this.toasterService.showWarnToaster(err.detail);
      }
    );
  }
}
