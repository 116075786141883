<ng-container *ngIf="assetConfigs?.length > 0">
  <div fxLayout="row" fxLayoutAlign="end" style="padding: 8px 0">
    <button id = "EmptyLoadHeaderEditButton" mat-flat-button color="primary" [disabled]="isDisabled" (click)="onEditClick()">Edit</button>

    <button id = "EmptyLoadHeaderExportButton" mat-flat-button  color="primary" (click)="onExportClick()">Export</button>
  </div>


  <ag-grid-angular
    style="width: 100%; height: 570px;"
    class="ag-theme-alpine"
    [gridOptions]="gripOptions"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged()"
    [rowSelection]="rowSelection"
    [rowData]="assetConfigs"
    [columnTypes]="columnTypes"
    [columnDefs]="columnDefs">
  </ag-grid-angular>
</ng-container>
