<div *ngIf="this.loaded">
  <table mat-table [class.mat-elevation-z2]="true" appearance="outline" [dataSource]="dataSource" matSort class="m-t-20" [hidden]="dataSource?.data?.length < 1">
      <ng-container matColumnDef="account">
        <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Account</th>
        <td mat-cell *matCellDef="let policy">{{policy.account}}</td>
      </ng-container>
      <ng-container matColumnDef="workspace">
        <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Workspace</th>
        <td mat-cell *matCellDef="let policy">{{policy.workspace}}</td>
      </ng-container>
      <ng-container matColumnDef="object">
        <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Resource</th>
        <td mat-cell *matCellDef="let policy">{{policy.object}}</td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell *matCellDef="let policy">{{policy.role}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headers; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: headers;">
      </tr>
    </table>
    <div *ngIf="dataSource?.data?.length < 1">
      {{emptyMessage}}
    </div>
</div>