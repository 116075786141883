import { Component, OnInit } from '@angular/core';
import { ColDef, FilterChangedEvent, GridApi, GridOptions, GridReadyEvent, IDatasource, IGetRowsParams, RowClickedEvent, SortChangedEvent, ValueFormatterParams } from 'ag-grid-community';
import { AssetResponse, AssetsV3Service, AssetsPage, ASSETS_V3_API_BASE_URL } from '../../services/assets-v3.service';
import Debug from 'debug';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ODataServerSideDataSource } from '../../services/ODataServerSideDataSource';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AssetGetOrCreateDialogComponent } from '../asset-get-or-create-dialog-component/asset-get-or-create-dialog.component';

const debug = Debug(`AssetsPaaS:AssetListComponent`);


@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss']
})
export class AssetListComponent implements OnInit {
  pageSize: number = 100;
  searchSubject: Subject<string> = new Subject();
  searchODataFilter: string = undefined;
  searchODataOrderBy: string = undefined;
  columnDefs: ColDef[] = [
    { headerName: 'Name', field: 'name', sortable: true },
    { headerName: 'Class', field: 'class', sortable: true },
    { headerName: 'Region', field: 'region', sortable: true },
    { headerName: 'Devices', field: 'devices', valueFormatter: this.formatDevices },
    { headerName: 'Accounts', field: 'accounts', valueFormatter: this.formatAccounts },
    { headerName: 'Active Accounts', field: 'accounts', valueFormatter: this.formatActiveAccounts },
    { headerName: 'Events In Stream', field: 'eventsInStream', sortable: true },
  ];
  gridOptions: GridOptions = {
    rowModelType: 'infinite',
    context: {
      nextPageContinuationToken: null
    },
    datasource: new ODataServerSideDataSource(this.getData.bind(this)),
    onRowClicked: this.onAssetClicked.bind(this),
    onFilterChanged: this.onGridFilterChanged.bind(this),
    onSortChanged: this.onGridSortChanged.bind(this),
  };
  gridApi: GridApi;

  constructor(
    private assetsV3Service: AssetsV3Service,
    private router: Router,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.searchSubject
      .pipe(debounceTime(500))
      .subscribe(x => {
          debug('AGGrid:searchSubject %o', x);

          if (x) {
            const normalizedX = x.replace(/[^a-zA-Z0-9]/g, '');
            this.searchODataFilter = `contains(nameNormalized, '${normalizedX}')`;
          } else {
            this.searchODataFilter = undefined;
          }

          this.gridApi.setDatasource(this.gridOptions.datasource);
        });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  onAssetClicked(event: RowClickedEvent) {
    this.router.navigate([this.router.url, `${event.data.id}`]);
  }

  onGridFilterChanged(event: FilterChangedEvent) {
    debug('AGGrid:onGridFilterChanged');
  }

  onGridSortChanged(event: SortChangedEvent) {
    debug('AGGrid:onGridSortChanged');
  }

  getData(odataFilter: string, odataOrderBy: string, continuationToken: string): Promise<any> {
    return this.assetsV3Service.getAssets(this.pageSize, this.searchODataFilter, odataOrderBy, undefined, undefined, continuationToken)
      .toPromise();
  }

  formatDevices(params: ValueFormatterParams) {
    if (!params.value) return;

    const asset: AssetResponse = params.data;
    return asset.devices.length.toString();
  }

  formatAccounts(params: ValueFormatterParams) {
    if (!params.value) return;

    const asset: AssetResponse = params.data;
    return asset.accounts.length.toString();
  }

  formatActiveAccounts(params: ValueFormatterParams) {
    if (!params.data) return;

    const asset: AssetResponse = params.data;
    return asset.accounts.filter(x => x.isADSDataFeedActivated).length.toString();
  }

  onSearchKeyup(event: KeyboardEvent) {
    debug('onSearchKeyup');
    this.searchSubject.next((event.target as HTMLInputElement).value);
  }

  onGetOrCreateClick() {
    this.dialog.open(AssetGetOrCreateDialogComponent);
  }
}
