import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UsersComponent } from './users.component';
import { ResetPasswordDialogComponent, UserDetailsComponent } from './user-details/user-details.component';
import { UserNewComponent } from './user-new/user-new.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersTableComponent } from './user-new/users-table.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UsersService, USERS_API_BASE_URL, UserGroupsService } from '../services/users.service';
import { environment } from '@env/environment';
import { SharedModule } from '../shared/shared.module';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';
import { ClipboardModule } from 'ngx-clipboard';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';

@NgModule({
  declarations: [
    UsersComponent,
    UserDetailsComponent,
    UserNewComponent,
    UsersTableComponent,
    UserEditComponent,
    ResetPasswordDialogComponent,
    UserPermissionsComponent
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    MatDialogModule,
    MatIconModule,
    SharedModule,
    CommonUiSharedModule,
    MatCheckboxModule
  ],
  entryComponents: [ResetPasswordDialogComponent],
  providers: [
    UsersService,
    UserGroupsService,
    { provide: USERS_API_BASE_URL, useValue: environment.usersBaseUrl}
  ]
})
export class UsersModule {
}
