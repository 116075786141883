import { Component, OnInit, Input, Output, OnChanges, OnDestroy, EventEmitter } from '@angular/core';

import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment-timezone';
import { RailSightService } from '../../../../../services/railsight.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ads-ag-grid-railsight-fleet-assets',
  templateUrl: './ag-grid-railsight-fleet-assets.component.html',
  styleUrls: ['./ag-grid-railsight-fleet-assets.component.scss']
})
export class AgGridRailSightFleetAssetsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() body: any = null;
  @Input() header: any = null;

  @Output() updateAccountFleetCLU: EventEmitter<any> = new EventEmitter();

  gridOptions: GridOptions;
  gridApi: any;
  gridColumnApi: any;
  overlayNoRowsTemplate: string;
  overlayLoadingTemplate: string;

  saveSelectedAssetsSub: Subscription;
  toggleSelectedRowsSub: Subscription;
  toggleUnselectedRowsSub: Subscription;
  toggleAllRowsSub: Subscription;
  undoChangesSub: Subscription;

  private existingFleetAssetsSelected: boolean;

  constructor(private railsightService: RailSightService) {
    this.gridOptions = {} as GridOptions;
    this.existingFleetAssetsSelected = false;
  }

  ngOnInit() {

    this.saveSelectedAssetsSub = this.railsightService.triggerSaveSelectedAssets$.subscribe((res: any) => {
      this.saveSelectedAssets();
    });

    this.toggleSelectedRowsSub = this.railsightService.triggerSelectedRowsToggle$.subscribe((res: any) => {
      this.toggleSelectedRows();
    });

    this.toggleUnselectedRowsSub = this.railsightService.triggerUnselectedRowsToggle$.subscribe((res: any) => {
      this.toggleUnselectedRows();
    });

    this.toggleAllRowsSub = this.railsightService.triggerAllRowsToggle$.subscribe((res: any) => {
      this.toggleAllRows();
    });

    this.undoChangesSub = this.railsightService.triggerResetAssetsGrid$.subscribe((res: any) => {
      this.undoChanges();
    });

    this.overlayNoRowsTemplate = 'No Rows Found';
    this.overlayLoadingTemplate = 'No Rows Found';

    this.gridOptions.rowSelection = 'multiple';
    this.gridOptions.stopEditingWhenGridLosesFocus = false;

    this.gridOptions.defaultColDef = {
      sortable: true,
      resizable: true,
      suppressMenu: true,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true, clearButton: true, headerHeight: 20 }
    };

    this.gridOptions.columnTypes = {
      'System.DateTime': {
        valueFormatter: this.dateFormatter.bind(this),
        filter: 'agDateColumnFilter',
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator(filterValue, cellValue) {
            let dateAsString = moment(cellValue).format('DD/MM/YYYY');

            if (dateAsString == null) {
              return 0;
            }

            let dateParts = dateAsString.split('/');
            const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

            dateAsString = moment(filterValue).format('DD/MM/YYYY');
            dateParts = dateAsString.split('/');
            const filterDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterDate) {
              return -1;
            } else if (cellDate > filterDate) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      },
      'System.String': {
        filter: 'agTextColumnFilter'
      },
      'System.String[]': {
        filter: 'agTextColumnFilter'
      },
      'System.Boolean': {
        filter: 'agTextColumnFilter'
      },
      'System.Nullable`1[System.Int16]': {
        filter: 'agTextColumnFilter',
      },
      'System.Nullable`1[System.Int16][]': {
        filter: 'agTextColumnFilter'
      },
      'System.Nullable`1[System.Int32]': {
        filter: 'agTextColumnFilter'
      }
    };

    this.gridOptions.excelStyles = [
      {
        id: 'stringType',
        dataType: 'String',
      }
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    if (this.railsightService.accountFleetAssetNames !== null
      && this.railsightService.accountFleetAssetNames !== undefined
      && !this.existingFleetAssetsSelected) {
      this.selectAllExistingFleetAssets();
    }
  }

  onRowDataChanged() {
    if (this.railsightService.accountFleetAssetNames !== null
      && this.railsightService.accountFleetAssetNames !== undefined
      && !this.existingFleetAssetsSelected) {
      this.selectAllExistingFleetAssets();
    }
  }

  saveSelectedAssets() {
    if (this.gridApi !== null && this.gridApi !== undefined) {
      this.railsightService.selectedFleetAssetNames = [];
      this.gridApi.getSelectedNodes().forEach((node) => {
        this.railsightService.selectedFleetAssetNames.push(node.data.Asset);
      });
    }

    this.updateAccountFleetCLU.emit();
  }

  selectAllExistingFleetAssets() {
    if (this.gridApi !== null && this.gridApi !== undefined) {
      this.gridApi.forEachNode((node) => {
        node.setSelected(this.railsightService.accountFleetAssetNames.includes(node.data.Asset));
      });
      this.gridApi.refreshCells();
      if (this.header && this.header === null) {
        this.gridApi.refreshHeader();
      }
      this.existingFleetAssetsSelected = true;
    }
  }

  onRowSelected(event) {
    event.node.data.Selected = event.node.selected;
  }

  toggleSelectedRows() {
    this.gridApi.setFilterModel({ Selected: { filterType: 'text', type: 'set', filter: 'true' } });
  }

  toggleUnselectedRows() {
    this.gridApi.setFilterModel({ Selected: { filterType: 'text', type: 'set', filter: 'false' } });
  }

  toggleAllRows() {
    this.gridApi.setFilterModel(null);
  }

  undoChanges() {
    this.existingFleetAssetsSelected = false;
    this.selectAllExistingFleetAssets();
  }

  dateFormatter(params) {
    // const currentTimeZone = this.userPreferenceService.Settings.Timezone;
    if (params.value === null || params.value === undefined) {
      return null;
    } else {
      return moment(params.value).tz('UTC').format('YYYY/MM/DD H:mm ');
    }
  }

  ngOnChanges() {
    this.showLoader();
  }

  showLoader() {
    if (this.gridOptions.api) {
      if (this.header && this.header === null) {
        this.gridOptions.api.showNoRowsOverlay();
      } else {
        this.gridOptions.api.hideOverlay();
        this.gridOptions.api.setRowData(this.body);
      }
    }
  }

  ngOnDestroy() {
    if (this.toggleSelectedRowsSub) {
      this.toggleSelectedRowsSub.unsubscribe();
    }
  }
}
