import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuditLogEntry, AuditsService } from '../../services/audits.service';
import { JsonDialogComponent } from '../../shared/dialog/json-dialog/json-dialog.component';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'ads-audit-table',
  templateUrl: './audit-table.component.html',
  styleUrls: ['./audit-table.component.scss']
})
export class AuditTableComponent implements OnInit {
  auditingEmptyData: boolean;
  auditingEmptyMessage = 'No audit records to show. Select a resource type, event type, and account.';
  auditLogsData: MatTableDataSource<AuditLogEntry>;
  availableAuditColumnsToDisplay: string[] = ['id', 'performedByDisplayName', 'eventType', 'eventTime', 'accountId'];
  continuationToken: string;
  dataLoaded: boolean;
  resultsPerPage: number;

  @Input() resourceType: string;
  @Input() eventType: string;
  @Input() accountId: string;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private auditService: AuditsService, private dialog: MatDialog) {
    this.dataLoaded = false;
    this.resultsPerPage = 10;
    this.auditingEmptyData = true;
  }

  ngOnInit() { }

  loadMore(token) {
    this.changeEventType(this.resourceType, this.accountId, this.eventType, token);
  }

  changeResourceType(resource, accountId, token) {
    if (resource !== null) {
      this.auditService.resourceTypeAuditLogs(resource, accountId, this.resultsPerPage, token).subscribe(x => {
        this.resourceType = resource;
        this.dataLoaded = true;

        if (x.logs.length === 0) {
          this.auditLogsData = new MatTableDataSource([]);
        }

        const logList = token ? this.auditLogsData.data.concat(...x.logs) : x.logs;
        this.auditLogsData = new MatTableDataSource(logList);
        this.auditLogsData.sort = this.sort;
        this.auditingEmptyData = logList.length === 0;
        this.continuationToken = x.continuationToken === '' ? null : x.continuationToken;
      });
    }
  }

  changeEventType(resource, accountId, eventType, token) {
    if (resource !== null) {
      this.auditService.resourceTypeAuditLogs(resource, accountId, this.resultsPerPage, token, eventType).subscribe(x => {
        this.resourceType = resource;
        this.dataLoaded = true;

        if (x.logs.length === 0) {
          this.auditLogsData = new MatTableDataSource([]);
        }

        const logList = token ? this.auditLogsData.data.concat(...x.logs) : x.logs;
        this.auditLogsData = new MatTableDataSource(logList);
        this.auditLogsData.sort = this.sort;
        this.auditingEmptyData = logList.length === 0;
        this.continuationToken = x.continuationToken === '' ? null : x.continuationToken;
      });
    }
  }

  onShowData(data) {
    const dialogRef = this.dialog.open(JsonDialogComponent, {
      data: {
        message: data,
        buttonText: 'Close'
      }
    });
  }
}
