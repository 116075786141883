import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { AccountsService } from '../../services/accounts.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountNameResolver implements Resolve<string> {

  constructor(private accountService: AccountsService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const accountId = parseInt(route.paramMap.get('accountId'));

    return this.accountService.account(accountId)
      .pipe(map(x => x.accountName));
  }
}
