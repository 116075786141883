<h2 mat-dialog-title>How Many?</h2>
<form (ngSubmit)="onSave()">
  <mat-dialog-content [formGroup]="form">
    <mat-form-field>
      <mat-label class="form-field-label">Company Name</mat-label>
      <input id="company-searchtext" formControlName="companyName" required matInput [matAutocomplete]="auto" (keyup)="searchTriggered($event.target.value)"
             placeholder="Change this if different than the customer.">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let installer of installerData" [value]="installer.companyName" (click)="onInstallerRowSelect(installer)">
          {{ installer.companyName }} <a class="tab">[{{ installer.companyCode }}]</a>
        </mat-option>
      </mat-autocomplete>
      <ads-control-messages [control]='form.get("companyName")'></ads-control-messages>
    </mat-form-field>
    <mat-form-field>
      <mat-label class="form-field-label">Customer ID</mat-label>
      <input placeholder="123456789" type="number" id="sapCustomerId" formControlName="sapCustomerId" matInput>
      <ads-control-messages [control]='form.get("sapCustomerId")'></ads-control-messages>
    </mat-form-field>
    <mat-form-field>
      <mat-label class="form-field-label">Company Code</mat-label>
      <input id="companyCode" required matInput type="text" maxlength="{{maxCodeLength}}" minlength="{{minCodeLength}}" formControlName="companyCode"
             placeholder="6-18 Characters Alpha Numeric, No Spaces"/>
      <ads-control-messages [control]='form.get("companyCode")'></ads-control-messages>
    </mat-form-field>
    <mat-form-field>
      <mat-label class="form-field-label">Address Line 1</mat-label>
      <input id="address1" formControlName="address1" matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label class="form-field-label">Address Line 2</mat-label>
      <input id="address2" formControlName="address2" matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label class="form-field-label">City</mat-label>
      <input id="city" formControlName="city" matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label class="form-field-label">State/Province</mat-label>
      <input id="region" formControlName="region" matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label class="form-field-label">Country</mat-label>
      <input id="country" formControlName="country" matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label class="form-field-label">Postal Code</mat-label>
      <input id="postalCode" formControlName="postalCode" matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label class="form-field-label">Number to Generate</mat-label>
      <input required type="number" formControlName="amountToCreate" max="10" min="1" matInput />
      <ads-control-messages [control]='form.get("amountToCreate")'></ads-control-messages>
    </mat-form-field>
  </mat-dialog-content>
  <mat-card-actions align="end">
    <button id='policy-new-cancelbutton' type="button" mat-stroked-button mat-dialog-close>
      Cancel
    </button>
    <button id='policy-new-createbutton' mat-flat-button color="primary" type="submit">
      Create
    </button>
  </mat-card-actions>
</form>
