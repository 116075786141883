<div class="table-container">
  <table class="mat-table-nohover" mat-table [dataSource]="auditLogsData" matSort class="m-t-20">
    <ng-container matColumnDef="id">
      <th class="table-header" mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let item">{{ item.id }}</td>
    </ng-container>

    <ng-container matColumnDef="performedByDisplayName">
      <th class="table-header" mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let item">{{ item.performedByDisplayName }}</td>
    </ng-container>

    <ng-container matColumnDef="eventType">
      <th class="table-header" mat-header-cell *matHeaderCellDef>Event Type</th>
      <td mat-cell *matCellDef="let item">{{ item.eventType }}</td>
    </ng-container>

    <ng-container matColumnDef="eventTime">
      <th class="table-header" mat-header-cell *matHeaderCellDef>Event Time</th>
      <td mat-cell *matCellDef="let item">{{ item.eventTime | date: 'medium' }}</td>
    </ng-container>

    <ng-container matColumnDef="accountId">
      <th class="table-header" mat-header-cell *matHeaderCellDef>Account Id</th>
      <td mat-cell *matCellDef="let item">
        <a id='ac-null' *ngIf="item.accountId == null || item.accountId == '0'">-</a>
        <a id='ac-accountlink' *ngIf="item.accountId != null && item.accountId != '0'" (click)="$event.stopPropagation()" href="/accounts/view/{{item.accountId}}/account-details">{{item.accountId}}</a>
      </td>
    </ng-container>
    

    <tr mat-header-row *matHeaderRowDef="availableAuditColumnsToDisplay; sticky: true"></tr>
    <tr mat-row class="hover" (click)="onShowData(row.data)" *matRowDef="let row; columns: availableAuditColumnsToDisplay"></tr>
  </table>
  <div *ngIf="auditingEmptyData">
    <label class="sm-note">{{ auditingEmptyMessage }}</label>
  </div>
  <ng-container *ngIf="continuationToken !== null && continuationToken !== undefined">
    <div class="load-more">
        <button id='load_more_logs' mat-button color="primary"
            (click)="loadMore(continuationToken)">Load More</button>
    </div>
</ng-container>
</div>
