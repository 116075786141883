<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    Edit Unassigned Assets
  </mat-card-title>
  <form (ngSubmit)="onSubmit()">
    <ng-container [formGroup]="assetGroupForm">
      <div class="card-layout m-t-50" *ngIf="assets">
        <mat-card class="mat-card">
          <mat-form-field>
            <input name="assetSearchText" matInput (keyup)="searchTriggered($event.target.value)"
              id="availableAssetsSearchInput" placeholder="Search for assets" />
          </mat-form-field>
          <table mat-table matSort class="m-t-20 assetActive-table" [dataSource]="groupAssetsData"
            *ngIf="assets !== undefined && assets.length > 0">
            <ng-container matColumnDef="assetName">
              <th class="table-header asset-column" mat-header-cell *matHeaderCellDef>Asset Name</th>
              <td class="asset-column" mat-cell *matCellDef="let asset">{{ asset.assetName }}</td>
            </ng-container>
            <ng-container matColumnDef="unitNumber">
              <th class="table-header asset-column" mat-header-cell *matHeaderCellDef>Unit Number</th>
              <td class="asset-column" mat-cell *matCellDef="let asset">{{ asset.unitNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="selected">
              <th class="table-header asset-column" mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let asset">
                <button id='ag-addassetbutton' mat-icon-button class="mat-icon-button green" (click)="setAssetToBeAdded(asset)">
                  <mat-icon>add_box</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="unassignedAssetColumnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: unassignedAssetColumnsToDisplay"></tr>
          </table>
          <div *ngIf="assets !== undefined && assets.length === 0">
            <label class="sm-note">All assets have been assigned to a group</label>
          </div>
        </mat-card>
        <mat-card class="three-card mat-card">
          <h2>Add to Asset Group</h2>
            <input type="text" id="ag-assetgroupsearch" placeholder="Select an Asset Group" aria-label="Number"
              matInput [matAutocomplete]="auto" formControlName="assetGroupName" required/>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of allAssetGroups" [value]="option.name">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
          <table mat-table matSort class="m-t-20" [dataSource]="assetsToAdd" *ngIf="assetsToAdd !== undefined && assetsToAdd.data.length > 0">
            <ng-container matColumnDef="assetToAddNames">
              <th class="table-header assetsToAdd-column" mat-header-cell *matHeaderCellDef>Asset Name</th>
              <td class="assetGroup-column" mat-cell *matCellDef="let assetToAdd">
                {{ assetToAdd.assetName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="assetToAddUnits">
              <th class="table-header assetsToAdd-column" mat-header-cell *matHeaderCellDef>Unit Number</th>
              <td class="assetGroup-column" mat-cell *matCellDef="let assetToAdd">
                {{ assetToAdd.unitNumber }}
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th class="table-header" mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let assetToAdd" class="table-actions">
                <button id='ag-removeassetbutton' mat-icon-button class="mat-icon-button red" (click)="removeAssetToBeAdded(assetToAdd)">
                  <mat-icon>clear</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="assetsToAddColumnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: assetsToAddColumnsToDisplay"></tr>
          </table>
          <div *ngIf="assetsToAdd === undefined || assetsToAdd.data.length === 0">
            <label class="sm-note">Select assets on the left to assign to the group.</label>
          </div>
          <mat-card-actions align="start">
            <button id='ag-cancelbutton' type="button" mat-stroked-button (click)="navigateToAccount()">Cancel</button>
            <button id='ag-savebutton' mat-flat-button color="primary" type="submit">Save</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </ng-container>
  </form>
</mat-card>

