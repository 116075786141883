import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ApplicationInsights, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PortalAppInsights {
  appInsights: ApplicationInsights;
  user;
  constructor(private authService: MsalService) {
    this.user = this.authService.getAccount();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
    if (this.user !== null) {
    this.appInsights.setAuthenticatedUserContext(this.user.userName);
    }
    this.appInsights.trackPageView();
  }
  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception, severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message }, properties);
  }
  private loadCustomTelemetryProperties() {

    this.appInsights.addTelemetryInitializer(envelope => {
      const item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties.UserName = this.user.name;
      item.properties.UserEmail = this.user.userName;
    }
    );
  }
}
