<mat-card>
  <mat-card-title>
    Empty Load
  </mat-card-title>
  <mat-card-subtitle>
    Empty Load
  </mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-card-content>
    <nav mat-tab-nav-bar>
      <a mat-tab-link *ngFor="let tab of tabs" [routerLink]="tab.path" routerLinkActive #rla="routerLinkActive"
        [active]="rla.isActive">
        {{tab.label}}
      </a>
    </nav>
    <router-outlet></router-outlet>
  </mat-card-content>
</mat-card>
