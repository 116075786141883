import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IProcessingLogEntity } from 'src/app/services/devices.service';
import { IProcessingResult, ProcessingStatus } from 'src/app/services/cda-file-processing.service';
import { CDAFileDownloadService } from 'src/app/services/cda-file-download.service';
import { ISAPFileInfoDTO } from 'src/app/services/devices.service';

@Component({
  selector: 'ads-log-entry-detail',
  templateUrl: './log-entry-detail.component.html',
  styleUrls: ['./log-entry-detail.component.scss']
})
export class LogEntryDetailComponent implements OnInit {
  processingResult: IProcessingResult;
  processingCompleted = false;
  processedFileNames: string[];

  constructor(
    public dialogRef: MatDialogRef<LogEntryDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IProcessingLogEntity,
    private cDAFileDownloadService: CDAFileDownloadService
  ) {
    this.data = data;
    this.processingResult = JSON.parse(data.processingResult);
    if (data.processingStatus === ProcessingStatus.Completed) {
      this.processingCompleted = true;
    }
    this.processedFileNames = JSON.parse(data.uploadedFileNames);
  }

  ngOnInit(): void {}
  closeDialog() {
    this.dialogRef.close();
  }

  download(fileName: string) {
    this.cDAFileDownloadService.downloadSAPFile(fileName).subscribe((b) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(b);
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }
}
