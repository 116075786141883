<h1 mat-dialog-title>{{title}}</h1>
<mat-dialog-content>
	<p [innerHTML]="message">
		<!-- {{message}} -->
	</p>
</mat-dialog-content>
<mat-dialog-actions>
	<div>
		<button mat-stroked-button color="{{confirmColor}}" (click)="onConfirmClick()" tabindex="1" id="modal-btn-ok">{{confirmButtonText}}</button>
		<button mat-stroked-button color="{{cancelColor}}" mat-dialog-close tabindex="-1" id="modal-btn-cancel">{{cancelButtonText}}</button>
	</div>
</mat-dialog-actions>
