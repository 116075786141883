import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AssetsComponent } from './assets.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssetsRoutingModule } from './assets-routing.module';
import { AssetsService, ASSETS_API_BASE_URL, AssetTypeService } from '../services/assets.service';
import { environment } from '@env/environment';
import { SharedModule } from '../shared/shared.module';
import { AssetDetailsComponent } from './asset-details/asset-details.component';
import { AssetEditComponent } from './asset-edit/asset-edit.component';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { ValidationService } from '../services/validation.service';
import { AssetsV3Service, AccountWorkspaceAssetsV3Service, ASSETS_V3_API_BASE_URL } from '../services/assets-v3.service';

@NgModule({
  imports: [
    CommonModule,
    AssetsRoutingModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    CommonUiSharedModule,
    NgxMaskModule,
    MatCheckboxModule
  ],
  declarations: [AssetsComponent, AssetDetailsComponent, AssetEditComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ValidationService,
    AssetsService,
    AssetsV3Service,
    { provide: ASSETS_V3_API_BASE_URL, useValue: environment.assetsV3BaseUrl },
    AccountWorkspaceAssetsV3Service,
    { provide: ASSETS_V3_API_BASE_URL, useValue: environment.assetsV3BaseUrl },
    AssetTypeService,
    { provide: ASSETS_API_BASE_URL, useValue: environment.assetsBaseUrl }
  ]
})
export class AssetsModule { }
