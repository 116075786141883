import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { AssetsV3Service } from 'src/app/services/assets-v3.service';
import { AccountsService } from '../../services/accounts.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AssetNameResolver implements Resolve<string> {

  constructor(private assetsService: AssetsV3Service) {}

  resolve(route: ActivatedRouteSnapshot) {
    const assetId = route.paramMap.get('assetId');

    return this.assetsService.getAsset(assetId)
      .pipe(map(x => x.name));
  }
}
