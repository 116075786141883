<button id="jd-close=button" mat-icon-button class="raw-message-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title>Share Assets</h1>
<mat-dialog-content class="share-asset-dialog">
	<form (ngSubmit)="onSubmit()">
    <ng-container [formGroup]="assetsShare">
      <mat-card-content>
        <ads-account-select-search [control]="assetsShare.get('targetAccountId')" placeholder="Target Account"
        formControlName="targetAccountId" [isRequired]="true" #targetAccountSelectBulk></ads-account-select-search>
        <div class="workspace-dropdown" [hidden]="!byWorkspace">
          <ads-workspace-select #workspaceSelect accountId="{{sourceAccountId}}"
          [control]="assetsShare.get('workspace')" formControlName="workspace" [isRequired]="true"></ads-workspace-select>
        </div>
        <mat-checkbox formControlName="canReshare">Can Reshare Assets</mat-checkbox>
      </mat-card-content>
      <mat-card-actions align="end">
        <button id='ad-cancelbutton' type="button" [mat-dialog-close]="true">Cancel</button>
        <button id='ad-updatebutton' mat-flat-button color="primary" type="submit">Share</button>
      </mat-card-actions>
    </ng-container>
  </form>
</mat-dialog-content>


