export enum GroupTypes {
  FLEETS = 'Fleets',
  DEVICES = 'Devices'
}

export const groupBy = (array: any[], groupByField) => {
  return array.reduce((result, obj) => {
    (result[obj[groupByField]] = result[obj[groupByField]] || []).push(obj);
    return result;
  }, {});
};
