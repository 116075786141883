import { IDatasource, IGetRowsParams } from "ag-grid-community";
//import { AssetsPage } from "src/app/services/assets-v3.service";
import Debug from 'debug';

export class ODataServerSideDataSource implements IDatasource {
  debug: Debug = Debug('ODataServerSideDataSource')
  constructor(
    private getData: (odataFilter: string, odataOrderBy: string, continuationToken: string) => Promise<any>
    ) { }

  getRows(params: IGetRowsParams): void {
    this.debug('getRows:params %o', params);

    const sortModel = params.sortModel as Array<IServerSideSortModel>;
    const odataOrderBy = sortModel.map(x => `${x.colId} ${x.sort}`).join(', ');

    let continuationToken = undefined;
    if (params.startRow !== 0 && params.context.nextPageContinuationToken) {
      continuationToken = params.context.nextPageContinuationToken;
    }

        this.getData(undefined, odataOrderBy, continuationToken)
        .then(r => this.handleDatasetSuccess(r, params))
        .catch(params.failCallback);
  }

  handleDatasetSuccess(result: any, origParams: IGetRowsParams) {
    this.debug('handleDatasetSuccess:result: %o', result);
    origParams.context.nextPageContinuationToken = result.continuationToken;

    let lastRow = null; // leave null to indicate there are more pages
    if (!result.continuationToken) {
      lastRow = origParams.startRow + result.items.length;
    }

    origParams.successCallback(result.items, lastRow);
  }
}

interface IServerSideSortModel {
  colId: string,
  sort: string
}
