import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceGroupResponse, DeviceGroupResponseResultSet, DeviceGroupsService } from '../../../../../services/devices.service';
import { ToasterNotificationService } from '../../../../../services/toasterNotification.service';

@Component({
  selector: 'ads-device-groups-table',
  templateUrl: './device-groups-table.component.html',
  styleUrls: ['./device-groups-table.component.scss']
})
export class DeviceGroupsTableComponent implements OnInit {
  private accountId: number;
  baseDeviceGroupsMessage: string;
  columnsToDisplay: string[] = ['name', 'deviceCount', 'action'];
  deviceGroupsTableData = new MatTableDataSource();
  emptyDeviceGroupsData: boolean;
  emptyDeviceGroupsMessage: string;

  @Input() workspace: string;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private route: ActivatedRoute,
    private deviceGroupService: DeviceGroupsService, private toasterService: ToasterNotificationService) {
    this.route.parent.url.subscribe((urlPath) => {
      let accountId = 0;
      if (urlPath.length > 1) {
        accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
      }

      if (isNaN(accountId)) {
        this.router.navigateByUrl('[/accounts]');
      }

      this.accountId = accountId;
    });
  }

  ngOnInit() {
    this.emptyDeviceGroupsData = true;
    this.baseDeviceGroupsMessage = '';
    this.emptyDeviceGroupsMessage = this.baseDeviceGroupsMessage;

    this.searchDeviceGroups('');
  }

  searchDeviceGroups(filterValue: string) {
    if(!this.workspace) {
      this.workspace = 'all';
    }

    this.deviceGroupService.deviceGroups(this.accountId, this.workspace, filterValue, 20, '').subscribe(
      ((deviceGroups: DeviceGroupResponseResultSet) => {
        const deviceGroupsData = deviceGroups;
        deviceGroupsData.results.sort((a, b) => a.name?.localeCompare(b.name));
        this.deviceGroupsTableData = new MatTableDataSource(deviceGroupsData.results);
        this.deviceGroupsTableData.sort = this.sort;

        this.emptyDeviceGroupsData = this.deviceGroupsTableData.filteredData.length === 0;
        this.emptyDeviceGroupsMessage = 'No results';
      }),
      error => {
        this.toasterService.showWarnToaster(error.detail);
      });
  }

  onRowClick(event: DeviceGroupResponse): void {
    this.router.navigate(['device-group', `${event.id}`], { relativeTo: this.route.parent });
  }

  onCreateDeviceGroupClick(): void {
    this.router.navigate(['device-group'], { relativeTo: this.route.parent });
  }
}
