import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { WorkspaceDto, WorkspacesService } from '../../../../services/accounts.service';
import { ToasterNotificationService } from '../../../../services/toasterNotification.service';
import { ConfirmationDialogComponent } from '../../../../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'ads-app-account-workspaces-tab',
  templateUrl: './account-workspaces-tab.component.html',
  styleUrls: ['./account-workspaces-tab.component.scss']
})
export class AccountWorkspacesTabComponent implements OnInit, AfterViewInit {
  accountId: number;
  dataSource: MatTableDataSource<WorkspaceDto>;
  emptyMessage = 'There are no workspaces in this account. Please click Add Workspaces to add a workspace.';
  headers: string[];
  search: Subject<string> = new Subject();
  workspaces: WorkspaceDto[];

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private toasterService: ToasterNotificationService,
    private router: Router,
    private workspacesService: WorkspacesService) {
    this.headers = ['id', 'displayName', 'deleted'];

    this.route.parent.url.subscribe((urlPath) => {
      let accountId = 0;
      if (urlPath.length > 1) {
        accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
      }

      if (isNaN(accountId)) {
        this.router.navigateByUrl('[/accounts]');
      }

      this.accountId = accountId;
    });
  }

  ngOnInit(): void {
    this.getWorkspaces();

    this.search.pipe(debounceTime(500)).subscribe(searchTextValue => {
      this.filterWorkspaces(searchTextValue);
    });
  }

  ngAfterViewInit() {
    this.getWorkspaces();
  }

  searchWorkspacesTriggered(filterValue: any) {
    this.search.next(filterValue);
  }

  filterWorkspaces(filterValue: any) {
    if (filterValue === '' || filterValue === undefined) {
      this.getWorkspaces();
    }
    else {
      this.dataSource.filter = filterValue.toLowerCase();
      if (this.dataSource.filteredData.length < 1) {
        this.emptyMessage = 'No results match search team ' + filterValue + '. Please click Add Workspaces to add a workspace.';
      }
    }
  }

  getWorkspaces() {
    this.workspacesService.workspaces(this.accountId).subscribe(workspaces => {
      this.workspaces = this.alphabetizeList(workspaces, 'id');
      this.dataSource = new MatTableDataSource(this.workspaces);
      this.dataSource.sort = this.sort;
    });
  }

  alphabetizeList(unsortedList, property) {
    return unsortedList.sort((a, b) => a[property].localeCompare(b[property]));
  }

  navigateAddWorkspace() {
    this.router.navigate([`/view/${this.accountId}/add/workspace`], { queryParams: { accountId: this.accountId } });
  }

  deleteWorkspace(workspace: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure you want to delete this workspace?',
        buttonText: {
          ok: 'Delete',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.workspacesService.deleteWorkspace(workspace.accountId, workspace.id)
          .subscribe({
            next: (resp: any) => {
              this.filterWorkspaces('');
              this.toasterService.showInfoToaster('Workspace successfully deleted.');
            },
            error: (err) => {
              this.toasterService.showWarnToaster(err.detail);
            }
          });
      }
    });
  }
}
