<mat-dialog-content>
	<p>
		{{message}}
	</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<div>
		<button mat-stroked-button mat-dialog-close tabindex="-1" id="modal-btn-ok">{{alertButtonText}}</button>
	</div>
</mat-dialog-actions>
