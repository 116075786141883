import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'ads-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
    title = 'Are you sure?';
    message = '';
    confirmButtonText = 'Yes';
    cancelButtonText = 'Cancel';
    confirmColor = 'primary';
    cancelColor = 'basic';
    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
        if (data) {
            this.title = data.title || this.title;
            this.message = data.message || this.message;
            if (data.buttonText) {
                this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
                this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
            }
            this.confirmColor = data.confirmColor || this.confirmColor;
            this.cancelColor = data.cancelColor || this.cancelColor;
        }
    }

    onConfirmClick(): void {
        this.dialogRef.close(true);
    }
}
