<div class="card-layout">
  <mat-card class="large-card">
    <mat-card-title>
      Recent Device Activity
    </mat-card-title>
    <mat-card-subtitle>
      Connected within the last 30 minutes
    </mat-card-subtitle>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div *ngIf="lastConnectedSource">No Data Available</div>
      <table *ngIf="!lastConnectedSource" mat-table [dataSource]="lastConnectedSource" matSort
        class="last-connected-widget">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div>
              Device ID
              <!--<input matInput type="text" name="id" (keyup)="doFilter($event.target)" />-->
            </div>

          </th>
          <td mat-cell *matCellDef="let device">{{ device.id }}</td>
        </ng-container>

        <ng-container matColumnDef="lastActiveTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div>
              Last Active Time
              <!--<input matInput type="text" name="lastActiveTime" (keyup)="doFilter($event.target)" />-->
            </div>

          </th>
          <td mat-cell *matCellDef="let device">{{ device.lastActiveTime }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div>
              Status
              <!--<input matInput type="text" name="status" (keyup)="doFilter($event.target)" />-->
            </div>

          </th>
          <td mat-cell *matCellDef="let device">{{ device.status }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>