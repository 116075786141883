<div class="my-3">
  <mat-card>
    <mat-card-content fxLayout="row" class="manage-permissions-wrapper">
      <button id='dd-managedevicepermissionsbutton' mat-button color="primary" (click)="manageAssetPermissions()">Manage Permissions </button>
    </mat-card-content>
    <mat-card-content class="table-toolbar-wrapper">
      <div fxFlex>
        <mat-form-field>
          <mat-label></mat-label>
          <input id="ac-asset-searchtext" name="searchText" matInput (keyup)="searchTriggered($event.target.value)"
            maxlength="50" #searchText placeholder="To see assets, please begin typing the asset's name or number." />
        </mat-form-field>
      </div>
      <div fxFlex>
        <ads-workspace-select #workspaceSelect accountId="{{accountId}}"></ads-workspace-select>
      </div>
      <div fxFlex>
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
          (page)="updatePaginator($event)">
        </mat-paginator>
      </div>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div class="left-column">
        <ng-container *ngIf="assetData.filteredData.length > 0">
          <span class="bold">Total Owner: </span>
          <mat-chip color="primary"> {{this.totalOwnerAssets}}</mat-chip>
          <span class="bold m-l-10">Total Operator: </span>
          <mat-chip color="accent">{{this.totalOperatorAssets}}</mat-chip>
        </ng-container>
      </div>
    </mat-card-content>
    <mat-card-content>
      <mat-card-content class="table-container">
        <table mat-table [dataSource]="assetData" class="constant-col" [hidden]="assetData.filteredData.length < 1">
          <ng-container matColumnDef="assetName">
            <th mat-header-cell class="table-header" *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let asset">{{ asset.assetName }}</td>
          </ng-container>
          <ng-container matColumnDef="unitName">
            <th mat-header-cell class="table-header" *matHeaderCellDef>Unit Name</th>
            <td mat-cell *matCellDef="let asset">{{ asset.unitName }}</td>
          </ng-container>
          <ng-container matColumnDef="unitNumber">
            <th mat-header-cell class="table-header" *matHeaderCellDef>Unit Number</th>
            <td mat-cell *matCellDef="let asset">{{ asset.unitNumber }}</td>
          </ng-container>
          <ng-container matColumnDef="assetGroupings">
            <th mat-header-cell class="table-header" *matHeaderCellDef>Asset Groups</th>
            <td mat-cell *matCellDef="let asset">{{ asset.assetGroupings }}</td>
          </ng-container>
          <ng-container matColumnDef="assetOwner">
            <th mat-header-cell class="table-header" *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let asset">
              <mat-chip-list>
                <mat-chip color="primary" *ngIf="asset.ownerAccountId === accountId">Owner</mat-chip>
                <mat-chip color="accent" *ngIf="asset.operatorAccountId === accountId">Operator</mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
          <tr mat-row class="hover" (click)="onRowClick(row)" *matRowDef="let row; columns: columnsToDisplay"></tr>
        </table>
      </mat-card-content>
      <div *ngIf="assetData.filteredData.length === 0">
        <label class="sm-note">{{ emptyMessage }}</label>
      </div>
    </mat-card-content>
  </mat-card>
</div>
