<div class="my-3" *ngIf="rowData">
  <mat-card>
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <label class="account-tab-top-padding bold">Search Devices Group:</label>
      </div>
      <div id="leftcolumn">
        <mat-form-field>
          <mat-label></mat-label>
          <input
            id="devices-group-searchtext"
            name="searchText"
            matInput
            (keyup)="searchDevicesGroupTriggered($event.target.value)"
            maxlength="50"
            #searchText
            placeholder="To search a device group, please begin typing the device group name."
          />
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div fxLayoutAlign="start center" class="header-view-list">
        <label class="bold">Total Groups : {{ counter }}</label>
        <button mat-button class="link-button" (click)="openAddDeviceGroupDialog()">Add Device Group</button>
        <button mat-button class="link-button" (click)="openMoveDevicesDialog()">Move Device(s)</button>
        <div fxFlex="410px" fxFlexOffset="120px" fxLayout="row" fxLayoutAlign="start center">
          <label fxFlex="130px" class="bold">Default Group :</label>
          <mat-form-field class="device-group-select" fxLayout="row">
            <mat-label></mat-label>
            <mat-select
              id="as-devicesgroupdropdown"
              [(value)]="defaultGroupSelected"
              (selectionChange)="onDefaultGroupChanged($event)"
              panelClass="as-devicesgroupdropdown-panel"
              disableOptionCentering
              #singleSelect
            >
              <mat-option *ngFor="let group of rowData" id="{{ group.id }}" [value]="group.id">
                {{ group.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button
            fxFlex="85px"
            mat-stroked-button
            class="confirm-button"
            (click)="defaultGroupSelected ? confirmDefaultGropChange() : null"
          >
            Confirm
          </button>
        </div>
      </div>
      <div class="aggrid-height">
        <ag-grid-angular
          style="width: 100%; height: 100%"
          class="ag-theme-alpine"
          (gridReady)="onGridReady($event)"
          [rowData]="rowData"
          [defaultColDef]="defaultColDef"
          [columnDefs]="columnDefs"
        >
        </ag-grid-angular>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<span id="tooltip" class="tooltiptext">
  <div>
    <label>(<span class="green">X</span>-<span class="blue">Y</span>-<span class="orange">Z</span>)</label>
    <p>
      <label>In move state:</label> <br />
      - Take a position every <strong class="green">X</strong> hours <br />
      - Send the list of positions every <strong class="blue">Y</strong> hours
    </p>

    <p>
      <label>In stationary state:</label><br />
      - Take and send a position every <strong class="orange">Z</strong> hours
    </p>
    <div class="divider-tp"></div>
    <label>Example: (<span class="green">1</span>-<span class="blue">12</span>-<span class="orange">24</span>)</label>
    <p>
      <label>Moving:</label><br />
      - Take position every <strong class="green">1</strong> hours <br />
      - Send this positions every <strong class="blue">12</strong> hours
    </p>

    <p>
      <label>Stationnary:</label><br />
      - Take and send a position every <strong class="orange">24</strong> hours
    </p>
  </div>
</span>
