import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionsComponent } from './permissions.component';
import { PolicyNewComponent } from './policy-new/policy-new.component';

const routes: Routes = [
  {
    path: '',
    component: PermissionsComponent,
    data: { breadcrumb: 'Policies' }
  },
  {
    path: 'add',
    component: PolicyNewComponent,
    data: { breadcrumb: 'Add' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PermissionsRoutingModule { }
