<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    Manage Sharing: {{sharedAccountName}}
    <div>
      <button mat-button color="primary" (click)="shareAssets()">Share Assets</button>
    </div>
  </mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class="names-search" id="leftcolumn">
      <mat-form-field>
        <input placeholder="Search Assets by Name" matInput (keyup)="onSearchKeyup($event)" maxlength="50" #searchText />
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-content>
    <ag-grid-angular
      style="width: 100%; height: 570px;"
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      [rowSelection]="'multiple'"
      [suppressRowClickSelection]="true"
      (rowSelected)="onRowSelected($event)"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </mat-card-content>
  <div>
    <mat-card-actions align="end">
      <button id="af-createbutton" mat-flat-button color="primary" (click)="deactivateAssetsClick()" type="submit" [disabled]="!enableSelectedAssets">Deactivate</button>
    </mat-card-actions>
  </div>
</mat-card>
