import { JsonPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'angular-crumbs';
import { Subject } from 'rxjs';
import { AssetResponse, AssetsV3Service } from 'src/app/services/assets-v3.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss']
})
export class AssetDetailsComponent implements OnInit {
  assetId: string;
  asset: AssetResponse
  assetLoadTrigger: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private assetsV3Service: AssetsV3Service,
    private snackBar : MatSnackBar,
  ) {
    this.assetId = this.route.snapshot.params.assetId;
  }

  ngOnInit(): void {
    this.assetLoadTrigger
      .subscribe(() => {
        this.assetsV3Service.getAsset(this.assetId)
          .toPromise()
          .then(x => {
            this.asset = x;
          });
      });

      this.assetLoadTrigger.next();
  }

  onReprojectClick() {
    this.assetsV3Service.reprojectAsset(this.assetId)
      .toPromise()
      .then(() => {
        this.assetLoadTrigger.next();
      });
  }

  copyToClipboard(text: string, fieldName: string) {
    if (text) {
      navigator.clipboard.writeText(text).then(() => {
        this.snackBar.open(`${fieldName} copied to clipboard`, 'Close', {
          duration: 2000,
        });
      }, (err) => {
        console.error('Could not copy text: ', err);
        this.snackBar.open(`Failed to copy ${fieldName}`, 'Close', {
          duration: 2000,
        });
      });
    } else {
      this.snackBar.open(`${fieldName} not available`, 'Close', {
        duration: 2000,
      });
    }
  }


}
