import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { from, Observable, of, Subscription } from 'rxjs';
import {
  DevicesGroupsService,
  IArenaConfigurationPackage,
  IArenaConfigurationPackageResponse,
  IDeviceGroupCreated,
  IDevicesGroups
} from 'src/app/services/devices-groups.service';
import { DeviceGroupResponse, DevicesService } from 'src/app/services/devices.service';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { DevicesSelectionComponent } from '../devices-selection/devices-selection.component';
import { groupBy, GroupTypes } from '../device-groups.model';
import { concatMap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-add-device-group',
  templateUrl: './add-device-group.component.html',
  styleUrls: ['./add-device-group.component.scss']
})
export class AddDeviceGroupComponent implements OnInit, OnDestroy {
  configurations: IArenaConfigurationPackage[] = [];
  configurationSelected: string = '';
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE, ENTER, TAB];
  itemsSelected: any[] = [];
  typeOfItemsGrouped: string = 'Devices';
  devices: DeviceGroupResponse[];
  deviceGroupForm: FormGroup;
  subscriptions: Subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { accountId: number; devices: DeviceGroupResponse[]; defaultGroupName: string; groups: IDevicesGroups[] },
    public dialogRef: MatDialogRef<AddDeviceGroupComponent>,
    private toasterService: ToasterNotificationService,
    private devicesGroupsService: DevicesGroupsService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.devices = [...this.data.devices];
  }

  ngOnInit(): void {
    this.buildDeviceGroupForm();
    this.getArenaConfigurationPackage(this.data.accountId);
  }

  onConfigurationChanged(event: MatSelectChange) {
    this.configurationSelected = event.value;
  }

  getArenaConfigurationPackage(accountId: number) {
    this.devicesGroupsService
      .getArenaConfigurationPackage(accountId, 'all')
      .toPromise()
      .then((response: IArenaConfigurationPackageResponse) => {
        this.configurations = response.results;
      })
      .catch((error) => this.toasterService.showWarnToaster(error));
  }

  buildDeviceGroupForm() {
    this.deviceGroupForm = this.fb.group({
      groupName: [this.data?.defaultGroupName || '', [Validators.required]],
      configuration: ['', [Validators.required]],
      typeItemsGrouped: ['Devices', [Validators.required]]
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our item
    if ((value || '').trim() && !this.itemsSelected.some((device) => device.serialNumber == value.trim())) {
      this.itemsSelected.push({ serialNumber: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(item): void {
    const index = this.itemsSelected.indexOf(item);

    if (index >= 0) {
      this.itemsSelected.splice(index, 1);
    }
  }

  paste(event: ClipboardEvent): void {
    event.preventDefault();
    event.clipboardData
      .getData('Text')
      .split(/;|,| |\n/)
      .forEach((value) => {
        if (value.trim() && !this.itemsSelected.some((device) => device.serialNumber == value.trim())) {
          this.itemsSelected.push({ serialNumber: value.trim() });
        }
      });
  }

  close(params?) {
    this.dialogRef.addPanelClass('add-device-group-dialog-close');
    this.dialogRef.removePanelClass('add-device-group-dialog');
    setTimeout(() => {
      this.dialogRef.close(params);
    }, 100);
  }

  invalidItemsSelected(): boolean {
    return this.itemsSelected.filter((item) => !item.isValid).length > 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openSelectionSection(section: GroupTypes.DEVICES | GroupTypes.FLEETS) {
    const { groupName, configuration } = this.deviceGroupForm.value;
    if (groupName.trim() && configuration.trim()) {
      switch (section) {
        case GroupTypes.DEVICES:
          const dialogRef = this.dialog.open(DevicesSelectionComponent, {
            data: {
              groupName: groupName,
              configuration: this.configurations.filter((config) => config.guid == this.configurationSelected).map((config) => config.name),
              devices: this.devices,
              devicesSelected: this.itemsSelected.map((item) => item.serialNumber)
            },
            panelClass: 'devices-selection-dialog',
            disableClose: true
          });

          this.subscriptions.add(
            dialogRef.afterClosed().subscribe((selection) => {
              this.itemsSelected = selection.changes;
            })
          );
          break;

        case GroupTypes.FLEETS:
          break;
      }
    } else {
      this.toasterService.showWarnToaster('You should fill the group name & configuration fields out, first');
    }
  }

  public get GroupTypes() {
    return GroupTypes;
  }

  addDeviceGroup() {
    const devices = this.itemsSelected.map((item) => ({
      ...this.devices.find((_item: any) => _item.serialNumber === item.serialNumber),
      ...item
    }));
    const devicesWithoutGroup = devices.filter((device) => device.groupId == null).map((device) => device.serialNumber);
    const nonSyncingGroup = this.data.groups.filter((group) => !group.isSyncEnabled);

    if (devicesWithoutGroup.length > 0) {
      if (nonSyncingGroup.length > 0) {
        this.devicesGroupsService.addDevices(this.data.accountId, 'all', nonSyncingGroup[0].id, devicesWithoutGroup).subscribe(
          (res) =>
            this.createGroupAndMoveDevices(
              groupBy(
                devices.map((device) => {
                  if (device.groupId == null) {
                    device.groupId = nonSyncingGroup[0].id;
                  }
                  return device;
                }),
                'groupId'
              )
            ),
          (error) => {
            this.toasterService.showWarnToaster(error?.error?.detail || error?.error?.title || error);
          }
        );
      } else {
        this.toasterService.showWarnToaster('No exists any non-syncing group to add some the ungrouped devices you selected');
      }
    } else {
      this.createGroupAndMoveDevices(groupBy(devices, 'groupId'));
    }
  }

  createGroupAndMoveDevices(devicesCollection) {
    this.devicesGroupsService
      .postDeviceGroup(this.data.accountId, 'all', this.deviceGroupForm.get('groupName').value)
      .pipe(
        switchMap(({ body }) =>
          this.devicesGroupsService
            .setConfigurationPackage(
              this.data.accountId,
              'all',
              body.id,
              this.configurations.find((config) => config.guid == this.configurationSelected)
            )
            .pipe(
              switchMap(() =>
                from(Object.keys(devicesCollection)).pipe(
                  concatMap((deviceGroupId) =>
                    this.devicesGroupsService.moveDevices(
                      this.data.accountId,
                      'all',
                      deviceGroupId,
                      body.id,
                      devicesCollection[deviceGroupId].map((device) => device.serialNumber)
                    )
                  )
                )
              )
            )
        )
      )
      .subscribe(
        () => {
          this.dialogRef.close({ result: 'success' });
          this.toasterService.showInfoToaster('Group created successfully');
        },
        (error) => {
          this.toasterService.showWarnToaster(error?.error?.detail || error?.error?.title || error);
        }
      );
  }
}
