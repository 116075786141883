import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Spinkit } from 'ng-http-loader';
import { AccountAssetEventFactoryEvent, AccountAssetEventFactoryPaged, AssetAccountsEventFactoryV3Service } from 'src/app/services/assets-v3.service';

@Component({
  selector: 'app-account-asset-trace-dialog',
  templateUrl: 'account-asset-trace-dialog.component.html',
  styleUrls: ['./account-asset-trace-dialog.component.scss']
})
export class AccountAssetTraceDialogComponent implements OnInit {
  accountId: number;
  assetId: string;
  locationArray: { lng: any; lat: any; date: string; city: string; }[] = [];
  isLoading: boolean = true;
  hasData: boolean = false;
  apiResponseItemsData: AccountAssetEventFactoryEvent[] = [];
  public spinkit = Spinkit;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<AccountAssetTraceDialogComponent>,
    private assetAccountsEventFactoryV3Service:  AssetAccountsEventFactoryV3Service,
    private datePipe: DatePipe) {
      if (data) {
        this.accountId = data.accountId;
        this.assetId = data.assetId;
      }
  }

  ngOnInit(): void {
    const endDate: Date = new Date();
    let startDate: Date = new Date(endDate);
    startDate.setFullYear(endDate.getFullYear() - 1);  
    //page size to 500 in the API call to enrich map visualization, ensuring a more impressive and powerful display
    let pageSize: number = 500;    
    let dateSort: string = 'desc';

    this.assetAccountsEventFactoryV3Service.getAccountAssetEvents(this.accountId, startDate, endDate, pageSize, undefined, undefined, dateSort, this.assetId)
      .subscribe(((response: AccountAssetEventFactoryPaged) => {
        this.hasData = response.count > 0 ? true : false;

        if (this.hasData) {
          //for line map component
          this.locationArray = response.items.map(item =>  {
            let location = item.eventData.data.extendedData.location;      
            if (location && location.longitude && location.latitude) {
              return {
                lng: location.longitude, 
                lat: location.latitude, 
                date: this.formatDate(item.eventDateTimeUtc), 
                city: location.closestLocale.city + ', '+ location.closestLocale.state
              }
            } 
            else {
              return null;
            }
          }).filter(item => item !== null);

          //for data grid component need entire filterted response.items data as it will be used to display raw json data
          this.apiResponseItemsData = response.items;
      }

      this.isLoading = false;
    }));
  }

  formatDate(originalDate: Date): string {
    const formattedDate: string = this.datePipe.transform(originalDate , 'medium' , 'UTC')
    return formattedDate || '';
  }

  cancel(): void {
      this.dialogRef.close(false);
  }

}
