import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackButtonDirective } from '../shared/directives/back-button.directive';
import { AccountNameComponent } from './account-name/account-name.component';
import { DeviceNameComponent } from './device-name/device-name.component';
import { RouterModule } from '@angular/router';
import { AccountSelectSearchComponent } from './account-select-search/account-select-search.component';
import { WorkspaceSelectComponent } from './workspace-select/workspace-select.component';
import { DeviceSelectSearchComponent } from './device-select-search/device-select-search.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PagesAndFeaturesComponent } from './pages-and-features/pages-and-features.component';
import { DevicesService, DEVICES_API_BASE_URL } from '../services/devices.service';
import { environment } from '@env/environment';
import { ControlMessagesComponent } from './control-messages/control-messages.component';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { ConfirmationDialogComponent } from './dialog/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from './dialog/alert-dialog/alert-dialog.component';
import { PermissionsDialogComponent } from './dialog/permissions-dialog/permissions-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { JsonDialogComponent } from './dialog/json-dialog/json-dialog.component';
import { DragDropDirective } from '../shared/directives/drag-drop.directive';
import { FileUploadComponent } from '../shared/file-upload/file-upload.component';
import { DeviceWorkspaceChipsComponent } from './device-workspace-chips/device-workspace-chips.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { AssetWorkspaceChipsComponent } from './asset-workspace-chips/asset-workspace-chips.component';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';
import { MapLineComponent } from './map-line/map-line.component';

@NgModule({
  declarations: [
    BackButtonDirective,
    AccountNameComponent,
    DeviceNameComponent,
    AccountSelectSearchComponent,
    WorkspaceSelectComponent,
    DeviceSelectSearchComponent,
    PagesAndFeaturesComponent,
    ControlMessagesComponent,
    DateAgoPipe,
    ConfirmationDialogComponent,
    AlertDialogComponent,
    JsonDialogComponent,
    PermissionsDialogComponent,
    DragDropDirective,
    FileUploadComponent,
    DeviceWorkspaceChipsComponent,
    AssetWorkspaceChipsComponent,
    MapLineComponent
  ],
  exports: [
    BackButtonDirective,
    AccountNameComponent,
    DeviceNameComponent,
    AccountSelectSearchComponent,
    WorkspaceSelectComponent,
    DeviceSelectSearchComponent,
    PagesAndFeaturesComponent,
    ControlMessagesComponent,
    DateAgoPipe,
    ConfirmationDialogComponent,
    AlertDialogComponent,
    JsonDialogComponent,
    PermissionsDialogComponent,
    DragDropDirective,
    FileUploadComponent,
    MapLineComponent,
    FormsModule,
    ReactiveFormsModule,
    DeviceWorkspaceChipsComponent,
    AssetWorkspaceChipsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatSelectSearchModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTableModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatChipsModule,
    CommonUiSharedModule
  ],
  entryComponents: [ConfirmationDialogComponent, AlertDialogComponent, JsonDialogComponent, PermissionsDialogComponent],
  providers: [
    DevicesService,
    {
      provide: DEVICES_API_BASE_URL,
      useValue: environment.devicesBaseUrl
    }
  ]
})
export class SharedModule {}
