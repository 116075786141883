import { Component, Inject, OnInit } from '@angular/core';
import { AccountWorkspaceAssetsV3Service, JsonPatchOperation } from '../../services/assets-v3.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToasterNotificationService } from '../../services/toasterNotification.service';

@Component({
  selector: 'ads-message-dialog',
  templateUrl: 'account-asset-properties-dialog.component.html',
  styleUrls: ['./account-asset-properties-dialog.component.scss']
})
export class AccountAssetPropertiesDialogComponent implements OnInit {
  accountId: number;
  assetId: string;
  class: string;
  region: string;
  updatedProperties: JsonPatchOperation[];
  propertyOps = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private accountWorkspaceAssetsV3Service: AccountWorkspaceAssetsV3Service,
    private toasterService: ToasterNotificationService,
    private dialogRef: MatDialogRef<AccountAssetPropertiesDialogComponent>) {
      if (data) {
        this.accountId = data.accountId;
        this.assetId = data.assetId;
        this.class = data.class;
        this.region = data.region;
      }
  }

  ngOnInit() {
    this.addNewOpRow();
  }

  addNewOpRow() {
    this.propertyOps.push({ operationType: "add", propertyName: "", propertyValue: "" });
  }

  removeOpRow(event) {
    var index = this.propertyOps.findIndex(x => x.operationType == event.operationType &&
      x.propertyName == event.propertyName &&
      x.propertyValue == event.propertyValue);

    this.propertyOps.splice(index, 1);
  }

  onSubmit() {
    this.propertyOps.forEach(x => {
      var updateValue = x.propertyValue;
      if(updateValue.toLowerCase() === 'true' || updateValue.toLowerCase() === 'false') {
        updateValue = (updateValue.toLowerCase() === 'true');
      }
      var patchOp = new JsonPatchOperation({
        op: x.operationType,
        path: "/properties/" + x.propertyName,
        value: updateValue
      });
      if(this.updatedProperties == undefined) {
        this.updatedProperties = [patchOp];
      }
      else{
        this.updatedProperties.push(patchOp);
      }
    });
    this.accountWorkspaceAssetsV3Service.patchAsset(this.accountId, "all", this.assetId, "Ops Portal", this.updatedProperties)
      .subscribe(() => {
        this.dialogRef.close(true);
      },
      error => {
        this.handleErrorResponse(error);
      });
  }

  handleErrorResponse(error) {
    var errorResponse = JSON.parse(error.response);
    var errorResponseToDisplay = "";
    if(errorResponse.errors != null) {
      errorResponseToDisplay = errorResponse.errors;
    } else{
      errorResponseToDisplay = errorResponse == null || errorResponse == undefined ? error.detail : errorResponse;
    }
    this.toasterService.showWarnToaster(JSON.stringify(errorResponseToDisplay));
  }

  cancel(): void {
      this.dialogRef.close(false);
  }
}
