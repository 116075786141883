import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MsalGuard, MsalService, BroadcastService } from '@azure/msal-angular';
import { AuditingComponent } from './auditing/auditing.component';
import { ShipmentsComponent } from './shipments/shipments.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [MsalGuard] },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Dashboard' }
  },
  {
    path: 'users',
    loadChildren: () => import('src/app/users/users.module').then((m) => m.UsersModule),
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Users' }
  },
  {
    path: 'devices',
    loadChildren: () => import('src/app/devices/devices.module').then((m) => m.DevicesModule),
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Devices' }
  },
  {
    path: 'sims',
    loadChildren: () => import('src/app/sims/sims.module').then((m) => m.SimsModule),
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Sims' }
  },
  {
    path: 'assets',
    loadChildren: () => import('src/app/assets/assets.module').then((m) => m.AssetsModule),
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Assets' }
  },
  {
    path: 'assets-paas',
    loadChildren: () => import('src/app/assets-paas/assets-paas.module').then((m) => m.AssetsPaaSModule),
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Assets PaaS' }
  },
  {
    path: 'assets-sharing',
    loadChildren: () => import('src/app/assets-sharing/assets-sharing.module').then((m) => m.AssetsSharingModule),
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Assets Sharing' }
  },
  {
    path: 'accounts',
    loadChildren: () => import('src/app/accounts/accounts.module').then((m) => m.AccountsModule),
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Accounts' }
  },
  {
    path: 'auditing',
    component: AuditingComponent,
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Audit History' }
  },
  {
    path: 'shipments',
    loadChildren: 'src/app/shipments/shipments.module#ShipmentsModule',
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Shipments' }
  },
  {
    path: 'shipments-v2',
    loadChildren: 'src/app/shipments-v2/shipments-v2.module#ShipmentsV2Module',
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Shipments V2' }
  },
  {
    path: 'policies',
    loadChildren: () => import('src/app/permissions/permissions.module').then((m) => m.PermissionsModule),
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Policies' }
  },
  {
    path: 'locations',
    loadChildren: 'src/app/locations/locations.module#LocationsModule',
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Locations' }
  },
  {
    path: 'empty-load',
    loadChildren: 'src/app/empty-load/empty-load.module#EmptyLoadModule',
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Empty Load' }
  }
];

const config: ExtraOptions = {
  useHash: false,
  relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private broadcastService: BroadcastService, private msalService: MsalService) {
    const loginFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', () => {
      loginFailureSubscription.unsubscribe();
      this.msalService.loginRedirect();
    });
  }
}
