import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef, GridReadyEvent, RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { IArenaConfigurationPackage } from 'src/app/services/devices-groups.service';
import { DeviceGroupResponse } from 'src/app/services/devices.service';
import { columnDefsDS, defaultColDefDS, setDevicesSelected } from './devices-selection.grid';

@Component({
  selector: 'app-devices-selection',
  templateUrl: './devices-selection.component.html',
  styleUrls: ['./devices-selection.component.scss']
})
export class DevicesSelectionComponent implements OnInit {
  defaultColDef = defaultColDefDS;
  columnDefs: ColDef[] = columnDefsDS;
  rowData!: DeviceGroupResponse[];
  gridApi;
  devicesSelected: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      groupName: string;
      configuration: IArenaConfigurationPackage;
      devices: DeviceGroupResponse[];
      devicesSelected: string[];
    },
    public dialogRef: MatDialogRef<DevicesSelectionComponent>
  ) {}

  ngOnInit(): void {}

  onGridReady(params: GridReadyEvent) {
    setDevicesSelected(this.data.devicesSelected);
    this.rowData = this.data.devices;
    this.gridApi = params.api;
  }

  close(params?) {
    this.dialogRef.addPanelClass('devices-selection-dialog-close');
    this.dialogRef.removePanelClass('devices-selection-dialog');
    setTimeout(() => {
      this.dialogRef.close({
        changes: this.mergeSelections(params)
      });
    }, 100);
  }

  onRowSelected(event: RowSelectedEvent) {
    if (event.node.isSelected()) {
      if (!this.data.devicesSelected.includes(event.data.serialNumber)) {
        this.devicesSelected.push({ serialNumber: event.data.serialNumber });
      }
    } else {
      const idxDevice = this.devicesSelected.findIndex((device) => device.serialNumber === event.data.serialNumber);
      const idxItemsSelected = this.data.devicesSelected.findIndex((item) => item === event.data.serialNumber);
      if (idxDevice !== -1) { this.devicesSelected.splice(idxDevice, 1); }
      if (idxItemsSelected !== -1) { this.data.devicesSelected.splice(idxItemsSelected, 1); }
    }
  }

  mergeSelections(params?) {
    const changes = [
      ...this.data.devicesSelected.map((item) => {
        return { serialNumber: item };
      })
    ];
    if (params) { changes.push(...this.devicesSelected); }
    return changes;
  }

}
