<div class="my-3">
  <mat-card>
    <mat-card-title>
      Create Installer Location
    </mat-card-title>
    <form (ngSubmit)="onSubmit()">
      <ng-container [formGroup]="newInstallerLocationForm">
        <mat-card-content>
          <ng-container>
            <mat-form-field>
              <input id='il-add-customer-id' type="number" matInput formControlName="customerId" maxlength="100" placeholder="Customer ID" required />
            </mat-form-field>
            <mat-form-field>
              <input id='il-add-company-name' matInput formControlName="companyName" maxlength="100" #companyName
                     oninput="this.value = this.value.toUpperCase()" placeholder="Company Name" required />
            </mat-form-field>
            <mat-form-field>
              <input id='il-add-company-code' matInput formControlName="companyCode" maxlength="100" #companyCode
                     oninput="this.value = this.value.toUpperCase()" placeholder="Company Code" required />
            </mat-form-field>
            <mat-form-field>
              <input id='il-add-house-no' matInput formControlName="houseNo" maxlength="100" placeholder="House Number" />
            </mat-form-field>
            <mat-form-field>
              <input id='il-add-street' matInput formControlName="street" maxlength="100" placeholder="Street" required />
            </mat-form-field>
            <mat-form-field>
              <input id='il-add-street2' matInput formControlName="street2" maxlength="100" placeholder="Street 2" />
            </mat-form-field>
            <mat-form-field>
              <input id='il-add-city' matInput formControlName="city" maxlength="100" placeholder="City" />
            </mat-form-field>
            <mat-form-field>
              <input id='il-add-region' matInput formControlName="region" maxlength="100" placeholder="Region" />
            </mat-form-field>
            <mat-form-field>
              <input id='il-add-country' matInput formControlName="country" maxlength="100" placeholder="Country" required />
            </mat-form-field>
            <mat-form-field>
              <input id='il-add-postal' matInput formControlName="postal" maxlength="100" placeholder="Postal Code" required />
            </mat-form-field>
          </ng-container>
        </mat-card-content>
        <mat-card-actions align="end">
          <button id='il-add-cancelbutton' type="button" mat-stroked-button adsBackbutton>
            Cancel
          </button>
          <button id='il-add-createbutton' mat-flat-button color="primary" [disabled]="newInstallerLocationForm.invalid" type="submit">
            Create
          </button>
        </mat-card-actions>
      </ng-container>
    </form>
  </mat-card>
</div>
