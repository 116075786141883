<div class="my-3">
  <mat-card>
    <mat-card-title fxLayout="row" fxLayoutAlign="space-between"> Import Shipment Files </mat-card-title>
    <mat-card-subtitle> Manage shipment files </mat-card-subtitle>

    <mat-divider></mat-divider>

    <mat-card-content>
      <div class="title">Select SAP Shipment File for upload</div>
      <input #fileInput type="file" accept=".xlsx" style="width: 90%" />
      <button id="is-validate-btn"
        type="submit"
        mat-raised-button
        color="primary"
        style="width: 100px; margin-top: 5px"
        (click)="validate(fileInput.files[0])"
        [disabled]="readyToProcess == false">
        Validate
      </button>
    </mat-card-content>

    <mat-divider></mat-divider>

    <mat-card-content>
      <mat-card-subtitle> Log </mat-card-subtitle>
    </mat-card-content>

    <div style="height: 400px; overflow-y: scroll">
      <table mat-table class="mt-20" [dataSource]="logEntries">
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element" class="col-min-width">{{ element.processingStatus }}</td>
        </ng-container>

        <ng-container matColumnDef="Message">
          <th mat-header-cell *matHeaderCellDef>Message</th>
          <td mat-cell *matCellDef="let element" class="col-min-width">{{ element.message }}</td>
        </ng-container>

        <ng-container matColumnDef="TimeStamp">
          <th mat-header-cell *matHeaderCellDef>TimeStamp</th>
          <td mat-cell *matCellDef="let element" class="col-min-width">{{ element.createdDate | date: 'short' }}</td>
        </ng-container>

        <ng-container matColumnDef="Import">
          <th mat-header-cell *matHeaderCellDef>Import</th>
          <td mat-cell *matCellDef="let element" class="col-min-width">
            <button
              class="stroked-btn"
              *ngIf="readyForImport(element)"
              (click)="$event.stopPropagation(); importValidatedFile(element)"
              [disabled]="readyToProcess === false">
              Import
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row class="transparent-hover" *matRowDef="let row; columns: displayedColumns" (click)="openDialog(row)"></tr>
      </table>
    </div>
    <div *ngIf="statusMessage" style="margin-top: 15px; display: flex; flex-direction: row">
      <mat-spinner [diameter]="20"></mat-spinner>
      <div style="margin-left: 3px">{{ statusMessage }}</div>
    </div>
  </mat-card>
</div>
