import { Component, OnInit, ɵSWITCH_RENDERER2_FACTORY__POST_R3__ } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CDAToolFileProcessingService, ProcessingStatus, IProcessingResult } from 'src/app/services/cda-file-processing.service';
import { CDAToolService, FileType } from 'src/app/services/devices.service';
import { ProcessingLogEntity, ProcessingLogEntityResultSet } from 'src/app/services/devices.service';
import { MatDialog } from '@angular/material/dialog';
import { LogEntryDetailComponent } from './log-entry-detail/log-entry-detail.component';

@Component({
  selector: 'ads-import-shipments',
  templateUrl: './import-shipments.component.html',
  styleUrls: ['./import-shipments.component.scss']
})
export class ImportShipmentsComponent implements OnInit {
  displayedColumns: string[] = ['Status', 'Message', 'TimeStamp', 'Import'];
  logEntries: ProcessingLogEntity[] = [];
  maxEntries = 500; // clean log will pop element in logEntries based on this number
  readyToProcess = false;
  statusMessage = '';
  processingResult: IProcessingResult;

  constructor(
    private fileProcessingService: CDAToolFileProcessingService,
    private cdaToolService: CDAToolService,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    await this.checkIfValidationIsInProgress(new Date());
  }

  async checkIfValidationIsInProgress(dateOfToday: Date) {
    this.statusMessage = 'checking backend for file validation in progress...';
    await this.getTodaysLog(dateOfToday);
    if (this.logEntries.length === 0) {
      await this.getYesterdaysLog(dateOfToday);
      if (this.logEntries.length === 0) {
        this.readyToProcess = true;
      } else {
        if (this.logEntries[0].processingStatus === ProcessingStatus.Completed) {
          this.readyToProcess = true;
        } else {
          this.readyToProcess = false;
          this.update(new Date());
        }
      }
    } else {
      if (this.logEntries[0].processingStatus === ProcessingStatus.Completed) {
        this.readyToProcess = true;
      } else {
        this.readyToProcess = false;
        this.update(new Date());
      }
    }
    this.statusMessage = '';
  }

  async getTodaysLog(dateOfToday: Date) {
    const promise: Promise<ProcessingLogEntityResultSet> = this.cdaToolService.getDailyLogEntries(this.getStartOfToday()).toPromise();
    const resultSet: ProcessingLogEntityResultSet = await promise;
    this.prependLog(resultSet);
  }

  async getYesterdaysLog(dateOfToday: Date) {
    const promise: Promise<ProcessingLogEntityResultSet> = this.cdaToolService
      .getDailyLogEntries(this.getStartOfYesterday(dateOfToday))
      .toPromise();
    const resultSet: ProcessingLogEntityResultSet = await promise;
    this.prependLog(resultSet);
  }

  prependLog(resultSet: ProcessingLogEntityResultSet) {
    if (resultSet.count > 0) {
      if (this.logEntries.length === 0) {
        this.logEntries = resultSet.results;
      } else if (resultSet.results.length !== 0) {
        this.logEntries = resultSet.results.concat(this.logEntries);
      }
      this.cleanLog();
    }
  }

  async update(date: Date) {
    if (this.logEntries[0].processingStatus !== ProcessingStatus.Completed.toString()) {
      const promise: Promise<ProcessingLogEntityResultSet> = this.cdaToolService.getDailyLogEntries(date).toPromise();
      const resultSet: ProcessingLogEntityResultSet = await promise;
      resultSet.results.pop(); // remove last item because it's already available from previous update
      this.prependLog(resultSet);
      if (this.logEntries[0].processingStatus !== ProcessingStatus.Completed.toString()) {
        await this.sleep(5000);
      }
      this.update(this.logEntries[0].createdDate);
    } else {
      this.readyToProcess = true;
      this.statusMessage = '';
    }
  }

  async startPolling(date: Date) {
    this.statusMessage = 'getting progress information...';
    const promise: Promise<ProcessingLogEntityResultSet> = this.cdaToolService.getDailyLogEntries(date).toPromise();
    const resultSet: ProcessingLogEntityResultSet = await promise;

    this.prependLog(resultSet);
    if (resultSet.count === 0) {
      await this.sleep(5000);
      this.startPolling(date);
    } else if (this.logEntries[0].processingStatus !== ProcessingStatus.Completed) {
      this.update(this.logEntries[0].createdDate);
    } else {
      this.readyToProcess = true;
      this.statusMessage = '';
    }
  }

  cleanLog() {
    while (this.logEntries.length > this.maxEntries) {
      this.logEntries.pop();
    }
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  validate(file: File) {
    if (file === null || file === undefined) {
      alert('Please select a file for validation!');
    } else {
      this.readyToProcess = false;
      const currentTime: Date = new Date();
      this.statusMessage = 'uploading file...';
      this.fileProcessingService.validateShipmentFile(FileType.SAPShipmentFile, file).subscribe((r) => {
        this.statusMessage = '';
        this.startPolling(currentTime);
      });
    }
  }

  importValidatedFile(entry: ProcessingLogEntity) {
    this.readyToProcess = false;
    const currentTime: Date = new Date();
    this.statusMessage = 'import started...';
    this.cdaToolService.importShipmentFile(FileType.SAPShipmentFile, JSON.parse(entry.uploadedFileNames)).subscribe((r) => {
      this.statusMessage = '';
      this.startPolling(currentTime);
    });
  }

  getStartOfToday(): Date {
    const today: Date = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    return today;
  }

  getStartOfYesterday(today: Date): Date {
    const yesterday: Date = new Date(today.valueOf());
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0);
    yesterday.setMinutes(0);
    yesterday.setSeconds(0);
    return yesterday;
  }

  openDialog(row: ProcessingLogEntity) {
    this.dialog.open(LogEntryDetailComponent, { autoFocus: false, data: row });
  }

  readyForImport(log: ProcessingLogEntity): boolean {
    const result: IProcessingResult = JSON.parse(log.processingResult);
    if (log.processingStatus === ProcessingStatus.Completed && result.ErrorList.length === 0 && log.message !== 'Import completed.') {
      return true;
    }
    return false;
  }
}
