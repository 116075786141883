<button id="jd-close=button" mat-icon-button class="raw-message-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title>Asset Type</h1>
<mat-dialog-content class="asset-type-dialog">
	<ng-container>
    <mat-card-content>
      <mat-form-field>
        <mat-label class="form-field-label">Type</mat-label>
        <mat-select [(value)]="type">
          <mat-option *ngFor="let supportedType of supportedTypes" [value]="supportedType">
            {{supportedType}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions align="end">
      <button type="button" [mat-dialog-close]="true">Cancel</button>
      <button mat-flat-button color="primary" type="submit" (click)="onSubmit()">Update</button>
    </mat-card-actions>
  </ng-container>
</mat-dialog-content>
