import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountSelectSearchComponent } from 'src/app/shared/account-select-search/account-select-search.component';
import { ToasterNotificationService } from '../../../../../services/toasterNotification.service';
import { AccountWorkspacesV3Service } from '../../../../../services/assets-v3.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'ads-message-dialog',
  templateUrl: 'share-to-account-dialog.component.html',
  styleUrls: ['./share-to-account-dialog.component.scss']
})
export class ShareToAccountDialogComponent implements OnInit {
  accountShare: FormGroup;
  sourceAccountId: number;
  sourceAccountName: string;
  targetAccountId: number;
  @ViewChild('accountSelect', { static: true }) accountSelect: AccountSelectSearchComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private formBuilder: FormBuilder,
    private toasterService: ToasterNotificationService,
    private accountWorkspacesV3Service: AccountWorkspacesV3Service,
    private dialogRef: MatDialogRef<ShareToAccountDialogComponent>) {
      if (data) {
        this.sourceAccountId = data.sourceAccountId;
        this.sourceAccountName = data.sourceAccountName;
      }
  }

  ngOnInit() {
    this.accountShare = this.formBuilder.group({
      targetAccountId: null
    });
  }

  onSubmit() {
    var targetAccountId = this.accountShare.value.targetAccountId;
    this.accountWorkspacesV3Service.addAccountToSharing(this.sourceAccountId, targetAccountId, "Ops Portal")
    .subscribe({
      next: (resp: any) => {
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.toasterService.showWarnToaster(err.detail);
      }
    });
  }

  cancel(): void {
      this.dialogRef.close(false);
  }
}
