import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { WorkspaceDto, WorkspacesService } from 'src/app/services/accounts.service';
import { AssetGroupDto, AssetGroupsService, AssetsService } from 'src/app/services/assets.service';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';

@Component({
  selector: 'ads-asset-workspace-chips',
  templateUrl: './asset-workspace-chips.component.html',
  styleUrls: ['./asset-workspace-chips.component.scss']
})
export class AssetWorkspaceChipsComponent implements OnInit {
  @Input() assetId: number;
  @Input() groupId: number;
  @Input() accountId: number;
  @Input() searchable: string;

  @ViewChild('workspaceInput') workspaceInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') autoComplete: MatAutocompleteModule;

  workspacesDisplayable: WorkspaceDto[] = [];
  workspaces: string[] = [];
  separatorKeyCodes: number[] = [ENTER, COMMA];
  allWorkspaces: WorkspaceDto[];
  searchableWorkspaces: WorkspaceDto[];
  filteredWorkspaces: Observable<WorkspaceDto[]>;
  inputFilteredWorkspaces: Observable<string[]>;
  workspaceCtrl = new FormControl();
  assetGroup: AssetGroupDto;


  constructor(private workspaceService: WorkspacesService, private assetService: AssetsService,
    private toasterService: ToasterNotificationService, private router: Router, private assetGroupService: AssetGroupsService) { }

  ngOnInit(): void {
    if(this.searchable === 'false') {
      this.assetService.asset(this.accountId, 'all', this.assetId).subscribe(x => {
        this.workspaces = x.workspaces;

        this.workspaceService.workspaces(this.accountId).subscribe(x => {
          this.allWorkspaces = x;
          this.searchableWorkspaces = x.filter(x => this.workspaces.indexOf(x.id) === -1);

          this.getWorkspaceNames();
        });

      });

      return;
    }

    if(this.assetId && !this.groupId && this.searchable === 'true') {
      this.assetService.asset(this.accountId, 'all', this.assetId).subscribe(x => {
        this.workspaces = x.workspaces;

        this.workspaceService.workspaces(this.accountId).subscribe(x => {
          this.allWorkspaces = x;
          this.searchableWorkspaces = x.filter(x => this.workspaces.indexOf(x.id) === -1);

          this.getWorkspaceNames();
    
          this.filteredWorkspaces = this.workspaceCtrl.valueChanges.pipe(
            startWith(null),
            map((workspace: string | null | undefined) => workspace ? this.filter(workspace) : this.searchableWorkspaces.slice())
          );
        });
      });
    } else {
      this.assetGroupService.assetGroup(this.accountId, 'all', this.groupId).subscribe(x => {
        this.assetGroup = x;
        this.workspaces = x.workspaces;

        this.workspaceService.workspaces(this.accountId).subscribe(x => {
          this.allWorkspaces = x;
          this.searchableWorkspaces = x.filter(x => this.workspaces.indexOf(x.id) === -1);

          this.getWorkspaceNames();

          this.filteredWorkspaces = this.workspaceCtrl.valueChanges.pipe(
            startWith(null),
            map((workspace: string | null | undefined) => workspace ? this.filter(workspace) : this.searchableWorkspaces.slice())
          );
        });
      });
    }
  }

  getWorkspaceNames() {
    this.workspaces.forEach(x => {
      var workspace = this.allWorkspaces.findIndex(y => y.id === x);

      if(!this.workspacesDisplayable.includes(this.allWorkspaces[workspace])) {
        this.workspacesDisplayable.push(this.allWorkspaces[workspace]);
      }
    });
  }

  onWorkspaceAdd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim().toLowerCase();

    if (value) {      
      let foundWorkspace: WorkspaceDto;

      this.searchableWorkspaces.forEach(x => {
        if(x.id.toLowerCase() === value || x.displayName.toLowerCase() === value) {
          foundWorkspace = x;
        }
      });

      if(foundWorkspace) {
        this.workspaces.push(foundWorkspace.id);
        this.getWorkspaceNames();

        var index = this.searchableWorkspaces.findIndex(x => x.id === foundWorkspace.id);
        this.searchableWorkspaces.splice(index, 1);

        this.filteredWorkspaces = of(this.searchableWorkspaces);               
      } else {
        this.toasterService.showWarnToaster("That workspace does not exist on this account");
      }
    }

    event.input!.value = '';

    this.workspaceCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.workspaces.push(event.option.value);

    var index = this.searchableWorkspaces.findIndex(x => x.id === event.option.value);
    this.searchableWorkspaces.splice(index, 1);

    this.filteredWorkspaces = of(this.searchableWorkspaces);

    this.getWorkspaceNames();
    
    this.workspaceInput.nativeElement.value = '';
    this.workspaceCtrl.setValue(null);
  }

  remove(workspace: WorkspaceDto): void {
    const index = this.workspacesDisplayable.findIndex(x => x === workspace);
    const idIndex = this.workspaces.findIndex(x => x === workspace.id);


    if(index >= 0) {
      this.workspacesDisplayable.splice(index, 1);
    }

    if(idIndex >= 0) {
      this.workspaces.splice(idIndex, 1);
    }

    this.workspaceService.workspaces(this.accountId).subscribe(x => {
      this.searchableWorkspaces = x.filter(y => this.workspaces.indexOf(y.id) === -1);

      this.filteredWorkspaces = of(this.searchableWorkspaces);
    });
  }

  private filter(value: string): WorkspaceDto[] {
    const filterValue = value.toLowerCase();
    
    return this.searchableWorkspaces.filter(x => x.displayName.toLowerCase().includes(filterValue));
  }

  chipClick() {
    this.router.navigateByUrl(`/accounts/view/${this.accountId}/workspaces`);
  }

}
