<div class="my-3">
  <mat-card>
    <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
      Manage Shipment Files
      <div>
        <button id="s-import-btn" mat-button color="primary" [routerLink]="['/shipments/import-shipments']"  routerLinkActive="active" >Import Shipment</button>
        <button id="s-update-btn" mat-button color="primary">Update Shipment</button>
      </div>
    </mat-card-title>
    <mat-card-subtitle> Manage shipment files </mat-card-subtitle>

    <mat-divider></mat-divider>

    <mat-card-content>
      <div>Search for shipment files by shipment date</div>
    </mat-card-content>

    <mat-form-field>
      <div>
        <input
          matInput
          #datePickerInput
          [matDatepicker]="picker"
          placeholder="Choose a shipment date"
          style="width: 200px; margin-right: 5px"
          (dateChange)="dateChange(datePickerInput.value)"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </mat-form-field>

    <mat-divider></mat-divider>

    <table *ngIf="files?.length != 0 && files != null" mat-table class="mt-20" [dataSource]="files">
      <ng-container matColumnDef="File Name">
        <th mat-header-cell *matHeaderCellDef>File Name</th>
        <td mat-cell *matCellDef="let element" class="col-min-width">
          <a class="underline" mat-button color="primary" (click)="download(element)">{{ element.originalName }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="File Type">
        <th mat-header-cell *matHeaderCellDef>File Type</th>
        <td mat-cell *matCellDef="let element" class="col-min-width">{{ element.fileType }}</td>
      </ng-container>

      <ng-container matColumnDef="Processed By">
        <th mat-header-cell *matHeaderCellDef>Processed By</th>
        <td mat-cell *matCellDef="let element" class="col-min-width">{{ element.processedBy }}</td>
      </ng-container>

      <ng-container matColumnDef="File Status">
        <th mat-header-cell *matHeaderCellDef>File Status</th>
        <td mat-cell *matCellDef="let element" class="col-min-width">{{ element.fileStatus }}</td>
      </ng-container>

      <ng-container matColumnDef="Created Date">
        <th mat-header-cell *matHeaderCellDef>Created Date</th>
        <td mat-cell *matCellDef="let element" class="col-min-width">{{ element.createdDate | date: 'long' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="transparent-hover" *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div *ngIf="datePickerInput.value != null && files?.length === 0" style="margin-top: 10px; margin-bottom: 10px">
      There are no shipment files found for this shipment date!
    </div>

    <mat-divider></mat-divider>

    <label class="sm-note">Search for shipment files with a shipment date</label>
  </mat-card>
</div>
