import { AfterViewInit, ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { WorkspacesService, NewWorkspace } from '../../../../../services/accounts.service';

@Component({
  selector: 'ads-app-workspace-new',
  templateUrl: './workspace-new.component.html',
  styleUrls: ['./workspace-new.component.scss']
})
export class WorkspaceNewComponent implements OnInit, AfterViewInit {
  accountId: number;
  workspaceForm: FormGroup;
  workspaceFromName: string;
  formValid = false;

  typingTimer;
  doneTypingInterval = 300;

  constructor(private activatedRouter: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private router: Router,
    private toasterService: ToasterNotificationService,
    private workspacesService: WorkspacesService) { }

  ngOnInit() {
    this.workspaceForm = this.fb.group({
      workspaceId: ['', Validators.required],
      workspaceName: ['', Validators.required]
    });

    this.activatedRouter.queryParams.subscribe(params => {
      if (params.accountId) {
        this.accountId = parseInt(params.accountId, 10);
      }
    });
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#workspace-create-name');
    element.focus();
    this.cdr.detectChanges();
  }

  workspaceNameChange(event) {
    this.workspaceFromName = this.cleanWorkspaceName(event.target.value);
  }

  checkWorkspaceId(workspaceId) {
    if(workspaceId.length === 0) {
      this.formValid = false;
      this.workspaceFromName = null;
      this.workspaceForm.setErrors({'invalid': true})
      return;
    }

    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.workspaceFromName ? this.workspacesService.workspace(this.accountId, workspaceId).subscribe(x => {
        if(x && x.id === this.workspaceFromName) {
          this.formValid = false;
          // this.workspaceForm.setErrors({'invalid': true})
        } else {
          this.formValid = true;
          // this.workspaceForm.setErrors({'invalid': false})
        }
      }) : this.formValid = false;
    }, this.doneTypingInterval);
  }

  cleanWorkspaceName(workspaceId: string) {
    const formatted =  workspaceId.replace(/[ ]/g, '-').replace(/[^a-zA-Z0-9_-]/g, '').toLowerCase();
    this.checkWorkspaceId(formatted);

    return formatted;
  }

  cleanWorkspaceId(workspaceId: any) {
    const pattern = /^[a-zA-Z0-9_-]+$/;
    let formatted = workspaceId;
    if (!pattern.test(workspaceId.target.value)) {
      formatted = workspaceId.target.value.replace(/[^a-zA-Z0-9_-]/g, '').toLowerCase();
      workspaceId.target.value = formatted;
    }

    this.checkWorkspaceId(formatted.target.value);
  }

  onSubmit() {
    const newWorkspace: NewWorkspace = {
      id: this.workspaceForm.controls.workspaceId.value,
      displayName: this.workspaceForm.controls.workspaceName.value,
      init: null,
      toJSON: null
    };

    this.workspacesService.createWorkspace(this.accountId, newWorkspace)
      .subscribe({
        next: () => {
          this.router.navigate([`/view/${this.accountId}/account-workspaces`]);
          this.toasterService.showInfoToaster('Workspace has been successfully created.');
        },
        error: (err) => {
          this.toasterService.showWarnToaster(err.detail);
          this.formValid = false;
        }
      });
  }
}
