<div *ngIf="!isUpdating" [@inOutAnimationDragDrop] class="container" adsDragDrop (fileDropped)="onFileDropped($event)">
    <input type="file" #fileDropRef id="fileDropRef" 
        multiple (change)="fileBrowseHandler($event.target.files)" />
    <img src="assets/img/ic-upload-file.svg" alt="">
    <h3>Drag and drop file here</h3>
    <h3>or</h3>
    <label for="fileDropRef">Browse for file</label>
</div>
<div class="files-list">
    <div class="single-file" [@inOutAnimationFile] *ngFor="let file of files; let i = index">
        <img src="assets/img/ic-file.svg" width="45px" alt="file">
        <div class="info">
            <h4 class="name">
                {{ file?.name }}
            </h4>
            <p class="size">
                {{ formatBytes(file?.size) }}
            </p>
            <mat-progress-bar *ngIf="!isUpdating" [value]="file?.progress"></mat-progress-bar>
            <p *ngIf="isUpdating" class="size">
                Status: {{ file?.status }}
            </p>
        </div>
        <img *ngIf="!isUpdating" src="assets/img/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFileByIndex(i)">
    </div>
</div>
<div class="center" [@inOutAnimationFile] *ngIf="!isUpdating && files.length > 0">
    <button id="file-upload-all-btn" class="button-lg" (click)="uploadAll()">
        Upload All
    </button>
    <button id="file-remove-all-btn" class="button-lg" (click)="deleteAll()">
        Remove All
    </button>
</div>
<div class="center" [@inOutAnimationFile] *ngIf="isUpdating && hasFinished">
    <button id="file-reset-btn" class="button-lg" (click)="reset()">
        Start New Upload
    </button>
</div>
<mat-divider></mat-divider>   
<section class="fileRequester" fxLayout="row wrap" fxLayoutAlign="start start">
    <label>Request File Status</label>
    <div class="center">
        <mat-form-field>
            <mat-label></mat-label>
            <input id='file-status-request' name="fileRequestText" matInput
            maxlength="255" #fileRequestText placeholder="File name" (keyup)="fileStatusRequested=false"/>
        </mat-form-field>
        <button class="button-sm" [disabled]="!fileRequestText.value" (click)="requestFileStatus(fileRequestText.value)">
            Request
        </button>
    </div>
    <p *ngIf="fileStatusRequested" class="size">
        Status: {{ requestedFile?.status }}
    </p>
</section>
