<div *ngIf="!InOverlay" class="gridPanelControlMap">
  <div class="icons d-flex align-items-end pb-1">
    <i
      (click)="returnBounds()"
      class="icon-btn far fa-dot-circle"
      tooltipPosition="bottom"
    ></i>
    <input
      type="checkbox"
      (click)="maximizeMap()"
      class="icon-btn fas fa-expand-alt"
      tooltipPosition="bottom"
    />
  </div>

  <div class="map-type">
    <button class="btn btn-default view-type selectedBtn disable-button-interaction">
      Map
    </button>
  </div>
</div>
<div [hidden]="mapLoaded" class="loading-icon"></div>
<div clas="mapWrapper">
  <div
    style="height: 250px; width: 100%;"
    id="map"
    class="map"
    (window:resize)="reloadMap()"
    [hidden]="!mapLoaded"
    #lineMap
  ></div>
  <div id="overview_map" [hidden]="!mapLoaded"></div>
</div>
