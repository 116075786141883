<div>
  <button #matMenuTrigger mat-raised-button color="primary" [matMenuTriggerFor]="menu" style="float: right">
    Columns
  </button>

  <mat-menu #menu="matMenu">
    <div *ngFor="let e of columns; let i = index;" style="padding: 0 20px">
      <mat-checkbox
        [(ngModel)]="e.visible"
        (click)="$event.stopPropagation()"
        (change)="updateColumnsToDisplay()"
        >
        {{ e.label }}
      </mat-checkbox>
    </div>
  </mat-menu>
</div>

<table mat-table [dataSource]="events">
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let e; columns: columnsToDisplay"></tr>
  <ng-container matColumnDef="dateTime">
    <th mat-header-cell *matHeaderCellDef>Date/Time</th>
    <td mat-cell *matCellDef="let e">{{e.time < inspecificFutureDate ? dateToString(e.time) : ''}}</td>
  </ng-container>
  <ng-container matColumnDef="receivedTime">
    <th mat-header-cell *matHeaderCellDef>Received Date/Time</th>
    <td mat-cell *matCellDef="let e">{{e.receivedTime ? dateToString(e.receivedTime) : ''}}</td>
  </ng-container>
  <ng-container matColumnDef="sequentiality">
    <th mat-header-cell *matHeaderCellDef>Sequentiality</th>
    <td mat-cell *matCellDef="let e">{{e.sequentiality}}</td>
  </ng-container>
  <ng-container matColumnDef="accounts">
    <th mat-header-cell *matHeaderCellDef>Accounts</th>
    <td mat-cell *matCellDef="let e">{{ e.accountIds.join(', ') }}</td>
  </ng-container>
  <ng-container matColumnDef="eventType">
    <th mat-header-cell *matHeaderCellDef>Event Type</th>
    <td mat-cell *matCellDef="let e">{{e.eventType}}</td>
  </ng-container>
  <ng-container matColumnDef="sightingCode">
    <th mat-header-cell *matHeaderCellDef>Code</th>
    <td mat-cell *matCellDef="let e">{{e.sightingCode}}</td>
  </ng-container>
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let e">{{e.description}}</td>
  </ng-container>
  <ng-container matColumnDef="emptyLoadStatus">
    <th mat-header-cell *matHeaderCellDef>E/L</th>
    <td mat-cell *matCellDef="let e">{{e.emptyLoadStatus}}</td>
  </ng-container>
  <ng-container matColumnDef="location">
    <th mat-header-cell *matHeaderCellDef>Location</th>
    <td mat-cell *matCellDef="let e">{{e.location}}</td>
  </ng-container>
  <ng-container matColumnDef="splc">
    <th mat-header-cell *matHeaderCellDef>SPLC</th>
    <td mat-cell *matCellDef="let e">{{e.splc}}</td>
  </ng-container>
  <ng-container matColumnDef="destinationSplc">
    <th mat-header-cell *matHeaderCellDef>Dest. SPLC</th>
    <td mat-cell *matCellDef="let e">--&gt; {{e.destinationSplc}}</td>
  </ng-container>
  <ng-container matColumnDef="distanceFromLastLocation">
    <th mat-header-cell *matHeaderCellDef>KMs Δ</th>
    <td mat-cell *matCellDef="let e" style="text-align: right">{{e.distanceFromLastLocation | number:'1.2' }}</td>
  </ng-container>
  <ng-container matColumnDef="links">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let e">
      <button mat-button color="primary" (click)="openRawEventDialog(e.eventType, e.source)">Raw Event</button>
      <span *ngFor="let l of e.resourceLinks">
        <a mat-button color="primary" *ngIf="l.link" [href]="l.link" target="_blank">{{ l.text }}</a>
        <button mat-button color="primary" *ngIf="l.resourceGetter" (click)="openRawEventDialog(l.text, null, l.resourceGetter)">{{ l.text }}</button>
      </span>
    </td>
  </ng-container>
</table>
