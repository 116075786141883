import { Component, OnInit, AfterViewInit, Renderer2, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AdsAccountDto, AccountsService } from '../../services/accounts.service';
import {
  DeviceGroupsService,
  DevicesService,
  Status,
  DeviceSearchDtoPagedListDto,
  DeviceGroupResponse,
  DeviceSearchDto,
  DeviceGroupUpdateRequest
} from '../../services/devices.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { ToasterNotificationService } from '../../services/toasterNotification.service';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../shared/dialog/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { DeviceWorkspaceChipsComponent } from 'src/app/shared/device-workspace-chips/device-workspace-chips.component';

@Component({
  selector: 'ads-app-account-edit',
  templateUrl: './device-group.component.html',
  styleUrls: ['./device-group.component.scss']
})

export class DeviceGroupEditComponent implements OnInit, AfterViewInit {

  @ViewChild('deviceGroupName') deviceGroupName: ElementRef;
  @ViewChild('chips') chips: DeviceWorkspaceChipsComponent;

  deviceGroupForm: FormGroup;
  private accountId: number;
  private deviceGroupId: string;
  private newDeviceGroup: boolean;
  public account: AdsAccountDto;
  public deviceGroup: DeviceGroupResponse = new DeviceGroupResponse();
  private search: Subject<string> = new Subject();
  groupDevicesData: MatTableDataSource<{}>;
  availableDevicesRawData: DeviceSearchDto[];
  availableDevicesData: MatTableDataSource<{}>;
  availableDevicesSearchTerm = '';
  availableDevicesEmptyData = true;
  availableDevicesBaseMessage = 'To see device information, please begin typing a serial number';
  availableDevicesEmptyMessage: string = this.availableDevicesBaseMessage;
  deviceGroupFormNameControl = 'deviceGroupName';
  loaded = false;

  availableDevicesColumnsToDisplay: string[] = ['serialNumber', 'imei', 'dateCreated', 'action'];
  addedDeviceColumnsToDisplay: string[] = ['serialNumber', 'action'];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterNotificationService,
    private deviceGroupService: DeviceGroupsService,
    private deviceService: DevicesService,
    private accountService: AccountsService,
    private formBuilder: FormBuilder,
    private location: Location,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog) {

    this.accountId = Number.parseInt(this.route.parent.snapshot.params.id, 10);

    this.newDeviceGroup = this.route.snapshot.params.deviceGroupId === undefined ? true : false;

    this.deviceGroupId = this.newDeviceGroup ? undefined : this.route.snapshot.params.deviceGroupId;

    this.deviceGroupForm = this.formBuilder.group({
      deviceGroupName: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.search.pipe(debounceTime(500)).subscribe(searchTextValue => {
      this.searchDevices(searchTextValue);
    });

    this.accountService.account(this.accountId).subscribe(
      ((accountResponse: AdsAccountDto) => {
        this.account = accountResponse;
        if (this.account === undefined) {
          this.router.navigateByUrl('/accounts');
        }
      }),
      error => {
        this.router.navigateByUrl('/accounts');
      });

    if (this.deviceGroupId !== undefined) {
      this.deviceGroupService.deviceGroup(this.accountId, 'all', this.deviceGroupId).subscribe(
        ((deviceGroupResponse: DeviceGroupResponse) => {
          this.deviceGroup = deviceGroupResponse;
          this.groupDevicesData = new MatTableDataSource(this.deviceGroup.devices);
          if (this.deviceGroup !== undefined) {
            this.deviceGroupForm.controls[this.deviceGroupFormNameControl].setValue(this.deviceGroup.name);
            
            this.loaded = true;
          }
        }),
        error => {
          this.location.back();
        });
    } else {
      this.deviceGroup.devices = [];
      this.groupDevicesData = new MatTableDataSource();
    }
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#dg-devicegroupname');
    element.focus();
    this.cdr.detectChanges();
  }

  searchTriggered(filterValue: any) {
    this.availableDevicesSearchTerm = filterValue;
    this.search.next(filterValue);
  }

  searchDevices(filterValue: any) {
    if (filterValue !== '') {
      this.deviceService.devices(0, 100, undefined, Status.Active,
        this.accountId, undefined, filterValue, true, this.deviceGroupId)
      .subscribe((resp: DeviceSearchDtoPagedListDto) => {
        this.availableDevicesRawData = resp.items;

        this.refreshAvailableDevicesData();

        this.availableDevicesEmptyMessage = 'No active devices match search term ' + filterValue;
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
    } else {
      this.availableDevicesEmptyData = true;
      this.availableDevicesEmptyMessage = this.availableDevicesBaseMessage;
    }
  }

  onSubmit() {
    this.deviceGroupForm.markAllAsTouched();

    if (this.deviceGroupForm.invalid) {
      this.deviceGroupName.nativeElement.focus();
      return;
    }

    if(this.deviceGroup.workspaces && this.deviceGroup.workspaces !== this.chips.workspaces) {
      this.deviceGroup.workspaces = this.chips.workspaces;
    }

    if (this.newDeviceGroup) {
      this.deviceGroup.name = this.deviceGroupForm.value.deviceGroupName;
      this.deviceGroup.accountId = this.accountId;

      this.deviceGroupService.postDeviceGroup(this.accountId, 'all', this.deviceGroup).subscribe(g => {
        this.toasterService.showInfoToaster('Device group created!');
        this.router.navigate([`${g.id}`], { relativeTo: this.route });
      },
      (error) => {
        this.toasterService.showWarnToaster(error.detail);
      });
    } else {
      this.deviceGroup.name = this.deviceGroupForm.value.deviceGroupName;

      const deviceUpdateRequest = new DeviceGroupUpdateRequest();
      deviceUpdateRequest.name = this.deviceGroup.name;
      deviceUpdateRequest.workspaces = this.deviceGroup.workspaces;

      this.deviceGroupService.updateDeviceGroup(this.deviceGroup.accountId, 'all',
        this.deviceGroup.id, deviceUpdateRequest).subscribe(u => {
        this.toasterService.showInfoToaster('Device group updated!');
      });
    }
  }

  refreshAvailableDevicesData() {
    if (this.availableDevicesRawData !== undefined) {
      let filteredData = this.availableDevicesRawData;

      if (this.deviceGroup.devices !== undefined) {
        for (const groupDevice of this.deviceGroup.devices) {
          filteredData = filteredData.filter(item => item.serialNumber !== groupDevice);
        }
      }
      this.availableDevicesData = new MatTableDataSource(filteredData);

      this.availableDevicesEmptyData = this.availableDevicesData.filteredData.length === 0;
    }
  }

  removeDevice(deviceSerial: string) {
    this.deviceGroup.devices = this.deviceGroup.devices.filter(item => item !== deviceSerial);
    this.groupDevicesData = new MatTableDataSource(this.deviceGroup.devices);
    this.refreshAvailableDevicesData();
  }

  addDevice(device: DeviceSearchDto) {
    // Add to right column
    this.deviceGroup.devices.push(device.serialNumber);
    this.groupDevicesData = new MatTableDataSource(this.deviceGroup.devices);

    // Refresh left column
    this.refreshAvailableDevicesData();
  }

  navigateToAccount() {
    this.router.navigateByUrl(`/accounts/view/${this.account.accountId}/account-details`);
  }

  onDeleteDeviceGroupClick() {
    if (this.deviceGroup.devices.length > 0) {
      this.dialog.open(AlertDialogComponent, {
        data: {
          message: 'Please remove all devices before deleting device group.'
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Are you sure you want to delete this device group?',
          message: 'This action is irreversible.',
          buttonText: {
            ok: 'Delete',
            cancel: 'No'
          }
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deviceGroupService.deleteDeviceGroup(this.deviceGroup.accountId, 'all', this.deviceGroup.id).subscribe(u => {
            this.toasterService.showInfoToaster('Device group deleted!');
            this.navigateToAccount();
          });
        }
      });
    }
  }

  canDeactivate(): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent>;
    let config = {
      data: {
        message: 'You may have unsaved changes. Are you sure you want to navigate away?'
      }
    }

    if(this.deviceGroupForm.dirty) {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, config);

      return dialogRef.afterClosed().pipe(map(result => result === true));
    } else {
      return of(true);
    }
  }
}

export class DevicesDeactivateGuard implements CanDeactivate<DeviceGroupEditComponent> {
  canDeactivate(component: DeviceGroupEditComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return component.canDeactivate();
  }
}
