import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AccountWorkspaceAssetsDeviceV3Service, InstallDeviceDto } from '../../services/assets-v3.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToasterNotificationService } from '../../services/toasterNotification.service';
import { FeatureFlagService } from '../../services/feature-flag.service';

@Component({
  selector: 'ads-message-dialog',
  templateUrl: 'account-asset-install-device.component.html',
  styleUrls: ['./account-asset-install-device.component.scss']
})
export class AccountAssetInstallDeviceComponent implements OnInit {
  assetInstallDevice: FormGroup;
  accountId: number;
  assetId: string;
  deviceCtrl = new FormControl();
  bogieIQDeviceActive = false;
  allowInstall = false;
  public installDeviceDto: InstallDeviceDto = new InstallDeviceDto();

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private accountWorkspaceAssetsDeviceV3Service: AccountWorkspaceAssetsDeviceV3Service,
    private formBuilder: FormBuilder,
    private toasterService: ToasterNotificationService,
    private featureFlagService: FeatureFlagService,
    private dialogRef: MatDialogRef<AccountAssetInstallDeviceComponent>) {
      if (data) {
        this.accountId = data.accountId;
        this.assetId = data.assetId;
      }
  }

  ngOnInit() {
    this.assetInstallDevice = this.formBuilder.group({
      location: null,
      side: null
    });

    this.featureFlagService.assetsPaaSDeviceInstallsEnabled.then((treatment) => {
      this.allowInstall = treatment === 'on';
    });
  }

  deviceIsBogieIq() {
    return this.deviceCtrl.value != null && this.deviceCtrl.value.toString().startsWith('B');
  }

  onSubmit() {
    if(!this.allowInstall) {
      this.toasterService.showWarnToaster("Device install feature not enabled");
    }
    this.installDeviceDto.deviceSerialNumber = this.deviceCtrl.value;
    this.installDeviceDto.location = this.assetInstallDevice.value.location;
    this.installDeviceDto.side = this.assetInstallDevice.value.side;
    this.accountWorkspaceAssetsDeviceV3Service.installDevice(this.accountId, "all", this.assetId, "Ops Portal", this.installDeviceDto)
      .subscribe(() => {
        this.dialogRef.close(true);
      },
      error => {
        this.handleErrorResponse(error);
      }
    );
  }

  handleErrorResponse(error) {
    var errorResponse = JSON.parse(error.response);
    var errorResponseToDisplay = "";
    if(errorResponse.errors != null) {
      errorResponseToDisplay = errorResponse.errors;
    } else{
      errorResponseToDisplay = errorResponse == null || errorResponse == undefined ? error.detail : errorResponse;
    }
    this.toasterService.showWarnToaster(JSON.stringify(errorResponseToDisplay));
  }

  cancel(): void {
      this.dialogRef.close(false);
  }
}
