<div>
  <mat-card>
    <mat-card-content>
      <div style="flex-grow: 1">
        <google-map id="map" #map height="800px" width="100%" [options]="mapOptions">
        </google-map>
        <div style="font-size: small; text-align: right;">{{dataTime}}</div>
      </div>
    </mat-card-content>

    <mat-card-content>
      <mat-form-field>
        <mat-label>Source Event Types Contains...</mat-label>
        <mat-select [formControl]="eventTypeFilter" multiple>
          <mat-option *ngFor="let eventType of eventTypes" [value]="eventType.index">{{ eventType.name }} ({{eventType.count}})</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Trip Types</mat-label>
        <mat-select [formControl]="tripTypeFilter" multiple>
          <mat-option *ngFor="let tripType of tripTypes" [value]="tripType.index">{{ tripType.name ? tripType.name : '{unknown}' }} ({{tripType.count}})</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Durations</mat-label>
        <mat-select [formControl]="tripAgeFilter" multiple>
          <mat-option *ngFor="let tripAge of tripAges" [value]="tripAge.index">{{ tripAge.name }} ({{tripAge.count}})</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Distance</mat-label>
        <mat-select [formControl]="tripDistanceFilter" multiple>
          <mat-option *ngFor="let tripDistance of tripDistances" [value]="tripDistance.index">{{ tripDistance.name }} ({{tripDistance.count}})</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Last Updated</mat-label>
        <mat-select [formControl]="tripUpdatedFilter" multiple>
          <mat-option *ngFor="let tripUpdatedPeriod of tripUpdatePeriods" [value]="tripUpdatedPeriod.index">{{ tripUpdatedPeriod.name }} ({{tripUpdatedPeriod.count}})</mat-option>
        </mat-select>
      </mat-form-field>

      <div>
        <button mat-flat-button color="primary" (click)="filterAndRenderTrips()">Apply Filters</button>
        <span *ngIf="filtering">
          Applying filters...
        </span>
        <span *ngIf="!filtering">
          Total Trips: {{ tripsViewModel?.activeTrips.length }}    Visible Trips: {{ visibleTrips }}
        </span>
      </div>

      <mat-checkbox (change)="showLOIs($event.checked)">
        Show LOIs
      </mat-checkbox>
    </mat-card-content>
  </mat-card>
</div>
