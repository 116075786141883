import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AccountWorkspaceAssetsDeviceV3Service } from '../../services/assets-v3.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToasterNotificationService } from '../../services/toasterNotification.service';
import { FeatureFlagService } from '../../services/feature-flag.service';

@Component({
  selector: 'ads-message-dialog',
  templateUrl: 'account-asset-uninstall-device.component.html',
  styleUrls: ['./account-asset-uninstall-device.component.scss']
})
export class AccountAssetUninstallDeviceComponent implements OnInit {
  assetInstallDevice: FormGroup;
  accountId: number;
  assetId: string;
  installedDevices: string[];
  selectedDevice: string;
  deviceCtrl = new FormControl();
  allowUninstall = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private accountWorkspaceAssetsDeviceV3Service: AccountWorkspaceAssetsDeviceV3Service,
    private formBuilder: FormBuilder,
    private toasterService: ToasterNotificationService,
    private featureFlagService: FeatureFlagService,
    private dialogRef: MatDialogRef<AccountAssetUninstallDeviceComponent>) {
      if (data) {
        this.accountId = data.accountId;
        this.assetId = data.assetId;
        this.installedDevices = data.installedDevices;
      }
  }

  ngOnInit() {
    this.featureFlagService.assetsPaaSDeviceInstallsEnabled.then((treatment) => {
      this.allowUninstall = treatment === 'on';
    });
  }

  onSubmit() {
    if(!this.allowUninstall) {
      this.toasterService.showWarnToaster("Device install feature not enabled");
    }
    this.accountWorkspaceAssetsDeviceV3Service.uninstallDevice(this.accountId, "all", this.assetId, this.selectedDevice, "Ops Portal")
      .subscribe(() => {
        this.dialogRef.close(true);
      },
      error => {
        this.handleErrorResponse(error);
      }
    );
  }

  handleErrorResponse(error) {
    var errorResponse = JSON.parse(error.response);
    var errorResponseToDisplay = "";
    if(errorResponse.errors != null) {
      errorResponseToDisplay = errorResponse.errors;
    } else{
      errorResponseToDisplay = errorResponse == null || errorResponse == undefined ? error.detail : errorResponse;
    }
    this.toasterService.showWarnToaster(JSON.stringify(errorResponseToDisplay));
  }

  cancel(): void {
      this.dialogRef.close(false);
  }
}
