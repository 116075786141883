<button id="jd-close=button" mat-icon-button class="raw-message-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<div [hidden]="selectAssets">
  <h1 mat-dialog-title>Share Assets</h1>
  <mat-dialog-content class="share-assets-dialog">
    <p class="mat-card-subtitle">
        Add Assets to Share
    </p>
    <p>In order for assets to be shared, the account {{sourceAccountName}} has authorized ADS to share with {{targetAccountName}} in the box below. Add assets and click "Share Assets".</p>
    <div>
      <mat-card-actions align="end">
        <button mat-button color="primary" (click)="viewAssetsClick()">Select Assets</button>
      </mat-card-actions>
    </div>
    <mat-divider [hidden]="assetsSelected.length==0"></mat-divider>
    <ng-container *ngFor="let selectedAsset of assetsSelected" >
      <div>
        <button mat-button (click)="removeSelectedAsset(selectedAsset)">
          <mat-icon class="red-icon">clear</mat-icon>
        </button>
        <span class="selected-asset-row">
          {{selectedAsset.name}}
        </span>
      </div>
    </ng-container>
    <mat-card-actions align="end" class="share-assets-actions">
      <mat-checkbox class="reshare-button" [(ngModel)]="canReshare" [disabled]="assetsSelected.length==0">Can Reshare Assets</mat-checkbox>
      <button id="af-createbutton" mat-flat-button color="primary" (click)="shareAssetsClick()" [disabled]="assetsSelected.length==0" type="submit">Share Assets</button>
    </mat-card-actions>
  </mat-dialog-content>
</div>
<div [hidden]="!selectAssets">
  <h1 mat-dialog-title>Select Assets to Share</h1>
  <mat-dialog-content class="share-assets-dialog">
      <p>Select the assets {{sourceAccountName}} has authorized ADS to share with {{targetAccountName}}.</p>
    <mat-card-content>
      <div class="names-search" id="leftcolumn">
        <mat-form-field>
          <input placeholder="Search Assets by Name" matInput (keyup)="onSearchKeyup($event)" maxlength="50" #searchText />
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-content>
      <ag-grid-angular
        style="width: 100%; height: 300px;"
        class="ag-theme-alpine"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        [rowSelection]="'multiple'"
        [suppressRowClickSelection]="true"
        (rowSelected)="onRowSelected($event)"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
      <mat-card-actions align="end" >
        <button id="af-createbutton" mat-flat-button color="primary" (click)="selectAssetsClick()" [disabled]="!enableSelectedAssets" type="submit">Select Assets</button>
      </mat-card-actions>
    </mat-card-content>
  </mat-dialog-content>
</div>



