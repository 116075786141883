import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ValueFormatterParams, RowSelectedEvent } from 'ag-grid-community';
import { AssetResponse, AccountWorkspaceAssetsV3Service, AccountAssetResponse, DeactivateAdsDataFeedBulkRequest, DeactivateAdsDataFeedBulkResult } from '../services/assets-v3.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ODataServerSideDataSource } from '../services/ODataServerSideDataSource';
import { MatDialog } from '@angular/material/dialog';
import { WorkspaceSelectComponent } from '../shared/workspace-select/workspace-select.component';
import { AccountSelectSearchComponent } from 'src/app/shared/account-select-search/account-select-search.component';
import { AssetBulkShareDialogComponent } from './asset-bulk-share-dialog-component/asset-bulk-share-dialog.component';
import { ConfirmationDialogComponent } from '../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { ToasterNotificationService } from '../services/toasterNotification.service';

@Component({
  selector: 'app-asset-sharing',
  templateUrl: './assets-sharing.component.html',
  styleUrls: ['./assets-sharing.component.scss']
})
export class AssetsSharingComponent implements OnInit {
  pageSize: number = 100;
  searchSubject: Subject<string> = new Subject();
  searchODataFilter: string = undefined;
  searchODataOrderBy: string = undefined;
  isDeactivate: boolean = false;
  enableSelectedAssets: boolean = false;
  assetsSelected: any[] = [];
  columnDefs: ColDef[] = [
    { headerName: '', field: 'share', width: 50, checkboxSelection: true },
    { headerName: 'Name', field: 'name', sortable: true },
    { headerName: 'Class', field: 'class', sortable: true },
    { headerName: 'Region', field: 'region', sortable: true },
    { headerName: 'Devices', field: 'devices', valueFormatter: this.formatDevices },
    { headerName: 'Shared To Accounts', field: 'sharedToAccountIds', valueFormatter: this.formatSharedToAccounts },
    { headerName: 'Shared From Accounts', field: 'sharedFromAccountIds', valueFormatter: this.formatSharedFromAccounts }
  ];
  sourceAccountId: number;
  targetAccountId: number;
  gridOptions: GridOptions = {
    rowModelType: 'infinite',
    context: {
      nextPageContinuationToken: null
    },
    datasource: new ODataServerSideDataSource(this.getData.bind(this)),
  };
  gridApi: GridApi;
  @ViewChild('workspaceSelect', { static: true }) workspaceSelect: WorkspaceSelectComponent;
  @ViewChild('accountSelect', { static: true }) accountSelect: AccountSelectSearchComponent;
  @ViewChild('targetAccountSelect', { static: true }) targetAccountSelect: AccountSelectSearchComponent;


  constructor(
    private accountWorkspacesAssetsV3Service: AccountWorkspaceAssetsV3Service,
    private dialog: MatDialog,
    private toasterService: ToasterNotificationService,
  ) {
  }

  ngOnInit(): void {
    this.sourceAccountId = 0;
    this.targetAccountId = 0;
    this.searchSubject
      .pipe(debounceTime(500))
      .subscribe(x => {
          if (x) {
            const normalizedX = x.replace(/[^a-zA-Z0-9]/g, '');
            this.searchODataFilter = `contains(nameNormalized, '${normalizedX}')`;
          } else {
            this.searchODataFilter = undefined;
          }

          this.gridApi.setDatasource(this.gridOptions.datasource);
        });


      this.accountSelect.registerOnChange(() => this.onAccountChange());
      this.targetAccountSelect.registerOnChange(() => this.onTargetAccountChange());
      this.workspaceSelect.registerOnChange(() => this.onWorkspaceChange());
  }

  onAccountChange() {
    this.sourceAccountId = this.accountSelect.singleSelect.value;
    this.workspaceSelect.accountId = this.sourceAccountId;
    this.workspaceSelect.setupWorkspaces();
    this.searchSubject.next("");
  }

  onTargetAccountChange() {
    this.targetAccountId = this.targetAccountSelect.singleSelect.value;
    this.searchSubject.next("");
  }

  onWorkspaceChange() {
    this.searchSubject.next();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  getData(odataFilter: string, odataOrderBy: string, continuationToken: string): Promise<any> {
    if(!this.isDeactivate && this.sourceAccountId != null && this.sourceAccountId != undefined) {
      return this.accountWorkspacesAssetsV3Service.getAssetsInWorkspace(this.sourceAccountId, this.workspaceSelect.workspaceSelected,
        this.pageSize, this.searchODataFilter, odataOrderBy, undefined, undefined, continuationToken)
        .toPromise();
    }

    if(this.isDeactivate && this.sourceAccountId != null && this.sourceAccountId != undefined && this.targetAccountId != null && this.targetAccountId != undefined) {
      return this.accountWorkspacesAssetsV3Service.getAccountAssetsBySharedAccount(this.sourceAccountId,
        this.workspaceSelect.workspaceSelected, this.targetAccountId, this.searchODataFilter, odataOrderBy, this.pageSize, continuationToken)
        .toPromise();
    }
  }

  formatDevices(params: ValueFormatterParams) {
    if (!params.value) return;

    const asset: AssetResponse = params.data;
    return asset.devices.length.toString();
  }

  formatSharedFromAccounts(params: ValueFormatterParams) {
    if (!params.value) return;

    const asset: AccountAssetResponse = params.data;
    return asset.sharedFromAccountIds.length.toString();
  }

  formatSharedToAccounts(params: ValueFormatterParams) {
    if (!params.value) return;

    const asset: AccountAssetResponse = params.data;
    return asset.sharedToAccountIds.length.toString();
  }

  onSearchKeyup(event: KeyboardEvent) {
    this.searchSubject.next((event.target as HTMLInputElement).value);
  }

  shareAssetsClick(byWorkspace) {
    this.assetsSelected = [];
    this.gridApi.getSelectedNodes().forEach((node) => {
      this.assetsSelected.push(node.data.id);
    });

    var dialogRef = this.dialog.open(AssetBulkShareDialogComponent, {
      data: {
        sourceAccountId: this.sourceAccountId,
        assetIds: this.assetsSelected,
        byWorkspace: byWorkspace
      }
    });

    dialogRef.afterClosed().subscribe((success) => {
      if (success) { this.ngOnInit(); }
    });
  }

  deactivateAssetsClick() {
    this.assetsSelected = [];
    this.gridApi.getSelectedNodes().forEach((node) => {
      this.assetsSelected.push(node.data.id);
    });
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure you want to deactivate the selected assets?',
        message: 'This action is irreversible.',
        buttonText: {
          ok: 'Deactivate',
          cancel: 'Cancel'
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      var request = new DeactivateAdsDataFeedBulkRequest ({
        targetAccountId: this.targetAccountId,
        targetAssetIds: this.assetsSelected
      });

      if (result) {
        this.accountWorkspacesAssetsV3Service.deactivateAdsDataFeedBulk(this.sourceAccountId, "all", "Ops Portal", request)
          .subscribe((x: any) => {
            this.handleErrorResponse(x);
            this.ngOnInit();
          }
        );
      }
    });
  }

  handleErrorResponse(response: [DeactivateAdsDataFeedBulkResult]) {
    var errorDisplay = "";
    response.forEach(x => {
      if(x.notFound) {
        errorDisplay += "Asset not found: " + x.assetId + ". ";
      }
      if(!x.success && x.errors != null) {
        errorDisplay += "Error for asset " + x.assetId + ": " + JSON.stringify(x.errors) + ". ";
      }
    })
    if(errorDisplay != "") {
      this.toasterService.showWarnToaster(JSON.stringify(errorDisplay));
    }
  }

  toggleDeactivate(e: Event) {
    this.isDeactivate = !this.isDeactivate;
    this.searchSubject.next("");
  }

  onRowSelected(event: RowSelectedEvent) {
    this.enableSelectedAssets = this.gridApi.getSelectedNodes().length > 0;
  }
}
