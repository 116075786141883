import { AfterViewInit, ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AccountsService } from '../services/accounts.service';

@Component({
  selector: 'ads-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, AfterViewInit {
  accountData = new MatTableDataSource();
  baseMessage = 'To see accounts please begin typing an account name or number';
  columnsToDisplay = ['sapAccountNumber', 'accountName', 'isNotEnabled'];
  search: Subject<string> = new Subject();
  searchMessage = this.baseMessage;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private accountsService: AccountsService,
    private renderer: Renderer2, private cdr: ChangeDetectorRef ) { }

  ngOnInit() {
    this.search.pipe(debounceTime(500)).subscribe(searchTextValue => {
      this.searchAccounts(searchTextValue);
    });
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#ac-searchtext');
    element.focus();
    this.cdr.detectChanges();
  }

  onRowClick(account) {
    this.router.navigate([`/accounts/view/${account.accountId}`]);
  }

  searchAccounts(filterValue: string) {
    this.searchMessage = this.baseMessage;
    if (filterValue !== '') {
      this.accountsService.searchAccounts(filterValue.toLowerCase()).subscribe(accounts => {
        this.accountData = new MatTableDataSource(accounts);
        this.accountData.sort = this.sort;

        if (this.accountData.filteredData.length === 0) {
          this.searchMessage = 'No results match search term ' + filterValue;
        }
      });
    }
  }

  searchTriggered(filterValue: any) {
    if (filterValue === '') {
      this.accountData.data = [];
    }

    this.search.next(filterValue);
  }
}
