import { Component, Inject, OnInit } from '@angular/core';
import { AssetClassesV3Service, AssetTypesV3Service } from '../../services/assets-v3.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToasterNotificationService } from '../../services/toasterNotification.service';

@Component({
  selector: 'ads-message-dialog',
  templateUrl: 'account-asset-type-dialog.component.html',
  styleUrls: ['./account-asset-type-dialog.component.scss']
})
export class AccountAssetTypeDialogComponent implements OnInit {
  accountId: number;
  assetId: string;
  class: string;
  region: string;
  type: string;
  typeTest: string;
  supportedTypes: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private assetClassesV3Service: AssetClassesV3Service,
    private assetTypesV3Service: AssetTypesV3Service,
    private toasterService: ToasterNotificationService,
    private dialogRef: MatDialogRef<AccountAssetTypeDialogComponent>) {
      if (data) {
        this.accountId = data.accountId;
        this.assetId = data.assetId;
        this.class = data.class;
        this.region = data.region;
        this.type = data.type;
      }
  }

  ngOnInit() {
    this.getSupportedTypesByClassRegion();
  }

  getSupportedTypesByClassRegion() {
    this.assetClassesV3Service.getSupportedTypesByClass(btoa(this.class), btoa(this.region))
    .subscribe(supportedTypesResponse => {
      this.supportedTypes = supportedTypesResponse;
    });
  }

  onSubmit() {
    this.assetTypesV3Service.patchAssetType(this.accountId, "all", this.assetId, this.type, "Ops Portal")
      .subscribe(() => {
        this.dialogRef.close(true);
      },
      error => {
        this.handleErrorResponse(error);
      });
  }

  handleErrorResponse(error) {
    var errorResponse = JSON.parse(error.response);
    var errorResponseToDisplay = "";
    if(errorResponse.errors != null) {
      errorResponseToDisplay = errorResponse.errors;
    } else{
      errorResponseToDisplay = errorResponse == null || errorResponse == undefined ? error.detail : errorResponse;
    }
    this.toasterService.showWarnToaster(JSON.stringify(errorResponseToDisplay));
  }

  cancel(): void {
      this.dialogRef.close(false);
  }
}
