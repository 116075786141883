<mat-card>
  <label>Create Workspaces</label>
  <form (ngSubmit)="onSubmit()">
    <ng-container [formGroup]="workspaceForm">
      <mat-card-content>
        <ng-container>
          <mat-form-field>
            <input id='workspace-create-name' (input)="workspaceNameChange($event)" matInput formControlName="workspaceName" maxlength="80" required placeholder="Display Name" autofocus />
            <ads-control-messages [control]="workspaceForm.get('workspaceName')"></ads-control-messages>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Workspace ID</mat-label>
            <input [(ngModel)]="workspaceFromName" id='workspace-create-id' matInput formControlName="workspaceId" maxlength="200" required
                   placeholder="Enter letters, numbers, dashes, and underscores only."
                   oninput="this.value = this.value.toLowerCase()" (input)="cleanWorkspaceId($event)"/>
            <span *ngIf="!this.formValid"><mat-icon>error_outline</mat-icon></span>
            <span *ngIf="this.formValid"><mat-icon>check_circle_outline</mat-icon></span><br>
            <a *ngIf="!this.formValid">This value must be unique</a>
            <ads-control-messages [control]="workspaceForm.get('workspaceId')"></ads-control-messages>
          </mat-form-field>
        </ng-container>
      </mat-card-content>
      <mat-card-actions align="end">
        <button id='workspace-new-cancelbutton' type="button" mat-stroked-button adsBackbutton>Cancel</button>
        <button id='workspace-new-createbutton' mat-flat-button color="primary" [disabled]="!this.formValid" type="submit">
          Create
        </button>
      </mat-card-actions>
    </ng-container>
  </form>
</mat-card>
