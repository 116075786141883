import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs/Observable';
import { map, startWith } from 'rxjs/operators';
import { TagService } from '../services/tag.service';

@Component({
  selector: 'ads-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTags: Observable<ISelectableItem[]>;
  selectedTags: ISelectableItem[];
  allTags: ISelectableItem[];
  @Input()
  TagType: string;
  @Input()
  Id: string;

  @ViewChild('tagInput') tagInput: ElementRef<
    HTMLInputElement
  >;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private tagService: TagService) {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      // tslint:disable-next-line: deprecation
      startWith(null),
      map((tag: string | null) =>
        tag ? this.filterTagByName(tag) : this.allTags.slice()
      )
    );
  }

  ngOnInit() {
    this.allTags = this.tagService.getAllTags().tags;
    this.selectedTags = this.tagService.getItemTags(this.Id, this.TagType).tags;
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;
      // Add our tag
      if ((value || ' ').trim()) {
        if (!this.selectedTags.map(x => x.tagName).includes(value.trim())) {
          const filtered = this.filterTagById(value);
          const selectedTag = filtered || { tagName: value, tagId: 0 };
          this.selectedTags.push(selectedTag);
        }
      }
      // Reset the input value
      if (input) {
        input.value = '';
      }
      this.tagCtrl.setValue(null);
    }
  }

  remove(value: ISelectableItem): void {
    const index = this.selectedTags.indexOf(value);
    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedTags.map(x => x.tagId).includes(event.option.value)) {
      this.selectedTags.push(this.filterTagById(event.option.value));
    }

    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private filterTagByName(value: string): ISelectableItem[] {
    const filterValue = value.toString().toLowerCase();
    return this.allTags.filter(
      tag => tag.tagName.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private filterTagById(value: string): ISelectableItem {
    return this.allTags.filter(tag => tag.tagId === Number(value))[0];
  }
}

export interface ISelectableItem {
  tagName: string;
  tagId: number;
}
export interface ITagServiceResponse {
  tags: ISelectableItem[];
}
