<mat-card-content class="mat-table-scroll-vertical">
  <mat-card-subtitle fxLayout="row" fxLayoutAlign="space-between">
    <div class="account-tab-top-padding">
      Asset Groups
    </div>
    <div>
      <button id='ad-agt-creategroupbutton' mat-button color="primary" (click)="createAssetGroup()">Create Group</button>
    </div>
  </mat-card-subtitle>
  <table mat-table matSort class="m-t-20 assetActive-table" [dataSource]="assetGroupsTableData">
    <ng-container matColumnDef="name">
      <th mat-header-cell class="table-header asset-group-column" *matHeaderCellDef mat-sort-header>Group Name</th>
      <td class="asset-group-column" mat-cell *matCellDef="let assetGroup">{{ assetGroup.name }}</td>
    </ng-container>
    <ng-container matColumnDef="assetCount">
      <th mat-header-cell class="table-header asset-group-column" *matHeaderCellDef mat-sort-header>Assets</th>
      <td class="asset-group-column" mat-cell *matCellDef="let assetGroup">{{ assetGroup.assetCount }}</td>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <th mat-header-cell class="table-header asset-group-column" *matHeaderCellDef mat-sort-header>Active</th>
      <td class="asset-group-column" mat-cell *matCellDef="let assetGroup">{{ assetGroup.isActive }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th class="table-header" mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let assetGroup">
        <button id='ad-agt-editbutton' mat-icon-button class="black" (click)="onRowClick(assetGroup)"><mat-icon>edit</mat-icon></button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
  </table>
  <div *ngIf="emptyAssetGroupsData">
    <label class="sm-note">{{emptyAssetGroupsMessage}}</label>
  </div>
</mat-card-content>
