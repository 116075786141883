<button id="jd-close=button" mat-icon-button class="raw-message-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title>Get Or Create Asset</h1>
<mat-dialog-content class="asset-get-or-create-dialog">
	<form>
    <ng-container [formGroup]="getOrCreate">
      <div [hidden]='getOrCreateByName || getOrCreateByIonxId'>
        <mat-card-actions align="center">
          <button mat-flat-button color="primary" (click)="onByNameClick()">By Name</button>
          <button mat-flat-button color="primary" (click)="onByIonxIdClick()">By Ionx Id</button>
        </mat-card-actions>
      </div>
      <mat-card-content>
        <div [hidden]='!getOrCreateByName'>
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input formControlName="name" matInput required/>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Class Id</mat-label>
            <div class="info-tooltip">
              <mat-icon  #tooltip="matTooltip" matTooltip="{{classificationIds}}" >info</mat-icon>
            </div>
            <input formControlName="classId" matInput/>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Region Id</mat-label>
            <div class="info-tooltip">
              <mat-icon #tooltip="matTooltip" matTooltip="{{regionIds}}">info</mat-icon>
            </div>
            <input formControlName="regionId" matInput/>
          </mat-form-field>
        </div>
        <div [hidden]='!getOrCreateByIonxId'>
          <mat-form-field>
            <mat-label>Ionx ID</mat-label>
            <input formControlName="ionxId" matInput/>
          </mat-form-field>
        </div>
        <div [hidden]='!getOrCreateByName && !getOrCreateByIonxId'>
          <ads-account-select-search [control]="getOrCreate.get('accountId')" formControlName="accountId" [isRequired]="true" placeholder="Select Account"></ads-account-select-search>
        </div>
      </mat-card-content>
      <div [hidden]='!getOrCreateByName && !getOrCreateByIonxId'>
        <mat-card-actions align="end">
          <button id='ad-cancelbutton' type="button" [mat-dialog-close]="true">Cancel</button>
          <button mat-flat-button color="primary" (click)="onBackClick()">Back</button>
          <button mat-flat-button color="primary" type="submit" (click)="onSubmit()">Submit</button>
        </mat-card-actions>
      </div>
    </ng-container>
  </form>
</mat-dialog-content>


