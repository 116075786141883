<mat-tab-group>
  <mat-tab label="Select Location">
    <mat-form-field>
      <input
        type="text"
        placeholder="Pick one"
        aria-label="Number"
        matInput
        [formControl]="searchLocationFormController.searchInput"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <cdk-virtual-scroll-viewport [itemSize]="5" style="height: 200px">
          <mat-option *cdkVirtualFor="let location of searchLocationFormController.filteredLocations | async" [value]="location">
            {{ location }}
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </mat-autocomplete>
    </mat-form-field>

    <div>
      <button mat-button color="primary" (click)="onAddLocation()">Add</button>
      <button mat-button color="warn" style="float: right" (click)="onClose()">Close</button>
    </div>
  </mat-tab>

  <mat-tab label="Custom">
    <mat-form-field>
      <input matInput placeholder="Name" [(ngModel)]="customLocationFormController.nameField" required />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Center Latitude" [(ngModel)]="customLocationFormController.latField" required />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Center Longitude" [(ngModel)]="customLocationFormController.longField" required />
    </mat-form-field>
    <mat-form-field style="min-height: 200px">
      <textarea
        #geoJsonInput
        matInput
        placeholder="GeoJson"
        style="height: 120px"
        [(ngModel)]="customLocationFormController.geoJsonField"
        required
      ></textarea>
    </mat-form-field>
    <div>
      <button mat-button color="primary" (click)="onAddCustomLocation()">Add</button>
      <button mat-button color="warn" style="float: right" (click)="onClose()">Close</button>
    </div>
  </mat-tab>
</mat-tab-group>
