import { Component, OnInit, OnDestroy } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Rsrd2Service, RSRD2AccountUser } from '../../../../services/rsrd2.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { timer, Subscription } from 'rxjs';
import { now } from 'moment';
import { ProblemDetails } from 'src/app/services/accounts.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'ads-account-rsrd2-tab',
  templateUrl: './account-rsrd2-tab.component.html',
  styleUrls: ['./account-rsrd2-tab.component.scss']
})
export class AccountRSRD2TabComponent implements OnInit, OnDestroy {
  private accountId: number;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  RSRD2Login = new FormGroup({
    username: new FormControl('', [
      Validators.required
    ]),
    password: new FormControl('', [
      Validators.required
    ])
  });

  matcher = new MyErrorStateMatcher();

  invalidCredentials = false;

  submitEnabled = false;

  gridData = { header: null, body: null };

  lastRefreshDays: string;
  refreshStatusUri: string = null;
  refreshId: string = null;
  refreshStarting = false;
  refreshStartTime: string;
  refreshError = false;

  initializing = true;
  subscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute,
    public rsrd2Service: Rsrd2Service, private toasterService: ToasterNotificationService) {
    this.route.parent.url.subscribe((urlPath) => {
      let accountId = 0;
      if (urlPath.length > 1) {
        accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
      }

      if (isNaN(accountId)) {
        this.router.navigateByUrl('[/accounts]');
      }

      this.accountId = accountId;
    }, error => {
      this.toasterService.showWarnToaster(error.message);
    });
  }

  ngOnInit() {
    this.checkForUser();
  }

  ngOnDestroy() {
    if (this.subscription !== null && this.subscription !== undefined && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  validateCredentials() {
    this.invalidCredentials = false;
    if (this.RSRD2Login.controls.username.value == null || this.RSRD2Login.controls.password.value == null ||
      this.RSRD2Login.controls.username.value.length < 1 || this.RSRD2Login.controls.password.value.length < 1) {
      this.submitEnabled = false;
    } else {
      if (this.RSRD2Login.controls.username.hasError('required') || this.RSRD2Login.controls.username.hasError('required')) {
        this.submitEnabled = false;
      } else {
        if (!this.invalidCredentials) {
          this.submitEnabled = true;
        }
      }
    }
  }

  checkForUser() {
    this.rsrd2Service.isConnected = false;

    this.rsrd2Service.checkForUser(this.accountId)
      .subscribe((res: any) => {
        this.rsrd2Service.isConnected = (res.body === 'true') ? true :
          typeof res.body === 'string' ? !!+res.body : !!res.body;

        this.initializeStatus();
      }, error => {
        this.toasterService.showWarnToaster(error.message);
      });
  }

  initializeStatus() {
    if (this.subscription !== null && this.subscription !== undefined && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    if (this.rsrd2Service.isConnected) {
      this.subscription = timer(0, 5000).subscribe(() => this.getRefreshStatus());
    }
  }

  connectAccount(username: string, password: string) {
    const user = new RSRD2AccountUser({ AccountId: this.accountId, Username: username, Password: password });
    this.rsrd2Service.connect(user)
      .subscribe((res: any) => {
        this.rsrd2Service.isConnected = true;
        this.RSRD2Login.reset();
        this.initializeStatus();
      }, error => {
        if (error.status === 401) {
          let result: any = null;
          const resultData = JSON.parse(error.error, this.jsonParseReviver);
          result = ProblemDetails.fromJS(resultData);
          this.toasterService.showWarnToaster(result.detail);
          this.invalidCredentials = true;
        } else if (error.status === 409) {
          let result: any = null;
          const resultData = JSON.parse(error.error, this.jsonParseReviver);
          result = ProblemDetails.fromJS(resultData);
          this.toasterService.showWarnToaster(result.detail);
        } else {
          this.toasterService.showWarnToaster(error.message);
        }
      });
  }

  disconnectAccount() {
    this.rsrd2Service.disconnect(this.accountId)
      .subscribe((res: any) => {
        this.rsrd2Service.isConnected = false;
        this.RSRD2Login.reset();
        if (this.subscription !== null && this.subscription !== undefined && !this.subscription.closed) {
          this.subscription.unsubscribe();
        }
      },
        error => {
          if (error.status === 404) {
            let result: any = null;
            const resultData = JSON.parse(error.error, this.jsonParseReviver);
            result = ProblemDetails.fromJS(resultData);
            this.toasterService.showWarnToaster(result.detail);
          } else {
            this.toasterService.showWarnToaster(error.message);
          }
        },
      );
  }

  getRollingStockDataset() {
    this.rsrd2Service.isRefreshing = true;
    this.refreshStarting = true;
    this.refreshStartTime = new Date().toISOString();
    this.rsrd2Service.getRollingStockDataset(this.accountId)
      .subscribe((res: any) => {
        this.refreshId = res;
      },
        error => {
          this.toasterService.showWarnToaster(error.message);
        },
      );

    if (this.subscription === null || this.subscription === undefined || this.subscription.closed) {
      this.initializeStatus();
    }
  }

  getRSRD2Assets() {
    this.rsrd2Service.getRSRD2Assets(this.accountId)
      .subscribe((res: any) => {
        if (res !== null) {
          this.gridData = res;

          if (this.gridData.body !== null && this.gridData.body.length > 0) {
            const lastRefresh: Date = new Date(this.gridData.body[0].MessageDateTime);
            this.lastRefreshDays = Math.max(0, Math.floor((new Date().getTime() - lastRefresh.getTime()) / (1000 * 3600 * 24))).toString();
            this.lastRefreshDays = this.lastRefreshDays + ((this.lastRefreshDays === '1') ? ' day ago' : ' days ago');
          }
        }
      },
        error => {
          this.toasterService.showWarnToaster(error.message);
        });
  }

  // The refresh status will only be polled if a refresh has been started or is running
  getRefreshStatus() {
    this.rsrd2Service.getRefreshStatus(this.accountId)
      .subscribe((res: any) => {
        const status: IRefreshStatus = res as IRefreshStatus;
        const wasRefreshing = this.rsrd2Service.isRefreshing;

        // Wait until the refresh request has started (runtimeStatus no longer pending)
        // Normally this request will match the current storage table status URI once started.
        // The status instanceId will match the refreshId returned by getRollingStockDataset.
        // Edge case - Another refresh started immediately before or after this one and is running.
        // Edge case - Another undetected refresh was running while this request was spinning up
        //             and has completed in-between polling.
        //             If lastUpdatedTime > this request's start time, and this refreshId is different
        //             than the status instanceId, this request has most likely been dropped.
        if (this.refreshStarting && this.refreshId !== null) {
          if ((this.refreshId === status.instanceId && status.runtimeStatus !== 'Pending') ||
            status.runtimeStatus === 'Running' ||
            (this.refreshId !== status.instanceId && this.refreshStartTime < status.lastUpdatedTime)) {
            this.refreshStarting = false;
          }
        }

        // Continue to check refresh status after started
        if (!this.refreshStarting) {
          this.rsrd2Service.isRefreshing =
            (status.runtimeStatus === 'Running') ? true :
              (status.runtimeStatus === 'Not Found' && wasRefreshing) ? true : false;

          if (status.runtimeStatus === 'Failure') {
            this.refreshError = true;
          } else {
            this.refreshError = false;
          }

          // Populate the grid if loading the page or a refresh has completed
          if (this.initializing || (wasRefreshing && !this.rsrd2Service.isRefreshing)) {
            this.getRSRD2Assets();
            this.initializing = false;
          }

          // Stop polling if a refresh is not in progress
          if (!this.rsrd2Service.isRefreshing) {
            this.subscription.unsubscribe();
          }
        }
      },
        error => {
          if (error.status !== 500) {
            this.refreshError = true;
            this.toasterService.showWarnToaster(error.message);
            this.subscription.unsubscribe();
          }
        });
  }

  downloadToExcel() {
    this.rsrd2Service.emitOnDownloadButtonSelected();
  }

}

export interface IRefreshStatus {
  name: string;
  instanceId: string;
  runtimeStatus: string;
  input: any;
  customStatus: string;
  output: string;
  createdTime: string;
  lastUpdatedTime: string;
}
