import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Event, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { InstallerLocationResponse, InstallerLocationsService } from '../../services/accounts.service';
import { ToasterNotificationService } from '../../services/toasterNotification.service';

@Component({
  selector: 'ads-app-installer-locations',
  templateUrl: './installer-locations.component.html',
  styleUrls: ['./installer-locations.component.scss']
})

export class InstallerLocationsComponent implements OnInit {
  dataSource: MatTableDataSource<InstallerLocationResponse>;
  emptyMessage = 'No installer locations available to display.';
  headers: string[] = ['customerId', 'companyName', 'companyCode', 'houseNo',
    'street', 'street2', 'city', 'region', 'country', 'postal'];
  installerLocations: InstallerLocationResponse[] = [];
  loadingData: boolean;

  @ViewChild(MatSort) sort: MatSort;

  constructor(public route: ActivatedRoute, private installerLocationService: InstallerLocationsService,
    private router: Router, private toasterService: ToasterNotificationService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.getInstallerLocations();
      }
    });
  }

  ngOnInit(): void {
    this.getInstallerLocations();
  }

  getInstallerLocations() {
    this.loadingData = true;
    this.installerLocationService.installerLocations().subscribe(installerLocations => {
      this.installerLocations = installerLocations;

      this.dataSource = new MatTableDataSource(this.installerLocations);
      this.dataSource.sort = this.sort;
      this.loadingData = false;

    },
      error => {
        this.toasterService.showWarnToaster(error.detail);
        this.loadingData = false;
      });
  }

  onRowClick(row) {
    const navigationExtras: NavigationExtras = { state: { installerLocation: row } };
    this.router.navigate([`/accounts/installer-location/edit-installer-location/${row.companyCode}`], navigationExtras);
  }
}
