import { HttpClient, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Optional } from 'ag-grid-community';
import { mergeMap as _observableMergeMap, catchError as _observableCatch, catchError } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf, throwError } from 'rxjs';
import { HttpHeaders } from '@azure/storage-blob';

export const DEVICES_GROUPS_API_BASE_URL = new InjectionToken<string>('DEVICES_GROUPS_API_BASE_URL');

export interface IDevicesGroupsService {}

@Injectable({
  providedIn: 'root'
})
export class DevicesGroupsService implements IDevicesGroupsService {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(DEVICES_GROUPS_API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : '';
  }

  getDevicesGroups(accountId: number, workspace: string | null) {
    let url_ = this.baseUrl + '/v2/accounts/{accountId}/workspaces/{workspace}/devicegroups';
    if (accountId === undefined || accountId === null) {
      throw new Error("The parameter 'accountId' must be defined.");
    }
    url_ = url_.replace('{accountId}', encodeURIComponent('' + accountId));
    if (workspace === undefined || workspace === null) throw new Error("The parameter 'workspace' must be defined.");
    url_ = url_.replace('{workspace}', encodeURIComponent('' + workspace));
    url_ = url_.replace(/[?&]$/, '');

    const options: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http
      .request<HttpResponse<IDevicesGroupsResponse>>('GET', url_, options)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processResponse(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processResponse(<any>response_);
            } catch (e) {
              return <Observable<IDevicesGroupsResponse>>(<any>_observableThrow(e));
            }
          } else return <Observable<IDevicesGroupsResponse>>(<any>_observableThrow(response_));
        })
      );
  }

  getArenaConfigurationPackage(accountId: number, workspace: string | null) {
    let url_ = this.baseUrl + '/v2/accounts/{accountId}/workspaces/{workspace}/devicegroups/configurations';
    if (accountId === undefined || accountId === null) {
      throw new Error("The parameter 'accountId' must be defined.");
    }
    url_ = url_.replace('{accountId}', encodeURIComponent('' + accountId));
    if (workspace === undefined || workspace === null) throw new Error("The parameter 'workspace' must be defined.");
    url_ = url_.replace('{workspace}', encodeURIComponent('' + workspace));
    url_ = url_.replace(/[?&]$/, '');

    const options: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json'
      }),
      params: { skip: 0, top: 200 }
    };
    return this.http
      .request<HttpResponse<IArenaConfigurationPackageResponse>>('GET', url_, options)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processResponse(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processResponse(<any>response_);
            } catch (e) {
              return <Observable<IArenaConfigurationPackageResponse>>(<any>_observableThrow(e));
            }
          } else return <Observable<IArenaConfigurationPackageResponse>>(<any>_observableThrow(response_));
        })
      );
  }

  getDefaultGroup(accountId: number, workspace: string | null) {
    let url_ = this.baseUrl + '/v2/accounts/{accountId}/workspaces/{workspace}/devicegroups/default';
    if (accountId === undefined || accountId === null) {
      throw new Error("The parameter 'accountId' must be defined.");
    }
    url_ = url_.replace('{accountId}', encodeURIComponent('' + accountId));
    if (workspace === undefined || workspace === null) throw new Error("The parameter 'workspace' must be defined.");
    url_ = url_.replace('{workspace}', encodeURIComponent('' + workspace));
    url_ = url_.replace(/[?&]$/, '');

    const options: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http
      .request<HttpResponse<IDevicesGroupsResponse>>('GET', url_, options)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processResponse(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processResponse(<any>response_);
            } catch (e) {
              return <Observable<IDevicesGroupsResponse>>(<any>_observableThrow(e));
            }
          } else return <Observable<IDevicesGroupsResponse>>(<any>_observableThrow(response_));
        })
      );
  }

  postDefaultGroup(accountId: number, workspace: string | null, defaultGroupId: string): Observable<any> {
    let url_ = this.baseUrl + '/v2/accounts/{accountId}/workspaces/{workspace}/devicegroups/default';
    if (accountId === undefined || accountId === null) {
      return throwError("The parameter 'accountId' must be defined.");
    }
    url_ = url_.replace('{accountId}', encodeURIComponent('' + accountId));
    if (workspace === undefined || workspace === null) throw new Error("The parameter 'workspace' must be defined.");
    url_ = url_.replace('{workspace}', encodeURIComponent('' + workspace));
    if (defaultGroupId === undefined || defaultGroupId === null) {
      return throwError("The parameter 'defaultGroupId' must be defined.");
    }
    url_ = url_.replace(/[?&]$/, '');

    const options: any = {
      body: {
        defaultGroupId: defaultGroupId
      },
      observe: 'response',
      responseType: 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    return this.http.request('POST', url_, options).pipe(catchError((err) => throwError(err)));
  }

  postDeviceGroup(accountId: number, workspace: string | null, groupName: string): Observable<any> {
    let url_ = this.baseUrl + '/v2/accounts/{accountId}/workspaces/{workspace}/devicegroups';
    if (accountId === undefined || accountId === null) {
      return throwError("The parameter 'accountId' must be defined.");
    }
    url_ = url_.replace('{accountId}', encodeURIComponent('' + accountId));
    if (workspace === undefined || workspace === null) throw new Error("The parameter 'workspace' must be defined.");
    url_ = url_.replace('{workspace}', encodeURIComponent('' + workspace));
    if (groupName === undefined || groupName === null) {
      return throwError("The parameter 'groupName' must be defined.");
    }
    url_ = url_.replace(/[?&]$/, '');

    const options: any = {
      body: {
        name: groupName,
        isSyncEnabled: true,
        description: '',
        workspaces: ['all']
      },
      observe: 'response',
      responseType: 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    return this.http.request('POST', url_, options).pipe(catchError((err) => throwError(err)));
  }

  setConfigurationPackage(
    accountId: number,
    workspace: string | null,
    deviceGroupId: string,
    configurationPackage: IArenaConfigurationPackage
  ): Observable<any> {
    let url_ = this.baseUrl + '/v2/accounts/{accountId}/workspaces/{workspace}/devicegroups/{deviceGroupId}/configuration';
    if (accountId === undefined || accountId === null) {
      throw new Error("The parameter 'accountId' must be defined.");
    }
    url_ = url_.replace('{accountId}', encodeURIComponent('' + accountId));
    if (workspace === undefined || workspace === null) throw new Error("The parameter 'workspace' must be defined.");
    url_ = url_.replace('{workspace}', encodeURIComponent('' + workspace));
    if (deviceGroupId === undefined || deviceGroupId === null) {
      return throwError("The parameter 'deviceGroupId' must be defined.");
    }
    url_ = url_.replace('{deviceGroupId}', encodeURIComponent('' + deviceGroupId));
    if (configurationPackage === undefined || configurationPackage === null) {
      return throwError("The parameter 'deviceGroupId' must be defined.");
    }
    url_ = url_.replace(/[?&]$/, '');

    const options: any = {
      body: configurationPackage,
      observe: 'response',
      responseType: 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    return this.http.request('PUT', url_, options).pipe(catchError((err) => throwError(err)));
  }

  moveDevices(
    accountId: number,
    workspace: string | null,
    ownerDeviceGroupId: string,
    finalDeviceGroupId: string,
    devices: string[]
  ): Observable<any> {
    let url_ = this.baseUrl + '/v2/accounts/{accountId}/workspaces/{workspace}/devicegroups/{deviceGroupId}/move';
    if (accountId === undefined || accountId === null) {
      return throwError("The parameter 'accountId' must be defined.");
    }
    url_ = url_.replace('{accountId}', encodeURIComponent('' + accountId));
    if (workspace === undefined || workspace === null) throw new Error("The parameter 'workspace' must be defined.");
    url_ = url_.replace('{workspace}', encodeURIComponent('' + workspace));
    if (ownerDeviceGroupId === undefined || ownerDeviceGroupId === null) {
      return throwError("The parameter 'ownerDeviceGroupId' must be defined.");
    }
    url_ = url_.replace('{deviceGroupId}', encodeURIComponent('' + ownerDeviceGroupId));
    if (devices === undefined || devices === null) {
      return throwError("The parameter 'devices' must be defined.");
    }
    if (finalDeviceGroupId === undefined || finalDeviceGroupId === null) {
      return throwError("The parameter 'finalDeviceGroupId' must be defined.");
    }
    url_ = url_.replace(/[?&]$/, '');

    const options: any = {
      body: { targetAccountId: accountId, targetGroup: finalDeviceGroupId, devices: devices },
      observe: 'response',
      responseType: 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    return this.http.request('PUT', url_, options).pipe(catchError((err) => throwError(err)));
  }

  addDevices(accountId: number, workspace: string | null, deviceGroupId: string, devices: string[]): Observable<any> {
    let url_ = this.baseUrl + '/v2/accounts/{accountId}/workspaces/{workspace}/devicegroups/{deviceGroupId}/devices';
    if (accountId === undefined || accountId === null) {
      return throwError("The parameter 'accountId' must be defined.");
    }
    url_ = url_.replace('{accountId}', encodeURIComponent('' + accountId));
    if (workspace === undefined || workspace === null) throw new Error("The parameter 'workspace' must be defined.");
    url_ = url_.replace('{workspace}', encodeURIComponent('' + workspace));
    if (deviceGroupId === undefined || deviceGroupId === null) {
      return throwError("The parameter 'deviceGroupId' must be defined.");
    }
    url_ = url_.replace('{deviceGroupId}', encodeURIComponent('' + deviceGroupId));
    if (devices === undefined || devices === null) {
      return throwError("The parameter 'devices' must be defined.");
    }
    url_ = url_.replace(/[?&]$/, '');

    const options: any = {
      body: { devices: devices },
      observe: 'response',
      responseType: 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    return this.http.request('PUT', url_, options).pipe(catchError((err) => throwError(err)));
  }

  protected processResponse(response: HttpResponseBase): Observable<any> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body : (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          let resultData201 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
          return _observableOf({ ...resultData201 });
        })
      );
    } else if (status === 400) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          let result400: any = null;
          let resultData400 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
          result400 = ProblemDetails.fromJS(resultData400);
          return throwException('Bad Request', status, _responseText, _headers, result400);
        })
      );
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          return throwException('An unexpected server error occurred.', status, _responseText, _headers);
        })
      );
    }
    return _observableOf<any>(<any>null);
  }
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next('');
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = (event) => {
        observer.next((<any>event.target).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}

export class ApiException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any };
  result: any;

  constructor(message: string, status: number, response: string, headers: { [key: string]: any }, result: any) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isApiException = true;

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true;
  }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any }, result?: any): Observable<any> {
  if (result !== null && result !== undefined) return _observableThrow(result);
  else return _observableThrow(new ApiException(message, status, response, headers, null));
}
export class ProblemDetails implements IProblemDetails {
  type?: string | undefined;
  title?: string | undefined;
  status?: number | undefined;
  detail?: string | undefined;
  instance?: string | undefined;
  readonly extensions?: { [key: string]: any } | undefined;

  constructor(data?: IProblemDetails) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.type = _data['type'];
      this.title = _data['title'];
      this.status = _data['status'];
      this.detail = _data['detail'];
      this.instance = _data['instance'];
      if (_data['extensions']) {
        (<any>this).extensions = {} as any;
        for (let key in _data['extensions']) {
          if (_data['extensions'].hasOwnProperty(key)) (<any>this).extensions![key] = _data['extensions'][key];
        }
      }
    }
  }

  static fromJS(data: any): ProblemDetails {
    data = typeof data === 'object' ? data : {};
    let result = new ProblemDetails();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['type'] = this.type;
    data['title'] = this.title;
    data['status'] = this.status;
    data['detail'] = this.detail;
    data['instance'] = this.instance;
    if (this.extensions) {
      data['extensions'] = {};
      for (let key in this.extensions) {
        if (this.extensions.hasOwnProperty(key)) data['extensions'][key] = this.extensions[key];
      }
    }
    return data;
  }
}

export interface IProblemDetails {
  type?: string | undefined;
  title?: string | undefined;
  status?: number | undefined;
  detail?: string | undefined;
  instance?: string | undefined;
  extensions?: { [key: string]: any } | undefined;
}

export interface IDevicesGroups {
  accountId: number;
  arenaConfigurationPackage: IArenaConfigurationPackage;
  configuration: string[];
  description: string;
  deviceCount: number;
  devices: string[];
  firmware: string;
  id: string;
  isActive: boolean;
  isLockedForUpdate: boolean;
  isSyncEnabled: boolean;
  name: string;
  syncProgress: number;
  syncingDevices: string[];
  workspaces: string[];
}

export interface IArenaConfigurationPackage {
  name: string;
  number: string;
  revisionNumber: string;
  guid: string;
}

export interface IArenaConfigurationPackageResponse {
  continuationToken: string;
  count: number;
  results: IArenaConfigurationPackage[];
}

export interface IDevicesGroupsResponse {
  continuationToken: string;
  count: number;
  results: IDevicesGroups[];
}

export interface IDeviceGroupCreated {
  id: string;
  name: string;
  description: string;
  deviceCount: number;
  accountId: number;
  isActive: true;
  isSyncEnabled: true;
  isLockedForUpdate: true;
  firmware: string;
  syncProgress: number;
  devices: string[];
  configuration: string[];
  arenaConfigurationPackage: IArenaConfigurationPackage;
  syncingDevices: [
    {
      deviceId: string;
      progress: number;
      status: string;
      reason: string;
    }
  ];
  workspaces: string[];
}

export interface IDefaultGroup {
  accountId: number;
  defaultGroupName: string;
  defaultGroupId: string;
}
