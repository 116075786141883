import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConfirmationDialogComponent } from '../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { FeatureFlagService } from '../services/feature-flag.service';

@Component({
  selector: 'ads-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  welcomeMessage = '';
  public links = [
    { displayText: 'Dashboard', path: 'dashboard', icon: 'dashboard' },
    { displayText: 'Users', path: 'users', icon: 'supervisor_account' },
    { displayText: 'Devices', path: 'devices', icon: 'settings_remote' },
    // { displayText: 'SIMs', path: 'sims', icon: 'sim_card'  },
    { displayText: 'Assets', path: 'assets', icon: 'train' },
    { displayText: 'Assets PaaS', path: 'assets-paas', icon: 'train' },
    { displayText: 'Assets Sharing', path: 'assets-sharing', icon: 'train' },
    { displayText: 'Accounts', path: 'accounts', icon: 'business' },
    { displayText: 'Shipments', path: 'shipments', icon: 'local_shipping' },
    { displayText: 'Shipments V2', path: 'shipments-v2', icon: 'local_shipping', hidden: true },
    { displayText: 'Permissions', path: 'policies', icon: 'lock' },
    { displayText: 'Locations', path: 'locations', icon: 'location_on' },
    { displayText: 'Empty Load', path: 'empty-load', icon: 'local_grocery_store' }
  ];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map((result) => result.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    public authService: MsalService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    const user = this.authService.getAccount();
    if (user) {
      this.welcomeMessage = user.name;
    }

    this.featureFlagService.permissionTabEnabled.then((treatment) => {
      if (treatment !== 'on') {
        this.links = this.links.filter((obj) => obj.displayText !== 'Permissions');
      }
    });

    this.featureFlagService.emptyLoadEnabled.then(treatment => {
      if (treatment !== 'on') {
        this.links = this.links.filter((obj) => obj.displayText !== 'Empty Load');
      }
    });

    this.featureFlagService.assetsPaaSEnabled.then(treatment => {
      if (treatment !== 'on') {
        this.links = this.links.filter((obj) => obj.displayText !== 'Assets PaaS');
      }
    });

    this.featureFlagService.assetsPaaSEnabled.then(treatment => {
      if (treatment !== 'on') {
        this.links = this.links.filter((obj) => obj.displayText !== 'Assets Sharing');
      }
    });

    this.showIfFeatureEnabled('Shipments V2', this.featureFlagService.shipmentsV2Enabled);
  }

  showIfFeatureEnabled(linkDisplayText: string, treatmentPromise: Promise<string>) {
    treatmentPromise.then(treatment => {
      if (treatment === 'on') {
        this.links.find((obj) => obj.displayText === linkDisplayText).hidden = false;
      }
    })
  }

  logout() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure you want to logout?',
        message: 'This will log you out of the Operational Portal',
        confirmColor: 'warn',
        buttonText: {
          ok: 'Logout',
          cancel: 'Cancel'
        }
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.authService.logout();
      }
    });
  }
}
