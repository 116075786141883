<ads-last-connected-widget *ngIf="hidden"></ads-last-connected-widget>
<div class="card-layout" *ngIf="hidden">
  <mat-card class="three-card">
    <mat-card-title>
      Ready to Ship
    </mat-card-title>
    <mat-card-subtitle>
      Bulk set devices to ready to ship
    </mat-card-subtitle>
    <mat-card-content>
      <!--<ads-file-uploader [options]="deviceShippedUploadOptions"></ads-file-uploader>-->
      <p>All of the devices in the csv will be marked as ready to ship and an IoThub record will be created.</p>
      <form>
        <input enctype="multipart/form-data" type="file" accept=".csv"
          (change)="handleFileSelection($event.target.files, 'shipping-import')" />
        <mat-card-actions align="end">
          <!--
          <button mat-flat-button color="primary" [disabled]="!devicesReadyToShipCsv" type="submit"
            (click)="uploadDevicesReadyToShipCsv()">
            Upload
          </button>
        -->
        </mat-card-actions>
      </form>
    </mat-card-content>
  </mat-card>

  <mat-card class="three-card">
    <mat-card-title>
      Assign Devices
    </mat-card-title>
    <mat-card-subtitle>
      Bulk assigning of devices to a customer
    </mat-card-subtitle>
    <mat-card-content>
      <p>All of the devices in the csv will be updated and associated to the account specified.</p>
      <form [formGroup]="deviceAccountForm">
        <div>
          <ads-account-select-search ngDefaultControl placeholder="Account" [control]="deviceAccountForm.get('accountId')"
            formControlName="accountId">
          </ads-account-select-search>
        </div>
        <input type="file" accept=".csv" (change)="handleFileSelection($event.target.files, 'customer-assignment')" />
        <mat-card-actions align="end">
          <!--
          <button mat-flat-button color="primary" [disabled]="!devicesShippedCsv" type="submit"
            (click)="uploadDevicesShippedCsv()">
            Upload
          </button>
          -->
        </mat-card-actions>
      </form>
    </mat-card-content>
  </mat-card>

  <mat-card class="three-card">
    <mat-card-title>
      Import Assets
    </mat-card-title>
    <mat-card-subtitle>
      Import assets from a CSV
    </mat-card-subtitle>
    <mat-card-content *ngIf='!importErrorsExist'>
      <!--<ads-file-uploader [options]="deviceShippedUploadOptions"></ads-file-uploader>-->
      <p>All of the assets in the csv will be imported and marked active.</p>
      <form>
        <input enctype="multipart/form-data" type="file" accept=".csv"
          (change)="handleFileSelection($event.target.files, 'assets-import')" />
        <mat-card-actions align="end">
          <button mat-flat-button color="primary" [disabled]="!assetsImportCsv" type="submit"
            (click)="uploadAssetsCsv()">
            Upload
          </button>
        </mat-card-actions>
      </form>
    </mat-card-content>
    <mat-card-content *ngIf='importErrorsExist'>
      <p>The following entries have failed to import</p>
      <ul>
        <!-- TODO: COmment back in after fixing the import process
        https://dev.azure.com/amsteddigital/IONX%20Platform/_workitems/edit/8053 -->
        <!-- <li *ngFor="let error of importErrorList">
          Row {{error.lineNumber}} - {{error.assetName}} ({{error.message}})
        </li> -->
      </ul>
      <mat-card-actions align="end">
        <button mat-flat-button color="primary" type="button"
          (click)="cancelErrorView()">
          Ok
        </button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
</div>
