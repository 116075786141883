<mat-card-content class="mat-table-scroll-vertical">
  <mat-card-subtitle fxLayout="row" fxLayoutAlign="space-between">
    <div class="account-tab-top-padding">
      User Groups
    </div>
    <div>
      <button id='ad-agt-creategroupbutton' mat-button color="primary" (click)="createUserGroup()">Create Group</button>
    </div>
  </mat-card-subtitle>

  <table mat-table matSort class="m-t-20 assetActive-table" [dataSource]="userGroupsTableData">
    <ng-container matColumnDef="name">
      <th class="table-header asset-group-column" mat-header-cell *matHeaderCellDef>Group Name</th>
      <td class="asset-group-column" mat-cell *matCellDef="let userGroup">{{ userGroup.groupName }}</td>
    </ng-container>
    <ng-container matColumnDef="userCount">
      <th class="table-header asset-group-column" mat-header-cell *matHeaderCellDef>Users</th>
      <td class="asset-group-column" mat-cell *matCellDef="let userGroup">{{ userGroup.usersCount || 0 }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th class="table-header" mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let userGroup">
        <button id='ad-agt-editbutton' mat-icon-button class="black" (click)="onRowClick(userGroup)"><mat-icon>edit</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
  </table>
  <div *ngIf="emptyUserGroupsData">
    <label class="sm-note">{{emptyUserGroupsMessage}}</label>
  </div>
</mat-card-content>
