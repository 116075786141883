<div class="my-3">
  <mat-card>
    <mat-card-content fxLayout="row" class="manage-permissions-wrapper">
      <button id='dd-managedevicepermissionsbutton' mat-button color="primary" (click)="manageDevicePermissions()">Manage Permissions </button>
    </mat-card-content>
    <mat-card-content fxLayout="row" class="table-toolbar-wrapper">
        <div fxFlex>
          <mat-form-field>
            <mat-label></mat-label>
            <input id='ac-devices-searchtext' name="searchText" matInput (keyup)="searchTriggered($event.target.value)" #searchText
                  placeholder="To search devices, please begin typing the serial number." />
          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field class="device-group-select">
            <mat-label>Group: </mat-label>
            <mat-select id="as-devicegroupdropdown" [(value)]="deviceGroupSelected" (selectionChange)="onDeviceGroupChanged($event)" placeholder=All disableOptionCentering #singleSelect>
                <mat-option *ngFor="let group of deviceGroups" id="{{group.id}}" [value]="group.id">
                  {{group.name}}
                </mat-option>
            </mat-select>
          </mat-form-field>
          <ads-workspace-select #workspaceSelect accountId="{{accountId}}"></ads-workspace-select>
        </div>        
        <div fxFlex>
          <mat-paginator #paginator
                        [length]="length"
                        [pageSize]="pageSize"
                        [pageSizeOptions]="pageSizeOptions"
                        (page)="updatePaginator($event)">

          </mat-paginator>
        </div>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-content>
      <mat-card-content class="table-container">
        <table mat-table [dataSource]="deviceData" matSort class="m-t-20 constant-col" [hidden]="emptyData">
          <ng-container matColumnDef="serialNumber">
            <th mat-header-cell class="table-header" *matHeaderCellDef>Serial Number</th>
            <td mat-cell *matCellDef="let device">{{ device.serialNumber }}</td>
          </ng-container>

          <ng-container matColumnDef="imei">
            <th mat-header-cell class="table-header" *matHeaderCellDef>IMEI</th>
            <td mat-cell *matCellDef="let device">{{ device.imei }}</td>
          </ng-container>

          <ng-container matColumnDef="createdDate">
            <th mat-header-cell class="table-header" *matHeaderCellDef>Date Created</th>
            <td mat-cell *matCellDef="let device">{{ device.createdDate | date: "MM/dd/yyyy" }}</td>
          </ng-container>

          <ng-container matColumnDef="deviceGroup">
            <th mat-header-cell class="table-header" *matHeaderCellDef>Device Group</th>
            <td mat-cell *matCellDef="let device">{{getDeviceGroupName(device.groupId)}}</td>
          </ng-container>

          <ng-container matColumnDef="isActive">
            <th mat-header-cell class="table-header" *matHeaderCellDef>Enabled</th>
            <td mat-cell *matCellDef="let device">{{ device.isActive }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
          <tr mat-row class="hover" (click)="onUserRowSelect(row)" *matRowDef="let row; columns: columnsToDisplay"></tr>
        </table>
      </mat-card-content>
      <div *ngIf="emptyData">
        <label class="sm-note">{{emptyMessage}}</label>
      </div>
    </mat-card-content>
  </mat-card>

</div>
