<div fxLayout="row" fxLayoutAlign="space-between center" mat-dialog-title>
  Create Device Groups <strong class="close-icon" (click)="close()">&times;</strong>
</div>
<mat-dialog-content [formGroup]="deviceGroupForm">
  <label class="bold section-title">Choose Configuration & Name:</label>
  <mat-form-field class="device-group-select">
    <mat-label>Configuration</mat-label>
    <mat-select
      id="as-devicesgroup-configuration-dropdown"
      formControlName="configuration"
      (selectionChange)="onConfigurationChanged($event)"
      panelClass="as-devicesgroupconfigdropdown-panel"
      disableOptionCentering
      #singleSelect
    >
      <mat-option disabled value="">Select a configuration</mat-option>
      <mat-option *ngFor="let config of configurations" id="{{ config.guid }}" [value]="config.guid">
        {{ config.number }} ({{ config.name }})
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Group Name</mat-label>
    <input
      id="devices-group-searchtext"
      name="DevicesGroupName"
      matInput
      maxlength="50"
      #searchText
      autocomplete="off"
      formControlName="groupName"
      placeholder="Group Name"
    />
  </mat-form-field>
  <label class="bold section-title"> Add Devices (or Fleets) into Group:</label>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-radio-group
      aria-label="Select an option"
      #radioGroup="matRadioGroup"
      formControlName="typeItemsGrouped"
      [disabled]="itemsSelected && itemsSelected.length > 0"
    >
      <mat-radio-button value="{{ GroupTypes.DEVICES }}">Devices</mat-radio-button>
      <mat-radio-button value="{{ GroupTypes.FLEETS }}" *ngIf="false">Fleets</mat-radio-button>
    </mat-radio-group>
    <div fxLayout="center center" class="view-devices-fleets" (click)="openSelectionSection(radioGroup.value)">
      <span>View all devices</span><mat-icon>keyboard_arrow_right</mat-icon>
    </div>
  </div>
  <div class="devices-fleets-list" (click)="chipListInput.focus()">
    <mat-form-field class="example-chip-list" appearance="fill">
      <mat-label></mat-label>
      <mat-chip-list #chipList aria-label="items selection">
        <mat-chip
          *ngFor="let item of itemsSelected"
          [removable]="true"
          (removed)="remove(item)"
          appDeviceValidator
          [existingDevices]="devices"
          [deviceEntered]="item.serialNumber"
          (settingValidFlag)="item.isValid = $event"
          (click)="$event.stopPropagation()"
        >
          {{ item.serialNumber }}
          <mat-icon matChipRemove (click)="$event.stopPropagation()">cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Type or Copy & Paste"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          (paste)="paste($event)"
          autocomplete="off"
          #chipListInput
        />
      </mat-chip-list>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="footer-advise">This will move the devices from their current group into this new one</div>
  <button mat-button class="save-btn" [disabled]="!deviceGroupForm.valid || invalidItemsSelected()" (click)="addDeviceGroup()">
    Save Group
  </button>
</mat-dialog-actions>
