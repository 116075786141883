import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { AddLocationDialogComponent } from './add-location-dialog/add-location-dialog.component';
import { LocationGeoJsonDTO, LocationList, LocationService, SimpleLocationDTO } from '../services/geo.service';
import { LocationCacheService } from '../services/location-cache.service';

@Component({
  selector: 'ads-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit, AfterViewInit {
  @ViewChild('map1') map1: google.maps.Map;
  panelOpenState = false;

  map1Controller: MapController;
  locationListController: LocationListController;
  apiLoaded: Observable<boolean>;
  defaultMapOptions: google.maps.MapOptions = {
    center: { lat: 46.241191778846265, lng: -63.130755021961434 },
    zoom: 8
  };

  constructor(
    httpClient: HttpClient,
    public dialog: MatDialog,
    private locationService: LocationService,
    private locationCacheService: LocationCacheService
  ) {
    // make sure the google api is loaded before the component loads
    this.apiLoaded = httpClient
      .jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyDgj3G2EV0bdMBJ573nRgAgKjJ2RCMa52A', 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  ngOnInit(): void {
    this.locationListController = new LocationListController();
    this.locationCacheService.getLocations().subscribe(); // calling this here to hydrate the cache
  }

  ngAfterViewInit(): void {
    this.map1Controller = new MapController(this.map1);
  }

  newLocationBtnClicked(): void {
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddLocationDialogComponent, {
      width: '700px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: LocationGeoJsonDTO) => {
      if (result !== null) {
        try {
          const json = JSON.parse(result.geoJson);
          this.map1Controller.addGeoJson(json);
          this.map1Controller.setCenter(new google.maps.LatLng(result.center[1], result.center[0]));
          this.locationListController.addLocation(result);
        } catch (e) {
          alert('Input Geo Json is invalid!');
        }
      }
    });
  }

  locationItemClicked(location: LocationGeoJsonDTO) {
    this.map1Controller.setCenter(new google.maps.LatLng(location.center[1], location.center[0]));
  }

  removeAllBtnClicked() {
    this.map1Controller.clearMap();
    this.locationListController.removeAllLocations();
  }
}

class LocationListController {
  locations: LocationGeoJsonDTO[];

  constructor() {
    this.locations = [];
  }

  addLocation(location: LocationGeoJsonDTO) {
    this.locations.push(location);
  }

  removeAllLocations() {
    const length = this.locations.length;
    for (let i = 0; i < length; i++) {
      this.locations.pop();
    }
  }
}

class MapController {
  googleMap: google.maps.Map;

  constructor(googleMap: google.maps.Map) {
    this.googleMap = googleMap;
    this.googleMap.data.setStyle({
      strokeColor: '#00acc1',
      fillColor: '#00acc1'
    });
  }

  addGeometryOnMap(geometry: google.maps.LatLng | google.maps.LatLngLiteral | google.maps.Data.Geometry) {
    this.googleMap.data.add({ geometry });
  }

  addGeoJson(geoJson: object) {
    this.googleMap.data.addGeoJson(geoJson);
  }

  clearMap() {
    this.googleMap.data.forEach((geometry) => {
      this.googleMap.data.remove(geometry);
    });
  }

  setCenter(latLng: google.maps.LatLng): void {
    this.googleMap.panTo(latLng);
  }
}
