<mat-card-content class="mat-table-scroll-vertical">
  <mat-card-subtitle fxLayout="row" fxLayoutAlign="space-between">
    <div class="account-tab-top-padding">
      Device Groups
    </div>
    <div>
      <button id='ad-dgt-creategroupbutton' mat-button color="primary" (click)="onCreateDeviceGroupClick()">Create Group</button>
    </div>
  </mat-card-subtitle>

  <table mat-table matSort class="m-t-20 deviceActive-table" [dataSource]="deviceGroupsTableData">
    <ng-container matColumnDef="name">
      <th mat-header-cell class="table-header device-group-column" *matHeaderCellDef mat-sort-header>Group Name</th>
      <td class="device-group-column" mat-cell *matCellDef="let deviceGroup">{{ deviceGroup.name }}</td>
    </ng-container>
    <ng-container matColumnDef="deviceCount">
      <th mat-header-cell class="table-header device-group-column" *matHeaderCellDef mat-sort-header>Devices</th>
      <td class="device-group-column" mat-cell *matCellDef="let deviceGroup">{{ deviceGroup.deviceCount }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th class="table-header" mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let deviceGroup">
        <button id='ad-dgt-editbutton' mat-icon-button class="black" (click)="onRowClick(deviceGroup)"><mat-icon>edit</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
  </table>
  <div *ngIf="emptyDeviceGroupsData">
    <label class="sm-note">{{emptyDeviceGroupsMessage}}</label>
  </div>
</mat-card-content>
