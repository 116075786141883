import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceGroupResponse } from '../../../../../services/devices.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DevicesGroupsService, IDevicesGroups } from '../../../../../services/devices-groups.service';
import { DevicesSelectionComponent } from '../devices-selection/devices-selection.component';
import { groupBy, GroupTypes } from '../device-groups.model';
import { from, Subscription } from 'rxjs';
import { ToasterNotificationService } from '../../../../../services/toasterNotification.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-move-device-group',
  templateUrl: './move-device-group.component.html',
  styleUrls: ['./move-device-group.component.scss']
})
export class MoveDeviceGroupComponent implements OnInit {
  moveGroupForm: FormGroup;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE, ENTER, TAB];
  itemsSelected: any[] = [];
  addOnBlur = true;
  devices: DeviceGroupResponse[];
  groups: IDevicesGroups[];

  subscriptions: Subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      accountId: number;
      devices: DeviceGroupResponse[];
      groups: IDevicesGroups[];
    },
    public dialogRef: MatDialogRef<MoveDeviceGroupComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private toasterService: ToasterNotificationService,
    private devicesGroupsService: DevicesGroupsService
  ) {
    this.devices = [...this.data.devices];
    this.groups = [...this.data.groups];
  }

  ngOnInit(): void {
    this.buildDeviceGroupForm();
  }

  buildDeviceGroupForm() {
    this.moveGroupForm = this.fb.group({
      groupId: ['', [Validators.required]],
      typeItemsGrouped: ['Devices', [Validators.required]]
    });
  }

  // auxiliary inputs methods
  openSelectionSection(section: GroupTypes.DEVICES | GroupTypes.FLEETS) {
    const { groupId } = this.moveGroupForm.value;
    if (groupId !== '') {
      switch (section) {
        case GroupTypes.DEVICES:
          const dialogRef = this.dialog.open(DevicesSelectionComponent, {
            data: {
              groupName: this.groups.find((item) => item.id === groupId).name,
              devices: this.devices,
              devicesSelected: this.itemsSelected.map((item) => item.serialNumber)
            },
            panelClass: 'devices-selection-dialog',
            disableClose: true
          });

          this.subscriptions.add(
            dialogRef.afterClosed().subscribe((selection) => {
              this.itemsSelected = selection.changes;
            })
          );
          break;

        case GroupTypes.FLEETS:
          break;
      }
    } else {
      this.toasterService.showWarnToaster('You should choose the group, first');
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our item
    if ((value || '').trim() && !this.itemsSelected.some((device) => device.serialNumber === value.trim())) {
      this.itemsSelected.push({ serialNumber: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(item): void {
    const index = this.itemsSelected.indexOf(item);

    if (index >= 0) {
      this.itemsSelected.splice(index, 1);
    }
  }

  paste(event: ClipboardEvent): void {
    event.preventDefault();
    event.clipboardData
      .getData('Text')
      .split(/;|,| |\n/)
      .forEach((value) => {
        if (value.trim() && !this.itemsSelected.some((device) => device.serialNumber === value.trim())) {
          this.itemsSelected.push({ serialNumber: value.trim() });
        }
      });
  }

  close(params?) {
    this.dialogRef.addPanelClass('add-device-group-dialog-close');
    this.dialogRef.removePanelClass('add-device-group-dialog');
    setTimeout(() => {
      this.dialogRef.close(params);
    }, 100);
  }

  invalidItemsSelected(): boolean {
    return this.itemsSelected.length <= 0 || this.itemsSelected.filter((item) => !item.isValid).length > 0;
  }

  public get GroupTypes() {
    return GroupTypes;
  }

  submitMove() {
    const devices = this.itemsSelected.map((item) => ({
      ...this.devices.find((_item: any) => _item.serialNumber === item.serialNumber),
      ...item
    }));
    const devicesWithoutGroup = devices.filter((device) => device.groupId == null).map((device) => device.serialNumber);
    const nonSyncingGroup = this.data.groups.filter((group) => !group.isSyncEnabled);

    if (devicesWithoutGroup.length > 0) {
      if (nonSyncingGroup.length > 0) {
        this.devicesGroupsService.addDevices(this.data.accountId, 'all', nonSyncingGroup[0].id, devicesWithoutGroup).subscribe(
          (res) =>
            this.MoveDevices(
              groupBy(
                devices.map((device) => {
                  if (device.groupId == null) {
                    device.groupId = nonSyncingGroup[0].id;
                  }
                  return device;
                }),
                'groupId'
              )
            ),
          (error) => {
            this.toasterService.showWarnToaster(error?.error?.detail || error?.error?.title || error);
          }
        );
      } else {
        this.toasterService.showWarnToaster('No exists any non-syncing group to add some the ungrouped devices you selected');
      }
    } else {
      this.MoveDevices(groupBy(devices, 'groupId'));
    }
  }

  MoveDevices(devicesCollection) {
    from(Object.keys(devicesCollection))
      .pipe(
        concatMap((deviceGroupId) =>
          this.devicesGroupsService.moveDevices(
            this.data.accountId,
            'all',
            deviceGroupId,
            this.moveGroupForm.get('groupId').value,
            devicesCollection[deviceGroupId].map((device) => device.serialNumber)
          )
        )
      )
      .subscribe(
        () => {
          this.dialogRef.close({ result: 'success' });
          this.toasterService.showInfoToaster('Devices moved successfully');
        },
        (error) => {
          this.toasterService.showWarnToaster(error?.error?.detail || error?.error?.title || error);
        }
      );
  }
}
