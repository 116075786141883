import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetsV3Service } from 'src/app/services/assets-v3.service';

@Component({
  selector: 'app-asset-events',
  templateUrl: './asset-events.component.html',
  styleUrls: ['./asset-events.component.scss']
})
export class AssetEventsComponent implements OnInit {
  assetId: string;
  assetEvents: Array<any>;

  constructor(
    private route: ActivatedRoute,
    private assetsV3Service: AssetsV3Service,
  ) {
    this.assetId = this.route.snapshot.params.assetId;
  }

  ngOnInit(): void {
    this.assetsV3Service.getAssetEvents(this.assetId)
      .subscribe(events => {
        this.assetEvents = events
          .map(x => ({
            // doing this so these fields always show first
            id: x.id,
            assetId: x.assetId,
            eventType: x.eventType,
            eventDateTimeUtc: x.eventDateTimeUtc,
            source: x.source,

            ...x
          }));
      });
    }
}
