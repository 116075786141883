<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    {{ deviceGroup.id == null ? 'New' : 'Edit' }} Device Group
    <div *ngIf="deviceGroup.id != null">
      <button id='dg-editstatusbutton' mat-button color="warn" (click)="onDeleteDeviceGroupClick()">
        Delete Device
        Group
      </button>
    </div>
  </mat-card-title>
  <form (ngSubmit)="onSubmit()">
    <ng-container [formGroup]="deviceGroupForm">
      <mat-card-content>
        <mat-form-field>
          <mat-label class="form-field-label">Device Group Name</mat-label>
          <input #deviceGroupName id='dg-devicegroupname' matInput class="w-25-pct" formControlName="deviceGroupName"
                 maxlength="100" required>
          <ads-control-messages [control]="deviceGroupForm.get('deviceGroupName')"></ads-control-messages>
        </mat-form-field>
        <div *ngIf="this.loaded">
          <ads-device-workspace-chips #chips groupId="{{this.deviceGroup.id}}" accountId="{{this.deviceGroup.accountId}}"></ads-device-workspace-chips>
        </div>
      </mat-card-content>
      <hr>
      <div class="card-layout m-t-35">
        <div fxFlex="60%">
          <mat-card class="medium-card">
            <h2>Available Devices</h2>
            <input name="deviceSearchText" matInput (keyup)="searchTriggered($event.target.value)"
                   id="availableDevicesSearchInput" placeholder="Search for devices" />
            <div class="mat-table-scroll-vertical">
              <table mat-table [dataSource]="availableDevicesData" matSort class="m-t-20"
                     *ngIf="!availableDevicesEmptyData">
                <ng-container matColumnDef="serialNumber">
                  <th class="table-header" mat-header-cell *matHeaderCellDef>Serial Number</th>
                  <td mat-cell *matCellDef="let device">{{ device.serialNumber }}</td>
                </ng-container>

                <ng-container matColumnDef="imei">
                  <th class="table-header" mat-header-cell *matHeaderCellDef>IMEI</th>
                  <td mat-cell *matCellDef="let device">{{ device.imei }}</td>
                </ng-container>

                <ng-container matColumnDef="dateCreated">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</th>
                  <td mat-cell *matCellDef="let device">{{ device.createdDate | date: "MM/dd/yyyy" }}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th class="table-header" mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let device">
                    <button id='dg-adddevicebutton' mat-icon-button class="mat-icon-button green"
                            (click)="addDevice(device)">
                      <mat-icon>add_box</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="availableDevicesColumnsToDisplay; sticky: true"></tr>
                <tr mat-row *matRowDef="let device; columns: availableDevicesColumnsToDisplay"
                    [hidden]="device.IsActive">
                </tr>
              </table>
            </div>
            <div *ngIf="availableDevicesEmptyData">
              <label class="sm-note">{{ availableDevicesEmptyMessage }}</label>
            </div>
          </mat-card>
        </div>
        <div fxFlex="40%">
          <mat-card class="medium-card">
            <h2>Devices In Group</h2>
            <mat-card-content class="table-container">
              <table mat-table matSort class="m-t-20 deviceActive-table" [dataSource]="groupDevicesData"
                     *ngIf="deviceGroup.devices !== undefined && deviceGroup.devices.length > 0">
                <ng-container matColumnDef="serialNumber">
                  <th class="table-header device-column" mat-header-cell *matHeaderCellDef>Serial Number</th>
                  <td class="device-column" mat-cell *matCellDef="let device">{{ device }}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th class="table-header" mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let device">
                    <button id='dg-removedevicebutton' mat-icon-button class="mat-icon-button red"
                            (click)="removeDevice(device)">
                      <mat-icon>clear</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="addedDeviceColumnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: addedDeviceColumnsToDisplay"></tr>
              </table>
            </mat-card-content>
            <div *ngIf="deviceGroup.devices !== undefined && deviceGroup.devices.length === 0">
              <label class="sm-note">No devices in this group</label>
            </div>
          </mat-card>
        </div>
      </div>
      <mat-card-actions align="end">
        <button id='dg-cancelbutton' type="button" mat-stroked-button (click)="navigateToAccount()">Cancel</button>
        <button id='dg-savegroupbutton' mat-flat-button color="primary" type="submit">{{ deviceGroup.id == null ? 'Create' : 'Update' }}</button>
      </mat-card-actions>
    </ng-container>
  </form>
</mat-card>
