import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';

import { ShipmentsRoutingModule } from './shipments-routing.module';
import { ShipmentsComponent } from './shipments.component';
import { CDAFileDownloadService, CDA_File_Download_API_BASE_URL } from '../services/cda-file-download.service';
import { CDAToolService, DEVICES_API_BASE_URL } from '../services/devices.service';
import { environment } from '@env/environment';
import { ImportShipmentsComponent } from './import-shipments/import-shipments.component';
import { CDAToolFileProcessingService, CDA_FILE_PROCESSING_API_BASE_URL } from '../services/cda-file-processing.service';
import { LogEntryDetailComponent } from './import-shipments/log-entry-detail/log-entry-detail.component';

@NgModule({
  declarations: [ShipmentsComponent, ImportShipmentsComponent, LogEntryDetailComponent],
  imports: [CommonModule, ShipmentsRoutingModule, CommonUiSharedModule],
  providers: [
    CDAToolService,
    CDAFileDownloadService,
    CDAToolFileProcessingService,
    {
      provide: DEVICES_API_BASE_URL,
      useValue: environment.devicesBaseUrl
    },
    {
      provide: CDA_File_Download_API_BASE_URL,
      useValue: environment.devicesBaseUrl
    },
    {
      provide: CDA_FILE_PROCESSING_API_BASE_URL,
      useValue: environment.devicesBaseUrl
    }
  ]
})
export class ShipmentsModule {}
