import { Component, Inject, HostBinding, ElementRef, OnInit } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { ViewChild } from '@angular/core';
import { DevicesService } from '../services/devices.service';
import { ToasterNotificationService } from '../services/toasterNotification.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { AssetsService } from '../services/assets.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'ads-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  @ViewChild('myInput', { static: true })
  @HostBinding('class')
  hidden = true;
  classes = 'example-items-rows';
  myInputVariable: ElementRef;
  uploader: FileUploader;
  item: FileItem;
  deviceShippedUploadOptions: any;
  deviceCustomerUploadOptions: any;
  devicesReadyToShipCsv: File = null;
  devicesShippedCsv: File = null;
  assetsImportCsv: File = null;
  deviceAccountForm: FormGroup;
  importErrorsExist = false;
  // importErrorList: BulkImportAssetResultDto[];

  constructor(private deviceService: DevicesService, private assetsService: AssetsService, private authService: MsalService,
    private toaster: ToasterNotificationService) {
    this.deviceShippedUploadOptions = {
      toastrMessageTitle: 'Import Devices to IoT Hub',
      uploadUrl: `${environment.devicesBaseUrl}/shipping-import`,
    };
  }

  ngOnInit(): void {
    this.deviceAccountForm = new FormGroup({
      accountId: new FormControl(null, [Validators.required]),
    });
  }

  handleFileSelection(files: FileList, endpoint: string) {
    switch (endpoint) {
      case 'shipping-import':
        this.devicesReadyToShipCsv = files.item(0);
        break;
      case 'customer-assignment':
        this.devicesShippedCsv = files.item(0);
        break;
      case 'assets-import':
        this.assetsImportCsv = files.item(0);
        break;
      default:
        break;
    }
  }
  /*
  uploadDevicesReadyToShipCsv() {
    const file = {
      data: this.devicesReadyToShipCsv,
      fileName: this.devicesReadyToShipCsv.name
    };

    this.deviceService.bulkUpdateShippingInfo(file).subscribe(() => {
      this.toaster.showInfoToaster('Records updated successfully');
    },
      error => {
        this.toaster.showWarnToaster(error.detail);
      });
  }

  uploadDevicesShippedCsv() {
    if (!this.deviceAccountForm.valid) {
      return;
    }

    const file = {
      data: this.devicesShippedCsv,
      fileName: this.devicesShippedCsv.name
    };

    this.deviceService.assignDevicesToCustomer(file,
      this.deviceAccountForm.controls.accountId.value).subscribe(
      () => {
        this.toaster.showInfoToaster('Records updated successfully');
      },
      error => {
        this.toaster.showInfoToaster(`${error.detail}`);
      });
  }
*/
  uploadAssetsCsv() {
    const file = {
      data: this.assetsImportCsv,
      fileName: this.assetsImportCsv.name
    };

    const account = this.authService.getAccount();
    console.error('not implemented');
    // TODO: Comment this back in after fixing import
    // https://dev.azure.com/amsteddigital/IONX%20Platform/_workitems/edit/8053
    // this.assetsService.bulkImportAssets(1, account.name, file).subscribe(
    //   (result) => {
    //     const assetResult = result.result[0];
    //     if (assetResult) {
    //       this.importErrorsExist = true;
    //       this.importErrorList = assetResult.failures;
    //       this.toaster.showInfoToaster('Errors occurred. See below for details.');
    //     } else {
    //       this.toaster.showInfoToaster('All Records processed successfully');
    //     }
    //   },
    //   error => {
    //     this.toaster.showWarnToaster(`${error.detail}`);
    //   });
  }

  reset() {
    this.myInputVariable.nativeElement.value = '';
  }

  cancelErrorView() {
    this.importErrorsExist = false;
    // this.importErrorList = [];
  }
}
