<button id="jd-close=button" mat-icon-button class="raw-message-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title>Asset Trace</h1>
<mat-dialog-content class="asset-trace-dialog">
	<ng-container>
    <mat-card-content>

      <ng-http-loader
        [debounceDelay]="100"
        [extraDuration]="100"
        [minDuration]="100"
        [opacity]="0.6"
        [spinner]="spinkit.skWave"
        [filteredUrlPatterns]="[
          '/api/v[0-9]+/Users/[0-9a-z\-]+/audits',
          '/api/v[0-9]+/Installers',
          '/api/v[0-9]+/rsrd2/GetRefreshStatusByAccount',
          '/api/v[0-9]+/CDATool/ProcessingLog',
          'dc\.services\.visualstudio\.com/v2/track'
        ]"
        >
      </ng-http-loader>

      <div *ngIf="!isLoading">
        <div *ngIf="!hasData" style="text-align: center;">
          No Asset Trace Data Available
        </div>
        <div *ngIf="hasData">
          <app-asset-trace-line-map [locationArray]="locationArray"></app-asset-trace-line-map>
          <app-asset-trace-data-view-grid [apiResponseItemsData]="apiResponseItemsData"></app-asset-trace-data-view-grid>
        </div>
      </div>

    </mat-card-content>
  </ng-container>
</mat-dialog-content>
