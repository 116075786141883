/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import {} from 'google-maps';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmitterService {
  // Subjects
  private deselectRow = new Subject<null>();
  private chartResize = new Subject<null>();

  // Filter Subjects
  private filterTrigger = new BehaviorSubject<any>([]);

  // Navigation Subjects
  private filterToggle = new Subject<boolean>();

  // Map Subjects
  private resizeTrigger = new Subject<string>();

  // public version - this is for ones that will subscribe but not emit via next
  public deselectRow$: Observable<null> = this.deselectRow;
  public chartResize$: Observable<null> = this.chartResize;

  // Filter Observables
  public filterTrigger$: Observable<any> = this.filterTrigger;

  // Navigation Observables
  public filterToggle$: Observable<boolean> = this.filterToggle;

  // Map Observables
  public resizeTrigger$: Observable<string> = this.resizeTrigger;

  constructor(
  ) {

  }

  // Functions to control when next call is made

  // Handles Deselection of markers
  emitOnMapMarkerDeselected(change: null = null) {
    this.deselectRow.next(change);
  }

  // Handles Splitter resizing (Map and menu part)
  emitOnSplitterDimensionResized(change: null = null) {
    this.chartResize.next(change);
  }

  // Handles unsubscribing
  unsubscribeFromSubscription(subscription: Subscription) {
    if (subscription) {
      subscription.unsubscribe();
    }
  }
}
