export class Popup extends google.maps.OverlayView {
  position: google.maps.LatLng;
  containerDiv: any;

  constructor(position: google.maps.LatLng, name: string, date = '') {
    super();
    this.position = position;

    const bubbleAnchor = document.createElement('div');
    bubbleAnchor.classList.add('mapLabel__spans');
    bubbleAnchor.innerHTML = `
      <span>${name}</span>
      <span>${date}</span>`;

    this.containerDiv = document.createElement('div');
    this.containerDiv.classList.add('mapLabel');
    this.containerDiv.appendChild(bubbleAnchor);

    Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
  }

  onAdd() {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    this.getPanes()!.floatPane.appendChild(this.containerDiv);
  }

  onRemove() {
    if (this.containerDiv.parentElement) {
      this.containerDiv.parentElement.removeChild(this.containerDiv);
    }
  }

  draw() {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    const divPosition = this.getProjection().fromLatLngToDivPixel(
      this.position
    )!;

    // Hide the popup when it is far out of view.
    const display =
      Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
        ? 'block'
        : 'none';

    if (display === 'block') {
      this.containerDiv.style.left = divPosition.x + 'px';
      this.containerDiv.style.top = divPosition.y + 'px';
    }

    if (this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display;
    }
  }
}
