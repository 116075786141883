<h1 mat-dialog-title>Log Entry Detail</h1>
<div mat-dialog-content class="content">
  <dl>
    <dt>Status:</dt>
    <dd>{{ data.processingStatus }}</dd>
    <dt>Message:</dt>
    <dd>{{ data.message }}</dd>
    <dt>Created Date:</dt>
    <dd>{{ data.createdDate }}</dd>
    <dt>Original Filename</dt>
    <dd>{{ data.originalFileName }}</dd>
  </dl>

  <mat-tab-group *ngIf="processingCompleted">
    <mat-tab label="Information">
      <ng-template mat-tab-label> Information ({{ processingResult.InfoList.length }}) </ng-template>
      <mat-selection-list class="result-list">
        <mat-list-option *ngFor="let info of processingResult.InfoList">
          {{ info.message }}
        </mat-list-option>
      </mat-selection-list>
    </mat-tab>
    <mat-tab label="Warning">
      <ng-template mat-tab-label> Warning ({{ processingResult.WarningList.length }}) </ng-template>

      <mat-selection-list class="result-list">
        <mat-list-option *ngFor="let warning of processingResult.WarningList">
          {{ warning.message }}
        </mat-list-option>
      </mat-selection-list>
    </mat-tab>
    <mat-tab label="Error">
      <ng-template mat-tab-label> Error ({{ processingResult.ErrorList.length }}) </ng-template>
      <mat-selection-list class="result-list">
        <mat-list-option *ngFor="let error of processingResult.ErrorList">
          {{ error.message }}
        </mat-list-option>
      </mat-selection-list>
    </mat-tab>
    <mat-tab label="Download">
      <ng-template mat-tab-label> Download</ng-template>
      <mat-list class="result-list">
        <mat-list-item *ngFor="let file of processedFileNames">
          <a class="underline" mat-button color="primary" (click)="download(file)">{{ file }}</a>
        </mat-list-item>
      </mat-list>
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions>
  <button id="le-close-btn" mat-stroked-button mat-dialog-close>Close</button>
</div>
