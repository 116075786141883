<button id="jd-close=button" mat-icon-button class="raw-message-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title>Asset Properties</h1>
<mat-dialog-content class="asset-properties-dialog">
	<ng-container>
    <mat-card-content >
      <div>
        <div class="edit-properties-header">
          <span>Operation</span>
          <span>Property Name</span>
          <span>Value</span>
        </div>
        <div *ngFor="let property of propertyOps">
          <mat-form-field class="select-field">
            <mat-select [(value)]="property.operationType" #singleSelect>
              <ng-container>
                  <mat-option value="add">
                    Add
                  </mat-option>
                  <mat-option value="remove">
                    Remove
                  </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input matInput [(ngModel)]="property.propertyName">
          </mat-form-field>
          <mat-form-field>
            <input matInput [(ngModel)]="property.propertyValue" [hidden]="property.operationType == 'remove'">
          </mat-form-field>
          <button id='ag-removeassetbutton' mat-icon-button class="mat-icon-button red" (click)="removeOpRow(property)">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
        <button id='ag-addassetbutton' mat-icon-button class="mat-icon-button green"  (click)="addNewOpRow()">
          <mat-icon>add_box</mat-icon>
        </button>
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <button type="button" [mat-dialog-close]="true">Cancel</button>
      <button mat-flat-button color="primary" type="submit" (click)="onSubmit()">Update</button>
    </mat-card-actions>
  </ng-container>
</mat-dialog-content>


