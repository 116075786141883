<router-outlet></router-outlet>
<div class="my-3" [hidden]="route.children.length != 0">
  <mat-card>
    <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
      Installer Locations
      <div>
        <button id='policy-new-button' [routerLink]="['/accounts/installer-location/add-installer-location']" mat-button color="primary">
          Add New Installer Location
        </button>
      </div>
    </mat-card-title>
    <mat-card-subtitle>
      Manage Installer Locations
    </mat-card-subtitle>
    <mat-divider></mat-divider>
    <mat-card-content class="table-container">
      <table mat-table [dataSource]="dataSource" matSort class="m-t-20 table-padding"
             [hidden]="dataSource?.data?.length < 1 || loadingData">
        <ng-container matColumnDef="customerId">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Customer ID</th>
          <td mat-cell *matCellDef="let installerLocation">{{installerLocation.customerId}}</td>
        </ng-container>
        <ng-container matColumnDef="companyName">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Company Name</th>
          <td mat-cell *matCellDef="let installerLocation">{{installerLocation.companyName}}</td>
        </ng-container>
        <ng-container matColumnDef="companyCode">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Company Code</th>
          <td mat-cell *matCellDef="let installerLocation">{{installerLocation.companyCode}}</td>
        </ng-container>
        <ng-container matColumnDef="houseNo">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>House Number</th>
          <td mat-cell *matCellDef="let installerLocation">{{installerLocation.houseNo}}</td>
        </ng-container>
        <ng-container matColumnDef="street">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Street</th>
          <td mat-cell *matCellDef="let installerLocation">{{installerLocation.street}}</td>
        </ng-container>
        <ng-container matColumnDef="street2">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Street 2</th>
          <td mat-cell *matCellDef="let installerLocation">{{installerLocation.street2}}</td>
        </ng-container>
        <ng-container matColumnDef="city">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>City</th>
          <td mat-cell *matCellDef="let installerLocation">{{installerLocation.city}}</td>
        </ng-container>
        <ng-container matColumnDef="region">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Region</th>
          <td mat-cell *matCellDef="let installerLocation">{{installerLocation.region}}</td>
        </ng-container>
        <ng-container matColumnDef="country">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Country</th>
          <td mat-cell *matCellDef="let installerLocation">{{installerLocation.country}}</td>
        </ng-container>
        <ng-container matColumnDef="postal">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Postal Code</th>
          <td mat-cell *matCellDef="let installerLocation">{{installerLocation.postal}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headers; sticky: true"></tr>
        <tr mat-row class="hover" *matRowDef="let row; columns: headers;" (click)="onRowClick(row)">
        </tr>
      </table>
      <div *ngIf="dataSource?.data?.length < 1">
        <label class="sm-note">{{emptyMessage}}</label>
      </div>
    </mat-card-content>
  </mat-card>
</div>
