import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';
import * as moment from 'moment';
import { Moment, unitOfTime } from 'moment';
import { AccountsService } from 'src/app/services/accounts.service';
import { LocationResponse, LocationsService } from 'src/app/services/geo-v2.service';
import { ActiveTripViewModel, ActiveTripViewModelTrip, TripModel, TripsService, TripSummaryModel } from '../../../services/shipments-v2.service';
import { DefaultMapOptions } from '../../constants';

export type NameCount = {
  index: number,
  name: string,
  count: number
}

type NamedTripPredicate = { name: string, predicate: (trip: ActiveTripViewModelTrip) => boolean };

type TripMarker = google.maps.Marker & {
  trip: ActiveTripViewModelTrip
}

const now = moment();
const namedTripAges: NamedTripPredicate[] = [
  { name: '< 1 Day', predicate: t => now.diff(moment(t.startDateTime), 'day', true) < 1 },
  { name: '< 1 Week', predicate: t => now.diff(moment(t.startDateTime), 'week', true) <= 1 },
  { name: '> 1 Week', predicate: t => now.diff(moment(t.startDateTime), 'week', true) >= 1 },
  { name: '> 1 Month', predicate: t => now.diff(moment(t.startDateTime), 'month', true) >= 1 },
  { name: '> 3 Months', predicate: t => now.diff(moment(t.startDateTime), 'month', true) >= 3 },
  { name: '> 6 Months', predicate: t => now.diff(moment(t.startDateTime), 'month', true) >= 6 },
  { name: '> 1 Year', predicate: t => now.diff(moment(t.startDateTime), 'year', true) >= 1 },
]

const namedTripDistances: NamedTripPredicate[] = [
  { name: 'None', predicate: t => t.crowFliesDistance == 0 },
  { name: '> 0kms', predicate: t => t.crowFliesDistance > 0 },
  { name: '> 50kms', predicate: t => t.crowFliesDistance >= 50 },
  { name: '> 100kms', predicate: t => t.crowFliesDistance >= 100 },
  { name: '> 500kms', predicate: t => t.crowFliesDistance >= 500 },
  { name: '> 1000kms', predicate: t => t.crowFliesDistance >= 1000 },
  { name: '> 2000kms', predicate: t => t.crowFliesDistance >= 2000 },
  { name: '> 5000kms', predicate: t => t.crowFliesDistance >= 2000 }
]

const namedTripLastUpdatedPeriods: NamedTripPredicate[] = [
  { name: '< 4 Hours Ago', predicate: t => now.diff(moment(t.lastUpdated), 'hours', true) <= 4 },
  { name: '< 1 Day Ago', predicate: t => now.diff(moment(t.lastUpdated), 'day', true) <= 1 },
  { name: '< 1 Week Ago', predicate: t => now.diff(moment(t.lastUpdated), 'week', true) <= 1 },
  { name: '> 1 Week Ago', predicate: t => now.diff(moment(t.lastUpdated), 'week', true) >= 1 },
  { name: '> 1 Month Ago', predicate: t => now.diff(moment(t.lastUpdated), 'month', true) >= 1 },
  { name: '> 3 Months Ago', predicate: t => now.diff(moment(t.lastUpdated), 'month', true) >= 3 },

]

@Component({
  selector: 'trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss']
})
export class TripsComponent implements OnInit {
  @Input() tripsViewModel: ActiveTripViewModel;
  @ViewChild(GoogleMap) map: GoogleMap;
  @ViewChild("infoWindowContents") infoWindowContents: Node;
  dataTime: string;
  infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();
  tripMarkers: TripMarker[] = [];
  visibleTrips: number;
  eventTypes: Array<NameCount> = [];
  tripTypes: Array<NameCount> = [];
  tripAges: Array<NameCount & NamedTripPredicate> = [];
  tripDistances: Array<NameCount & NamedTripPredicate> = [];
  tripUpdatePeriods: Array<NameCount & NamedTripPredicate> = [];
  eventTypeFilter = new FormControl([]);
  tripTypeFilter = new FormControl([]);
  tripAgeFilter = new FormControl([]);
  tripDistanceFilter = new FormControl([]);
  tripUpdatedFilter = new FormControl([]);
  lois: LocationResponse[] | null = null;
  loiPolygons: google.maps.Polygon[] | null = null;
  tripPathFeature: google.maps.Data.Feature | null = null;
  filtering = false;
  mapOptions: google.maps.MapOptions = DefaultMapOptions;

  constructor(
    private tripService: TripsService,
    private loiService: LocationsService,
    private accountService: AccountsService
  ) {
  }

  ngOnInit(): void {
    const self = this;

    this.dataTime = moment(this.tripsViewModel.time).fromNow();

    this.eventTypeFilter.setValue(this.tripsViewModel.eventTypes.map((_, i) => i));
    this.tripTypeFilter.setValue(this.tripsViewModel.tripTypes.map((_, i) => i));
    this.tripAgeFilter.setValue(namedTripAges.map((_, i) => i));
    this.tripDistanceFilter.setValue(namedTripDistances.map((_, i) => i));
    this.tripUpdatedFilter.setValue(namedTripLastUpdatedPeriods.map((_, i) => i));

    const eventTypes: Array<NameCount> = this.tripsViewModel.eventTypes.map((x, i) => ({ index: i, name: x, count: 0 }));
    const tripTypes: Array<NameCount> = this.tripsViewModel.tripTypes.map((x, i) => ({ index: i, name: x, count: 0 }));
    const tripAges: Array<NameCount & NamedTripPredicate> = namedTripAges.map((x, i) => ({ index: i, count: 0, ...x }));
    const tripDistances: Array<NameCount & NamedTripPredicate> = namedTripDistances.map((x, i) => ({ index: i, count: 0, ...x }));
    const tripUpdatePeriods: Array<NameCount & NamedTripPredicate> = namedTripLastUpdatedPeriods.map((x, i) => ({ index: i, count: 0, ...x }));

    for (const trip of this.tripsViewModel.activeTrips)
    {
      var tripMarker = new google.maps.Marker({
        icon: 'assets/img/train.png',
        position: { lat: trip.coordinates[0], lng: trip.coordinates[1] },
      }) as TripMarker;
      tripMarker.trip = trip;
      tripMarker.addListener('click', function markerClick($event) {
        const marker = this;

        self.onMarkerClick(marker);
      });

      this.tripMarkers.push(tripMarker);
    }

    this.eventTypes = eventTypes;
    this.tripTypes = tripTypes;
    this.tripAges = tripAges;
    this.tripDistances = tripDistances;
    this.tripUpdatePeriods = tripUpdatePeriods;

    this.filterAndRenderTrips();

    // not sure how else to wait for the map to be available
    var intervalToken = setInterval(() => {
      if (this.map) {
        this.onMapAndDataReady();
        clearInterval(intervalToken);
      }
    }, 500);
  }

  onMapAndDataReady() {
    for (const marker of this.tripMarkers)
    {
      marker.setMap(this.map.googleMap);
    }
  }

  onMarkerClick(marker: TripMarker) {
    const trip = marker.trip;

    this.clearPreviousTripPath();

    this.tripService.getTrip(trip.id)
      .toPromise()
      .then(async trip => {
        const account = await this.accountService.account(trip.accountId)
          .toPromise();

        return { account, trip };
      })
      .then(({account, trip}) => {
        this.addTripDetailsToMap(trip);

        const contentRows: {name: string, value: string }[] = [];

        contentRows.push({ name: 'Account', value: account.accountName });
        contentRows.push({ name: 'Started', value: trip.startDateTime.toISOString() });
        contentRows.push({ name: 'Origin', value: trip.origin.description ?? '' });
        contentRows.push({ name: 'Destination', value: trip.destination?.description ?? '' });
        contentRows.push({ name: 'Type', value: trip.type ?? '{unknown}' });
        contentRows.push({ name: 'Last Updated', value: trip.lastKnownLocation.dateTime.toISOString() });
        if (marker.trip.crowFliesDistance) {
          contentRows.push({ name: 'Crow Flies Distance', value: marker.trip.crowFliesDistance?.toString() + 'km' });
        }

        this.infoWindow.setContent(`
          <div>
            <div><b>${trip.assetName}</b></div>
            <table>
            ${contentRows.map(x => `<tr><td>${x.name}</td><td>${x.value}</td></tr>`).join('')}
            </table>
            <div><a href="/shipments-v2/trips/${trip.id}" target="_blank">Trip Details...</a></div>
            <div><a href="/shipments-v2/assets/${trip.assetId}" target="_blank">Asset History...</a></div>
          </div>
        `);
        this.infoWindow.open(this.map.googleMap, marker);
      });
  }

  addTripDetailsToMap(trip: TripModel) {
    const tripFeature = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: trip.events
          .filter(x => x.latitude && x.longitude)
          .map(x => [ x.longitude, x.latitude])
      }
    }

    const features = this.map.data.addGeoJson(tripFeature);
    this.tripPathFeature = features[0];
  }

  clearPreviousTripPath() {
    if (this.tripPathFeature != null) {
      this.map.data.remove(this.tripPathFeature);
      this.tripPathFeature = null;
    }
  }

  async filterAndRenderTrips()
  {
    this.filtering = true;

    setTimeout(() => {
      let visibleTrips = 0;
      for (const marker of this.tripMarkers) {
        const trip = marker.trip;
        let visible = true;

        if (!this.tripTypeFilter.value.includes(trip.tripType)) visible = false;
        if (!(this.eventTypeFilter.value.filter(x => trip.eventTypes.includes(x)).length > 0)) visible = false;
        if (!(this.tripAgeFilter.value.some(x => this.tripAges.find(y => y.index === x).predicate(trip)))) visible = false;
        if (!(this.tripDistanceFilter.value.some(x => this.tripDistances.find(y => y.index === x).predicate(trip)))) visible = false;
        if (!(this.tripUpdatedFilter.value.some(x => this.tripUpdatePeriods.find(y => y.index === x).predicate(trip)))) visible = false;

        marker.setVisible(visible);
        if (visible) visibleTrips++;
      }
      this.visibleTrips = visibleTrips;
      this.updateCounts(this.tripMarkers.filter(x => x.getVisible()).map(x => x.trip));
      this.filtering = false;
    });
  }

  async updateCounts(trips: ActiveTripViewModelTrip[]) {
    const namedTripPredicates = this.tripAges
      .concat(this.tripDistances)
      .concat(this.tripUpdatePeriods);

    const allFilters = this.eventTypes
      .concat(this.tripTypes)
      .concat(namedTripPredicates);

    for (const filter of allFilters) filter.count = 0;

    for (const trip of trips)
    {
      this.tripTypes[trip.tripType].count++;;

      for (const eventTypeIndex of trip.eventTypes)
      {
        this.eventTypes[eventTypeIndex].count++;
      }

      for (const tripPredicate of namedTripPredicates) {
        if (tripPredicate.predicate(trip)) {
          tripPredicate.count++;
        }
      }
    }
  }

  async showLOIs(show: boolean) {
    if (!this.lois) {
      this.lois = await this.loiService.getLois().toPromise();
      this.loiPolygons = [];
      for (const loi of this.lois) {
        const locationPolygon = new google.maps.Polygon({
          paths: loi.coordinates.map(function(x) {
            return {
              "lat": x.latitude,
              "lng": x.longitude
            }
          }),
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });

        google.maps.event.addListener(locationPolygon, 'click', event => this.onLocationClick(event, loi, locationPolygon));

        this.loiPolygons.push(locationPolygon);
      }
    }

    console.log('showLOIs', show);

    let map = this.map.googleMap;
    if (!show) map = null;

    for (const loiPolygon of this.loiPolygons) {
      loiPolygon.setMap(map);
    }
  }

  onLocationClick(event, location: LocationResponse, polygon: google.maps.Polygon) {
    const content = "Hi";

    this.infoWindow.setContent(`<pre>${htmlEncode(JSON.stringify(location, null, 2))}</pre>`);
    this.infoWindow.setPosition(new google.maps.LatLng(location.latitude, location.longitude));
    this.infoWindow.open(this.map.googleMap);
  }
}

function htmlEncode(input: string): string {
  const textArea = document.createElement("textarea");
  textArea.innerText = input;
  return textArea.innerHTML.split("<br>").join("\n");
}
