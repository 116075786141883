import { Component, OnInit, AfterViewInit, Renderer2, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UsersService, NewUser } from '../../services/users.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { PagesAndFeaturesUtils } from 'src/app/shared/pages-and-features/pages-and-features.component';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { MatSelectSearchComponent } from 'ngx-mat-select-search';

@Component({
  selector: 'ads-user-new',
  templateUrl: './user-new.component.html',
  styleUrls: ['./user-new.component.scss']
})

export class UserNewComponent implements OnInit, AfterViewInit {

  isBulkCreate: boolean;
  newUsers: Array<any> = [];
  newUserForm: FormGroup;
  accountControl = new FormControl();
  featuresControl = new FormControl();
  pagesControl = new FormControl();
  allowSendWelcomeEmail = false;
  sendWelcomeEmail = false;
  bypassMfa = false;

  constructor(private fb: FormBuilder,
    private userService: UsersService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private toasterService: ToasterNotificationService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService
  ) {

  }

  ngOnInit() {
    this.newUserForm = this.fb.group({
      accountControl: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      featuresControl: [''],
      pagesControl: [''],
      mobilePhone: [''],
      dateDisplayFormat: [''],
      dateTimeDisplayFormat: [''],
      lengthUnitOfMeasure: [''],
      tankVolumeUnitOfMeasure: [''],
      loadingDimensionsUnitOfMeasure: [''],
      weightUnitOfMeasure: [''],
      jobTitle: ['']
    });

    this.activatedRouter.queryParams.subscribe(params => {
      if (params.accountId) {
        this.newUserForm.controls.accountControl.setValue(parseInt(params.accountId, 10));
      }
      if (params.pages) {
        this.pagesControl.setValue(PagesAndFeaturesUtils.CsvToArray(params.pages));
      }
      if (params.features) {
        this.featuresControl.setValue(PagesAndFeaturesUtils.CsvToArray(params.features));
      }
      if(params.dateDisplayFormat) {
        this.newUserForm.get('dateDisplayFormat').setValue(params.dateDisplayFormat);
      }
      if(params.dateTimeDisplayFormat) {
        this.newUserForm.get('dateTimeDisplayFormat').setValue(params.dateTimeDisplayFormat);
      }
      if(params.lengthUnitOfMeasure) {
        this.newUserForm.get('lengthUnitOfMeasure').setValue(params.lengthUnitOfMeasure);
      }
      if(params.tankVolumeUnitOfMeasure) {
        this.newUserForm.get('tankVolumeUnitOfMeasure').setValue(params.tankVolumeUnitOfMeasure);
      }
      if(params.loadingDimensionsUnitOfMeasure) {
        this.newUserForm.get('loadingDimensionsUnitOfMeasure').setValue(params.loadingDimensionsUnitOfMeasure);
      }
      if(params.weightUnitOfMeasure) {
        this.newUserForm.get('weightUnitOfMeasure').setValue(params.weightUnitOfMeasure);
      }
    });

    // dateDisplayFormat: [''],
    // dateTimeDisplayFormat: [''],
    // lengthUnitOfMeasure: [''],
    // tankVolumeUnitOfMeasure: [''],
    // loadingDimensionsUnitOfMeasure: [''],
    // weightUnitOfMeasure: [''],

    this.featureFlagService.welcomeEmailSliderEnabled.then(treatment => {
      this.allowSendWelcomeEmail =
        this.sendWelcomeEmail = treatment === 'on';
    });
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#usr-new-firstname');

    element.focus();

    this.cdr.detectChanges();
  }

  onSubmit() {
    this.newUserForm.markAllAsTouched();

    if (this.newUserForm.invalid) {
      return;
    }

    if (this.isBulkCreate) {
      let usersToSave: NewUser[];

      usersToSave = this.newUsers.map(user => ({
        ...user,
        accountId: this.newUserForm.controls.accountControl.value,
        features: PagesAndFeaturesUtils.ArrayToCsv(this.featuresControl.value),
        pages: PagesAndFeaturesUtils.ArrayToCsv(this.pagesControl.value),
        sendWelcomeEmail: this.allowSendWelcomeEmail ? this.sendWelcomeEmail : false,
        bypassMfa: this.bypassMfa
      })
      );

      this.userService.createUsers(this.newUserForm.controls.accountControl.value, usersToSave)
        .subscribe({
          next: (resp: any) => {
            this.router.navigate([`/accounts/view/${this.newUserForm.controls.accountControl.value}/account-users`]);
          },
          error: (err) => {
            this.toasterService.showWarnToaster(err.detail);
          }
        });
    } else {

      const userData = {
        ...this.newUserForm.value,
        accountId: this.newUserForm.controls.accountControl.value,
        features: PagesAndFeaturesUtils.ArrayToCsv(this.featuresControl.value),
        pages: PagesAndFeaturesUtils.ArrayToCsv(this.pagesControl.value),
        sendWelcomeEmail: this.allowSendWelcomeEmail ? this.sendWelcomeEmail : false,
        bypassMfa: this.bypassMfa
      };

      const userToSave = new NewUser();
      userToSave.init(userData);

      this.userService.createUser(userData.accountId, userToSave)
        .subscribe({
          next: (resp: any) => {
            this.router.navigate([`/accounts/${resp.accountId}/users/${resp.userId}`]);
          },
          error: (err) => {
            this.toasterService.showWarnToaster(err.detail);
          }
        });
    }
  }

  toggleBulkCreate(e: Event) {
    this.isBulkCreate = !this.isBulkCreate;

    if (this.isBulkCreate) {
      this.newUserForm.get('firstName').disable();
      this.newUserForm.get('lastName').disable();
      this.newUserForm.get('email').disable();
      this.newUserForm.get('mobilePhone').disable();
      this.newUserForm.get('dateDisplayFormat').disable();      
      this.newUserForm.get('dateTimeDisplayFormat').disable();
      this.newUserForm.get('lengthUnitOfMeasure').disable();
      this.newUserForm.get('tankVolumeUnitOfMeasure').disable();
      this.newUserForm.get('loadingDimensionsUnitOfMeasure').disable();
      this.newUserForm.get('weightUnitOfMeasure').disable();
      this.newUserForm.get('jobTitle').disable();
    } else {
      this.newUserForm.get('firstName').enable();
      this.newUserForm.get('lastName').enable();
      this.newUserForm.get('email').enable();
      this.newUserForm.get('mobilePhone').enable();
      this.newUserForm.get('dateDisplayFormat').enable();      
      this.newUserForm.get('dateTimeDisplayFormat').enable();
      this.newUserForm.get('lengthUnitOfMeasure').enable();
      this.newUserForm.get('tankVolumeUnitOfMeasure').enable();
      this.newUserForm.get('loadingDimensionsUnitOfMeasure').enable();
      this.newUserForm.get('weightUnitOfMeasure').enable();
      this.newUserForm.get('jobTitle').enable();
    }

    this.newUserForm.markAsUntouched();
  }
}
