import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-load',
  templateUrl: './empty-load.component.html',
  styleUrls: ['./empty-load.component.scss']
})
export class EmptyLoadComponent implements OnInit {
  public tabs: { label: string, path: string }[] = [
    { label: "Asset Configs", path: "asset-configs" },
    { label: "Other", path: "other1" },
    { label: "Other", path: "other2" },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
