import { Component, OnInit, AfterViewInit, ViewChild, Renderer2, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BreadcrumbService } from 'angular-crumbs';
import { AdsAccountDto, AccountsService } from '../../services/accounts.service';
import {
  AssetGroupsService,
  AssetsService,
  AssetGroupDto,
  AssetDto,
  AssetBasicDto,
  AssetGroupAddUserGroupResponse,
  AssetGroupAddUserGroupError,
  AssetGroupRemoveUserGroupResponse,
  AssetDtoAssetPagedListDto,
  Operation
} from '../../services/assets.service';
import { UserGroupsService, UserGroupResponse } from '../../services/users.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ToasterNotificationService } from '../../services/toasterNotification.service';
import { Subject, Observable, forkJoin, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../shared/dialog/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { ValidationService } from '../../services/validation.service';
import { AssetWorkspaceChipsComponent } from 'src/app/shared/asset-workspace-chips/asset-workspace-chips.component';
import { DeviceGroupEditComponent } from '../device-groups/device-group.component';

@Component({
  selector: 'ads-asset-groups',
  templateUrl: './asset-groups.component.html',
  styleUrls: ['./asset-groups.component.scss']
})
export class AssetGroupsComponent implements OnInit, AfterViewInit {

  @ViewChild('assetGroupName') assetGroupName: ElementRef;

  assetGroupForm: FormGroup;
  private accountId: number;
  private assetGroupId: number;
  public newAssetGroup: boolean;
  public account: AdsAccountDto;
  public assetGroup: AssetGroupDto = new AssetGroupDto();
  private search: Subject<string> = new Subject();
  groupAssetsData: MatTableDataSource<{}>;
  filteredAssetGroups: AssetGroupDto[] = [];
  availableAssetsRawData: AssetDto[];
  availableAssetsData: MatTableDataSource<{}>;
  availableAssetsSearchTerm = '';
  availableAssetsEmptyData = true;
  availableAssetsBaseMessage = 'To see asset information, please begin typing a asset number';
  availableAssetsEmptyMessage: string = this.availableAssetsBaseMessage;
  assetGroupFormNameControl = 'assetGroupName';
  allowedUserGroups: UserGroupResponse[] = [];
  allowedUserGroupsData: MatTableDataSource<{}>;
  allowedUserGroupsAutoCompleteInput = new FormControl();
  allUserGroups: UserGroupResponse[] = [];
  availableUserGroups: UserGroupResponse[] = [];
  filteredAvailableUserGroups: UserGroupResponse[];

  loaded = false;

  availableAssetsColumnsToDisplay: string[] = ['assetName', 'unitNumber', 'unitName', 'action'];
  addedAssetColumnsToDisplay: string[] = ['assetName', 'unitNumber', 'action'];
  allowedUserGroupsColumnsToDisplay: string[] = ['userGroupName', 'userGroupUsers', 'action'];

  @ViewChild('chips') chips: AssetWorkspaceChipsComponent;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private toasterService: ToasterNotificationService,
    private assetGroupService: AssetGroupsService,
    private assetService: AssetsService,
    private userGroupService: UserGroupsService,
    private accountService: AccountsService,
    private formBuilder: FormBuilder,
    private location: Location,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog) {

    this.accountId = Number.parseInt(this.route.parent.snapshot.params.id, 10);

    this.newAssetGroup = this.route.snapshot.params.assetGroupId === undefined ? true : false;

    this.assetGroupId = this.newAssetGroup ? undefined : Number.parseInt(this.route.snapshot.params.assetGroupId, 10);

    this.assetGroupForm = this.formBuilder.group({
      assetGroupName: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.search.pipe(debounceTime(500)).subscribe(searchTextValue => {
      this.searchAssets(searchTextValue);
    });

    this.accountService.account(this.accountId).subscribe(
      ((accountResponse: AdsAccountDto) => {
        this.account = accountResponse;
        if (this.account === undefined) {
          this.router.navigateByUrl('/accounts');
        }
        this.breadcrumbService.changeBreadcrumb(this.route.snapshot, this.account.accountName);
      }),
      error => {
        this.router.navigateByUrl('/accounts');
      }
    );

    if (this.assetGroupId !== undefined) {
      const assetGroupRequest = this.assetGroupService.assetGroup(this.accountId, 'all', this.assetGroupId);
      assetGroupRequest.subscribe(
        ((assetGroupResponse: AssetGroupDto) => {
          this.assetGroup = assetGroupResponse;
          this.filteredAssetGroups = this.assetGroup.assets;
          this.groupAssetsData = new MatTableDataSource(this.assetGroup.assets);
          if (this.assetGroup !== undefined) {
            this.assetGroupForm.controls[this.assetGroupFormNameControl].setValue(this.assetGroup.name);
          }

          this.loaded = true;
        }),
        error => {
          this.location.back();
        });

        const userGroupRequest = this.userGroupService.userGroups(this.accountId);

        forkJoin([assetGroupRequest, userGroupRequest]).subscribe(([assetGroupResponse, userGroupResponse]) => {
          this.allUserGroups = userGroupResponse;

          userGroupResponse.forEach((userGroup: UserGroupResponse) => {
            if (assetGroupResponse.allowedUserGroups.indexOf(userGroup.userGroupId) < 0) {
              this.availableUserGroups.push(userGroup);
            }
          });

          this.availableUserGroups.sort((a, b) =>  b.createdOn.valueOf() - a.createdOn.valueOf());

          this.filteredAvailableUserGroups = this.availableUserGroups.slice(0, 5);

          if (assetGroupResponse.allowedUserGroups.length > 0) {
            assetGroupResponse.allowedUserGroups.forEach((userGroup: number) => {
              const matchingUserGroup = this.findUserGroupById(userGroup);

              if (matchingUserGroup !== undefined) {
                this.allowedUserGroups.push(matchingUserGroup);
              }
            });

            this.allowedUserGroupsData = new MatTableDataSource(this.allowedUserGroups);
          }
        });


    } else {
      this.assetGroup.assets = [];
      this.groupAssetsData = new MatTableDataSource();
    }
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#ag-assetgroupname');
    element.focus();
    this.cdr.detectChanges();
  }

  searchTriggered(filterValue: any) {
    this.availableAssetsSearchTerm = filterValue;
    this.search.next(filterValue);
  }

  assetGroupSearch(filterValue: any) {
    this.filteredAssetGroups = this.assetGroup.assets.filter(g => g.assetName.toLowerCase().includes(filterValue.toLowerCase())).slice(0);
    this.groupAssetsData = new MatTableDataSource(this.filteredAssetGroups);
  }

  filterAvailableUserGroups(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredAvailableUserGroups = this.availableUserGroups
      .filter(g => g.groupName.toLowerCase().includes(filterValue) && this.allowedUserGroups.indexOf(g) < 0).slice(0, 5);
  }

  addAllowedUserGroupClient(userGroup: UserGroupResponse) {
    this.allowedUserGroups.push(userGroup);
    this.allowedUserGroupsData = new MatTableDataSource(this.allowedUserGroups);

    this.filterAvailableUserGroups('');
  }

  removeAllowedUserGroupClient(userGroup: UserGroupResponse) {
    if (this.availableUserGroups.findIndex(g => g.userGroupId === userGroup.userGroupId) < 0) {
      this.availableUserGroups.push(userGroup);
    }

    this.allowedUserGroups = this.allowedUserGroups.filter(g => g.userGroupId !== userGroup.userGroupId);
    this.allowedUserGroupsData = new MatTableDataSource(this.allowedUserGroups);

    this.filterAvailableUserGroups('');
  }

  addAllowedUserGroup(allowedUserGroup: UserGroupResponse) {
    this.addAllowedUserGroupClient(allowedUserGroup);

    // Remove focus from element after selection so that the list can be updated
    document.getElementById('ag-allowedUserGroupSearch').blur();

    this.assetGroupService.postAllowedUserGroups(this.accountId, 'all', this.assetGroupId, [allowedUserGroup.userGroupId])
      .subscribe((response: AssetGroupAddUserGroupResponse) => {
      if (response.errors.length > 0) {
        this.removeAllowedUserGroupClient(allowedUserGroup);

        // Only posting one at a time, so grab the first (and only) error and show it
        this.toasterService.showWarnToaster(response.errors[0].error);
      } else {
        this.toasterService.showInfoToaster('Asset group updated!');
      }
    });
  }

  removeAllowedUserGroup(allowedUserGroup: UserGroupResponse) {
    this.removeAllowedUserGroupClient(allowedUserGroup);

    this.assetGroupService.deleteAllowedUserGroups(this.accountId, 'all', this.assetGroupId, [allowedUserGroup.userGroupId])
    .subscribe((response: AssetGroupRemoveUserGroupResponse) => {
      if (response.errors.length > 0) {
        this.addAllowedUserGroupClient(allowedUserGroup);

        // Only deleting one at a time, so grab the first (and only) error and show it
        this.toasterService.showWarnToaster(response.errors[0].error);
      } else {
        this.toasterService.showInfoToaster('Asset group updated!');
      }
    });
  }

  findUserGroupById(userGroupId: number) {
    return this.allUserGroups.find(g => g.userGroupId === userGroupId);
  }

  searchAssets(filterValue: any) {
    if (filterValue !== '') {
      this.assetService.assets(this.accountId, 'all', filterValue, true).subscribe((resp: AssetDtoAssetPagedListDto) => {
        this.availableAssetsRawData = resp.items;

        this.refreshAvailableAssetsData();

        this.availableAssetsEmptyMessage = 'No results match search term ' + filterValue;
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
    } else {
      this.availableAssetsEmptyData = true;
      this.availableAssetsEmptyMessage = this.availableAssetsBaseMessage;
    }
  }

  onSubmit() {
    this.assetGroupForm.markAllAsTouched();

    if (this.assetGroupForm.invalid) {
      this.assetGroupName.nativeElement.focus();
      return;
    }

    if (this.newAssetGroup) {
      this.assetGroup.name = this.assetGroupForm.value.assetGroupName;
      this.assetGroup.customerId = this.accountId;

      this.assetGroupService.createAssetGroup(this.accountId, 'all', this.assetGroup).subscribe(g => {
        this.toasterService.showInfoToaster('Asset group created!');
        this.router.navigate([`${g.assetGroupId}`], { relativeTo: this.route });
      },
      (error) => {
        this.toasterService.showWarnToaster(error.detail);
      });
    } else {
      this.assetGroup.name = this.assetGroupForm.value.assetGroupName;

      this.assetGroup.workspaces = this.chips.workspaces;



      this.assetGroupService.updateAssetGroup(this.accountId, 'all', this.assetGroup.toJSON()).subscribe(u => {
        this.assetGroupService.updateAssetGroupWorkspaces(this.accountId, this.assetGroupId, 'all', this.chips.workspaces).subscribe(() => {
          this.toasterService.showInfoToaster('Asset group updated!');
        }, error => {
          this.toasterService.showWarnToaster("There was an error updating the asset group.");
        });
      });
    }
  }

  refreshAvailableAssetsData() {
    let filteredData = this.availableAssetsRawData;

    if (this.assetGroup.assets !== undefined) {
      for (const groupDevice of this.assetGroup.assets) {
        if (filteredData !== undefined) {
          filteredData = filteredData.filter(item => item.assetId !== groupDevice.assetId);
        }
      }
    }
    this.availableAssetsData = new MatTableDataSource(filteredData);

    this.availableAssetsEmptyData = this.availableAssetsData.filteredData.length === 0;
  }

  removeAsset(asset) {
    this.assetGroup.assets = this.assetGroup.assets.filter(item => item.assetId !== asset.assetId);
    this.groupAssetsData = new MatTableDataSource(this.assetGroup.assets);
    this.refreshAvailableAssetsData();
  }

  addAsset(asset) {
    const newAsset = new AssetBasicDto();
    newAsset.assetId = asset.assetId;
    newAsset.assetName = asset.assetName;
    newAsset.unitNumber = asset.unitNumber;
    newAsset.unitName = asset.unitName;

    // Add to right column
    this.assetGroup.assets.push(newAsset);
    this.groupAssetsData = new MatTableDataSource(this.assetGroup.assets);

    // Refresh left column
    this.refreshAvailableAssetsData();
  }

  onDeleteAssetGroupClick() {
    if (this.assetGroup.assets.length > 0) {
      this.dialog.open(AlertDialogComponent, {
        data: {
          message: 'Please remove all assets before deleting asset group.'
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Are you sure you want to delete this asset group?',
          message: 'This action is irreversible.',
          buttonText: {
            ok: 'Delete',
            cancel: 'No'
          }
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.assetGroupService.deleteAssetGroup(this.accountId, 'all', this.assetGroup.assetGroupId).subscribe(u => {
              this.toasterService.showInfoToaster('Asset group deleted!');
              this.navigateToAccount();
          });
        }
      });
    }
  }

  navigateToAccount() {
    this.router.navigateByUrl(`/accounts/view/${this.accountId}/account-details`);
  }

  canDeactivate(): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent>;
    let config = {
      data: {
        message: 'You may have unsaved changes. Are you sure you want to navigate away?'
      }
    }

    if(this.assetGroupForm.dirty) {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, config);

      return dialogRef.afterClosed().pipe(map(result => result === true));
    } else {
      return of(true);
    }
  }
}

export class AssetsDeactivateGuard implements CanDeactivate<AssetGroupsComponent> {
  canDeactivate(component: AssetGroupsComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return component.canDeactivate();
  }
  
}