import { Component, OnInit, ViewChild } from '@angular/core';
import { AuditsService } from '../services/audits.service';
import { MatSelectChange } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { AuditTableComponent } from './audit-table/audit-table.component';
import { Observable, ReplaySubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../services/users.service';
import { AccountsService, AdsAccountDto } from '../services/accounts.service';
import { DevicesService } from '../services/devices.service';
import { AssetsService } from '../services/assets.service';
import { AssetsV3Service } from '../services/assets-v3.service';
import { ToasterNotificationService } from '../services/toasterNotification.service';
import { AccountSelectSearchComponent } from '../shared/account-select-search/account-select-search.component';

@Component({
  selector: 'ads-auditing',
  templateUrl: './auditing.component.html',
  styleUrls: ['./auditing.component.scss']
})
export class AuditingComponent implements OnInit {
  @ViewChild(AuditTableComponent, { static: true }) auditTable: AuditTableComponent;
  @ViewChild('accountDrop', { static: true }) accountDrop: AccountSelectSearchComponent;

  private sub: any;
  auditTableCtrl = new FormControl();

  availableResourceTypes: Observable<string[]>;
  selectedResourceType: string;
  availableEventTypes: Observable<string[]>;
  selectedEventType: string;
  accountId = 0;

  resourceType: string[];

  constructor(
    private auditService: AuditsService,
    private route: ActivatedRoute,
    private router: Router,
    private UserService: UsersService,
    private AccountService: AccountsService,
    private DeviceService: DevicesService,
    private AssetService: AssetsService,
    private AssetsV3Service: AssetsV3Service,
    private toasterService: ToasterNotificationService) {
      this.accountDrop = new AccountSelectSearchComponent(AccountService, toasterService);
  }

  ngOnInit() {
    this.availableResourceTypes = this.auditService.resources();

    this.selectedResourceType = this.route.snapshot.queryParamMap.get('resourceType');
    if (this.selectedResourceType != null) {
      this.availableResourceTypes.subscribe(() => {
        this.availableEventTypes = this.fetchEventTypes(this.selectedResourceType);
        this.availableEventTypes.subscribe(a => {
          this.selectedEventType = a[0];
          this.auditTable.changeEventType(this.selectedResourceType, this.accountId, this.selectedEventType, null);
        });
      });
    }

    this.accountDrop.registerOnChange(() => this.onAccountChange());
    this.setupAccountDropDown();
  }

  onResourceSelectionChanged(event: MatSelectChange) {
    this.auditTable.auditLogsData = null;
    this.selectedResourceType = event.value;
    this.availableEventTypes = this.fetchEventTypes(this.selectedResourceType);
    this.router.navigate(['/auditing'], { queryParams: { resourceType: this.selectedResourceType } });

    this.availableEventTypes.subscribe(a => {
      this.selectedEventType = a[0];
      this.auditTable.changeEventType(this.selectedResourceType, this.accountId, this.selectedEventType, null);
    });

  }

  onEventTypeSelectionChanged(event: MatSelectChange) {
    this.auditTable.auditLogsData = null;
    this.selectedEventType = event.value;
    this.router.navigate(['/auditing'], { queryParams: { resourceType: this.selectedResourceType } });
    this.auditTable.changeEventType(this.selectedResourceType, this.accountId, this.selectedEventType, null);
  }

  onAccountIdSelect() {
    this.auditTable.auditLogsData = null;
    this.auditTable.changeEventType(this.selectedResourceType, this.accountId, this.selectedEventType, null);
    console.log(this.accountId);
  }

  onAccountChange() {
    if (this.accountId !== null) {
      this.accountId = this.accountDrop.singleSelect.value;
      this.onAccountIdSelect();
    }
  }

  setupAccountDropDown() {
    this.accountDrop.isRequired = false;
    this.accountDrop.allOption = true;
    this.accountDrop.starOption = false;
    this.accountDrop.allowEmptySelection = false;
    this.accountDrop.placeholder = 'Account';
  }

  fetchEventTypes(resourceCategory: string): Observable<string[]> {
    const resourceRuleMatcher = resourceCategory + '.';

    switch (true) {
      case ['ADSAccountsAccount', 'ADSAccountsWorkspace'].includes(resourceCategory):
        return this.AccountService.getEventTypes(resourceRuleMatcher);

      case ['ADSAssetsAsset', 'ADSAssetsAssetGroup', 'ADSAssetsAssetDevice'].includes(resourceCategory):
        return this.AssetService.eventTypes(resourceRuleMatcher);

      case ['ADSDevicesDevice', 'ADSDevicesDeviceGroup', 'ADSDevicesDeviceDiagnostics'].includes(resourceCategory):
        return this.DeviceService.getEventTypes(resourceRuleMatcher);

      case ['ADSUsersInstaller', 'ADSUsersUser', 'ADSUsersUserGroup'].includes(resourceCategory):
        return this.UserService.getEventTypes(resourceRuleMatcher);

      case resourceCategory.startsWith('ADSAssetsv3'):
        return this.AssetsV3Service.eventTypes(resourceRuleMatcher);

      default: {
        this.toasterService.showWarnToaster(resourceCategory + ' does not have any available event types.');
        return null;
      }
    }
  }
}
