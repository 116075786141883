import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService, Operation, AppUserDto, DirectoryAudit, OperationResult } from '../../services/users.service';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'angular-crumbs';
import { FeatureTypes, PageTypes, PagesAndFeaturesUtils } from 'src/app/shared/pages-and-features/pages-and-features.component';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { MsalService } from '@azure/msal-angular';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared/dialog/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'ads-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  public user: any = {
    accountEnabled: false,
    accountName: '',
    accountId: '',
    objectId: '',
    emailAddress: '',
    displayName: '',
    firstName: '',
    lastName: '',
    pages: '',
    features: ''
  };
  featuresValues;
  pagesValues;
  userId: string;
  public resourcesLoaded: boolean;
  sendWelcomeEmailTreatment: boolean;
  resetPasswordTreatment: boolean;
  accountId: number;

  lastLoggedInLoaded = false;
  lastLoggedIn?: Date = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UsersService,
    private breadcrumbService: BreadcrumbService,
    private featureFlagService: FeatureFlagService,
    private toasterService: ToasterNotificationService,
    private dialog: MatDialog
  ) {
    this.userId = this.route.snapshot.params.id;
    this.resourcesLoaded = false;
    this.accountId = this.route.snapshot.params.accountId;
  }

  ngOnInit() {
    this.userService.getUser(this.accountId, this.userId).subscribe((resp: AppUserDto) => {
      this.user = {...resp
      };

      this.featureFlagService.welcomeEmailSliderEnabled.then((treatment) => {
        this.sendWelcomeEmailTreatment = treatment === 'on';
      });

      this.featureFlagService.resetPasswordAllowed.then((treatment) => {
        this.resetPasswordTreatment = treatment === 'on';
      })

      this.pagesValues = this.user.pages;
      this.featuresValues = this.user.features;

      this.user.pages = PagesAndFeaturesUtils.CsvToEnumValueArray(this.user.pages, PageTypes).join(', ');
      this.user.features = PagesAndFeaturesUtils.CsvToEnumValueArray(this.user.features, FeatureTypes).join(', ');
      this.resourcesLoaded = true;
      this.breadcrumbService.changeBreadcrumb(
        this.route.snapshot,
        this.user.displayName
      );
    }, err => {
      this.toasterService.showWarnToaster('An unexpected error occurred getting user data');
      console.error(err);
    });
  }

  onEditUserClick() {
    this.router.navigate([`/accounts/${this.accountId}/users/edit/${this.userId}`]);
  }

  resetUserPassword() {
    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure you want to reset this user\'s password?',
        message:
          `This will reset the user\'s password and only show it once.

           Please note this should only be used on demo accounts.`,
        confirmColor: 'warn',
        buttonText: {
          ok: 'Reset',
          cancel: 'Cancel'
        }
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.userService.resetUserPassword(this.accountId, this.userId).subscribe(x => {
          this.dialog.open(ResetPasswordDialogComponent,{
            data: {
              password: x.password
            }
          });
        });
      }
    });
  }

  onResendClick() {
    this.userService.resendWelcomeEmail(this.accountId, this.userId).subscribe((resp: any) => {
      this.toasterService.showInfoToaster('Welcome Email Sent');
    });
  }

  onChangeAccountActiveClick() {
    const userPatch: Operation[] = [
      new Operation({ op: 'replace', path: '/accountEnabled', value: !this.user.accountEnabled })
    ];

    this.userService.updateUser(this.user.accountId, this.userId, userPatch).subscribe((resp: any) => {
      this.user.accountEnabled = !this.user.accountEnabled;
    });
  }
}

@Component({
  selector: 'ads-password-dialog',
  templateUrl: 'password-dialog.html',
})
export class ResetPasswordDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {password: string}) { }
}

