import { ChangeDetectorRef, Component, Input, OnDestroy, ViewChild, ElementRef, OnInit } from '@angular/core';
import { BlobStorageService } from 'src/app/services/blob-storage.service';
import { ProblemDetails } from 'src/app/services/blob-storage.service';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { ConfirmationDialogComponent } from 'src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, of, Subject, Subscription, throwError, timer } from 'rxjs';
import { catchError, retry, switchMap, takeUntil } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';

// https://medium.com/@tarekabdelkhalek/how-to-create-a-drag-and-drop-file-uploading-in-angular-78d9eba0b854
// https://github.com/progtarek/angular-drag-n-drop-directive

@Component({
    selector: 'ads-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    animations: [
        trigger(
            'inOutAnimationDragDrop',
            [
                transition(
                    ':enter',
                    [
                        style({ height: 0, opacity: 0 }),
                        animate('1s 550ms ease-out',
                            style({ height: 200, opacity: 1 }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({ height: 200, opacity: 1 }),
                        animate('1s ease-in',
                            style({ height: 0, opacity: 0 }))
                    ]
                )
            ]
        ),
        trigger(
            'inOutAnimationFile',
            [
                transition(
                    ':enter',
                    [
                        style({ height: 0, opacity: 0 }),
                        animate('500ms ease-out',
                            style({ height: 44, opacity: 1 }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({ height: 44, opacity: 1 }),
                        animate('500ms ease-in',
                            style({ height: 0, opacity: 0 }))
                    ]
                )
            ]
        )
    ]
})
export class FileUploadComponent implements OnDestroy, OnInit {
    // destination storage type: 'blob'
    @Input() destType: string;
    // service that has implemented required functions
    // getSasToken, getStatus, processFiles, checkFileValid
    @Input() service: any;

    @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;

    files: any[] = [];
    newFiles: any[] = [];
    processFilesStartTime: Date;
    isUpdating: boolean;
    hasFinished: boolean;
    fileStatusRequested: boolean;
    requestedFile: any;
    fileRequestSubscription: Subscription;

    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(
        private blobStorageService: BlobStorageService,
        private changeDetectorRef: ChangeDetectorRef,
        private toasterService: ToasterNotificationService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.isUpdating = false;
        this.hasFinished = true;
    }

    ngOnDestroy() {
        for (const file of this.files) {
            if (file !== null && file !== undefined
                && file.stopPolling !== null && file.stopPolling !== undefined) {
                file.stopPolling.next();
            }
        }

        if (this.requestedFile !== null && this.requestedFile !== undefined
            && this.requestedFile.stopPolling !== null && this.requestedFile.stopPolling !== undefined) {
            this.requestedFile.stopPolling.next();
        }
    }

    /**
     * on file drop handler
     */
    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(files) {
        this.prepareFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFileByIndex(index: number) {
        this.files.splice(index, 1);
    }

    /**
     * Delete file from files list
     * @param file (File)
     */
    deleteFile(file: any) {
        const index: number = this.files.indexOf(file);
        this.files.splice(index, 1);
    }

    /**
     * Delete all files from files list
     */
    public deleteAll() {
        this.files.splice(0, this.files.length);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Upload all files in files list
     */
    public uploadAll() {
        // Upload all files to blob storage container
        if (this.destType === 'blob') {
            this.uploadToBlobStorage();
        }
    }

    /**
     * Get blob storage container sas token.
     * Requires input service getSasToken implementation.
     */
    private async getSasToken() {
        const response = await this.service.getSasToken().toPromise()
            .catch(function(error) {
                this.toasterService.showWarnToaster('Requesting access to file storage failed. ' + error.detail);
                throw (error);
            }
            );

        const token: string = response.token;
        return token;
    }

    /**
     * Upload all files in file list to blob storage container.
     * Uses blob container sas token authentication.
     */
    private async uploadToBlobStorage() {
        if (this.service !== null && this.service !== undefined) {
            // Get blob container sas token from input service
            try {
                this.blobStorageService.setSasTokenUrl(await this.getSasToken());
            } catch (error) {
                this.toasterService.showWarnToaster('The file import did not complete. ' + error.message);
                throw (error);
            }

            let fileNames: string[] = [];
            const uploads: any[] = [];

            // upload all files in file list
            for (const file of this.files) {
                file.status = 'Uploading';
                file.statusCount = 0;

                // upload file and add promise to array
                uploads.push(this.blobStorageService.uploadToContainer(file)
                    .then((res) => {
                        if (res.errorCode) {
                            this.toasterService.showWarnToaster(res.errorCode);
                            fileNames = [];
                            return;
                        } else {
                            fileNames.push(file.name);
                        }
                    })
                    .catch((error) => {
                        this.toasterService.showWarnToaster('File upload failed. ' + error);
                        throw (error);
                    }));
            }

            // When all files have uploaded, begin processing
            Promise.all(uploads)
                .then(() => {
                    this.toasterService.showInfoToaster('Files uploaded successfully.');
                    this.processFiles(fileNames);
                }).catch((error) => {
                    this.toasterService.showWarnToaster('The file import did not complete. ' + error);
                    throw (error);
                });
        }
    }

    /**
     * Call input service to process files.
     * @param fileNames (File names)
     */
    processFiles(fileNames: string[]) {
        this.processFilesStartTime = new Date();

        this.service.processFiles(fileNames)
            .pipe(catchError(async err => await this.handleError(err)))
            .subscribe(() => {
                this.isUpdating = true;
                this.hasFinished = false;
                const tasks$ = [];

                // After file processing has started, poll for status for each file every 5 seconds
                for (const file of this.files) {
                    file.stopPolling = new Subject();
                    const t = timer(0, 5000)
                        .pipe(switchMap(() => of(this.getStatus(file))),
                            retry(5),
                            takeUntil(file.stopPolling));
                    tasks$.push(t);
                }

                // When all files have completed, set UI status to finished
                forkJoin(tasks$).subscribe(res => {
                    console.log('done');
                    this.hasFinished = true;
                    this.toasterService.showInfoToaster('Files processed. Please see file results for success or failure.');
                });
            },
                error => {
                    this.toasterService.showWarnToaster('The file import did not complete. ' + error);
                    throw (error);
                });
    }

    /**
     * Poll for status of manually entered file name
     * @param fileName (File name)
     */
    requestFileStatus(fileName) {
        this.fileStatusRequested = true;

        this.requestedFile = {
            name: fileName,
            status: 'Requesting file status',
            statusCount: 0,
            stopPolling: new Subject(),
            requestType: 'manual'
        };

        timer(0, 5000)
            .pipe(switchMap(() => of(this.getStatus(this.requestedFile))),
                retry(5),
                takeUntil(this.requestedFile.stopPolling)).subscribe();
    }

    /**
     * Request file status from input service
     * @param file (File)
     */
    getStatus(file: any) {
        this.service.getStatus(file.name)
            .subscribe((res: any) => {
                const status: IRefreshStatus = res as IRefreshStatus;
                const createdDate: Date = new Date(status.createdDate + 'Z');

                // Wait until the refresh request has started (CreatedDate newer than processFilesStartTime)
                // Ignore start time if manually entered file name request
                // Continue to check update status until status indicates Complete or Failure
                if (file.requestType === 'manual'
                    || (this.processFilesStartTime !== null && this.processFilesStartTime !== undefined
                        && createdDate !== null && createdDate !== undefined
                        && this.processFilesStartTime.toISOString() < createdDate.toISOString())) {
                    file.status = status.status;

                    if (file.status === 'Complete' || file.status.includes('Failure')) {
                        file.stopPolling.next();
                    }
                }
            },
                error => {
                    file.status = 'Failure ' + error.status;
                    this.toasterService.showWarnToaster(error.message);
                    file.stopPolling.next();
                });
    }

    /**
     * After processing completes, will reset UI state when Import Events is selected
     */
    reset() {
        this.isUpdating = false;
        this.deleteAll();
    }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    async prepareFilesList(files: Array<any>) {
        this.newFiles = [];
        for (const item of files) {
            // Check service for valid file
            if (this.service.checkFileValid(item)) {
                item.progress = 0;
                this.files.push(item);
                this.newFiles.push(item);
            }
            else {
                this.toasterService.showWarnToaster(item.name + ' is not valid');
            }
        }
        this.fileDropEl.nativeElement.value = '';

        if (this.destType === 'blob') {
            // Check for existing blob file
            try {
                this.blobStorageService.setSasTokenUrl(await this.getSasToken());

                const blobs = this.blobStorageService.listContainerBlobs();

                for await (const blob of blobs) {
                    this.newFiles.forEach((file, index) => {
                        if (file.name === blob.name) {
                            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                                data: {
                                    title: 'File Exists',
                                    message:
                                        `The file ${file.name} already exists.<br />
                                         Do you want to include and overwrite it or remove it from the list?<br />
                                         Any existing files not in this list will not be included in the import.`,
                                    confirmColor: 'warn',
                                    buttonText: {
                                        ok: 'Include',
                                        cancel: 'Remove'
                                    }
                                }
                            });
                            dialogRef.afterClosed().subscribe(result => {
                                if (!result) {
                                    this.deleteFile(file);
                                }
                            });
                        }
                    });
                }
            } catch (error) {
                this.toasterService.showWarnToaster('A blob error has occurred when checking for existing files. ' + error.message);
                this.deleteAll();
                throw (error);
            }
        }
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    async handleError(error) {
        const err = await this.getErrorMessage(error);
        this.toasterService.showWarnToaster(err);
        throw err;
    }

    async getErrorMessage(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else if (error.response !== undefined && error.response.includes('detail')) {
            // server-side ProblemDetails error
            const resultData = JSON.parse(error.response, this.jsonParseReviver);
            const result = ProblemDetails.fromJS(resultData);
            errorMessage = result.detail;
        } else if (error.error !== undefined && error.error.detail !== undefined) {
            // server-side ProblemDetails error
            errorMessage = error.error.detail;
        }
        else if (error.error instanceof Blob) {
            // client-side ProblemDetails error
            await error.error.text().then(text => {
                const resultData = JSON.parse(text, this.jsonParseReviver);
                const result = ProblemDetails.fromJS(resultData);
                errorMessage = result.detail;
            });
        }
        else if (error.message === undefined) {
            // service error
            errorMessage = error;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        return errorMessage;
    }
}

export interface IRefreshStatus {
    fileName: string;
    status: string;
    createdDate: Date;
}
