import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportShipmentsComponent } from './import-shipments/import-shipments.component';
import { ShipmentsComponent } from './shipments.component';

const routes: Routes = [
  {
    path: '',
    component: ShipmentsComponent,
    data: { breadcrumb: 'Shipments' }
  },
  {
    path: 'import-shipments',
    component: ImportShipmentsComponent,
    data: { breadcrumb: 'Import Shipments' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShipmentsRoutingModule {}
