import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef, FilterChangedEvent, GridApi, GridOptions, GridReadyEvent, IDatasource, IGetRowsParams, RowClickedEvent, SortChangedEvent, ValueFormatterParams } from 'ag-grid-community';
import { AssetResponse, AccountWorkspaceAssetsV3Service, AssetsPage, ASSETS_V3_API_BASE_URL } from '../../services/assets-v3.service';
import Debug from 'debug';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ODataServerSideDataSource } from '../../services/ODataServerSideDataSource';
import { ActivatedRoute, Router } from '@angular/router';

const debug = Debug(`AssetsPaaS:AccountAssetsListComponent`);

@Component({
  selector: 'app-account-assets-list',
  templateUrl: './account-assets-list.component.html',
  styleUrls: ['./account-assets-list.component.scss']
})
export class AccountAssetsListComponent implements OnInit {
  pageSize: number = 100;
  searchSubject: Subject<string> = new Subject();
  searchODataFilter: string = undefined;
  accountName: string;
  accountId: number;

  columnDefs: ColDef[] = [
    { headerName: 'Name', field: 'name', sortable: true },
    { headerName: 'Class', field: 'class', sortable: true },
    { headerName: 'Region', field: 'region', sortable: true },
    { headerName: 'Is Active?', field: 'isADSDataFeedActive' }, // can't sort by this right now. behind the scenes, the assetv3 provider queries a full asset model (not an account asset) so the odata string would be wrong
    { headerName: 'Devices', field: 'devices', valueFormatter: this.formatDevices },
  ];
  gridOptions: GridOptions = {
    rowModelType: 'infinite',
    context: {
      nextPageContinuationToken: null
    },
    datasource: new ODataServerSideDataSource(this.getData.bind(this)),
    onRowClicked: this.onAssetClicked.bind(this),
    onFilterChanged: this.onGridFilterChanged.bind(this),
    onSortChanged: this.onGridSortChanged.bind(this),
  };
  gridApi: GridApi;

  constructor(
    private accountWorkspacesAssetsV3Service: AccountWorkspaceAssetsV3Service,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.accountName = this.route.snapshot.data.accountName;
    this.accountId = this.route.snapshot.params.accountId;
  }

  ngOnInit(): void {
    this.searchSubject
      .pipe(debounceTime(300))
      .subscribe(x => {
        debug('AGGrid:searchSubject %o', x);

        if (x) {
          const normalizedX = x.replace(/[^a-zA-Z0-9]/g, '');
          this.searchODataFilter = `contains(nameNormalized, '${normalizedX}')`;
        } else {
          this.searchODataFilter = undefined;
        }

        this.refreshGrid();
      });
    }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  refreshGrid() {
    this.gridApi.setDatasource(this.gridOptions.datasource);
  }

  onAssetClicked(event: RowClickedEvent) {
    this.router.navigate([this.router.url, `${event.data.id}`]);
  }

  onGridFilterChanged(event: FilterChangedEvent) {
    debug('AGGrid:onGridFilterChanged');
  }

  onGridSortChanged(event: SortChangedEvent) {
    debug('AGGrid:onGridSortChanged');
  }

  getData(odataFilter: string, odataOrderBy: string, continuationToken: string): Promise<any> {
    if (!this.accountId) return Promise.resolve([]);

    return this.accountWorkspacesAssetsV3Service.getAssetsInWorkspace(this.accountId, 'all', this.pageSize, this.searchODataFilter, odataOrderBy, undefined, undefined, continuationToken)
      .toPromise();
  }

  formatDevices(params: ValueFormatterParams) {
    if (!params.value) return;

    const asset: AssetResponse = params.data;
    return asset.devices.length.toString();
  }

  onSearchKeyup(event: KeyboardEvent) {
    debug('onSearchKeyup');
    this.searchSubject.next((event.target as HTMLInputElement).value);
  }
}
