import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetsService, Operation, AssetDto } from '../../services/assets.service';
import { AccountWorkspaceAssetsV3Service, AccountAssetResponse } from '../../services/assets-v3.service';
import { BreadcrumbService } from 'angular-crumbs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { AssetWorkspaceChipsComponent } from 'src/app/shared/asset-workspace-chips/asset-workspace-chips.component';
import { PermissionsDialogComponent } from '../../shared/dialog/permissions-dialog/permissions-dialog.component';

@Component({
  selector: 'ads-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss']
})
export class AssetDetailsComponent implements OnInit {
  public asset: AssetDto = new AssetDto();
  public v3Asset: AccountAssetResponse = new AccountAssetResponse();
  assetId: number;
  accountId: number;
  loaded = false;

  @ViewChild('chips') chips: AssetWorkspaceChipsComponent;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private assetService: AssetsService,
    private accountWorkspaceAssetsV3Service: AccountWorkspaceAssetsV3Service,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    this.assetId = this.route.snapshot.params.id;
    this.assetService.getAsset(this.assetId).subscribe(asset => {
      this.asset = asset;
      this.accountId = asset.ownerAccountId;
      this.breadcrumbService.changeBreadcrumb(this.route.snapshot, this.asset.assetName);
      this.loaded = true;

      this.accountWorkspaceAssetsV3Service.getOrCreateByIonxIdInWorkspace(this.accountId, "all", this.assetId, "ops portal").subscribe(asset => {
        this.v3Asset = asset;
      });
    });
  }

  onEditAsset() {
    this.router.navigate([`/assets/${this.asset.assetId}/edit`]);
  }

  onChangeAssetActiveClick() {
    if (!this.asset.active) {
      this.toggleEnableAsset();
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Are you sure you want to disable this asset?',
          message: 'If it is in a group, it will be removed.',
          buttonText: {
            ok: 'Disable',
            cancel: 'No'
          }
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.toggleEnableAsset();
        }
      });
    }
  }

  onManagePermissionsClick() {
    var resourcePath = `/accounts/${this.asset.ownerAccountId}/workspaces/all/assets/${this.asset.assetId}`;
    const dialogRef = this.dialog.open(PermissionsDialogComponent, {
      data: {
        resourceType: 'Asset',
        resourcePath: resourcePath
      }
    });
  }

  toggleEnableAsset() {
    const patch: Operation[] = [
      new Operation({ op: 'replace', path: '/active', value: !this.asset.active })
    ];

    this.assetService.patchAsset(this.asset.ownerAccountId, 'all', this.assetId, patch).subscribe((resp: any) => {
      this.asset.active = !this.asset.active;
    });
  }
}
