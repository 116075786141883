import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountWorkspaceAssetsV3Service, AssetSharedToAccountDto } from '../../services/assets-v3.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToasterNotificationService } from '../../services/toasterNotification.service';
import { AccountSelectSearchComponent } from 'src/app/shared/account-select-search/account-select-search.component';
import { Router } from '@angular/router';

@Component({
  selector: 'ads-message-dialog',
  templateUrl: 'account-asset-share-dialog.component.html',
  styleUrls: ['./account-asset-share-dialog.component.scss']
})
export class AccountAssetShareDialogComponent implements OnInit {
  assetShare: FormGroup;
  accountId: number;
  assetId: string;
  public shareAccountDto: AssetSharedToAccountDto = new AssetSharedToAccountDto();
  @ViewChild('targetAccountId') targetAccountId: AccountSelectSearchComponent;

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data: any,
    private accountWorkspaceAssetsV3Service: AccountWorkspaceAssetsV3Service,
    private formBuilder: FormBuilder,
    private toasterService: ToasterNotificationService,
    private dialogRef: MatDialogRef<AccountAssetShareDialogComponent>) {
      if (data) {
        this.accountId = data.accountId;
        this.assetId = data.assetId;
      }
  }

  ngOnInit() {
    this.assetShare = this.formBuilder.group({
      targetAccountId: null,
      canReshare: [false]
    });
  }

  onSubmit() {
    this.shareAccountDto.targetAccountId = this.assetShare.value.targetAccountId;
    this.shareAccountDto.canReshare = this.assetShare.value.canReshare;
    this.accountWorkspaceAssetsV3Service.shareAdsDataFeed(this.accountId, "all", this.assetId, "Ops Portal", this.shareAccountDto)
      .subscribe(() => {
        this.dialogRef.close(true);
      },
      error => {
        this.handleErrorResponse(error);
      }
    );
  }

  handleErrorResponse(error) {
    var errorResponse = JSON.parse(error.response);
    var errorResponseToDisplay = "";
    if(errorResponse.errors != null) {
      errorResponseToDisplay = errorResponse.errors;
    } else{
      errorResponseToDisplay = errorResponse == null || errorResponse == undefined ? error.detail : errorResponse;
    }
    this.toasterService.showWarnToaster(JSON.stringify(errorResponseToDisplay));
  }

  cancel(): void {
      this.dialogRef.close(false);
  }
}
