import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { WorkspaceSelectComponent } from 'src/app/shared/workspace-select/workspace-select.component';
import { AdsAccountDto, AccountsService, NewAccountOption } from '../../../../services/accounts.service';
import { AssetsGroupsTableComponent } from './assets-groups-table/assets-groups-table.component';
import { DeviceGroupsTableComponent } from './device-groups-table/device-groups-table.component';

@Component({
  selector: 'ads-account-details-tab',
  templateUrl: './account-details-tab.component.html',
  styleUrls: ['./account-details-tab.component.scss']
})

export class AccountDetailsTabComponent implements OnInit {
  @ViewChild('assetGroupsTable') assetGroupsTable: AssetsGroupsTableComponent;
  @ViewChild('deviceGroupsTable') deviceGroupsTable: DeviceGroupsTableComponent;
  @ViewChild('workspaceDropDown') workspaceDropdown: WorkspaceSelectComponent;

  @Input()
  selectedValue: string;

  GEOMETRIX = "GeoMetrix";
  SCV = "AssetManagement";

  private accountId: number;
  public account: AdsAccountDto;

  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountsService, private toasterService: ToasterNotificationService) {
    this.route.parent.url.subscribe((urlPath) => {
      let accountId = 0;
      if (urlPath.length > 1) {
        accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
      }

      if (isNaN(accountId)) {
        this.router.navigateByUrl('[/accounts]');
      }
      this.accountId = accountId;
    });

    this.router.events.subscribe(event => {
      this.reloadAssetGroupsTable(event);
    });
  }

  onButtonChange(event) {
    if (event.value === this.SCV) this.updateOption(this.accountId, this.SCV);
    else if (event.value === this.GEOMETRIX) this.updateOption(this.accountId, this.GEOMETRIX);
  } 

  ngOnInit() {
    this.accountService.account(this.accountId).subscribe((foundAccount: AdsAccountDto) => {
      if (foundAccount === null) {
        this.router.navigateByUrl('/accounts');
      }
      this.account = foundAccount;
    });
    this.accountService.accountOption(this.accountId).subscribe((option) => {
      if (option.fleetDataSource === this.GEOMETRIX) this.selectedValue = this.GEOMETRIX;
      else if (option.fleetDataSource === this.SCV) this.selectedValue = this.SCV; 
      else if (option.fleetDataSource === null || 
               option.fleetDataSource === undefined ||
               option.fleetDataSource === "") this.updateOption(this.accountId, this.GEOMETRIX);
      else this.updateOption(this.accountId, this.GEOMETRIX);
    }, (error) => {
      if (error.status === 404) this.createOption(this.accountId, this.GEOMETRIX);
      else this.toasterService.showWarnToaster('ERROR: Unable to get Fleets/Assignment source data.');
    });
  }

  reloadAssetGroupsTable(event: any) {
    if(event instanceof NavigationEnd) {
      this.route.queryParams.subscribe(params => {
        this.assetGroupsTable.workspace = params['workspace'];
        this.deviceGroupsTable.workspace = params['workspace'];

        this.deviceGroupsTable.searchDeviceGroups(null);
        this.assetGroupsTable.searchAssetGroups(null);
      });
    }
  }

  createOption(accountId: number, source: string) {
    let body: NewAccountOption = { accountId: accountId, fleetDataSource: source };
    this.accountService.createOption(body).subscribe((option) => {
      this.selectedValue = this.GEOMETRIX;
    }, (error) => {
      if (error.status === 409) this.toasterService.showWarnToaster('ERROR: If account option already exist a conflict will occur.');
      else this.toasterService.showWarnToaster('ERROR: Unable to connect server.');
    });
  }

  updateOption(accountId: number, source: string) {
    let body: NewAccountOption = { accountId: accountId, fleetDataSource: source };
    this.accountService.updateOption(body).subscribe((option) => {
      this.selectedValue = source;
    }, (error) => {
      if (error.status === 404) this.toasterService.showWarnToaster('ERROR: Account option does not exist in the system');      
      else this.toasterService.showWarnToaster('ERROR: Unable to connect server.'); 
    });
  }
}
