<div class="my-3">
  <mat-card>
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <label class="account-tab-top-padding">Search Workspaces</label>
          <div>
            <button id="aws-add-workspaces" mat-button color="primary" (click)="navigateAddWorkspace()">Add Workspaces</button>
          </div>
      </div>
    <div id="leftcolumn">
      <mat-form-field>
        <mat-label></mat-label>
        <input id='workspace-searchtext' name="searchText" matInput
               (keyup)="searchWorkspacesTriggered($event.target.value)"
               maxlength="50" #searchText
               placeholder="To see workspaces, please begin typing the workspace ID or name." />
      </mat-form-field>
    </div>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-content class="table-container">
      <table mat-table matSort [dataSource]="dataSource" class="user-component-table" [hidden]="dataSource?.filteredData?.length < 1">
        <ng-container matColumnDef="id">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Workspace ID</th>
          <td mat-cell *matCellDef="let workspace">
            <a id= "workspace"  [routerLink]="[workspace.id]">
              {{workspace.id}}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="displayName">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Display Name</th>
          <td mat-cell *matCellDef="let workspace">
            <a id= "workspace"  [routerLink]="[workspace.id]">
              {{workspace.displayName}}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="deleted">
          <th mat-header-cell *matHeaderCellDef class="table-header">Delete</th>
          <td mat-cell *matCellDef="let workspace">
            <button mat-button (click)="deleteWorkspace(workspace)">
              <mat-icon class="red-icon">clear</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headers; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: headers;">
        </tr>
      </table>
      <div *ngIf="dataSource?.filteredData?.length < 1">
        <label class="sm-note">{{emptyMessage}}</label>
      </div>
    </mat-card-content>
  </mat-card>
</div>
