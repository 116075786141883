import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditTableComponent } from './audit-table/audit-table.component';
import { SharedModule } from '../shared/shared.module';
import { AuditsService, AUDIT_API_BASE_URL } from '../services/audits.service';
import { environment } from '@env/environment';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';
import { AuditingComponent } from './auditing.component';


@NgModule({
  declarations: [
    AuditTableComponent,
    AuditingComponent
  ],
  exports: [
    AuditTableComponent
  ],
  imports: [
    CommonModule,
    CommonUiSharedModule,
    SharedModule
  ],
  providers: [
    AuditsService, {
      provide: AUDIT_API_BASE_URL, useValue: environment.auditingBaseUrl
    }
  ]
})
export class AuditingModule { }
