import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { AssetsService as EmptyLoadAssetsService, AssetConfig } from '../../services/empty-load.service';
import * as moment from 'moment-timezone';
import { ColDef, GridOptions, ValueFormatterParams,GridReadyEvent, GridApi } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { EmptyLoadEditComponent } from '../empty-load-edit/empty-load-edit.component';



@Component({
  selector: 'app-empty-load-config-list',
  templateUrl: './empty-load-config-list.component.html',
  styleUrls: ['./empty-load-config-list.component.scss']
})
export class EmptyLoadConfigListComponent implements OnInit {
  assetConfigs: AssetConfigExt[] = [];
  columnDefs: ColDef[];
  columnTypes: { [key: string]: ColDef; };
  gripOptions: GridOptions;
  gridApi: GridApi;

  isDisabled = true;
  public rowSelection: 'single' | 'multiple' = 'single';
  selectedAssetId: number = null;
  refreshing: boolean = false;
  constructor(
    private emptyLoadAssetsService: EmptyLoadAssetsService,
    private decimalPipe: DecimalPipe,
    public dialog: MatDialog,) {
    this.gripOptions = {
      pagination: true,
      paginationPageSize: 10,
      defaultColDef: {
        sortable: true,
        floatingFilter: true,
      }
    };
    this.columnTypes = {
      dateColumn: {
        valueFormatter: this.formatDate.bind(this)
      },
      decimalColumn: {
        valueFormatter: this.formatDecimal.bind(this)
      },
      boolColumn: {
        valueFormatter: (x: ValueFormatterParams) => this.formatBoolean(x.value)
      }
    };
    this.columnDefs = [
      { headerName: 'Asset Id', field: 'assetId', filter: 'agTextColumnFilter' },
      { headerName: 'Visible', field: 'visibleAsString', filter: true },
      { headerName: 'WSN415: Min Threshold', field: 'wsn415_MinimumThreasholdOverride', type: ['decimalColumn'] },
      { headerName: 'WSN415: Flip Determination', field: 'wsn415_FlipDeterminationOverride', type: ['boolColumn'] },
      { headerName: 'WSN415: Splitpoint Ratio', field: 'wsn415_SplitpointRatioOverride', type: ['decimalColumn'] },
    ];
  }

  ngOnInit(): void {
    this.emptyLoadAssetsService.getAllAssetConfigs(10000)
      .subscribe(x => this.assetConfigs = x.result.map(x => ({
        ...x,
        visibleAsString: this.formatBoolean(x.visible),
        init: x.init,
        toJSON: x.toJSON
      })));
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    params.columnApi.autoSizeAllColumns();
  }

  onExportClick(): void {
    this.gridApi.exportDataAsExcel({
      fileName: 'Empty Load Asset Configs'
    });

  }

  onEditClick(): void {
    let dialogRef = this.dialog.open(EmptyLoadEditComponent, {
      data: {
        assetId: this.selectedAssetId,
      },
    })
    this.isDisabled = true;
    dialogRef.afterClosed().subscribe(res => {
      this.ngOnInit();
    })

  }

  onSelectionChanged() {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows[0].assetId === "") {
      this.isDisabled = true;
    }
    else {
      this.isDisabled = false;
      this.selectedAssetId = selectedRows[0].assetId;
    }
  }

  formatDate(params: ValueFormatterParams): string {
    return moment(params.value).tz('UTC').format('YYYY-MM-DD H:mm ');
  }

  formatTemperature(params: ValueFormatterParams): string {
    if (params.value) return this.decimalPipe.transform(params.value, '1.1-1') + '° C';

    return '';
  }

  formatBoolean(value: boolean): string {
    if (value == undefined || value == null) return '';

    return value ? 'Yes' : 'No';
  }

  formatDecimal(params: ValueFormatterParams): string {
    if (params.value) return this.decimalPipe.transform(params.value, '1.1-4');

    return '';
  }
}

interface AssetConfigExt extends AssetConfig {
  visibleAsString: string; // need to do this because I can't figure out how to make the built in filter show "Yes" and "No", instead of true and false.
}
