import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer, of, Subscription } from 'rxjs';
import { catchError, expand, map, reduce } from 'rxjs/operators';
import { DeviceSearchDto, DeviceSearchDtoPagedListDto, DevicesService, Status } from '../devices.service';
import { ToasterNotificationService } from '../toasterNotification.service';

@Injectable({
  providedIn: 'root'
})
export class DevicesListResolverService implements Resolve<any>, OnDestroy {
  subscriptions: Subscription = new Subscription();

  constructor(private deviceService: DevicesService, private toasterService: ToasterNotificationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getAllDevices(Number(route.parent.paramMap.get('id')));
  }

  getDevices(accountId: number, skipRecords: number, countRecords: number = 100): Observable<any> {
    return this.deviceService
      .devices(skipRecords, 100, undefined, Status.All, accountId, '', null, false, undefined, null, false, true, 'all')
      .pipe(
        map((resp: DeviceSearchDtoPagedListDto) => {
          return {
            next: resp.total - skipRecords <= countRecords ? false : true,
            items: resp.items,
            currentSkip: skipRecords
          };
        })
      );
  }

  getAllDevices(accountId: number, countRecords: number = 100): Observable<DeviceSearchDto[]> {
    return new Observable((observer: Observer<any>) => {
      this.subscriptions.add(
        this.getDevices(accountId, 0)
          .pipe(
            expand((data) => {
              return data.next ? this.getDevices(accountId, data.currentSkip + countRecords) : of();
            }),
            reduce((acc, data) => {
              return acc.concat(data.items);
            }, []),
            catchError((error) => of(error))
          )
          .subscribe(
            (devices) => {
              observer.next(devices);
              observer.complete();
            },
            (error) => {
              this.toasterService.showWarnToaster(error.detail);
            }
          )
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
