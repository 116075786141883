<button id="jd-close=button" mat-icon-button class="raw-message-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title>Install Device</h1>
<mat-dialog-content class="asset-install-device-dialog">
	<form (ngSubmit)="onSubmit()">
    <ng-container [formGroup]="assetInstallDevice">
      <mat-card-content>
        <ads-device-select-search label='Device' placeholder="Choose Device to Install"
                                  ngDefaultControl accountId="{{accountId}}" [formControl]="deviceCtrl">
        </ads-device-select-search>
        <div class="bogie-iq-otions" [hidden]="!deviceIsBogieIq()">
          <mat-form-field>
            <mat-label>Location</mat-label>
            <input formControlName="location" matInput maxlength="10"/>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Side</mat-label>
            <input formControlName="side" matInput maxlength="10"/>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <button id='ad-cancelbutton' type="button" [mat-dialog-close]="true">Cancel</button>
        <button id='ad-updatebutton' mat-flat-button color="primary" type="submit">Install</button>
      </mat-card-actions>
    </ng-container>
  </form>
</mat-dialog-content>


