<div class="my-3">
  <mat-card>
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <label class="account-tab-top-padding">Search Users</label>
        <div>
          <button mat-button color="primary" (click)="addUsers()">Add Users</button>
        </div>
      </div>
        <div id="leftcolumn">
          <mat-form-field>
            <mat-label></mat-label>
            <input id='ac-users-searchtext' name="searchText" matInput
                   (keyup)="searchTriggered($event.target.value)" maxlength="50"
                   #searchText placeholder="To see users, please begin typing the user's display name." />
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-content class="table-container">
        <table mat-table matSort class="m-t-20" [dataSource]="userData" [hidden]="userData.filteredData.length < 1">
          <ng-container matColumnDef="accountName">
            <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Account</th>
            <td mat-cell *matCellDef="let user">{{ user.accountName }}</td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let user">{{ user.email }}</td>
          </ng-container>
          <ng-container matColumnDef="displayName">
            <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Display Name</th>
            <td mat-cell *matCellDef="let user">{{ user.displayName }}</td>
          </ng-container>
          <ng-container matColumnDef="lastSignInDate">
            <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Last Logged In
            <mat-icon #tooltip="matTooltip" matTooltip="Recent logins can take up to 10 minutes to be reflected">info</mat-icon></th>
            <td mat-cell *matCellDef="let user"><span matTooltip="{{ user.lastSignInDate }}" matTooltipPosition="below">{{ user.lastSignInDate | dateAgo: '--' }}</span></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
          <tr mat-row class="hover" (click)="onRowClick(row)" *matRowDef="let row; columns: columnsToDisplay">
          </tr>
        </table>
        <div *ngIf="skipToken !== null && skipToken !== undefined">
          <button mat-button color="primary" id='usr-loadmoredata' (click)='loadMoreResults()'>
            Load More
          </button>
        </div>
        <div *ngIf="userData.filteredData.length === 0">
          <label class="sm-note">{{emptyMessage}}</label>
        </div>
      </mat-card-content>
  </mat-card>
</div>
