import { Injectable, InjectionToken, Inject, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export const RSRD2_API_BASE_URL = new InjectionToken<string>('RSRD2_API_BASE_URL');

export interface IRsrd2Service {
  checkForUser(accountId?: number);
  connect(body?: RSRD2AccountUser);
  disconnect(accountId?: number);
  getLastRefreshDate(accountId?: number);
  getRefreshStatus(accountId?: number);
  getRSRD2Assets(accountId?: number);
  getRollingStockDataset(accountId?: number);
  emitOnDownloadButtonSelected(change: null);
}

@Injectable({
  providedIn: 'root'
})
export class Rsrd2Service implements IRsrd2Service {
  private http: HttpClient;
  private baseUrl: string;

  isConnected: boolean;
  isRefreshing: boolean;

  exportEnabled = true;
  private triggerDownload = new Subject<null>();
  public triggerDownload$: Observable<null> = this.triggerDownload;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(RSRD2_API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : '';
  }

  checkForUser(accountId?: number) {
    if (accountId === undefined || accountId === null) {
      throw new Error('The parameter \'accountId\' must be defined.');
    }

    let url = this.baseUrl + '/api/v1/auth/accountuserexists';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams().set('accountId', accountId.toString()),
      observe: 'response',
      responseType: 'text'
    };

    return this.http.request('GET', url, options);
  }

  connect(body?: RSRD2AccountUser) {
    if (body === undefined || body === null) {
      throw new Error('The parameter \'body\' must be defined.');
    }
    if (body.AccountId === undefined || body.AccountId === null) {
      throw new Error('\'body.AccountId\' must be defined.');
    }
    if (body.Username === undefined || body.Username === null) {
      throw new Error('\'body.Username\' must be defined.');
    }
    if (body.Password === undefined || body.Password === null) {
      throw new Error('\'body.Password\' must be defined.');
    }

    let url = this.baseUrl + '/api/v1/auth/connect';
    url = url.replace(/[?&]$/, '');

    const content = JSON.stringify(body);

    const options: any = {
      body: content,
      observe: 'response',
      responseType: 'text',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    return this.http.request('POST', url, options);
  }

  disconnect(accountId?: number) {
    if (accountId === undefined || accountId === null) {
      throw new Error('The parameter \'accountId\' must be defined.');
    }

    let url = this.baseUrl + '/api/v1/auth/disconnect';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams().set('accountId', accountId.toString()),
      observe: 'response',
      responseType: 'text'
    };

    return this.http.request('POST', url, options);
  }

  getLastRefreshDate(accountId?: number) {
    if (accountId === undefined || accountId === null) {
      throw new Error('The parameter \'accountId\' must be defined.');
    }

    let url = this.baseUrl + '/api/v1/rsrd2assets/getlastrefreshdatebyaccount';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams().set('accountId', accountId.toString()),
      observe: 'response',
      responseType: 'text'
    };

    return this.http.request('GET', url, options);
  }

  getRefreshStatus(accountId?: number) {
    if (accountId === undefined || accountId === null) {
      throw new Error('The parameter \'accountId\' must be defined.');
    }

    let url = this.baseUrl + '/api/v1/rsrd2/GetRefreshStatusByAccount';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams().set('accountId', accountId.toString()),
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('GET', url, options);
  }

  getRSRD2Assets(accountId?: number) {
    if (accountId === undefined || accountId === null) {
      throw new Error('The parameter \'accountId\' must be defined.');
    }

    let url = this.baseUrl + '/api/v1/rsrd2assets/';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams().set('accountId', accountId.toString()),
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };

    return this.http.request('GET', url, options);
  }

  getRollingStockDataset(accountId?: number) {
    if (accountId === undefined || accountId === null) {
      throw new Error('The parameter \'accountId\' must be defined.');
    }

    let url = this.baseUrl + '/api/v1/rsrd2/RollingStockDataset';
    url = url.replace(/[?&]$/, '');

    const options: any = {
      params: new HttpParams().set('accountId', accountId.toString()),
      responseType: 'text'
    };

    return this.http.request('GET', url, options);
  }

  // Handles Download button logic
  emitOnDownloadButtonSelected(change: null = null) {
    this.triggerDownload.next(change);
  }
}

export interface IRSRD2AccountUser {
  AccountId?: number;
  Username?: string | undefined;
  Password?: string | undefined;
}

export class RSRD2AccountUser implements IRSRD2AccountUser {

  constructor(data?: IRSRD2AccountUser) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (this as any)[property] = (data as any)[property];
        }
      }
    }
  }
  AccountId?: number;
  Username?: string | undefined;
  Password?: string | undefined;

  static fromJS(data: any): RSRD2AccountUser {
    data = typeof data === 'object' ? data : {};
    const result = new RSRD2AccountUser();
    result.init(data);
    return result;
  }

  init(data?: any) {
    if (data) {
      this.AccountId = data.AccountId;
      this.Username = data.Username;
      this.Password = data.Password;
    }
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data.AccountId = this.AccountId;
    data.Username = this.Username;
    data.Password = this.Password;
    return data;
  }
}
