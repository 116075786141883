<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    Accounts

    <div>
    </div>
  </mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <ads-account-select-search #accountDrop></ads-account-select-search>
    <label class="sm-note">Select an account to view account assets</label>
  </mat-card-content>
</mat-card>
