import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ISimpleLocationDTO, LocationService, SimpleLocationDTO } from './geo.service';

@Injectable({
  providedIn: 'root'
})
export class LocationCacheService {
  observable: Observable<ISimpleLocationDTO[]> = null;

  constructor(private locationService: LocationService) {}

  public getLocations(): Observable<ISimpleLocationDTO[]> {
    if (!this.observable) {
      this.observable = this.locationService.all().pipe(shareReplay(1));
    }
    return this.observable;
  }
}
