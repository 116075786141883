<div class="my-3">
  <mat-card [hidden]="!showPolicies">
    <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
      Policies
      <div>
        <button id='policy-new-button' [routerLink]="['/policies/add']" mat-button color="primary">
          Add New Policy
        </button>
      </div>
    </mat-card-title>
    <mat-card-subtitle>
      Manage Policies
    </mat-card-subtitle>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div id="leftcolumn">
        <ads-account-select-search id='policy-account-select' ngDefaultControl [formControl]="accountSearchControl"
                                   [allowEmptySelection]="false" [allOption]="true" [isRequired]="false" placeholder="Select Account">
        </ads-account-select-search>
      </div>
    </mat-card-content>
    <mat-card-content *ngIf="loaded" class="table-container">
      <table mat-table [dataSource]="dataSource" matSort class="m-t-20" [hidden]="dataSource?.data?.length < 1">
        <ng-container matColumnDef="account">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Account</th>
          <td mat-cell *matCellDef="let policy">{{policy.account}}</td>
        </ng-container>
        <ng-container matColumnDef="workspace">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Workspace</th>
          <td mat-cell *matCellDef="let policy">{{policy.workspace}}</td>
        </ng-container>
        <ng-container matColumnDef="object">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Resource</th>
          <td mat-cell *matCellDef="let policy">{{policy.object}}</td>
        </ng-container>
        <ng-container matColumnDef="subject">
          <th mat-header-cell class="table-header" *matHeaderCellDef>Access For</th>
          <td matTooltip="{{getEmailFromMap(policy)}}" matTooltipDisabled="{{policy.isSecurityGroup}}" mat-cell *matCellDef="let policy">
            <mat-icon *ngIf="policy.isSecurityGroup">people_outline</mat-icon>
            <mat-icon *ngIf="!policy.isSecurityGroup">account_box</mat-icon>
            {{policy | getAccessName | async}}
          </td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Role</th>
          <td mat-cell *matCellDef="let policy">{{policy.role}}</td>
        </ng-container>
        <ng-container matColumnDef="deleted">
          <th mat-header-cell *matHeaderCellDef class="table-header">Delete</th>
          <td mat-cell *matCellDef="let policy">
            <button mat-button (click)="deletePolicy(policy)">
              <mat-icon class="red-icon">clear</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headers; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: headers;">
        </tr>
      </table>
      <div *ngIf="dataSource?.data?.length < 1">
        <label class="sm-note">{{emptyMessage}}</label>
      </div>
    </mat-card-content>
  </mat-card>
</div>
