<mat-card *ngIf="account">
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    {{account.accountName}}
    <div>
        <button id='av-managepermissions' mat-button color="primary" (click)="manageDevicePermissions()">Manage Permissions </button>
        <button id='av-editaccountbutton' mat-button color="primary" (click)="onEditAccountClick()">Edit Account </button>
        <button id='av-enableaccountbutton' mat-button color="warn" (click)="onChangeAccountActiveClick()">{{ account.isNotEnabled ? 'Enable' : 'Disable' }} Account</button>
    </div>
  </mat-card-title>
  <mat-card-subtitle>{{ !account.isNotEnabled ? '' : 'Note: This account is currently disabled' }}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-card-content>
    <nav mat-tab-nav-bar mat-tab-group mat-align-tabs="center">
      <a id='tb-{{tab.label}}' [style.width]="getTabWidth()" mat-tab-link *ngFor="let tab of tabs" [routerLink]="tab.path" routerLinkActive #rla="routerLinkActive"
        [active]="rla.isActive">
        {{tab.label}}
      </a>
    </nav>
    <router-outlet></router-outlet>
  </mat-card-content>
</mat-card>
