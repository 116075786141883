import { Component, OnInit } from '@angular/core';
import { CDAToolService, ISAPFileInfoDTO } from '../services/devices.service';
import { CDAFileDownloadService } from '../services/cda-file-download.service';

@Component({
  selector: 'ads-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.scss']
})
export class ShipmentsComponent implements OnInit {
  displayedColumns: string[] = ['File Name', 'File Type', 'Processed By', 'File Status', 'Created Date'];
  files: ISAPFileInfoDTO[];

  constructor(private cDAToolService: CDAToolService, private cDAFileDownloadService: CDAFileDownloadService) {}

  ngOnInit() {
    this.files = null;
  }

  dateChange(val: Date | null) {
    if (val != null) {
      this.cDAToolService.getSAPFilesInfo(new Date(val)).subscribe((r) => {
        this.files = r.results;
        this.files.sort((a: ISAPFileInfoDTO, b: ISAPFileInfoDTO) => {
          if (this.getTime(new Date(a.createdDate)) === this.getTime(new Date(b.createdDate))) {
            // verifier should appear at the top, then validator, then initial checker
            if (a.processedBy === 'Verifier') {
              return -1;
            }
            if (b.processedBy === 'Verifier') {
              return 1;
            }
            if (a.processedBy === 'InitialChecker') {
              return 1;
            }
            if (b.processedBy === 'InitialChecker') {
              return -1;
            }
          }
          return (this.getTime(new Date(a.createdDate)) - this.getTime(new Date(b.createdDate))) * -1;
        });
      });
    }
  }

  getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }

  download(file: ISAPFileInfoDTO) {
    this.cDAFileDownloadService.downloadSAPFile(file.name).subscribe((b) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(b);
      downloadLink.setAttribute('download', file.name);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }
}
