export const DefaultMapOptions: google.maps.MapOptions = {
  center: { lat: 40, lng: -40 },
  zoom: 3,
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    }]
};
