import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssetTraceDetailsDialogComponent } from './asset-trace-details-dialog/asset-trace-details-dialog.component';
import { DatePipe } from '@angular/common';
import { ColDef } from 'ag-grid-community';
import { AccountAssetEventFactoryEvent } from 'src/app/services/assets-v3.service';

@Component({
  selector: 'app-asset-trace-data-view-grid',
  templateUrl: './asset-trace-data-view-grid.component.html',
  styleUrls: [],
})
export class AssetTraceDataViewGridComponent implements OnInit {

  @Input() apiResponseItemsData: AccountAssetEventFactoryEvent[] = [];
  rowData: any[] = [];

  columnDefs: ColDef[] = [
    { headerName: 'Event Date', field: 'eventDateTimeUtc', width: 300 , sortable: true , filter: 'agTextColumnFilter' },
    { headerName: 'Location', field: 'eventData', width: 250 , sortable: true , filter: 'agTextColumnFilter' },
    { headerName: 'Event Type', field: 'eventType', width: 250, sortable: true , filter: 'agTextColumnFilter' },
    { headerName: 'Source', field: 'source', width: 300, sortable: true , filter: 'agTextColumnFilter' },
  ];

  constructor(
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.rowData = this.apiResponseItemsData.map(item => {
      return {
        eventDateTimeUtc: item.eventDateTimeUtc ? this.formatDate(item.eventDateTimeUtc) : null,
        eventData: item.eventData?.data?.extendedData?.location ? this.formatLocation(item.eventData.data.extendedData.location) : null,
        eventType: item.eventType ? this.formatEventType(item.eventType) : null,
        source: item.source
        }
    });
  }

  formatDate(originalDate: Date | null): string | null {
    if (!originalDate) return null;
    const formattedDate = this.datePipe.transform(originalDate , 'medium' , 'UTC')
    return formattedDate || '';
  }

  formatLocation(originalLocation: any | null): string |null {
    if (!originalLocation) return null;
    return originalLocation.closestLocale.city + ', ' + originalLocation.closestLocale.state;
  }

  formatEventType(eventType: string | null): string | null {
    if (!eventType) return null;
    const prefix: string = "ADS.Assets.v3.Asset.Account.";
    let formattedEventType: string = eventType.slice(prefix.length);
    formattedEventType = formattedEventType.replace(".", " ")
    return formattedEventType;
  }

  onRowClicked(event): void {
    let rowData: AccountAssetEventFactoryEvent = this.apiResponseItemsData[event.rowIndex];

    var dialogRef = this.dialog.open(AssetTraceDetailsDialogComponent, {
      data: rowData
    });

    dialogRef.afterClosed().subscribe((success) => {
      if (success) { this.ngOnInit(); }
    });
  }

}
