import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetResponse, AssetsV3Service } from 'src/app/services/assets-v3.service';
import { TripModel, TripsService } from 'src/app/services/shipments-v2.service';

@Component({
  templateUrl: './trip-details.page.html',
  styleUrls: ['./trip-details.page.scss']
})
export class TripDetailsPage implements OnInit {
  trip: TripModel | null = null;
  asset: AssetResponse | null = null;

  constructor(
    private tripService: TripsService,
    private assetV3Service: AssetsV3Service,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    const tripId = this.route.snapshot.params.tripId;

    const tripPromise = this.tripService.getTrip(tripId).toPromise()
    const assetPromise = tripPromise.then(trip => this.assetV3Service.getAsset(trip.assetId).toPromise());

    tripPromise
      .then(trip => {
        this.trip = trip;
      });

    assetPromise
      .then(asset => {
        this.asset = asset;
      })
      .catch(err => {
        this.asset = null;
        console.log('Asset not found');
      });
  }
}
