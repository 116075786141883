import { Component, OnInit, Input } from '@angular/core';
import { AdsDeviceDto, DevicesService } from '../../services/devices.service';

@Component({
  selector: 'ads-device-name',
  templateUrl: './device-name.component.html',
  styleUrls: ['./device-name.component.scss']
})
export class DeviceNameComponent implements OnInit {
  @Input() serialNumber: string;

  public device: AdsDeviceDto;

  constructor(private devicesService: DevicesService) {}

  ngOnInit() {
    // tslint:disable-next-line: no-shadowed-variable
    this.devicesService.deviceBySerialNumber(this.serialNumber).subscribe((d: AdsDeviceDto) => {
      this.device = d;
    });
  }
}
