import { Component, OnInit, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'angular-crumbs';
import { Operation, AdsAccountDto, AccountsService, WorkspacesService, WorkspaceDto } from '../../services/accounts.service';
import { AssetsService, AssetDtoAssetPagedListDto } from '../../services/assets.service';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { DevicesService, Status, DeviceSearchDtoPagedListDto } from 'src/app/services/devices.service';
import { UsersService, AppUserDtoPagedListDto } from '../../services/users.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PermissionsDialogComponent } from '../../shared/dialog/permissions-dialog/permissions-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { AccountWorkspacesV3Service, AccountShare } from '../../services/assets-v3.service';


@Injectable({
  providedIn: 'root'
})
export class CurrentAccountService {
  public account: AdsAccountDto;
}

@Component({
  selector: 'ads-account-view',
  templateUrl: './account-view.component.html',
  styleUrls: ['./account-view.component.scss']
})
export class AccountViewComponent implements OnInit {
  public tabs = [
    {
      label: 'Account Details',
      path: 'account-details'
    },
    {
      label: 'Users',
      path: 'account-users'
    },
    {
      label: 'Devices',
      path: 'account-devices'
    },
    {
      label: 'Assets',
      path: 'account-assets'
    },
    {
      label: 'Installer Apps',
      path: 'account-installers'
    }
  ];

  public account: AdsAccountDto;
  private accountId: number;
  private assetCount: number;
  private deviceCount: number;
  public resourcesLoaded: boolean;
  public showRSRD2: boolean;
  public showRailSight: boolean;
  public showWorkspaces: boolean;
  public showSharing: boolean;
  private userCount: number;
  private workspaceCount: number;
  private sharedToAccountsCount: number;
  public showDevicesGroups: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private spinner: SpinnerVisibilityService,
    private currentAccountService: CurrentAccountService,
    private assetsService: AssetsService,
    private deviceService: DevicesService,
    private accountsService: AccountsService,
    private featureFlagService: FeatureFlagService,
    private usersService: UsersService,
    private workspacesService: WorkspacesService,
    private accountWorkspacesV3Service: AccountWorkspacesV3Service,
    private dialog: MatDialog
  ) {
    const accountId = Number.parseInt(this.route.snapshot.params.id, 10);
    if (isNaN(accountId)) {
      this.router.navigateByUrl('[/accounts]');
    }

    this.accountId = accountId;
    this.resourcesLoaded = false;
  }

  ngOnInit() {
    this.featureFlagService.devicesGroups.then((treatment) => {
      this.showDevicesGroups = treatment === 'on';
      if (this.showDevicesGroups) {
        this.tabs.push({
          label: 'Device Groups',
          path: 'account-devices-groups'
        });
      }
    });

    this.featureFlagService.rsrd2TabEnabled.then((treatment) => {
      this.showRSRD2 = treatment === 'on';
      if (this.showRSRD2) {
        this.tabs.push({
          label: 'RSRD2',
          path: 'account-RSRD2'
        });
      }
    });

    this.featureFlagService.workspacesEnabled.then((treatment) => {
      this.showWorkspaces = treatment === 'on';
      if (this.showWorkspaces) {
        const workspacesTab = {
          label: 'Workspaces',
          path: 'workspaces'
        };

        this.tabs.splice(1, 0, workspacesTab);
        this.getWorkspaces();
      }
    });

    this.featureFlagService.assetsPaaSEnabled.then((treatment) => {
      this.showSharing = treatment === 'on';
      if (this.showSharing) {
        const sharingTab = {
          label: 'Sharing',
          path: 'sharing'
        };

        this.tabs.splice(1, 0, sharingTab);
        this.getSharedAccounts();
      }
    });

    this.featureFlagService.railsightTabEnabled.then((treatment) => {
      this.showRailSight = treatment === 'on';
      if (this.showRailSight) {
        this.tabs.push({
          label: 'RailSight',
          path: 'account-railsight'
        });
      }
    });

    this.assetsService.assets(this.accountId, 'all', '').subscribe((resp: AssetDtoAssetPagedListDto) => {
      this.assetCount = resp.total;
      this.tabs[this.tabs.findIndex((x) => x.label.localeCompare('Assets') === 0)].label += ` (${this.assetCount})`;
    });

    this.deviceService.devices(0, 1, undefined, Status.All, this.accountId).subscribe((resp: DeviceSearchDtoPagedListDto) => {
      this.deviceCount = resp.total;
      this.tabs[this.tabs.findIndex((x) => x.label.localeCompare('Devices') === 0)].label += ` (${this.deviceCount})`;
    });

    this.usersService.getUsersByAccount(this.accountId).subscribe((resp: AppUserDtoPagedListDto) => {
      this.userCount = resp.items.length;
      this.tabs[this.tabs.findIndex((x) => x.label.localeCompare('Users') === 0)].label += ` (${this.userCount})`;
    });

    this.accountsService.account(this.accountId).subscribe((foundAccount: AdsAccountDto) => {
      if (this.account === null) {
        this.router.navigateByUrl('/accounts');
      }

      this.account = foundAccount;
      this.currentAccountService.account = foundAccount;

      this.resourcesLoaded = true;
      this.breadcrumbService.changeBreadcrumb(this.route.snapshot, this.account === undefined ? '' : this.account.accountName);
      this.spinner.hide();
    });
  }

  getSharedAccounts() {
    this.accountWorkspacesV3Service.getSharedToAccounts(this.accountId).subscribe((resp: AccountShare) => {
      this.sharedToAccountsCount = resp.sharedMappings.length
      this.tabs[this.tabs.findIndex((x) => x.label.localeCompare('Sharing') === 0)].label += ` (${this.sharedToAccountsCount})`;
    });
  }

  getWorkspaces() {
    this.workspacesService.workspaces(this.accountId).subscribe((resp: WorkspaceDto[]) => {
      this.workspaceCount = resp.length;
      this.tabs[this.tabs.findIndex((x) => x.label.localeCompare('Workspaces') === 0)].label += ` (${this.workspaceCount})`;
    });
  }

  getTabWidth() {
    const tabs = 5 + Number(this.showRSRD2) + Number(this.showRailSight) + Number(this.showWorkspaces) + Number(this.showSharing);
    return Math.floor(100 / tabs) + '%';
  }

  onEditAccountClick() {
    let currentRoute = this.router.url;
    let dialogRef: MatDialogRef<ConfirmationDialogComponent>;
    let config = {
      data: {
        message: 'You may have unsaved changes. Be sure to save before navigating away.'
      }
    };

    if (
      currentRoute.includes(`/accounts/view/${this.accountId}/device-group/`) ||
      currentRoute.includes(`/accounts/view/${this.accountId}/asset-group/`) ||
      currentRoute.includes(`/accounts/view/${this.accountId}/user-group/`) ||
      currentRoute.includes(`/view/${this.accountId}/add/workspace`)
    ) {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, config);
    } else {
      this.router.navigate([`/accounts/edit/${this.accountId}`]);
    }

    dialogRef.afterClosed().subscribe((x) => {
      if (x) {
        this.router.navigate([`/accounts/edit/${this.accountId}`]);
      }
    });
  }

  onChangeAccountActiveClick() {
    const accountPatch: Operation[] = [new Operation({ op: 'replace', path: '/IsNotEnabled', value: !this.account.isNotEnabled })];

    this.accountsService.update(this.accountId, accountPatch).subscribe((foundAccount: AdsAccountDto) => {
      this.account.isNotEnabled = !this.account.isNotEnabled;
    });
  }

  manageDevicePermissions() {
    var resourcePath = `/accounts/${this.accountId}/**`;
    const dialogRef = this.dialog.open(PermissionsDialogComponent, {
      data: {
        resourceType: 'Account',
        resourcePath: resourcePath
      }
    });
  }
}
