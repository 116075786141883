import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts.service';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { AccountSelectSearchComponent } from 'src/app/shared/account-select-search/account-select-search.component';

@Component({
  selector: 'app-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss']
})
export class AccountSelectorComponent implements OnInit {
  @ViewChild('accountDrop', { static: true }) accountDrop: AccountSelectSearchComponent;

  constructor(
    private router: Router,
    accountService: AccountsService,
    toasterService: ToasterNotificationService
  ) {
    this.accountDrop = new AccountSelectSearchComponent(accountService, toasterService);
  }

  ngOnInit(): void {
    this.setupAccountDropDown();
  }

  setupAccountDropDown() {
    this.accountDrop.isRequired = true;
    this.accountDrop.allOption = false;
    this.accountDrop.starOption = false;
    this.accountDrop.allowEmptySelection = false;
    this.accountDrop.placeholder = 'Account';
    this.accountDrop.registerOnChange(() => this.onAccountChange());
  }

  onAccountChange() {
    const accountId = this.accountDrop.singleSelect.value;
    this.router.navigate([`/assets-paas/accounts/${accountId}/assets`]);
  }
}
