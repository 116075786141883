<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    Asset Details
    <div>
      <button id='ad-manageassetpermissionsbutton' mat-button color="primary" (click)="onManagePermissionsClick()">Manage Permissions</button>
      <button id='ad-editassetbutton' mat-button color="primary" (click)="onEditAsset()">Edit Asset</button>
      <button id='ad-editstatusbutton' mat-button color="warn" (click)="onChangeAssetActiveClick()">{{ asset.active == true ? 'Disable' : 'Enable' }} Asset</button>
    </div>
  </mat-card-title>
  <mat-card-subtitle>
    {{ asset.active === false ? 'Note: This asset is currently disabled' : ''}}
  </mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-card-content>
    <dl>
      <ng-container *ngIf="asset.assetName">
        <dt>Asset Name</dt>
        <dd *ngIf="asset.assetName">{{asset.assetName}}</dd>
      </ng-container>
      <ng-container *ngIf="asset.unitName">
        <dt>Unit Name</dt>
        <dd *ngIf="asset.unitName">{{asset.unitName}}</dd>
      </ng-container>
      <ng-container *ngIf="asset.assetTypeName">
        <dt>Type</dt>
        <dd *ngIf="asset.assetTypeName">{{asset.assetTypeName}}</dd>
      </ng-container>
      <ng-container *ngIf="asset.unitNumber">
        <dt>Unit Number</dt>
        <dd *ngIf="asset.unitNumber">{{asset.unitNumber}}</dd>
      </ng-container>
      <ng-container *ngIf="asset.ownerAccountId">
        <dt>Owner</dt>
        <dd *ngIf="asset.ownerAccountId">
          <ads-account-name [accountId]="asset.ownerAccountId"></ads-account-name>
        </dd>
      </ng-container>
      <ng-container *ngIf="asset.operatorAccountId">
        <dt>Operator</dt>
        <dd *ngIf="asset.operatorAccountId">
          <ads-account-name [accountId]="asset.operatorAccountId"></ads-account-name>
        </dd>
      </ng-container>
      <ng-container>
        <dt>Installed Device</dt>
        <dd>
          <ads-device-name *ngIf="asset.deviceSerial" [serialNumber]="asset.deviceSerial"></ads-device-name>
          <span *ngIf="!asset.deviceSerial">Not Installed</span>
        </dd>
      </ng-container>
      <ng-container *ngIf="v3Asset.isADSDataFeedActive">
        <dt>Shared From Accounts</dt>
        <dd>{{v3Asset.sharedFromAccountIds}}</dd>
      </ng-container>
      <ng-container *ngIf="v3Asset.isADSDataFeedActive">
        <dt>Shared To Accounts</dt>
        <dd>{{v3Asset.sharedToAccountIds}}</dd>
      </ng-container>
      <ng-container *ngIf="this.loaded">
        <dt class="workspaces">Workspaces</dt>
        <dd>
          <ads-asset-workspace-chips #chips searchable="false" assetId="{{this.assetId}}" accountId="{{this.accountId}}"></ads-asset-workspace-chips>
        </dd>
      </ng-container>
    </dl>
  </mat-card-content>
</mat-card>
