import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DevicesListResolverService } from '../services/resolvers/devices-list-resolver.service';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountFormComponent } from './account-form/account-form.component';
import { AccountViewComponent } from './account-view/account-view.component';
import { AccountAssetsTabComponent } from './account-view/tabs/account-assets-tab/account-assets-tab.component';
import { AccountDetailsTabComponent } from './account-view/tabs/account-details-tab/account-details-tab.component';
import { AccountDevicesGroupsTabComponent } from './account-view/tabs/account-devices-groups-tab/account-devices-groups-tab.component';
import { AccountDevicesTabComponent } from './account-view/tabs/account-devices-tab/account-devices-tab.component';
import { AccountInstallersTabComponent } from './account-view/tabs/account-installers-tab/account-installers-tab.component';
import { AccountRailSightTabComponent } from './account-view/tabs/account-railsight-tab/account-railsight-tab.component';
import { AccountRSRD2TabComponent } from './account-view/tabs/account-rsrd2-tab/account-rsrd2-tab.component';
import { AccountUsersTabComponent } from './account-view/tabs/account-users-tab/account-users-tab.component';
import { AccountWorkspacesTabComponent } from './account-view/tabs/account-workspaces-tab/account-workspaces-tab.component';
import { AccountSharingTabComponent } from './account-view/tabs/account-sharing-tab/account-sharing-tab.component';
import { WorkspaceNewComponent } from './account-view/tabs/account-workspaces-tab/workspace-new/workspace-new.component';
import { WorkspaceViewComponent } from './account-view/tabs/account-workspaces-tab/workspace-view/workspace-view.component';
import { SharingViewComponent } from './account-view/tabs/account-sharing-tab/sharing-view/sharing-view.component';
import { AccountsComponent } from './accounts.component';
import { AssetGroupUnassignedComponent } from './asset-groups-unassigned/asset-groups-unassigned.component';
import { AssetGroupsComponent, AssetsDeactivateGuard } from './asset-groups/asset-groups.component';
import { DeviceGroupEditComponent, DevicesDeactivateGuard } from './device-groups/device-group.component';
import { AddInstallerLocationsComponent } from './installer-locations/add-installer-locations/add-installer-locations.component';
import { EditInstallerLocationsComponent } from './installer-locations/edit-installer-locations/edit-installer-locations.component';
import { InstallerLocationsComponent } from './installer-locations/installer-locations.component';
import { UserGroupsComponent, UsersDeactivateGuard } from './user-groups/user-groups.component';

const routes: Routes = [
  {
    path: '',
    component: AccountsComponent
  },
  {
    path: 'new',
    component: AccountFormComponent
  },
  {
    path: 'edit/:id',
    component: AccountEditComponent
  },
  {
    path: 'view/:id',
    component: AccountViewComponent,
    children: [
      {
        path: 'account-details',
        component: AccountDetailsTabComponent
      },
      {
        path: 'account-assets',
        component: AccountAssetsTabComponent,
        data: { breadcrumb: 'Assets' }
      },
      {
        path: 'account-devices',
        component: AccountDevicesTabComponent,
        data: { breadcrumb: 'Devices' }
      },
      {
        path: 'account-devices-groups',
        component: AccountDevicesGroupsTabComponent,
        data: { breadcrumb: 'Device Groups' },
        resolve: {
          items: DevicesListResolverService
        }
      },
      {
        path: 'account-installers',
        component: AccountInstallersTabComponent,
        data: { breadcrumb: 'Installer Apps' }
      },
      {
        path: 'account-users',
        component: AccountUsersTabComponent,
        data: { breadcrumb: 'Users' }
      },
      {
        path: 'workspaces',
        component: AccountWorkspacesTabComponent,
        data: { breadcrumb: 'Workspaces' }
      },
      {
        path: 'workspaces/:workspaceId',
        component: WorkspaceViewComponent,
        data: { breadcrumb: 'Workspaces' }
      },
      {
        path: 'sharing',
        component: AccountSharingTabComponent,
        data: { breadcrumb: 'Sharing' }
      },
      {
        path: 'sharing/:sharedAccountId',
        component: SharingViewComponent,
        data: { breadcrumb: 'Sharing' }
      },
      { path: 'account-RSRD2', component: AccountRSRD2TabComponent },
      {
        path: 'account-railsight',
        component: AccountRailSightTabComponent,
        data: { breadcrumb: 'RailSight' }
      },
      { path: 'device-group', component: DeviceGroupEditComponent },
      { path: 'device-group/:deviceGroupId', component: DeviceGroupEditComponent, canDeactivate: [DevicesDeactivateGuard] },
      { path: 'asset-group', component: AssetGroupsComponent },
      { path: 'asset-group/:assetGroupId', component: AssetGroupsComponent, canDeactivate: [AssetsDeactivateGuard] },
      { path: 'asset-group-unassigned', component: AssetGroupUnassignedComponent },
      { path: 'user-group', component: UserGroupsComponent },
      { path: 'user-group/:userGroupId', component: UserGroupsComponent, canDeactivate: [UsersDeactivateGuard] },
      { path: '', pathMatch: 'full', redirectTo: 'account-details' },
      {
        path: 'add',
        children: [{ path: 'workspace', component: WorkspaceNewComponent }]
      }
    ]
  },
  {
    path: 'installer-location',
    component: InstallerLocationsComponent,
    data: { breadcrumb: 'Installer Locations' },
    children: [
      { path: 'add-installer-location', component: AddInstallerLocationsComponent, data: { breadcrumb: 'Add Installer Location' } },
      {
        path: 'edit-installer-location/:companyCode',
        component: EditInstallerLocationsComponent,
        data: { breadcrumb: 'Edit Installer Location' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountsRoutingModule {}
