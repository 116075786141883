import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from '../../services/validation.service';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'ads-control-messages',
  template: `<div [@error-animation]="increment" style="font-size:.75rem; margin-bottom:-1rem;"
    class="required-warn" *ngIf="errorMessage !== null">
      <mat-error>{{errorMessage}}</mat-error>
    </div>`,
  animations: [
    trigger('error-animation', [
      transition(':increment', [
        style({ opacity: 0 }),
        animate('200ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-1rem)' }),
        animate('200ms ease-in', style({ opacity: 1, transform: 'translateY(0rem)' })),
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ opacity: 0, transform: 'translateY(-1rem)' }))
      ]),
    ])
  ],
})
export class ControlMessagesComponent {
  increment = 0;
  previousErrorMessage: string;
  @Input() control: FormControl;
  constructor() {}

  get errorMessage() {
    if (this.control !== null && this.control !== undefined) {
      for (const propertyName in this.control.errors) {
        if (
          this.control.errors.hasOwnProperty(propertyName) &&
          this.control.touched
        ) {
          const errorMessage = ValidationService.getValidatorErrorMessage(
            propertyName,
            this.control.errors[propertyName]
          );
          if (errorMessage.localeCompare(this.previousErrorMessage) !== 0) {
            this.previousErrorMessage = errorMessage;
            this.increment++;
          }
          return errorMessage;
        }
      }
    }

    return null;
  }
}
