import { NgModule } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { environment } from '@env/environment';
import { AgGridModule } from 'ag-grid-angular';
import { AssetsPaaSRoutingModule } from './assets-paas-routing.module';
import { AssetsV3Service, AccountWorkspaceAssetsV3Service, AccountWorkspaceAssetsDeviceV3Service, AssetClassesV3Service, AssetTypesV3Service, ASSETS_V3_API_BASE_URL, AssetAccountsEventFactoryV3Service } from '../services/assets-v3.service';
import { AssetsPaaSComponent } from './assets-paas.component';
import { AssetListComponent } from './asset-list/asset-list.component';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';
import { AssetDetailsComponent } from './asset-details/asset-details.component';
import { AssetEventsComponent } from './asset-events/asset-events.component';
import { AccountAssetsListComponent } from './account-assets-list/account-assets-list.component';
import { SharedModule } from '../shared/shared.module';
import { AccountSelectorComponent } from './account-selector/account-selector.component';
import { AccountNameResolver } from './services/AccountNameResolver';
import { AssetNameResolver } from './services/AssetNameResolver';
import { BreadcrumbResolver } from './services/BreadcrumbResolver';
import { AccountAssetDetailsComponent } from './account-asset-details/account-asset-details.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AccountAssetInstallDeviceComponent } from './account-asset-install-device-component/account-asset-install-device.component';
import { AccountAssetUninstallDeviceComponent } from './account-asset-uninstall-device-component/account-asset-uninstall-device.component';
import { AccountAssetReplaceDeviceComponent } from './account-asset-replace-device-component/account-asset-replace-device.component';
import { AccountAssetEndShareDialogComponent } from './account-asset-endshare-dialog-component/account-asset-endshare-dialog.component';
import { AccountAssetShareDialogComponent } from './account-asset-share-dialog-component/account-asset-share-dialog.component';
import { AssetGetOrCreateDialogComponent } from './asset-get-or-create-dialog-component/asset-get-or-create-dialog.component';
import { AccountAssetPropertiesDialogComponent } from './account-asset-properties-dialog-component/account-asset-properties-dialog.component';
import { AccountAssetTypeDialogComponent } from './account-asset-type-dialog-component/account-asset-type-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { AccountAssetTraceDialogComponent } from './account-asset-trace-dialog-component/account-asset-trace-dialog.component';
import { AssetTraceDataViewGridComponent } from './account-asset-trace-dialog-component/asset-trace-data-view-grid/asset-trace-data-view-grid.component';
import { AssetTraceLineMapComponent } from './account-asset-trace-dialog-component/asset-trace-line-map/asset-trace-line-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { AssetTraceDetailsDialogComponent } from './account-asset-trace-dialog-component/asset-trace-data-view-grid/asset-trace-details-dialog/asset-trace-details-dialog.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { DatePipe } from '@angular/common';
import {NgxJsonViewerModule} from "ngx-json-viewer";


@NgModule({
  declarations: [
    AssetsPaaSComponent,
    AssetListComponent,
    AssetDetailsComponent,
    AssetEventsComponent,
    AccountAssetsListComponent,
    AccountSelectorComponent,
    AccountAssetDetailsComponent,
    AccountAssetShareDialogComponent,
    AccountAssetEndShareDialogComponent,
    AccountAssetInstallDeviceComponent,
    AccountAssetUninstallDeviceComponent,
    AccountAssetReplaceDeviceComponent,
    AssetGetOrCreateDialogComponent,
    AccountAssetPropertiesDialogComponent,
    AccountAssetTypeDialogComponent,
    AccountAssetTraceDialogComponent,
    AssetTraceDataViewGridComponent,
    AssetTraceDetailsDialogComponent,
    AssetTraceLineMapComponent
  ],
    imports: [
        CommonModule,
        CommonUiSharedModule,
        SharedModule,
        AgGridModule,
        AssetsPaaSRoutingModule,
        MatDialogModule,
        MatCheckboxModule,
        MatMenuModule,
        GoogleMapsModule,
        NgHttpLoaderModule,
        NgxJsonViewerModule
    ],
  providers: [
    AssetsV3Service,
    AccountWorkspaceAssetsV3Service,
    AssetClassesV3Service,
    AssetTypesV3Service,
    AssetAccountsEventFactoryV3Service,
    AccountWorkspaceAssetsDeviceV3Service,
    { provide: ASSETS_V3_API_BASE_URL, useValue: environment.assetsV3BaseUrl },
    JsonPipe,
    AccountNameResolver,
    AssetNameResolver,
    BreadcrumbResolver,
    DatePipe
  ]
})
export class AssetsPaaSModule { }
