import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '@env/environment';
import { AccountsService, AdsAccountDto } from 'src/app/services/accounts.service';
import { Policy, PolicyResultSet, RbacService } from 'src/app/services/rbac.service';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { ConfirmationDialogComponent } from 'src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'ads-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss']
})
export class UserPermissionsComponent implements OnInit {
  @Input('userId') userId: string;
  @Input('accountId') accountId: number;

  permissions: Policy[];
  policies: any[];
  subject: string;
  dataSource: MatTableDataSource<PolicyResultSet>;
  loaded = false;
  headers: string[];
  accounts: AdsAccountDto[];
  emptyMessage = 'There are no policies to display for this user.';

  @ViewChild(MatSort) sort: MatSort;

  constructor(private rbacService: RbacService, private accountService: AccountsService, public dialog: MatDialog, private toasterService: ToasterNotificationService) {
    this.headers = ['account', 'workspace', 'object', 'role'];
   }

  ngOnInit(): void {
    this.constructSubject();
    this.accountService.searchAccounts().subscribe(x => {
      this.accounts = x;
      this.getUserPermissions();
    });
  }

  getUserPermissions() {
    this.rbacService.getPoliciesBySubjectFormatted(this.subject).subscribe(async (x: any[]) => {
      this.permissions = x;
      this.policies = await this.setupPolicyDisplay(this.permissions);
      this.dataSource = new MatTableDataSource(this.policies);
      this.dataSource.sort = this.sort;
      this.loaded = true;
    });
  }

  getAccountName(policy) {
    let accountName = '';

    if (policy.accountId && this.accounts) {
      const accountHolder = this.accounts.filter(account => account.accountId === policy.accountId);
      if (accountHolder?.length > 0) {
        accountName = accountHolder[0].accountName;
      }
    }
    else {
      accountName = '*';
    }

    return accountName;
  }

  async setupPolicyDisplay(policies: any[]) {
    let formattedPolicies = [];

    for (let i = 0; i < policies.length; i++) {
      const accountName = this.getAccountName(policies[i]);

      let role = policies[i].role.replace('role:', '');
      role = role.charAt(0).toUpperCase() + role.slice(1);

      formattedPolicies[i] = {
        policyId: policies[i].id,
        account: accountName,
        workspace: policies[i].workspace,
        subject: policies[i].subject,
        object: policies[i].resource,
        role
      };
    }

    formattedPolicies = this.alphabetizeList(formattedPolicies, 'account');

    return formattedPolicies;
  }

  alphabetizeList(unsortedList, property) {
    return unsortedList.sort((a, b) => a[property].localeCompare(b[property]));
  }

  constructSubject() {
    this.subject = `user:${this.accountId}:${environment.tenant}${this.userId}`;
  }
}
