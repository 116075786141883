import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { FileType, ProblemDetails, ApiException } from './devices.service';

export const CDA_FILE_PROCESSING_API_BASE_URL = new InjectionToken<string>('CDA_FILE_PROCESSING_API_BASE_URL');

export interface ICDAToolFileProcessingService {
  /**
   * @return Success
   */
  validateShipmentFile(fileType: FileType, file: File): Observable<string>;
}

@Injectable()
export class CDAToolFileProcessingService implements ICDAToolFileProcessingService {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(CDA_FILE_PROCESSING_API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : 'https://localhost:5004';
  }

  /**
   * @return Success
   */
  validateShipmentFile(fileType: FileType, file: File): Observable<string> {
    let url_ = this.baseUrl + '/api/v1/CDATool/validate/{fileType}';
    if (fileType === undefined || fileType === null) {
      throw new Error('The parameter "fileType" must be defined.');
    }
    if (file === null || file === undefined) {
      throw new Error('The parameter "file" must be defined.');
    }
    url_ = url_.replace('{fileType}', encodeURIComponent('' + fileType));
    url_ = url_.replace(/[?&]$/, '');

    const formData = new FormData();
    formData.append('uploadedFile', file);

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json'
      }),
      body: formData
    };

    return this.http
      .request('post', url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processValidateShipmentFile(response_ as any);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processValidateShipmentFile(response_ as any);
            } catch (e) {
              return _observableThrow(e) as any as Observable<string>;
            }
          } else {
            return _observableThrow(response_) as any as Observable<string>;
          }
        })
      );
  }

  protected processValidateShipmentFile(response: HttpResponseBase): Observable<string> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body : (response as any).error instanceof Blob ? (response as any).error : undefined;

    const _headers: any = {};
    if (response.headers) {
      for (const key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 202) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          let result200: any = null;
          const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
          result200 = resultData200 !== undefined ? resultData200 : (null as any);
          return _observableOf(result200);
        })
      );
    } else if (status === 400) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          let result400: any = null;
          const resultData400 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
          result400 = ProblemDetails.fromJS(resultData400);
          return throwException('Bad Request', status, _responseText, _headers, result400);
        })
      );
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          return throwException('An unexpected server error occurred.', status, _responseText, _headers);
        })
      );
    }
    return _observableOf<string>(null as any);
  }
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next('');
      observer.complete();
    } else {
      const reader = new FileReader();
      reader.onload = (event) => {
        observer.next((event.target as any).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any }, result?: any): Observable<any> {
  if (result !== null && result !== undefined) {
    return _observableThrow(result);
  } else {
    return _observableThrow(new ApiException(message, status, response, headers, null));
  }
}

export enum ProcessingStatus {
  Started = 'Started',
  Processing = 'Processing',
  Completed = 'Completed'
}

export interface IProcessingResult {
  InfoList: IInfo[];
  WarningList: IWarning[];
  ErrorList: IError[];
}

export interface IInfo {
  message: string;
}
export interface IWarning {
  message: string;
}
export interface IError {
  message: string;
}
