import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionsComponent, AccessName } from './permissions.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PermissionsRoutingModule } from './permissions-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { ValidationService } from '../services/validation.service';
import { PolicyNewComponent } from './policy-new/policy-new.component';
import { RbacService, RBAC_API_BASE_URL } from '../services/rbac.service';
import { environment } from '../../environments/environment';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    PermissionsRoutingModule,
    FormsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CommonUiSharedModule,
    NgxMaskModule,
    NgxMatSelectSearchModule,
    MatTooltipModule
  ],
  declarations: [PermissionsComponent, PolicyNewComponent, AccessName],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ValidationService,
    RbacService,
    { provide: RBAC_API_BASE_URL, useValue: environment.rbacBaseUrl }
  ]
})
export class PermissionsModule { }
