import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-load-empty',
  templateUrl: './empty-load-empty.component.html',
  styleUrls: ['./empty-load-empty.component.scss']
})
export class EmptyLoadEmptyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
