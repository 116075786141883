import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { SplitFactory } from '@splitsoftware/splitio';
import { MsalService } from '@azure/msal-angular';


@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private factory: SplitIO.ISDK;
  private client: any;
  private ready: Promise<void>;

  constructor(authService: MsalService) {
    const userName = authService.getAccount() == null ? null : authService.getAccount().userName;

    this.factory = SplitFactory({
      core: {
        authorizationKey: environment.splitIOApiKey,
        key: userName,
      },
      startup: {
        readyTimeout: 1.5,
      }
    });
    this.client = this.factory.client();
    this.ready = new Promise((resolve, reject) => {
      this.client.on(this.client.Event.SDK_READY, () => {
        resolve();
      });
      this.client.on(this.client.Event.SDK_READY_TIMED_OUT, () => {
        reject('SDK timed out.');
      });
    });
  }

  private async getTreatmentForFeature(featureName: string): Promise<string> {
    try {
      await this.ready;
      return this.client.getTreatment(featureName);
    } catch (err) {
      console.error(`An error occured getting treatment for: ${featureName}. ${err}`);
      return 'control';
    }
  }

  public get rsrd2TabEnabled(): Promise<string> {
    return this.getTreatmentForFeature('rsrd2_tab');
  }

  public get railsightTabEnabled(): Promise<string> {
    return this.getTreatmentForFeature('railsight_tab');
  }

  public get welcomeEmailSliderEnabled(): Promise<string> {
    return this.getTreatmentForFeature('send_welcome_email');
  }

  public get permissionTabEnabled(): Promise<string> {
    return this.getTreatmentForFeature('permissions_tab');
  }

  public get workspacesEnabled(): Promise<string> {
    return this.getTreatmentForFeature('workspaces_tab');
  }

  public get emptyLoadEnabled(): Promise<string> {
    return this.getTreatmentForFeature('empty_load_feature');
  }

  public get assetsPaaSEnabled(): Promise<string> {
    return this.getTreatmentForFeature('assets_paas_feature');
  }

  public get shipmentsV2Enabled(): Promise<string> {
    return this.getTreatmentForFeature('shipments_v2_feature');
  }

  public get resetPasswordAllowed(): Promise<string> {
    return this.getTreatmentForFeature('reset_user_password');
  }

  public get assetsPaaSDeviceInstallsEnabled(): Promise<string> {
    return this.getTreatmentForFeature('assets_paas_device_install_feature');
  }

  public get devicesGroups(): Promise<string> {
    return this.getTreatmentForFeature('opsportal_devicegroups_tab');
  }
}
