import { NgModule } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { environment } from '@env/environment';

import { ShipmentsV2RoutingModule } from './shipments-v2-routing.module';
import { TripsPage } from './pages/trips/trips.page';
import { AccountTripsService, AssetsService, SHIPMENTS_V2_API_BASE_URL, TripsService } from '../services/shipments-v2.service';
import { AgGridModule } from 'ag-grid-angular';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { TripDetailsPage } from './pages/trip-details/trip-details.page';
import { TripDetailsComponent } from './components/trip-details/trip-details.component';
import { TripEventDetailsDialogComponent } from './components/trip-event-details-dialog/trip-event-details-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AccountTripDetailsPage } from './pages/account-trip-details/account-trip-details.page';
import { BreadcrumbResolver } from '../services/resolvers/breadcrumb-resolver.service';
import { AccountNameBreadcrumbResolver } from '../services/resolvers/account-name-breadcrumb-resolver.service';
import { TripsComponent } from './components/trips/trips.component';
import { AccountTripsPage } from './pages/account-trips/account-trips.page';
import { AssetDetailsPage } from './pages/asset-details/asset-details.page';
import { GEO_API_BASE_URL, LocationsService } from '../services/geo-v2.service';
import { EventsMapComponent } from './components/events-map/events-map.component';
import { JsonDialogComponent } from './components/json-dialog/json-dialog.component';
import { EventsTableComponent } from './components/events-table/events-table.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    TripsPage,
    TripDetailsPage,
    TripDetailsComponent,
    TripEventDetailsDialogComponent,
    AccountTripDetailsPage,
    TripsComponent,
    AccountTripsPage,
    AssetDetailsPage,
    EventsMapComponent,
    JsonDialogComponent,
    EventsTableComponent
  ],
  imports: [
    CommonModule,
    CommonUiSharedModule,
    SharedModule,
    ShipmentsV2RoutingModule,
    AgGridModule,
    GoogleMapsModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule
  ],
  providers: [
    TripsService,
    AccountTripsService,
    LocationsService,
    AssetsService,
    { provide: GEO_API_BASE_URL, useValue: environment.geoBaseUrl },
    { provide: SHIPMENTS_V2_API_BASE_URL, useValue: environment.shipmentsV2BaseUrl },
    JsonPipe,
    BreadcrumbResolver,
    AccountNameBreadcrumbResolver
  ]
})
export class ShipmentsV2Module { }
