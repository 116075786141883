import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssetListComponent } from './asset-list/asset-list.component';
import { AssetDetailsComponent } from './asset-details/asset-details.component';
import { AssetEventsComponent } from './asset-events/asset-events.component';
import { AccountAssetsListComponent } from './account-assets-list/account-assets-list.component';
import { AccountSelectorComponent } from './account-selector/account-selector.component';
import { AccountNameResolver } from './services/AccountNameResolver';
import { AssetNameResolver } from './services/AssetNameResolver';
import { BreadcrumbResolver } from './services/BreadcrumbResolver';
import { AccountAssetDetailsComponent } from './account-asset-details/account-asset-details.component';

// doing this because using a resolver on the route appears to override all of the rest of the breakcrumbs in the heirarchy
const withBreadcrumb = (name: string) => {
  return {
    data: { breadcrumb: name },
    resolve: { breadcrumb: BreadcrumbResolver }
  }
}

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'assets' },

    {
      path: 'accounts',
      data: { breadcrumb: 'Accounts' },
      children: [
        { path: '', component: AccountSelectorComponent },
        {
          path: ':accountId',
          resolve: { breadcrumb: AccountNameResolver, accountName: AccountNameResolver },
          children: [
            { path: '', pathMatch: 'full', redirectTo: 'assets' },
            {
              path: 'assets', ...withBreadcrumb('Assets'),
              children: [
                { path: '', component: AccountAssetsListComponent },
                { path: ':assetId', component: AccountAssetDetailsComponent, resolve: { breadcrumb: AssetNameResolver } },
              ]
            },
          ]
        },
      ]
    },

    {
      path: 'assets',
      data: { breadcrumb: 'Assets' },
      children: [
        {
          path: '',
          component: AssetListComponent,
        },
        {
          path: ':assetId',
          data: { breadcrumb: 'Asset' },
          resolve: { breadcrumb: AssetNameResolver },
          children: [
            { path: '', component: AssetDetailsComponent },
            { path: 'events', component: AssetEventsComponent, ...withBreadcrumb('Event Stream') },
          ]
        },
      ]
    },

  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AssetsPaaSRoutingModule { }
