 <mat-form-field class="example-chip-list">
  <mat-chip-list #chipList aria-label="Tag selection">
    <mat-chip
      *ngFor="let tag of selectedTags"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(tag)">
      {{ tag.tagName }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input 
      placeholder=""
      #tagInput
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"/>
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag.tagId">
      {{ tag.tagName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field> 
<!-- input and output values for each type -->
{{ TagType }}
{{ Id }}
