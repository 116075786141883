import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { DashboardModule } from './dashboard/dashboard.module';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UsersModule } from './users/users.module';
import { SimsModule } from './sims/sims.module';
import { AssetsModule } from './assets/assets.module';
import { AccountsModule } from './accounts/accounts.module';
import { PermissionsModule } from './permissions/permissions.module';
import { AuditingModule } from './auditing/auditing.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { CommonUiSharedModule } from './shared/common-ui-shared/common-ui-shared.module';
import { BreadcrumbModule } from 'angular-crumbs';
import { NgxMaskModule } from 'ngx-mask';
import { Logger } from 'msal';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { ShipmentsModule } from './shipments/shipments.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import 'hammerjs';
import { LocationsModule } from './locations/locations.module';
import { EmptyLoadModule } from './empty-load/empty-load.module';
import { AssetsPaaSModule } from './assets-paas/assets-paas.module';
import { AssetsSharingModule } from './assets-sharing/assets-sharing.module';
import { ShipmentsV2Module } from './shipments-v2/shipments-v2.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

export const protectedResourceMap: [string, string[]][] = [
  [environment.auditingBaseUrl, [environment.userImpersonationScope]],
  [environment.devicesBaseUrl, [environment.userImpersonationScope]],
  [environment.assetsBaseUrl, [environment.userImpersonationScope]],
  [environment.assetsV3BaseUrl, [environment.userImpersonationScope]],
  [environment.accountsBaseUrl, [environment.userImpersonationScope]],
  [environment.usersBaseUrl, [environment.userImpersonationScope]],
  [environment.rbacBaseUrl, [environment.userImpersonationScope]],
  [environment.rsrd2BaseUrl, [environment.userImpersonationScope]],
  [environment.railsightBaseUrl, [environment.userImpersonationScope]],
  [environment.geoBaseUrl, [environment.userImpersonationScope]],
  [environment.temperatureBaseUrl, [environment.userImpersonationScope]],
  [environment.emptyLoadBaseUrl, [environment.userImpersonationScope]],
  [environment.shipmentsV2BaseUrl, [environment.userImpersonationScope]]
];

@NgModule({
  declarations: [AppComponent, NavComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    DashboardModule,
    UsersModule,
    SimsModule,
    AssetsModule,
    AssetsPaaSModule,
    AssetsSharingModule,
    PermissionsModule,
    AccountsModule,
    AuditingModule,
    BreadcrumbModule,
    CommonUiSharedModule,
    ShipmentsModule,
    ShipmentsV2Module,
    LocationsModule,
    EmptyLoadModule,
    MsalModule.forRoot(
      {
        auth: {
          clientId: environment.clientId,
          authority: environment.authority,
          validateAuthority: true,
          redirectUri: environment.redirectUri,
          postLogoutRedirectUri: environment.redirectUri,
          navigateToLoginRequestUrl: true
        },
        cache: {
          cacheLocation: 'localStorage'
        }
      },
      {
        popUp: false,
        consentScopes: [environment.userImpersonationScope],
        unprotectedResources: undefined,
        protectedResourceMap
      }
    ),
    NgHttpLoaderModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxMatMomentModule,
    NgxJsonViewerModule
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule {}
