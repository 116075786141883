
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimsComponent } from './sims.component';
import { SimsRoutingModule } from './sims-routing.module';
import { FormsModule } from '@angular/forms';
import { TagsModule } from '../tags/tags.module';
import { DEVICES_API_BASE_URL } from '../services/devices.service';
import { environment } from '@env/environment';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';

@NgModule({
  imports: [
    CommonModule,
    SimsRoutingModule,
    CommonUiSharedModule,
    FormsModule,
    TagsModule,
  ],
  declarations: [SimsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: DEVICES_API_BASE_URL, useValue: environment.devicesBaseUrl }
  ]
})
export class SimsModule { }
