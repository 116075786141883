import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './users.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserNewComponent } from './user-new/user-new.component';
import { UserEditComponent } from './user-edit/user-edit.component';

const routes: Routes = [
  {
    path: '',
    component: UsersComponent,
    data: { breadcrumb: 'User'}
  },
  {
    path: 'add',
    component: UserNewComponent,
    data: { breadcrumb: 'Add'}
  },
  {
    path: 'accounts/:accountId/users/edit/:id',
    component: UserEditComponent,
    data: { breadcrumb: 'Edit'}
  },
  {
    path: 'accounts/:accountId/users/:id',
    component: UserDetailsComponent,
    data: { breadcrumb: 'Details'}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule { }
