<mat-card>
  <mat-card-title>
    Create Policy
  </mat-card-title>
  <form (ngSubmit)="onSubmit()">
    <ng-container [formGroup]="newPolicyForm">
      <mat-card-content>
        <ng-container>
          <ads-account-select-search id='usr-new-account' [control]="newPolicyForm.get('accountControl')" ngDefaultControl
                                     formControlName="accountControl" [allowEmptySelection]="false" [starOption]="true" placeholder="Account">
          </ads-account-select-search>

          <mat-form-field>
            <input id='policy-new-workspace' matInput formControlName="workspaceControl" maxlength="100" placeholder="Workspace" prefilled="All" />
          </mat-form-field>

          <mat-form-field>
            <mat-select id='policy-new-access-select' disableOptionCentering formControlName="accessControl"
                        placeholder="Assign Access To" required panelClass="disable-outer-scroll" #assignAccess>
              <mat-option id='policy-new-access-search'>
                <ngx-mat-select-search [formControl]="accessSearchControl" placeholderLabel="search"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngIf="users?.length < 1 && groups?.length < 1" disabled>No users or groups match the search.</mat-option>
              <div class="scroll-groups-users">
                <div class="floatLeft">
                  <table mat-table [dataSource]="dataSourceGroups" class="user-component-table border-right" *ngIf="dataSourceGroups?.data?.length > 0">
                    <ng-container matColumnDef="groupNames">
                      <th mat-header-cell *matHeaderCellDef class="table-header">Security Groups</th>
                      <td mat-cell *matCellDef="let group">
                        <mat-option [value]="group">
                          {{group.groupName}}
                          <span class="account-color">[{{group.accountName}}]</span>
                        </mat-option>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="groupHeaders; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: groupHeaders;">
                    </tr>
                  </table>
                </div>
                <div class="floatRight">
                  <table mat-table [dataSource]="dataSourceUsers" class="user-component-table" *ngIf="dataSourceUsers?.data?.length > 0">
                    <ng-container matColumnDef="userDisplay">
                      <th mat-header-cell *matHeaderCellDef class="table-header">Users</th>
                      <td mat-cell *matCellDef="let user">
                        <mat-option [value]="user">
                          {{user.displayName}} 
                          <span class="account-color">({{user.emailAddress}}) </span>
                          <span class="account-color">[{{user.accountName}}]</span>
                        </mat-option>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="userHeaders; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: userHeaders;">
                    </tr>
                  </table>
                </div>
              </div>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <input id='policy-new-resource' matInput formControlName="resourceControl" maxlength="100" placeholder="Resource" required />
          </mat-form-field>

          <mat-form-field>
            <mat-select id='policy-new-role' disableOptionCentering formControlName="roleControl" placeholder="Role" required>
              <mat-option [value]="'contributor'">Contributor</mat-option>
              <mat-option [value]="'owner'">Owner</mat-option>
              <mat-option [value]="'reader'">Reader</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </mat-card-content>
      <mat-card-actions align="end">
        <button id='policy-new-cancelbutton' type="button" mat-stroked-button adsBackbutton>
          Cancel
        </button>
        <button id='policy-new-createbutton' mat-flat-button color="primary" [disabled]="newPolicyForm.invalid" type="submit">
          Create
        </button>
      </mat-card-actions>
    </ng-container>
  </form>
</mat-card>
