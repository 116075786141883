<mat-card *ngIf="account">
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    {{ userGroup.userGroupId == null ? 'New' : 'Edit' }} User Group
    <div *ngIf="userGroup.userGroupId != null">
      <button id="ug-editstatusbutton" mat-button color="warn" (click)="onDeleteUserGroupClick()">Delete User Group</button>
    </div>
  </mat-card-title>
  <form (ngSubmit)="onSubmit()">
    <ng-container [formGroup]="userGroupForm">
      <mat-card-content>
        <mat-form-field>
          <mat-label class="form-field-label">User Group Name</mat-label>
          <input #userGroupName name="userGroupName" id='ug-usergroupname' matInput formControlName="userGroupName" maxlength="100" class="w-25-pct" required>
          <ads-control-messages [control]="userGroupForm.get('userGroupName')"></ads-control-messages>
        </mat-form-field>
      </mat-card-content>
      <hr>
      <div class="card-layout m-t-35">
        <mat-card class="medium-card">
          <h2>Available Users</h2>
          <input name="userSearchText" matInput (keyup)="searchTriggered($event.target.value)"
                 id="availableUsersSearchInput" placeholder="Search for users" />
          <div class="mat-table-scroll-vertical">
            <table class="mat-table-nohover" mat-table [dataSource]="availableUsersData" matSort class="m-t-20"
                   *ngIf="!availableUsersEmptyData">
              <ng-container matColumnDef="displayName">
                <th class="table-header" mat-header-cell *matHeaderCellDef>User Name</th>
                <td mat-cell *matCellDef="let user">{{ user.displayName }}</td>
              </ng-container>

              <ng-container matColumnDef="emailAddress">
                <th class="table-header" mat-header-cell *matHeaderCellDef>Email Address</th>
                <td mat-cell *matCellDef="let user">{{ user.emailAddress }}</td>
              </ng-container>

              <ng-container matColumnDef="features">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Features</th>
                <td mat-cell *matCellDef="let user">{{ user.features }}</td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th class="table-header" mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let user">
                  <button id='ug-adduserbutton' mat-icon-button class="mat-icon-button green" (click)="addUser(user)"><mat-icon>add_box</mat-icon></button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="availableUsersColumnsToDisplay; sticky: true"></tr>
              <tr mat-row *matRowDef="let user; columns: availableUsersColumnsToDisplay" [hidden]="user.IsActive">
              </tr>
            </table>
          </div>
          <div *ngIf="availableUsersEmptyData">
            <label class="sm-note">{{ availableUsersEmptyMessage }}</label>
          </div>
        </mat-card>
        <mat-card class="medium-card">
          <h2>Users In Group</h2>
          <mat-card-content class="table-container">
            <table mat-table matSort class="m-t-20 assetActive-table" [dataSource]="groupUsersData"
                   *ngIf="usersInGroup !== undefined && usersInGroup.length > 0">
              <ng-container matColumnDef="displayName">
                <th class="table-header asset-column" mat-header-cell *matHeaderCellDef>User Name</th>
                <td class="asset-column" mat-cell *matCellDef="let user">{{ user.displayName }}</td>
              </ng-container>
              <ng-container matColumnDef="emailAddress">
                <th class="table-header asset-column" mat-header-cell *matHeaderCellDef>Email Address</th>
                <td class="asset-column" mat-cell *matCellDef="let user">{{ user.emailAddress }}</td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th class="table-header" mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let user">
                  <button id='ug-removeuserbutton' mat-icon-button class="mat-icon-button red" (click)="removeUser(user)"><mat-icon>clear</mat-icon></button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="addedUserColumnsToDisplay"></tr>
              <tr mat-row *matRowDef="let row; columns: addedUserColumnsToDisplay"></tr>
            </table>
          </mat-card-content>
          <div *ngIf="usersInGroup !== undefined && usersInGroup.length === 0">
            <label class="sm-note">No users in this group</label>
          </div>
        </mat-card>
      </div>
      <mat-card-actions align="end">
        <button id='ag-cancelbutton' type="button" mat-stroked-button (click)="navigateToAccount()">Cancel</button>
        <button id='ag-savebutton' mat-flat-button color="primary" type="submit">{{ userGroup.userGroupId == null ? 'Create' : 'Update' }}</button>
      </mat-card-actions>
    </ng-container>
  </form>
</mat-card>
