<mat-card>
  <mat-card-title id="af-title">
    Create Account
  </mat-card-title>
  <form (ngSubmit)="onSubmit()">
    <ng-container [formGroup]="accountForm">
      <mat-card-content>
        <mat-form-field>
          <mat-label class="form-field-label">Account Name</mat-label>
          <input id="af-accountname" matInput formControlName="accountName" maxlength="100" required>
          <ads-control-messages [control]="accountForm.get('accountName')"></ads-control-messages>
        </mat-form-field>
        <mat-form-field>
          <mat-label class="form-field-label">SAP Account Number</mat-label>
          <input id="af-sapaccountnumber" matInput type="text" formControlName="sapAccountNumber" mask="099999999" [specialCharacters]="[]"
            placeholder="Enter an account number between 1 and 999999999" required>
          <ads-control-messages [control]="accountForm.get('sapAccountNumber')"></ads-control-messages>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions align="end">
        <button id="af-cancelbutton" type="button" mat-stroked-button adsBackbutton>Cancel</button>
        <button id="af-createbutton" mat-flat-button color="primary" type="submit">Create</button>
      </mat-card-actions>
    </ng-container>
  </form>
</mat-card>
