import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MapService } from '../../common/services/map.service';
import { EmitterService } from '../../common/services/emitter.service';
import { Popup } from './Popup';

// eslint-disable-next-line no-var
declare var google: any;

let infoBubble;

  @Component({
    selector: 'app-map-line',
    templateUrl: './map-line.component.html',
    styleUrls: ['./map-line.component.scss'],
    encapsulation: ViewEncapsulation.None,
  })
  export class MapLineComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('lineMap', { static: true }) lineMap;
    // subscription
    filterSubscription: Subscription;
    filterClearSubscription: Subscription;
    resizeSubscription: Subscription;
  
    // map
    data: any = [];
    map: any;
    markers: google.maps.Marker[] = [];
    infoBubbles: google.maps.InfoWindow[] = [];
  
    public lines: google.maps.Polyline[] = [];
    bounds: any;
    lastValidCenter: any;
    resetZoom = true;
    resizeMapOnLoad = false; 
    resultTripPath = null;

    mapOptions = {
      clickableIcons: false,
      minZoom: 3, // 1000km
      maxZoom: 15, // 200m
      scaleControl: true,
      streetViewControl: false,
      center: { lat: 40, lng: -40 },
      mapTypeControl: false,
      disableDefaultUI: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'satellite'],
      },
      mapTypeId: 'roadmap',
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      gestureHandling: 'greedy',
      styles: [
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.country',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.locality',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.province',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
      ],
    };
  
    selectedPane = 'roadmap';
    @Input() events = [];
    @Input() InOverlay = false;
    @Input() selectedOverlay = null;
  
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() resize: EventEmitter<any> = new EventEmitter(false);
  
    mapLoaded = false;
    lock = false;
  
    constructor(
      private mapService: MapService,
      private emitterService: EmitterService,  
    ) {
      infoBubble = new google.maps.InfoWindow();
  
      this.resizeMapOnLoad = true;
      // load markers when moving away from global filter screen
      this.filterSubscription = this.emitterService.filterToggle$
        .pipe(
          tap((res) => {
            if (res === false) {
              this.mapOverlays();
            }
          })
        )
        .subscribe();
  
      this.resizeSubscription = this.emitterService.resizeTrigger$
        .pipe(
          tap((x) => {
            setTimeout(() => {
              this.mapService.closeInfowindow(infoBubble);
              this.reloadMap();
              this.emitterService.emitOnMapMarkerDeselected();
              if (
                this.lines !== undefined &&
                this.lines.length > 0 &&
                this.events.length > 0
              ) {
                this.lines.forEach((element) => {
                  const tripPathArray = element.getPath().getArray();
                  tripPathArray.forEach((coord) => {
                    this.bounds.extend(coord);
                  });
                });
                this.map.fitBounds(this.bounds);
                this.map.panToBounds(this.bounds);
              }
            }, 400);
          })
        )
        .subscribe();
  
      this.filterClearSubscription = this.emitterService.filterTrigger$
        .pipe(
          tap((x) => {
            
              setTimeout(() => {
                this.mapService.closeInfowindow(infoBubble);
                google.maps.event.trigger(this.map, 'resize');
                if (this.bounds && this.events.length > 0) {
                  this.map.fitBounds(this.bounds);
                  this.map.panToBounds(this.bounds);
                }
              }, 20);
            
          })
        )
        .subscribe();
    }
  
    ngOnInit() {
      this.map = new google.maps.Map(
        this.lineMap.nativeElement,
        this.mapOptions,
        '4504f8b37365c3d0'
      );

      this.mapService.setStyle(this.map, '#c0f2f2');
  
      this.map.data &&
        this.map.data.addListener &&
        this.map.data.addListener('click', (event) => {
          console.log()
          // close info window, if displayed
          if (infoBubble.anchor) {
            this.emitterService.emitOnMapMarkerDeselected();
            this.mapService.closeInfowindow(infoBubble);
          }
          let coordinates = { lat: event.latLng.lat(), lng: event.latLng.lng() };
  
          let dataLayer;
          let name = [];
          let geofenceNames = '';
          this.map.data.toGeoJson((json: any) => {
            dataLayer = json;
            dataLayer.features.forEach((element) => {
              let latlng = [];
              element.geometry.coordinates[0].forEach((element: any[]) => {
                latlng.push({ lat: element[1], lng: element[0] });
              });
              let coord = new google.maps.LatLng(
                event.latLng.lat(),
                event.latLng.lng()
              );
              let polygon = new google.maps.Polygon({ paths: latlng });
              google.maps.geometry.poly.containsLocation(coord, polygon)
                ? name.push(element.properties['name'])
                : '';
            });
            if (name.length > 1) {
              name.forEach((element) => {
                geofenceNames =
                  geofenceNames !== ''
                    ? geofenceNames + '</br>' + element
                    : element;
              });
            } else {
              geofenceNames = event.feature.getProperty('name');
            }
            // display the info window on geo-fence
            const contentString =
              '<div id="info-window" > <br>' +
              '<p class ="infoPara"><span><strong>' +
              geofenceNames +
              '</span>' +
              '</div>';
            if (
              infoBubble.position !== coordinates &&
              infoBubble.content !== contentString
            ) {
              infoBubble.setContent(contentString);
              // open info window at the coordinates of click inside of geo-fence
              infoBubble.setPosition(coordinates);
              infoBubble.open(this.map);
              this.emitterService.emitOnMapMarkerDeselected();
            } else {
              this.mapService.closeInfowindow(infoBubble);
            }
          });
        });
  
      if (google && google.maps) {
        this.mapOverlays();
      }
  
      this.lastValidCenter = this.map.getCenter();
      google.maps.event.addListener(this.map, 'center_changed', (evt) =>
        this.limitMapBounds()
      );
      google.maps.event.addListener(this.map, 'zoom_changed', (evt) =>
        this.limitMapZoom()
      );
      google.maps.event.addListener(this.map, 'click', (evt) =>
        this.emitMapClickEvent()
      );
      google.maps.event.addListener(infoBubble, 'domready', (evt) =>
        this.mapService.showInfoWindow()
      );
    }
  
    // limit panning on zoom
    limitMapZoom() {
      this.selectedPane = this.map.getMapTypeId();
      let bounds = this.map.getBounds();
      let mapZoomLevel = this.map.zoom;
      if (bounds && this.resetZoom) {
        let sLat = bounds.getSouthWest().lat();
        let nLat = bounds.getNorthEast().lat();
        if (sLat < -85 || nLat > 85) {
          this.map.panTo(this.lastValidCenter);
          this.resetZoom = false;
          this.map.setZoom(mapZoomLevel + 1);
        }
      } else {
        this.resetZoom = true;
      }
    }
  
    // limit panning on dragging
    limitMapBounds() {
      let bounds = this.map.getBounds();
      if (bounds) {
        let sLat = bounds.getSouthWest().lat();
        let nLat = bounds.getNorthEast().lat();
        if (sLat < -85 || nLat > 85) {
          this.map.panTo(this.lastValidCenter);
        } else {
          this.lastValidCenter = this.map.getCenter();
        }
      }
    }
  
    ngOnChanges(changes: SimpleChanges) {
      this.removeMarkers();
      if (this.map) {
        this.map.setOptions(this.mapOptions);
        this.changeMapType('roadmap');
      }
      if (this.events?.length) {
        if (this.events?.length > 0) {
          this.bounds = [];
          this.resizeMapOnLoad = false;
          this.mapOverlays(true);
        } else {
          if (this.map) {
            this.bounds = new google.maps.LatLngBounds();
    
            this.mapLoaded = true;
          }
          this.mapOverlays();
        }
      } else {
       
        this.mapOverlays(true);
      }
  
    }
  
    // function for Markers Overlay, Click and Clustering
    mapOverlays(load = false) {
      if (!this.map) return;
  
      this.mapLoaded = true;
      this.data = [];
      if (this.lines.length > 0) {
        this.lines.forEach((element) => {
          element.setMap(null);
        });
      }
      this.removePops();
      this.bounds = new google.maps.LatLngBounds();
      this.lines = [];
      if (this.events !== undefined && this.events.length > 0) {
    
  
        this.data = this.mapService.groupEvents(this.events);
  
        let firstPin = this.data[0].CompletedLine[0];
        // NOTE: inbound pin is first, array is reversed
        if (this.selectedOverlay && this.selectedOverlay === 'ETA') {
          let firstMarker = new google.maps.Marker({
            position: firstPin,
            map: this.map,
            icon: 'assets/img/Map_Line_Pin_Red.svg',
          });
          this.markers.push(firstMarker);
          this.mapOverlayDetails(false, firstPin, firstMarker);
  
          let lastPin =
            this.data[0].CompletedLine[this.data[0].CompletedLine.length - 1];
          let lastMarker = new google.maps.Marker({
            position: lastPin,
            map: this.map,
            icon: 'assets/img/Map_Line_Pin_Green.svg',
          });
          this.markers.push(lastMarker);
          this.mapOverlayDetails(true, lastPin, lastMarker);
        }
        for (const location of this.data) {
          if (location.position.lat !== null && location.position.lng !== null) {
            let StartLatitude: any;
            let StartLongitude: any;
            let StopLatitude: any;
            let StopLongitude: any;
            if (
              location.StartLatitude !== null &&
              location.StartLongitude !== null &&
              location.StopLatitude !== null &&
              location.StopLongitude !== null
            ) {
              StartLatitude = location.StartLatitude;
              StartLongitude = location.StartLongitude;
              StopLatitude = location.StopLatitude;
              StopLongitude = location.StopLongitude;
            }
  
            if (!this.InOverlay) {
              this.resultTripPath = new google.maps.Polyline({
                path: [
                  { lat: StartLatitude, lng: StartLongitude },
                  { lat: StopLatitude, lng: StopLongitude },
                ],
                geodesic: true,
                strokeColor: '#38BEC4',
                strokeOpacity: 1.0,
                strokeWeight: 2,
              });
            } else {
              if (location.IncompleteLine) {
                this.resultTripPath = new google.maps.Polyline({
                  path: location.IncompleteLine,
                  strokeOpacity: 0,
                  geodesic: true,
                  icons: [
                    {
                      icon: {
                        path: 'M 0,-1 0,1',
                        strokeOpacity: 1,
                        scale: 4,
                      },
                      offset: '0',
                      repeat: '20px',
                    },
                  ],
                });
              } else {
                this.resultTripPath = new google.maps.Polyline({
                  path: location.CompletedLine,
                  geodesic: true,
                  strokeColor: '#000',
                  strokeOpacity: 1.0,
                  strokeWeight: 4,
                });
  
                // TODO: review and improve this code, add types
                const getGeoLabel = location.CompletedLine.filter(
                  (geo) => geo.name
                );
  
                if (getGeoLabel.length) {
                  getGeoLabel.forEach((geoInfo, index: number) => {
                    if (geoInfo.name) {
                      const popup = new Popup(
                        new google.maps.LatLng(geoInfo.lat, geoInfo.lng),
                        geoInfo.name,
                        geoInfo.date
                      );
                      popup.setMap(this.map);
                    }
                  });
                }
              }
            }
  
            this.resultTripPath.setMap(null);
  
            let tripPathArray = [];
            tripPathArray = this.resultTripPath.getPath().getArray();
            tripPathArray.forEach((element) => {
              this.bounds.extend(element);
            });
  
            // this.markers.push(marker);
            this.lines.push(this.resultTripPath);
  
            this.resultTripPath.setMap(this.map);
          }
        }
        if (this.resizeMapOnLoad) {
          google.maps.event.trigger(this.map, 'resize');
        }
        this.emitterService.emitOnMapMarkerDeselected();
        this.map.fitBounds(this.bounds);
        this.map.panToBounds(this.bounds);
      } else {
        if (this.resultTripPath) this.resultTripPath.setMap(null);
        this.map.setZoom(3);
      }
  
      google.maps.event &&
        google.maps.event.trigger &&
        google.maps.event.trigger(this.map, 'resize');
      if (load) {
        this.mapLoaded = true;
      }
    }
  
    mapOverlayDetails(outbound: boolean, location: any, marker: any) {
      if (!location.city || !location.date || !marker) {
        return;
      }

      let content =
        '<div class="detail-dialog" > <br>' +
        '<div class="detail-dialog-header">' +
        '<div class="detail-dialog-icon" ">' +
        (outbound
          ? '<img src="assets/img/arrow-outbound.png"/>'  
          : '<img src="assets/img/arrow-inbound.png"/>') +
        '</div>' +
        '<div class="detail-dialog-title">' +
        '<strong>' +
        location.city +
        '</strong>' +
        '</div>' +
        '</div>' +
        '<p class="detail-dialog-date">' +
        location.date +
        '</p>' +
        '</span>' +
        '</div>';
  
      const infoBubble = new google.maps.InfoWindow();
  
      infoBubble.setContent(content);
  
      infoBubble.setPosition(location);
      infoBubble.open(this.map, location);
      marker.infowindow = infoBubble;
      google.maps.event.addListener(marker, 'click', function () {
        if (
          location.lat === marker.position.lat() &&
          location.lng === marker.position.lng()
        ) {
          infoBubble.setContent(content);
          infoBubble.setPosition(marker.position);
          infoBubble.open(this.map, marker.position);
        }
      });
  
      google.maps.event.addListener(this.map, 'click', () => {
        infoBubble.close();
      });
    }
  
    removeMarkers(): void {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];
    }
  
    removePops() {
      const prevPops = document.querySelectorAll('.mapLabel');
      if (!prevPops.length) return;
      prevPops.forEach((pop) => {
        pop.classList.add('hide');
        pop.innerHTML = '';
      });
    }
  
  
    emitMapClickEvent() {
      this.mapService.closeInfowindow(infoBubble);
      this.emitterService.emitOnMapMarkerDeselected();
    }
  
    // maximize the map on click of maximize button
    maximizeMap() {
      this.emitMapClickEvent();
      this.resize.emit(this.map);
      this.emitterService.emitOnSplitterDimensionResized();
      setTimeout(function () {
        this.google.maps.event.trigger(this.map, 'resize');
      }, 50);
    }
  
    returnBounds() {
      if (
        this.lines !== undefined &&
        this.lines.length > 0 &&
        this.events.length > 0
      ) {
       
        this.lines.forEach((element) => {
          const tripPathArray = element.getPath().getArray();
          tripPathArray.forEach((coord) => {
            this.bounds.extend(coord);
          });
        });
        this.map.fitBounds(this.bounds);
        this.map.panToBounds(this.bounds);
      }
    }
  
    reloadMap() {
      google.maps.event.trigger(this.map, 'resize');
    }
  
    changeMapType(input: string, lock: boolean = false) {
      this.selectedPane = input;
      if (input === 'satellite') {
        this.map.setMapTypeId(google.maps.MapTypeId.HYBRID);
      } else {
        this.map.setMapTypeId(input);
      }
      if (lock) {
        this.lock = true;
      }
    }
  
    chooseWeight(days: number) {
      if (days <= 0) {
        return 0;
      } else {
        return days * 24;
      }
    }
    
    // destroy the subscribed emitter after every successful subscribe
    ngOnDestroy() {
      this.removeMarkers();
      this.emitterService.unsubscribeFromSubscription(this.filterSubscription);
      this.emitterService.unsubscribeFromSubscription(this.filterClearSubscription);
      this.emitterService.unsubscribeFromSubscription(this.resizeSubscription);
    }
  
  }
  