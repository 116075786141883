<button id="jd-close=button" mat-icon-button class="raw-message-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<ng-container *ngIf="selectedRowAssetId > 0">
  <h1 mat-dialog-title>Empty-Load Edit</h1>
  <mat-dialog-content class="edit-load-dialog">
    <mat-label class="empty-load-form-label">Asset Id:</mat-label><br>
    <input maxlength="25" class="mat-input-element ng-tns-c134-1" disabled [(ngModel)]="assetConfigData.assetId">
    <br><br>

    <mat-label class="empty-load-form-label">Visible:</mat-label>
    <mat-select [(ngModel)]="assetConfigData.visible">
      <mat-option value="true">Yes</mat-option>
      <mat-option value="false">No</mat-option>
    </mat-select>
    <br><br>
    <mat-label class="empty-load-form-label">Min Threshold:</mat-label>
    <br>
    <input id="minThresholdInput" maxlength="25" class="mat-input-element ng-tns-c134-1"
      [(ngModel)]="assetConfigData.wsn415_MinimumThreasholdOverride">
    <br><br>
    <mat-label class="empty-load-form-label">Flip Determination:</mat-label>
    <mat-select [(ngModel)]="assetConfigData.wsn415_FlipDeterminationOverride">
      <mat-option value="true">Yes</mat-option>
      <mat-option value="false">No</mat-option>
      <mat-option value="">Null</mat-option>
    </mat-select>
    <br><br>
    <mat-label class="empty-load-form-label">Splitpoint Ratio:</mat-label><br>
    <input id="SplitpointRationInput" maxlength="25" class="mat-input-element ng-tns-c134-1"
    [(ngModel)]="assetConfigData.wsn415_SplitpointRatioOverride">
    <br><br>

    <div class=updateClosebuttons>
      <button _ngcontent-eti-c348="" id="emptyLoadupdatebutton" mat-flat-button="" color="primary" type="submit"
        class="mat-focus-indicator mat-flat-button mat-button-base mat-primary" ng-reflect-color="primary"
        (click)="editEmptyLoad()"><span class="mat-button-wrapper">Update</span><span matripple=""
          class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false"
          ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>

      <button _ngcontent-vos-c357="" id="emptyLoadcancelbutton" type="button" mat-stroked-button="" adsbackbutton=""
        class="mat-focus-indicator mat-stroked-button mat-button-base" [mat-dialog-close]="true"><span
          class="mat-button-wrapper">Cancel</span><span matripple="" class="mat-ripple mat-button-ripple"
          ng-reflect-disabled="false" ng-reflect-centered="false"
          ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button>
    </div>
  </mat-dialog-content>
</ng-container>
