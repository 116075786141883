import { NgModule } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { environment } from '@env/environment';
import { AgGridModule } from 'ag-grid-angular';
import { AssetsSharingRoutingModule } from './assets-sharing-routing.module';
import { AssetsV3Service, AccountWorkspaceAssetsV3Service, AccountWorkspaceAssetsDeviceV3Service, AssetClassesV3Service, AssetTypesV3Service, ASSETS_V3_API_BASE_URL } from '../services/assets-v3.service';
import { AssetsSharingComponent } from './assets-sharing.component';
import { SharedModule } from '../shared/shared.module';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { AssetBulkShareDialogComponent } from './asset-bulk-share-dialog-component/asset-bulk-share-dialog.component';

@NgModule({
  declarations: [
    AssetsSharingComponent,
    AssetBulkShareDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    AssetsSharingRoutingModule,
    MatDialogModule,
    MatCheckboxModule,
    MatMenuModule,
    CommonUiSharedModule
  ],
  providers: [
    AssetsV3Service,
    AccountWorkspaceAssetsV3Service,
    AssetClassesV3Service,
    AssetTypesV3Service,
    AccountWorkspaceAssetsDeviceV3Service,
    { provide: ASSETS_V3_API_BASE_URL, useValue: environment.assetsV3BaseUrl },
    JsonPipe
  ]
})
export class AssetsSharingModule { }
