import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyLoadEmptyComponent } from './empty-load-empty/empty-load-empty.component';
import { EmptyLoadConfigListComponent } from './empty-load-config-list/empty-load-config-list.component';
import { EmptyLoadComponent } from './empty-load.component';

const routes: Routes = [
  {
    path: '',
    component: EmptyLoadComponent,
    children: [
      { path: 'asset-configs', component: EmptyLoadConfigListComponent },
      { path: 'other1', component: EmptyLoadEmptyComponent },
      { path: 'other2', component: EmptyLoadEmptyComponent },
      { path: '', pathMatch: 'full', redirectTo: 'asset-configs' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmptyLoadRoutingModule { }
