import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { NewPolicy, RbacService } from '../../services/rbac.service';
import { AppUserDtoPagedListDto, UserGroupResponse, UserGroupsService, UsersService } from '../../services/users.service';

@Component({
  selector: 'ads-app-policy-new',
  templateUrl: './policy-new.component.html',
  styleUrls: ['./policy-new.component.scss'],
})

export class PolicyNewComponent implements OnInit, OnDestroy {
  _onDestroy = new Subject<void>();
  accessSearchControl: FormControl = new FormControl();
  dataSourceGroups: MatTableDataSource<UserGroupResponse>;
  dataSourceUsers: MatTableDataSource<AppUserDtoPagedListDto>;
  groupHeaders = ['groupNames'];
  groups: UserGroupResponse[];
  newPolicyForm: FormGroup;
  searchAccess: Subject<string> = new Subject();
  selection = new SelectionModel<any>(true, []);
  userHeaders = ['userDisplay'];
  users: AppUserDtoPagedListDto[];

  @ViewChild('assignAccess') assignAccess;

  constructor(private fb: FormBuilder,
    private rbacService: RbacService, private router: Router,
    private toasterService: ToasterNotificationService,
    private userGroupsService: UserGroupsService,
    private userService: UsersService) {
  }

  ngOnInit() {
    this.newPolicyForm = this.fb.group({
      accountControl: ['', Validators.required],
      workspaceControl: [''],
      accessControl: ['', Validators.required],
      resourceControl: ['', Validators.required],
      roleControl: ['', Validators.required]
    });

    // TODO: Remove default value all and disable when able to add workspace value.
    this.newPolicyForm.get('workspaceControl').setValue('All');
    this.newPolicyForm.get('workspaceControl').disable();

    this.filterUsers();
    this.filterGroups();

    this.accessSearchControl.valueChanges.pipe(debounceTime(500))
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.assignAccess._panelOpen) {
          this.filterUsers();
          this.filterGroups();
        }
      });
  }

  searchAccessTriggered(filterValue: any) {
    this.searchAccess.next(filterValue);
  }

  filterUsers() {
    const search = this.accessSearchControl.value;

    if (search === '' || search === undefined || search === null) {
      this.userService.getUsers().subscribe((users: AppUserDtoPagedListDto) => {
        users.items = users.items.filter(user => user.accountId);
        this.setupUsers(users);
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
    }
    else {
      this.userService.getUsers(search.toLowerCase()).subscribe((users: AppUserDtoPagedListDto) => {
        this.setupUsers(users);
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
    }
  }

  setupUsers(users) {
    this.users = this.alphabetizeList(users.items, 'displayName');
    this.dataSourceUsers = new MatTableDataSource(this.users);
  }

  filterGroups() {
    const search = this.accessSearchControl.value;

    if (search === '' || search === undefined || search === null) {
      this.userGroupsService.userGroups().subscribe((groups: UserGroupResponse[]) => {
        this.setupGroups(groups);
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
    }
    else {
      this.userGroupsService.userGroups(undefined, search.toLowerCase()).subscribe((groups: UserGroupResponse[]) => {
        this.setupGroups(groups);
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
    }
  }

  setupGroups(groups) {
    this.groups = this.alphabetizeList(groups, 'groupName');
    this.dataSourceGroups = new MatTableDataSource(this.groups);
  }

  alphabetizeList(unsortedList, property) {
    return unsortedList.sort((a, b) => a[property].localeCompare(b[property]));
  }

  onSubmit() {
    const resourceBuilder = '/accounts/' + this.newPolicyForm.controls.accountControl.value +
      '/workspaces/' + this.newPolicyForm.controls.workspaceControl.value +
      '/' + this.newPolicyForm.controls.resourceControl.value;

    let access = '';
    if (this.newPolicyForm.controls.accessControl.value instanceof UserGroupResponse) {
      access = 'group:' + this.newPolicyForm.controls.accessControl.value.accountId + ':'
        + this.newPolicyForm.controls.accessControl.value.userGroupId;
    }
    else {
      access = 'user:' + this.newPolicyForm.controls.accessControl.value.accountId  + ':'
        + this.newPolicyForm.controls.accessControl.value.issuer + this.newPolicyForm.controls.accessControl.value.userId;
    }

    const newPolicy: NewPolicy = {
      subject: access.toLowerCase(),
      resource: resourceBuilder.toLowerCase(),
      role: this.newPolicyForm.controls.roleControl.value.toLowerCase(),
    };

    this.rbacService.createPolicy(newPolicy).subscribe(() => {
      this.router.navigate([`/policies`]);
      this.toasterService.showInfoToaster('Policy has been successfully created.');
    },
      error => {
        this.toasterService.showWarnToaster(error.detail);
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
