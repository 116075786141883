import { AfterViewInit, ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { WorkspacesService, WorkspaceDto } from '../../../../../services/accounts.service';
import { DevicesService, DeviceGroupsService, Status, DeviceSearchDtoPagedListDto, DeviceGroupResponseResultSet } from '../../../../../services/devices.service';
import { AssetsService, AssetGroupsService, AssetDtoAssetPagedListDto, AssetGroupDtoPagedListDto } from '../../../../../services/assets.service';
import { MatDialog } from '@angular/material/dialog';
import { PermissionsDialogComponent } from '../../../../../shared/dialog/permissions-dialog/permissions-dialog.component';
import { BreadcrumbService } from 'angular-crumbs';
import { T } from '@angular/cdk/keycodes';

@Component({
  selector: 'ads-app-workspace-view',
  templateUrl: './workspace-view.component.html',
  styleUrls: ['./workspace-view.component.scss']
})
export class WorkspaceViewComponent implements OnInit, AfterViewInit {
  accountId: number;
  workspaceId: string;
  workspace: WorkspaceDto = new WorkspaceDto();
  deviceCount: number;
  deviceGroupCount: number;
  assetCount: number;
  assetGroupCount: number;

  constructor(private activatedRouter: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterNotificationService,
    private workspacesService: WorkspacesService,
    private devicesService: DevicesService,
    private deviceGroupsService: DeviceGroupsService,
    private assetsService: AssetsService,
    private assetGroupsService: AssetGroupsService,
    private dialog: MatDialog,
    private breadCrumbService: BreadcrumbService) {
      this.route.parent.url.subscribe((urlPath) => {
        let accountId = 0;
        if (urlPath.length > 1) {
          accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
        }

        if (isNaN(accountId)) {
          this.router.navigateByUrl('[/accounts]');
        }

        this.accountId = accountId;

      });
      this.route.params.subscribe(param => {
        this.workspaceId = param.workspaceId;
      });
    }

  ngOnInit() {
    this.breadCrumbService.changeBreadcrumb(this.route.snapshot, this.workspaceId);
    this.loadWorkspaceDetails();
  }

  ngAfterViewInit() {
  }

  loadWorkspaceDetails() {
    this.workspacesService.workspace(this.accountId, this.workspaceId).subscribe(ws => {
      this.workspace = ws;
    });

    this.devicesService.devices(0, 1, undefined, Status.All, this.accountId, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.workspaceId).subscribe((resp: DeviceSearchDtoPagedListDto) => {
      this.deviceCount = resp.total;
    });

    this.deviceGroupsService.deviceGroups(this.accountId, this.workspaceId).subscribe((resp: DeviceGroupResponseResultSet) => {
      this.deviceGroupCount = resp.count;
    });

    this.assetsService.assets(this.accountId, this.workspaceId, undefined, undefined, undefined, 1).subscribe((resp: AssetDtoAssetPagedListDto) => {
      this.assetCount = resp.total;
    });

    this.assetGroupsService.assetGroups(this.accountId, this.workspaceId, undefined, 1).subscribe((resp: AssetGroupDtoPagedListDto) => {
      this.assetGroupCount = resp.total;
    });
  }


  manageDevicePermissions() {
    var resourcePath = `/accounts/${this.accountId}/workspaces/${this.workspaceId}/**`;
    const dialogRef = this.dialog.open(PermissionsDialogComponent, {
      data: {
        resourceType: 'Workspace',
        resourcePath: resourcePath
      }
    });
  }
}
