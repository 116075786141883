import { Component, OnInit, Input } from '@angular/core';
import { AdsAccountDto, AccountsService } from '../../services/accounts.service';

@Component({
  selector: 'ads-account-name',
  templateUrl: './account-name.component.html',
  styleUrls: ['./account-name.component.scss']
})
export class AccountNameComponent implements OnInit {
  @Input() accountId: string;

  public account: AdsAccountDto;

  constructor(private accountsService: AccountsService) {}

  ngOnInit() {
    const accountId = Number.parseInt(this.accountId, 10);

    // tslint:disable-next-line: no-shadowed-variable
    this.accountsService.account(accountId).subscribe((d: AdsAccountDto) => {
      this.account = d;
    });
  }
}
