<button id="jd-close=button" mat-icon-button class="raw-message-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title>Uninstall Device</h1>
<mat-dialog-content class="asset-uninstall-device-dialog">
	<form (ngSubmit)="onSubmit()">
    <ng-container>
      <mat-card-content>
        <mat-form-field>
          <mat-label>Installed Devices</mat-label>
          <mat-select disableOptionCentering required #singleSelect [(value)]="selectedDevice" >
            <ng-container *ngFor="let device of installedDevices">
              <mat-option [value]="device.serialNumber">
                {{device.serialNumber}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions align="end">
        <button id='ad-cancelbutton' type="button" [mat-dialog-close]="true">Cancel</button>
        <button id='ad-updatebutton' mat-flat-button color="primary" type="submit">Uninstall</button>
      </mat-card-actions>
    </ng-container>
  </form>
</mat-dialog-content>


