import { Component, OnInit, AfterViewInit, Renderer2, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AssetsService, AssetDto } from '../services/assets.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ToasterNotificationService } from '../services/toasterNotification.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'ads-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit, AfterViewInit {
  assetsDataSource: MatTableDataSource<{}>;
  columnsToDisplay = ['assetName', 'unitName', 'unitNumber', 'assetTypeName', 'assetGroupings', 'ownerAccount', 'operatorAccount'];
  search: Subject<string> = new Subject();
  assetData = new MatTableDataSource();
  baseMessage = 'To see assets please begin typing an assets name or number';
  searchMessage = this.baseMessage;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private assetsService: AssetsService, private router: Router,
    private toasterService: ToasterNotificationService, private renderer: Renderer2,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.search.pipe(debounceTime(500)).subscribe(searchTextValue => {
      this.searchAssets(searchTextValue);
    });
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#ad-searchtext');
    element.focus();
    this.cdr.detectChanges();
  }

  searchAssets(filterValue: string) {
    this.searchMessage = this.baseMessage;
    if (filterValue !== '') {
      this.assetsService.getAssets(filterValue).subscribe(resp => {
        const assetData = resp.items.map((u: AssetDto) => ({ ...u }));
        this.assetData = new MatTableDataSource(assetData);
        this.assetData.sort = this.sort;
        if (this.assetData.filteredData.length === 0) {
          this.searchMessage = 'No results match search term ' + filterValue;
        }
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
    }
  }

  onRowClick(asset: AssetDto) {
    this.router.navigate([`/assets/${asset.assetId}`]);
  }

  searchTriggered(filterValue: any) {
    if (filterValue === '') {
      this.assetData.data = [];
    }

    this.search.next(filterValue);
  }
}
