import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ToasterNotificationService } from '../../../../services/toasterNotification.service';
import { ConfirmationDialogComponent } from '../../../../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatSort } from '@angular/material/sort';
import { ShareToAccountDialogComponent } from './share-to-account-dialog-component/share-to-account-dialog.component'
import { AccountShare, AccountWorkspacesV3Service } from '../../../../services/assets-v3.service';
import { AccountsService } from '../../../../services/accounts.service';
import { AccountWorkspaceAssetsV3Service } from '../../../../services/assets-v3.service';

@Component({
  selector: 'ads-app-account-sharing-tab',
  templateUrl: './account-sharing-tab.component.html',
  styleUrls: ['./account-sharing-tab.component.scss']
})
export class AccountSharingTabComponent implements OnInit, AfterViewInit {
  accountId: number;
  accountName: string;
  dataSource = new MatTableDataSource();
  emptyMessage = 'There are no accounts authorized to receive sharing from this account. Please click Add Account to add an account.';
  headers: string[];
  search: Subject<string> = new Subject();
  accountShare: AccountShare;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private toasterService: ToasterNotificationService,
    private router: Router,
    private accountsService: AccountsService,
    private accountWorkspacesAssetsV3Service: AccountWorkspaceAssetsV3Service,
    private accountWorkspacesV3Service: AccountWorkspacesV3Service) {
    this.headers = ['id', 'displayName', 'deleted'];

    this.route.parent.url.subscribe((urlPath) => {
      let accountId = 0;
      if (urlPath.length > 1) {
        accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
      }

      if (isNaN(accountId)) {
        this.router.navigateByUrl('[/accounts]');
      }

      this.accountId = accountId;
    });
  }

  ngOnInit(): void {
    this.accountsService.account(this.accountId).subscribe(account => {
      this.accountName = account.accountName
    });
    this.getSharedToAccounts();
  }

  ngAfterViewInit() {
    this.getSharedToAccounts();
  }

  getSharedToAccounts() {
    this.accountWorkspacesV3Service.getSharedToAccounts(this.accountId).subscribe(accountShare => {
      this.accountShare = accountShare;
      this.getSharedToAccountData();
    });
  }

  getSharedToAccountData() {
    var count = 0;
    var accountData = [];
    this.accountShare.sharedMappings.forEach(mapping => {
      this.accountsService.account(mapping.accountId).subscribe(account => {
        this.accountWorkspacesAssetsV3Service.getAccountAssetsBySharedFromAccount(mapping.accountId,
          "all", this.accountId).subscribe(assets => {
            accountData.push({"accountId": account.accountId, "accountName": account.accountName, "sharedAssets": assets.count})
            count++;
            if(count == this.accountShare.sharedMappings.length) {
              this.dataSource = new MatTableDataSource(accountData);
              this.dataSource.sort = this.sort;
            }
          });
      });
    });
  }

  shareToAccount() {
    var dialogRef = this.dialog.open(ShareToAccountDialogComponent, {
      data: {
        sourceAccountId: this.accountId,
        sourceAccountName: this.accountName
      }
    });

    dialogRef.afterClosed().subscribe((success) => {
      //We reload the page so the sharing tab count is updated
      if (success) { window.location.reload(); }
    });
  }

  removeAccountShare(account: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Remove Sharing Authorization',
        message: 'Removing authorization from ' + this.accountName + ' will stop any new data from being shared with ' + account.accountName + '.  Any data being re-shared by ' + account.accountName + ' will also be terminated.  This action cannot be reversed once "Remove Authorization" is selected',
        buttonText: {
          ok: 'Remove Authorization',
          cancel: 'Cancel'
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.accountWorkspacesV3Service.removeAccountFromSharing(this.accountId, account.accountId, "Ops Portal")
          .subscribe({
            next: (resp: any) => {
              this.toasterService.showInfoToaster('Account successfully removed.');
              window.location.reload();
            },
            error: (err) => {
              this.toasterService.showWarnToaster(err.detail);
            }
          });
      }
    });
  }
}
