import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AccountsService, AdsAccountDto } from 'src/app/services/accounts.service';
import { AccountAssetResponse, AssetResponse } from 'src/app/services/assets-v3.service';
import { RailSightService } from 'src/app/services/railsight.service';
import { AccountTripEventModel, AccountTripModel, TripEventModel, TripModel } from 'src/app/services/shipments-v2.service';
import { createTripEventViewModels } from '../../TripEventViewModelFactory';
import { TripEventViewModel } from '../../types';

interface IHasLocation {
  latitude: number,
  longitude: number
}

type TripEventBase = TripEventModel | AccountTripEventModel;
type TripEventBaseWithLocation = TripEventBase & IHasLocation;

@Component({
  selector: 'trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.scss']
})
export class TripDetailsComponent implements OnInit {
  @Input() trip: AccountTripModel | TripModel;
  @Input() asset: AccountAssetResponse | AssetResponse | null;
  account: AdsAccountDto;
  selectedTab: string = "Origin";
  events: TripEventViewModel[];

  constructor(
    private railsightService: RailSightService,
    private accountService: AccountsService
  ) { }

  ngOnInit(): void {
    this.events = createTripEventViewModels(this.asset, this.trip.events, [ this.trip ], this.railsightService);

    this.accountService.account(this.trip.accountId)
      .subscribe(a => {
        this.account = a;
      });
  }
}
