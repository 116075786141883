import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AccountsService, AdsAccountDto, InstallerDto, InstallersService } from 'src/app/services/accounts.service';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
// tslint:disable-next-line: max-line-length
import { InstallerAppAccountsService, InstallerState, InstallerUserDto } from 'src/app/services/users.service';
import { ConfirmationDialogComponent } from 'src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { CurrentAccountService } from '../../account-view.component';

const DEFAULT_BULK_CREATE_QUANTITY = 5;
const MAX_COMPANY_CODE_LENGTH = 18;
const MIN_COMPANY_CODE_LENGTH = 6;

@Component({
  selector: 'ads-account-installers-tab',
  templateUrl: './account-installers-tab.component.html',
  styleUrls: ['./account-installers-tab.component.scss']
})
export class AccountInstallersTabComponent implements OnInit {

  private dialogRef: MatDialogRef<DialogSelectQuantityComponent, any>;

  public emptyData = false;
  public isLoading = false;
  public errorLoading = false;
  public filterState = InstallerState.ReadyForActivation;

  account: AdsAccountDto;

  private columnMap = {
    ReadyForActivation: [
      'companyName',
      'companyCode',
      'activationCode',
      'createdOn',
      'chips',
      'actions'
    ],
    InUse: [
      'companyName',
      'companyCode',
      'activationCode',
      'createdOn',
      'lastLoggedIn',
      'chips',
      'actions'
    ],
    Disabled: [
      'companyName',
      'companyCode',
      'activationCode',
      'createdOn',
      'chips'
    ],
    '': [
      'companyName',
      'companyCode',
      'activationCode',
      'createdOn',
      'chips',
      'actions'
    ]
  };

  columnsToDisplay: string[] = this.columnMap[this.filterState];
  userData: MatTableDataSource<{}>;

  constructor(
    private dialog: MatDialog,
    private installerAppAccountsService: InstallerAppAccountsService,
    private toasterService: ToasterNotificationService,
    private currentAccountService: CurrentAccountService,
  ) {
    this.account = currentAccountService.account;
  }

  ngOnInit() {
    this.loadUserData();
  }

  public retry() {
    this.errorLoading = false;
    this.loadUserData();
  }

  public chooseNumberOfActivations() {
    const matches = this.account.accountName.match(/[0-9a-zA-Z]+/g);

    // Grab from the matches until we hit the max length
    let defaultCode = '';
    let i = 0;
    while (defaultCode.length < MAX_COMPANY_CODE_LENGTH && i < matches.length) {
      const currentMatch = matches[i];
      if (currentMatch.length >= (MAX_COMPANY_CODE_LENGTH - defaultCode.length)) {
        defaultCode = defaultCode + currentMatch.substring(0, MAX_COMPANY_CODE_LENGTH - defaultCode.length);
      } else {
        defaultCode = defaultCode + currentMatch;
      }
      i++;
    }

    if (defaultCode.length < MIN_COMPANY_CODE_LENGTH) {
      const appendCode = 'INSTAL';
      defaultCode = defaultCode + appendCode.substring(0, MIN_COMPANY_CODE_LENGTH - defaultCode.length);
    }

    this.dialogRef = this.dialog.open(DialogSelectQuantityComponent, {
      data: new DialogSelectQuantityComponentData(this.account.accountName, defaultCode.toUpperCase(), DEFAULT_BULK_CREATE_QUANTITY)
    });

    this.dialogRef.afterClosed().subscribe(n => {
      if (!n) {
        return;
      }

      this.installerAppAccountsService.createInstallers(
        this.currentAccountService.account.accountId, n.amountToCreate, n.companyName, n.companyCode,
        n.sapCustomerId, n.address1, n.address2, n.city, n.region, n.country, n.postalCode)
        .subscribe((r) => {
          this.filterState = InstallerState.ReadyForActivation;
          this.loadUserData();
        },
          (err) => {
            console.error(err);
          });
    });
  }

  public activeChanged(input) {
    this.filterState = input;
    this.loadUserData();
  }

  public disable(installer: InstallerUserDto) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure you want to disable this installer account?',
        message:
          `This will disable access for the ADS Installer App associated with ${installer.activationCode} for ${installer.companyName}.

           Please note it will take up to 1 hour to completely disable access.`,
        confirmColor: 'warn',
        buttonText: {
          ok: 'Disable',
          cancel: 'Cancel'
        }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.installerAppAccountsService.disableInstaller(installer.accountId, installer.userId).subscribe(() => {
          this.filterState = InstallerState.Disabled;
          this.loadUserData();
        });
      }
    });
  }

  private loadUserData() {
    this.isLoading = true;
    this.columnsToDisplay = this.columnMap[this.filterState];

    this.installerAppAccountsService.listInstallers(
      this.currentAccountService.account.accountId,
      this.filterState).subscribe(
        (resp: InstallerUserDto[]) => {
          const data = resp.map(x => x as InstallerUserDto);
          this.userData = new MatTableDataSource(data);
          this.emptyData = resp.length === 0;

          this.isLoading = false;
          this.errorLoading = false;
        },
        error => {
          this.isLoading = false;
          this.errorLoading = true;
          this.toasterService.showWarnToaster(error.detail);
        });
  }
}

class DialogSelectQuantityComponentData {
  constructor(
    public companyName: string,
    public companyCode: string,
    public amountToCreate: number
  ) { }
}

@Component({
  selector: 'ads-dialog-select-quantity',
  templateUrl: 'dialog-select-quantity.html',
  styleUrls: ['dialog-select-quantity.scss']
})
export class DialogSelectQuantityComponent {

  form: FormGroup;
  maxCodeLength = MAX_COMPANY_CODE_LENGTH;
  minCodeLength = MIN_COMPANY_CODE_LENGTH;

  companyName: any;
  companyCode: any;
  sapCustomerId: any;
  address1: any;
  address2: any;
  city: any;
  region: any;
  country: any;
  postalCode: any;
  installerData: InstallerDto[] = [];

  private search: Subject<InstallerDto> = new Subject();

  constructor(public dialogRef: MatDialogRef<DialogSelectQuantityComponent>,
    private accountsService: AccountsService,
    private installerAppsService: InstallerAppAccountsService,
    private installersService: InstallersService,
    private toasterService: ToasterNotificationService,
    private renderer: Renderer2,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogSelectQuantityComponentData) {
    this.form = fb.group({
      companyName: ['', Validators.required],
      companyCode: ['', [
        Validators.required,
        Validators.minLength(MIN_COMPANY_CODE_LENGTH),
        Validators.maxLength(MAX_COMPANY_CODE_LENGTH)
      ]],
      address1: [''],
      address2: [''],
      city: [''],
      region: [''],
      country: [''],
      postalCode: [''],
      sapCustomerId: ['', [Validators.pattern('[0-9]+')]],
      amountToCreate: ['', [Validators.required, Validators.max(10), Validators.min(1)]],
    });
    this.form.patchValue(data);

    this.search.pipe(debounceTime(500)).subscribe(searchTextValue => {
      this.searchInstallers(searchTextValue);
    });
  }

  onSave() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(key => {
        this.form.get(key).markAllAsTouched();
      });
      return;
    }
    this.dialogRef.close(this.form.value);
  }

  searchTriggered(filterValue: any) {
    if (filterValue === '') {
      this.installerData = [];
    }

    this.search.next(filterValue);
  }

  searchInstallers(filterValue: any) {
    if (filterValue !== '') {
      this.installersService.searchInstallers(undefined, filterValue).subscribe((resp: InstallerDto[]) => {
        const installerData = resp.map((i: any) => new InstallerDto({
          companyName: i.companyName,
          companyCode: i.companyCode,
          city: i.city,
          country: i.country,
          customerId: i.customerId,
          houseNo: i.houseNo,
          postal: i.postal,
          region: i.region,
          street: i.street,
          street2: i.street2
        }));
        this.installerData = installerData;
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
    }
  }

  onInstallerRowSelect(installer: InstallerDto) {
    this.form.get('companyName').setValue(installer.companyName);
    this.form.get('companyCode').setValue(installer.companyCode);
    this.form.get('sapCustomerId').setValue(installer.customerId);
    this.form.get('address1').setValue(installer.houseNo + ' ' + installer.street);
    this.form.get('address2').setValue(installer.street2);
    this.form.get('city').setValue(installer.city);
    this.form.get('region').setValue(installer.region);
    this.form.get('country').setValue(installer.country);
    this.form.get('postalCode').setValue(installer.postal);
  }
}
