<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    {{ assetGroup.assetGroupId == null ? 'New' : 'Edit' }} Asset Group
    <div *ngIf="assetGroup.assetGroupId != null">
      <button id="ag-editstatusbutton" mat-button color="warn" (click)="onDeleteAssetGroupClick()">Delete Asset Group</button>
    </div>
  </mat-card-title>
  <form (ngSubmit)="onSubmit()">
    <ng-container [formGroup]="assetGroupForm">
      <mat-card-content>
        <mat-form-field>
          <mat-label class="form-field-label">Asset Group Name</mat-label>
          <input #assetGroupName id='ag-assetgroupname' matInput formControlName="assetGroupName" maxlength="100" class="w-25-pct" required>
          <ads-control-messages [control]="assetGroupForm.get('assetGroupName')"></ads-control-messages>
        </mat-form-field>
        <div *ngIf="this.loaded">
          <ads-asset-workspace-chips #chips groupId="{{this.assetGroup.assetGroupId}}" accountId="{{this.assetGroup.customerId}}"></ads-asset-workspace-chips>
        </div>
      </mat-card-content>
      <hr>
      <div class="card-layout m-t-50" *ngIf="!newAssetGroup">
        <mat-card class="three-card mat-card">
          <h2>Available Assets</h2>
          <input name="assetSearchText" matInput (keyup)="searchTriggered($event.target.value)"
            id="availableAssetsSearchInput" placeholder="Search for assets" />
          <div class="mat-table-scroll-vertical">
            <table class="mat-table-nohover" mat-table [dataSource]="availableAssetsData" matSort class="m-t-20"
              *ngIf="!availableAssetsEmptyData">
              <ng-container matColumnDef="assetName">
                <th class="table-header" mat-header-cell *matHeaderCellDef>Asset Name</th>
                <td mat-cell *matCellDef="let asset">{{ asset.assetName }}</td>
              </ng-container>

              <ng-container matColumnDef="unitNumber">
                <th class="table-header" mat-header-cell *matHeaderCellDef>Unit Number</th>
                <td mat-cell *matCellDef="let asset">{{ asset.unitNumber }}</td>
              </ng-container>

              <ng-container matColumnDef="unitName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Unit Name</th>
                <td mat-cell *matCellDef="let asset">{{ asset.unitName }}</td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th class="table-header" mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let asset">
                  <button id='ag-addassetbutton' mat-icon-button class="mat-icon-button green" (click)="addAsset(asset)">
                    <mat-icon>add_box</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="availableAssetsColumnsToDisplay; sticky: true"></tr>
              <tr mat-row *matRowDef="let asset; columns: availableAssetsColumnsToDisplay" [hidden]="asset.IsActive">
              </tr>
            </table>
          </div>
          <div *ngIf="availableAssetsEmptyData">
            <label class="sm-note">{{ availableAssetsEmptyMessage }}</label>
          </div>
        </mat-card>
        <mat-card class="three-card mat-card">
          <h2>Assets In Group</h2>
          <input name="assetGroupSearchText" matInput (keyup)="assetGroupSearch($event.target.value)"
                 id="assetGroupsSearchInput" placeholder="Search for asset groups" />
          <mat-card-content class="table-container">
            <table mat-table matSort class="m-t-20 assetActive-table" [dataSource]="groupAssetsData"
                   *ngIf="assetGroup.assets !== undefined && assetGroup.assets.length > 0">
              <ng-container matColumnDef="assetName">
                <th class="table-header asset-column" mat-header-cell *matHeaderCellDef>Asset Name</th>
                <td class="asset-column" mat-cell *matCellDef="let asset">{{ asset.assetName }}</td>
              </ng-container>
              <ng-container matColumnDef="unitNumber">
                <th class="table-header asset-column" mat-header-cell *matHeaderCellDef>Unit Number</th>
                <td class="asset-column" mat-cell *matCellDef="let asset">{{ asset.unitNumber }}</td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th class="table-header" mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let asset" class="table-actions">
                  <button id='ag-removeassetbutton' mat-icon-button class="mat-icon-button red" (click)="removeAsset(asset)">
                    <mat-icon>clear</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="addedAssetColumnsToDisplay; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: addedAssetColumnsToDisplay"></tr>
            </table>
            </mat-card-content>
            <div *ngIf="assetGroup.assets !== undefined && assetGroup.assets.length === 0">
              <label class="sm-note">No assets in this group</label>
            </div>
        </mat-card>
        <mat-card class="three-card mat-card">
          <h2>Allowed User Groups</h2>
          <input type="text" id="ag-allowedUserGroupSearch" placeholder="Select User Groups" aria-label="Number"
                 matInput [matAutocomplete]="auto" (keyup)="filterAvailableUserGroups($event.target.value)" />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredAvailableUserGroups" [value]="option.groupId" (click)="addAllowedUserGroup(option)">
              {{ option.groupName }} [{{ option.usersCount }}]
            </mat-option>
          </mat-autocomplete>
          <mat-card-content class="table-container">
            <table mat-table matSort class="m-t-20" [dataSource]="allowedUserGroupsData" *ngIf="allowedUserGroups !== undefined && allowedUserGroups.length > 0">
              <ng-container matColumnDef="userGroupName">
                <th class="table-header allowedUserGroup-column" mat-header-cell *matHeaderCellDef>User Group Name</th>
                <td class="allowedUserGroup-column" mat-cell *matCellDef="let allowedUserGroup">
                  {{ allowedUserGroup.groupName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="userGroupUsers">
                <th class="table-header allowedUserGroup-column" mat-header-cell *matHeaderCellDef>Users</th>
                <td class="allowedUserGroup-column" mat-cell *matCellDef="let allowedUserGroup">
                  {{ allowedUserGroup.usersCount }}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th class="table-header" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let allowedUserGroup" class="table-actions">
                  <button id='ag-removeusergroupbutton' mat-icon-button class="mat-icon-button red" (click)="removeAllowedUserGroup(allowedUserGroup)">
                    <mat-icon>clear</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="allowedUserGroupsColumnsToDisplay; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: allowedUserGroupsColumnsToDisplay"></tr>
            </table>
            </mat-card-content>
            <div *ngIf="allowedUserGroups === undefined || allowedUserGroups.length === 0">
              <label class="sm-note">No user groups are currently allowed to access this asset group.</label>
            </div>
        </mat-card>
      </div>
      <mat-card-actions align="end">
        <button id='ag-cancelbutton' type="button" mat-stroked-button (click)="navigateToAccount()">Cancel</button>
        <button id='ag-savebutton' mat-flat-button color="primary" type="submit">{{ assetGroup.assetGroupId == null ? 'Create' : 'Update' }}</button>
      </mat-card-actions>
    </ng-container>
  </form>
</mat-card>
