<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    {{workspace.displayName}} Workspace
    <div>
      <button id='dd-managedevicepermissionsbutton' mat-button color="primary" (click)="manageDevicePermissions()">Manage Permissions </button>
    </div>
  </mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <dl>
      <dt>Id</dt>
      <dd>{{workspace.id}}</dd>
      <dt>Display Name</dt>
      <dd>{{workspace.displayName}}</dd>
      <dt>Devices</dt>
      <dd>
        <a id="devices"  [routerLink]="['../../account-devices']" [queryParams]="{workspace: workspace.id}">
          {{deviceCount}}
        </a>
      </dd>
      <dt>Device Groups</dt>
      <dd>{{deviceGroupCount}}</dd>
      <dt>Assets</dt>
      <dd>
        <a id="devices"  [routerLink]="['../../account-assets']" [queryParams]="{workspace: workspace.id}">
          {{assetCount}}
        </a>
      </dd>
      <dt>Asset Groups</dt>
      <dd>{{assetGroupCount}}</dd>
    </dl>
  </mat-card-content>
</mat-card>
