<div class="my-3">
  <mat-card>
    <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
      Audit History
    </mat-card-title>
    <mat-card-subtitle>
      View audit logs
    </mat-card-subtitle>
    <mat-divider></mat-divider>
    <mat-card-content>
      <ads-account-select-search #accountDrop></ads-account-select-search>
      <mat-form-field>
        <mat-select id='as-resourcedropdown' [(value)]="selectedResourceType" (selectionChange)="onResourceSelectionChanged($event)" placeholder="Resource Type" disableOptionCentering #singleSelect>
          <ng-container *ngFor="let resource of availableResourceTypes | async">
              <mat-option *ngIf="resource != 'healthcheck'" id='op-{{resource}}'  [value]="resource">
                {{resource}}
              </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select id='as-eventdropdown' [(value)]="selectedEventType" (selectionChange)="onEventTypeSelectionChanged($event)" placeholder="Event Type" disableOptionCentering #singleSelect>
          <ng-container *ngFor="let eventType of availableEventTypes | async">
              <mat-option id='op-{{eventType}}'  [value]="eventType">
                {{eventType}}
              </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <ads-audit-table accountId="{{accountId}}" eventType="{{selectedEventType}}" resourceType="{{selectedResourceType}}"></ads-audit-table>
    </mat-card-content>
  </mat-card>
</div>
