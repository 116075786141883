import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AccountsService, AdsAccountDto } from '../../services/accounts.service';
import { ToasterNotificationService } from '../../services/toasterNotification.service';

@Component({
  selector: 'ads-account-select-search',
  templateUrl: './account-select-search.component.html',
  styleUrls: ['./account-select-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountSelectSearchComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AccountSelectSearchComponent),
      multi: true
    }
  ]
})

export class AccountSelectSearchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @Input() allowEmptySelection: boolean;
  @Input() control: any;
  @Input() isRequired = true;
  @Input() placeholder: string;

  // Adds * as an Account option. Returns value string * and not an accountId number.
  @Input() starOption: boolean;

  // Empty option will display as All. Returns empty accountID.
  @Input() allOption: boolean;

  //Filter the select list with a set of account ids
  @Input() prefilteredAccounts: number[];

  protected accounts: AdsAccountDto[];
  protected onDestroy = new Subject<void>();

  accountSearchControl: FormControl = new FormControl();
  filteredAccounts: ReplaySubject<AdsAccountDto[]> = new ReplaySubject<AdsAccountDto[]>(1);
  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(private accountsService: AccountsService, private toasterService: ToasterNotificationService) { }

  ngOnInit() {
    this.filterAccounts();

    // listen for search field value changes
    this.accountSearchControl.valueChanges.pipe(debounceTime(500))
      .pipe(takeUntil(this.onDestroy))
      .subscribe((resp) => {
        this.filterAccounts(resp);
      });
  }

  onSelectionChanged(event: MatSelectChange) {
    this.onChange(event.value);
    this.onTouched();
  }

  writeValue(obj: any): void {
    if (obj) {
      this.singleSelect.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  validate({ accountId }: AdsAccountDto) {
    if (!this.allowEmptySelection && accountId === 0) {
      return false;
    }
    return accountId > 0;
  }

  protected filterAccounts(searchValue?: string) {
    if (searchValue === '' || searchValue === undefined || searchValue === null) {
      this.accountsService.searchAccounts().subscribe(accounts => {
        if(this.prefilteredAccounts)
        {
          accounts = accounts.filter(x => this.prefilteredAccounts.includes(x.accountId));
        }
        this.setupAccounts(accounts);
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
    }
    else {
      this.accountsService.searchAccounts(searchValue.toLowerCase()).subscribe(accounts => {
        if(this.prefilteredAccounts)
        {
          accounts = accounts.filter(x => this.prefilteredAccounts.includes(x.accountId));
        }
        this.setupAccounts(accounts);
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
    }
  }

  setupAccounts(accounts) {
    const sortedResults = this.alphabetizeList(accounts, 'accountName');

    const emptyAccount = new AdsAccountDto();
    emptyAccount.isNotEnabled = this.allowEmptySelection === undefined ? true : this.allowEmptySelection;

    if (this.allOption) {
      emptyAccount.accountName = 'All';
      emptyAccount.accountId = 0;

      if (!this.singleSelect.value) {
        this.singleSelect.value = 0;
      }
    }

    this.accounts = [
      emptyAccount,
      ...sortedResults
    ];

    if (this.starOption) {
      const starAccount = new AdsAccountDto();
      starAccount.accountName = '*';

      this.accounts.splice(1, 0, starAccount);
    }

    this.filteredAccounts.next(this.accounts.slice());
  }

  alphabetizeList(unsortedList, property) {
    return unsortedList.sort((a, b) => a[property].localeCompare(b[property]));
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
