import { Injectable } from '@angular/core';
@Injectable()
export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: 'This field is required.',
      invalidEmailAddress: 'Invalid email address',
      invalidRequiredField: 'This field is required',
      minlength: `Must be at least ${validatorValue.requiredLength} characters`,
      maxlength: `Must be at most ${validatorValue.requiredLength} characters`,
      min: `Value must be at least ${validatorValue.min}`,
      max: `Value must be at most ${validatorValue.max}`,
      requiredTrue: 'This must be enabled',
      email: 'Invalid email address',
      pattern: 'This is not a valid input',
    };

    return config[validatorName];
  }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    // tslint:disable-next-line: max-line-length
    if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }
}
