import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountNameBreadcrumbResolver } from '../services/resolvers/account-name-breadcrumb-resolver.service';
import { withBreadcrumb } from '../services/resolvers/breadcrumb-resolver.service';
import { AccountTripDetailsPage } from './pages/account-trip-details/account-trip-details.page';
import { TripsPage } from './pages/trips/trips.page';
import { TripDetailsPage } from './pages/trip-details/trip-details.page';
import { AccountTripsPage } from './pages/account-trips/account-trips.page';
import { AssetDetailsPage } from './pages/asset-details/asset-details.page';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'trips'
  },
  {
    path: 'assets',
    data: { breadcrumb: 'Assets' },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'assets' },
      { path: ':assetId', data: { breadcrumb: 'Details' } , component: AssetDetailsPage },
    ]
  },
  {
    path: 'trips',
    data: { breadcrumb: 'Active Trips' },
    children: [
      { path: '', pathMatch: 'full', component: TripsPage },
      { path: ':tripId', component: TripDetailsPage, ...withBreadcrumb('Trip Details') },
    ]
  },
  {
    path: 'accounts',
    data: { breadcrumb: 'Accounts' },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'trips' },
      {
        path: ':accountId',
        resolve: { breadcrumb: AccountNameBreadcrumbResolver },
        children: [
          {
            path: 'trips',
            ...withBreadcrumb('Trips'),
            children: [
              { path: '', pathMatch: 'full', component: AccountTripsPage },
              { path: ':tripId', component: AccountTripDetailsPage, ...withBreadcrumb('Trip Details') },
            ]
          },
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShipmentsV2RoutingModule { }
