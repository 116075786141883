<div>
  <mat-card>
    <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
      Asset Details
    </mat-card-title>
    <mat-card-subtitle *ngIf="ready">
      <div *ngIf="asset">{{ asset.name }} - {{asset.class}}</div>
      <a *ngIf="!asset" [routerLink]="['/shipments-v2', 'assets', asset.id]">Unknown</a>
    </mat-card-subtitle>

    <mat-card-content *ngIf="ready">
      <events-map [events]="events"></events-map>
    </mat-card-content>

    <mat-card-content *ngIf="ready">
      <events-table [events]="events"></events-table>
    </mat-card-content>
  </mat-card>
</div>
