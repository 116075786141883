import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AccountsService, AdsAccountDto } from '../../../services/accounts.service';
import { FeatureFlagService } from '../../../services/feature-flag.service';
import { ToasterNotificationService } from '../../../services/toasterNotification.service';
import { Policy, NewPolicy, RbacService } from '../../../services/rbac.service';
import { ConfirmationDialogComponent } from '../../dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { AppUserDtoPagedListDto, UserGroupResponse, UserGroupsService, UsersService, AppUserDto } from '../../../services/users.service';
import { Router } from '@angular/router';

@Component({
    selector: 'ads-permissions-dialog',
    templateUrl: './permissions-dialog.component.html',
    styleUrls: ['./permissions-dialog.component.scss']
})
export class PermissionsDialogComponent {
    _onDestroy = new Subject<void>();
    accessSearchControl: FormControl = new FormControl();
    accounts: AdsAccountDto[];
    allGroups: UserGroupResponse[];
    allUsers: AppUserDto[];
    dataSource: MatTableDataSource<Policy>;
    emptyMessage: string;
    groups: UserGroupResponse[];
    headers: string[];
    newPolicyForm: FormGroup;
    resourcePath: '';
    resourceType: '';
    showPolicies = false;
    users: AppUserDtoPagedListDto[];
    userTypeActive = true;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
      private accountsService: AccountsService,
      private fb: FormBuilder,
      private featureFlagService: FeatureFlagService,
      private rbacService: RbacService,
      private router: Router,
      private toasterService: ToasterNotificationService,
      private userGroupsService: UserGroupsService,
      private userService: UsersService,
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) private data: any,
      private dialogRef: MatDialogRef<PermissionsDialogComponent>) {
      this.headers = ['type', 'matchedUserName', 'role', 'update'];
      if (data) {
          this.resourcePath = data.resourcePath || this.resourcePath;
          this.resourceType = data.resourceType || this.resourceType;
      }
    }

    ngOnInit(): void {
      this.setupNewPolicyForm();

      this.featureFlagService.permissionTabEnabled.then(treatment => {
        if (treatment === 'on') {
          this.showPolicies = true;
        }
      });
    }

    setupNewPolicyForm() {
      this.newPolicyForm = this.fb.group({
        roleControl: ['', Validators.required],
        accessControl: ['', Validators.required],
        typeControl: ['', Validators.required]
      });

      this.filterUsers();
      this.filterGroups();

      this.newPolicyForm.get("typeControl").valueChanges.subscribe(x => {
        this.userTypeActive = x == "contributor" ? true : false;
      });

      this.accessSearchControl.valueChanges.pipe(debounceTime(500))
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUsers();
        this.filterGroups();
      });
    }

    getAccounts() {
      this.accountsService.searchAccounts().subscribe(accounts => {
        this.accounts = accounts;
        this.getPoliciesByResource();
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
    }

    getUserEmail(name: string) {
      let email = this.allUsers.find(x => x.displayName === name)?.emailAddress;
      
      return email ? `(${email})` : '';
    }

    getPoliciesByResource() {
      var formattedResourcePath = btoa(this.resourcePath.toLowerCase());
        this.rbacService.getPoliciesThatMatchResource(formattedResourcePath).subscribe(async returnedPolicies => {
          const policies = await this.setupPolicyDisplay(returnedPolicies);
          this.dataSource = new MatTableDataSource(policies);
          this.dataSource.sort = this.sort;
        },
        error => {
          this.getPoliciesError(error);
        });

      if (!this.dataSource?.data || this.dataSource.data.length < 1) {
        this.emptyMessage = 'No policies in selected resource.';
      }
    }

    compareUsers(object1: any, object2: any) {
      return object1 && object2 && object1.userId == object2.userId;
    }

    compareGroups(object1: any, object2: any) {
      return object1 && object2 && object1.userGroupId == object2.userGroupId;
    }

    filterUsers() {
      const search = this.accessSearchControl.value;

      if (search === '' || search === undefined || search === null) {
        

        this.userService.getUsers().subscribe((users: AppUserDtoPagedListDto) => {
          users.items = users.items.filter(user => user.accountId);
          this.users = this.alphabetizeList(users.items, 'displayName');

          if(!this.allUsers) {
            this.allUsers = users.items;
            this.getAccounts();
          }
        },
          error => {
            this.toasterService.showWarnToaster(error.detail);
          });
      }
      else {
        this.userService.getUsers(search.toLowerCase()).subscribe((users: AppUserDtoPagedListDto) => {
          this.users = this.alphabetizeList(users.items, 'displayName');
        },
          error => {
            this.toasterService.showWarnToaster(error.detail);
          });
      }
    }

    filterGroups() {
      const search = this.accessSearchControl.value;

      if (search === '' || search === undefined || search === null) {
        this.userGroupsService.userGroups().subscribe((groups: UserGroupResponse[]) => {
          this.groups = this.alphabetizeList(groups, 'groupName');
          if(!this.allGroups) {
            this.allGroups = groups;
          }
        },
          error => {
            this.toasterService.showWarnToaster(error.detail);
          });
      }
      else {
        this.userGroupsService.userGroups(undefined, search.toLowerCase()).subscribe((groups: UserGroupResponse[]) => {
          this.groups = this.alphabetizeList(groups, 'groupName');
        },
          error => {
            this.toasterService.showWarnToaster(error.detail);
          });
      }
    }

    alphabetizeList(unsortedList, property) {
      return unsortedList.sort((a, b) => a[property].localeCompare(b[property]));
    }

    async setupPolicyDisplay(policies: Policy[]) {
      let formattedPolicies = [];

      for (let i = 0; i < policies.length; i++) {
        const accountName = this.getAccountName(policies[i]);
        const policyType = policies[i].subject.type === 'Security Group' ? 'Group' : 'User';
        const matchedPolicy = this.resourcePath.toLowerCase() == policies[i].path.toLowerCase();

        var userNameSubject = new BehaviorSubject<string>("test");
        var userNameGuid = new BehaviorSubject<string>("");

        this.getPolicyUserName(policyType, policies[i], userNameSubject, userNameGuid);

        let role = policies[i].role.replace('role:', '');
        role = role.charAt(0).toUpperCase() + role.slice(1);       

        formattedPolicies[i] = {
          id: policies[i].id,
          accountName,
          type: policyType,
          role,
          matchedPolicy,
          path: policies[i].path,
          subject: policies[i].subject.raw,
          userNameSubject,
          userNameGuid
        };
      }

      formattedPolicies = formattedPolicies.sort(function(x) { return x.matchedPolicy }).reverse();
      return formattedPolicies;
    }

    getPolicyUserName(policyType, policy, userNameSubject, userNameGuid) {
      if(policyType == 'Group') {
        if(!policy.subject.accountId) {
          userNameSubject.next(policy.subject.id);
        }
        var matchedGroup = this.allGroups.find(group => group.userGroupId.toString() === policy.subject.id);

        userNameSubject.next(matchedGroup ? matchedGroup.groupName : policy.subject.id);
      } else {
        if(!policy.subject.accountId) {
          userNameSubject.next(policy.subject.id);
        }

        var userId = policy.subject.id.split("/").pop();
        this.userService.getUser(policy.subject.accountId, userId).subscribe((user: AppUserDto) => {
          userNameSubject.next(user.displayName);
          userNameGuid.next(userId);
          //If username is an email, display the email only and pass in the user guid as a tooltip
          if(user.displayName.startsWith("https") && user.displayName.includes("com/")){
            var split = user.displayName.split("com/");
            userNameSubject.next(split[0] + "com");
          }
        },
          error => {
            this.toasterService.showWarnToaster(error.detail);
          });
      }
    }

    getAccountName(policy) {
      let accountName = '';

      if (policy.accountId && this.accounts) {
        const accountHolder = this.accounts.filter(account => account.accountId === policy.accountId);
        if (accountHolder?.length > 0) {
          accountName = accountHolder[0].accountName;
        }
      }
      else {
        accountName = '*';
      }

      return accountName;
    }

    getPoliciesError(error) {
      error.status === 403 ? this.toasterService.showWarnToaster('You do not have access to this resource')
        : this.toasterService.showWarnToaster(error.detail);
      if (this.dataSource?.data) {
        this.dataSource.data.length = 0;
      }
    }

    deletePolicy(policy: any) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Are you sure you want to delete this policy?',
          buttonText: {
            ok: 'Delete',
            cancel: 'No'
          }
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          var formattedPolicyPath = btoa(policy.path.toLowerCase());
          this.rbacService.deletePolicyWithParameters(policy.subject, formattedPolicyPath, "role:" + policy.role.toLowerCase())
            .subscribe({
              next: (resp: any) => {
                this.getPoliciesByResource();
                this.toasterService.showInfoToaster('Policy successfully deleted.');
              },
              error: (err) => {
                this.toasterService.showWarnToaster(err.detail);
              }
            });
        }
      });
    }

    openPolicy(policy: any) {
      //TODO: Implement
    }

    onSubmit(e) {
      e.preventDefault();
      let access = '';
      var groupPolicy = this.newPolicyForm.controls.accessControl.value instanceof UserGroupResponse;
      if (groupPolicy) {
        access = 'group:' + this.newPolicyForm.controls.accessControl.value.accountId + ':'
          + this.newPolicyForm.controls.accessControl.value.userGroupId;
      }
      else {
        access = 'user:' + this.newPolicyForm.controls.accessControl.value.accountId  + ':'
          + this.newPolicyForm.controls.accessControl.value.issuer + this.newPolicyForm.controls.accessControl.value.userId;
      }

      const newPolicy: NewPolicy = {
        subject: access.toLowerCase(),
        resource: this.resourcePath.toLowerCase(),
        role: this.newPolicyForm.controls.roleControl.value.toLowerCase(),
      };

      this.rbacService.createPolicy(newPolicy).subscribe(() => {
        this.getPoliciesByResource();
        if(groupPolicy) {
          this.filterGroups();
        } else{
          this.filterUsers();
        }
        this.toasterService.showInfoToaster('Policy has been successfully created.');
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
    }
}
