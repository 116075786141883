// Adapted: https://medium.com/@thunderroid/angular-date-ago-pipe-minutes-hours-days-months-years-ago-c4b5efae5fe5
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {

  transform(value?: Date, defaultValue?: any): any {
    if (value != null) {
      const seconds = Math.floor((+new Date() - +value) / 1000);
      if (seconds < 29) { // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      }

      const intervals = [
        { label: 'year', span: 31536000 },
        { label: 'month', span: 2592000 },
        { label: 'week', span: 604800 },
        { label: 'day', span: 86400 },
        { label: 'hour', span: 3600 },
        { label: 'minute', span: 60 },
        { label: 'second', span: 1 }];

      let counter;
      for (const unit of intervals) {
        counter = Math.floor(seconds / unit.span);
        if (counter > 0) {
          if (counter === 1) {
            return counter + ' ' + unit.label + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + unit.label + 's ago'; // plural (2 days ago)
          }
        }
      }
    } else {
      if (defaultValue) {
        return defaultValue;
      }
    }
    return value;
  }
}
