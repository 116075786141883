<div>
  <mat-card *ngFor="let event of assetEvents" style="margin: 10px;">
    <mat-card-header>
      <mat-card-title>{{event.eventType}}</mat-card-title>
      <mat-card-subtitle>Source: {{event.source}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content style="margin: 0 2em">
      <pre>{{event | json}}</pre>
    </mat-card-content>
  </mat-card>
</div>
