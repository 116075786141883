import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountAssetResponse, AccountWorkspaceAssetsV3Service } from 'src/app/services/assets-v3.service';
import { AccountAssetShareDialogComponent } from '../account-asset-share-dialog-component/account-asset-share-dialog.component';
import { AccountAssetEndShareDialogComponent } from '../account-asset-endshare-dialog-component/account-asset-endshare-dialog.component';
import { AccountAssetInstallDeviceComponent } from '../account-asset-install-device-component/account-asset-install-device.component';
import { AccountAssetUninstallDeviceComponent } from '../account-asset-uninstall-device-component/account-asset-uninstall-device.component';
import { AccountAssetReplaceDeviceComponent } from '../account-asset-replace-device-component/account-asset-replace-device.component';
import { AccountAssetPropertiesDialogComponent } from '../account-asset-properties-dialog-component/account-asset-properties-dialog.component';
import { AccountAssetTypeDialogComponent } from '../account-asset-type-dialog-component/account-asset-type-dialog.component';
import { AccountAssetTraceDialogComponent } from '../account-asset-trace-dialog-component/account-asset-trace-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeatureFlagService } from '../../services/feature-flag.service';

@Component({
  selector: 'app-account-asset-details',
  templateUrl: './account-asset-details.component.html',
  styleUrls: ['./account-asset-details.component.scss']
})
export class AccountAssetDetailsComponent implements OnInit {
  accountId: number;
  assetId: string;
  asset: AccountAssetResponse;
  showDeviceActions = false;

  constructor(
    private route: ActivatedRoute,
    private assetsV3Service: AccountWorkspaceAssetsV3Service,
    private featureFlagService: FeatureFlagService,
    private dialog: MatDialog,
    private snackBar : MatSnackBar,
  ) {
    this.accountId = parseInt(this.route.snapshot.params.accountId);
    this.assetId = this.route.snapshot.params.assetId;
  }

  ngOnInit(): void {
    this.assetsV3Service.getAssetInWorkspace(this.accountId, 'all', this.assetId)
    .subscribe(x => {
      this.asset = x;
    });

    this.featureFlagService.assetsPaaSDeviceInstallsEnabled.then((treatment) => {
      this.showDeviceActions = treatment === 'on';
    });
  }

  onShareAssetClick() {
    var dialogRef = this.dialog.open(AccountAssetShareDialogComponent, {
      data: {
        accountId: this.accountId,
        assetId: this.assetId
      }
    });

    dialogRef.afterClosed().subscribe((success) => {
      if (success) { this.ngOnInit(); }
    });
  }

  onAssetEndShareClick() {
    var dialogRef = this.dialog.open(AccountAssetEndShareDialogComponent, {
      data: {
        accountId: this.accountId,
        assetId: this.assetId,
        sharedToAccountIds: this.asset.sharedToAccountIds
      }
    });

    dialogRef.afterClosed().subscribe((success) => {
      if (success) { this.ngOnInit(); }
    });
  }

  onInstallDeviceClick() {
    var dialogRef = this.dialog.open(AccountAssetInstallDeviceComponent, {
      data: {
        accountId: this.accountId,
        assetId: this.assetId
      }
    });

    dialogRef.afterClosed().subscribe((success) => {
      if (success) { this.ngOnInit(); }
    });
  }

  onUninstallDeviceClick() {
    var dialogRef = this.dialog.open(AccountAssetUninstallDeviceComponent, {
      data: {
        accountId: this.accountId,
        assetId: this.assetId,
        installedDevices: this.asset.devices
      }
    });

    dialogRef.afterClosed().subscribe((success) => {
      if (success) { this.ngOnInit(); }
    });
  }

  onReplaceDeviceClick() {
    var dialogRef = this.dialog.open(AccountAssetReplaceDeviceComponent, {
      data: {
        accountId: this.accountId,
        assetId: this.assetId,
        installedDevices: this.asset.devices
      }
    });

    dialogRef.afterClosed().subscribe((success) => {
      if (success) { this.ngOnInit(); }
    });
  }

  updateProperties() {
    var dialogRef = this.dialog.open(AccountAssetPropertiesDialogComponent, {
      data: {
        accountId: this.accountId,
        assetId: this.assetId,
        class: this.asset.class,
        region: this.asset.region
      }
    });

    dialogRef.afterClosed().subscribe((success) => {
      if (success) { this.ngOnInit(); }
    });
  }

  updateType() {
    var dialogRef = this.dialog.open(AccountAssetTypeDialogComponent, {
      data: {
        accountId: this.accountId,
        assetId: this.assetId,
        class: this.asset.class,
        region: this.asset.region,
        type: this.asset.type
      }
    });

    dialogRef.afterClosed().subscribe((success) => {
      if (success) { this.ngOnInit(); }
    });
  }

  onTraceAssetClick() {
    var dialogRef = this.dialog.open(AccountAssetTraceDialogComponent, {
      data: {
        accountId: this.accountId,
        assetId: this.assetId,
      }
    });

    dialogRef.afterClosed().subscribe((success) => {
      if (success) { this.ngOnInit(); }
    });
  }

  copyToClipboard(text: string, fieldName: string) {
    if (text) {
      navigator.clipboard.writeText(text).then(() => {
        this.snackBar.open(`${fieldName} copied to clipboard`, 'Close', {
          duration: 2000,
        });
      }, (err) => {
        console.error('Could not copy text: ', err);
        this.snackBar.open(`Failed to copy ${fieldName}`, 'Close', {
          duration: 2000,
        });
      });
    } else {
      this.snackBar.open(`${fieldName} not available`, 'Close', {
        duration: 2000,
      });
    }
  }
}
