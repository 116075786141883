<div class="my-3">
  <mat-card>
    <mat-card-title>
      Edit Installer Location
    </mat-card-title>
    <form (ngSubmit)="onSubmit()" *ngIf="editInstallerLocationForm">
      <ng-container [formGroup]="editInstallerLocationForm">
        <mat-card-content>
          <ng-container>
            <mat-form-field>
              <input id='il-edit-customer-id' type="number" matInput formControlName="customerId" maxlength="100" placeholder="Customer ID" required />
            </mat-form-field>
            <mat-form-field>
              <input id='il-edit-company-name' matInput formControlName="companyName" maxlength="100" #companyName
                     oninput="this.value = this.value.toUpperCase()" placeholder="Company Name" required />
            </mat-form-field>
            <mat-form-field>
              <input id='il-edit-company-code' matInput formControlName="companyCode" maxlength="100" #companyCode
                     oninput="this.value = this.value.toUpperCase()" placeholder="Company Code" />
            </mat-form-field>
            <mat-form-field>
              <input id='il-edit-house-no' matInput formControlName="houseNo" maxlength="100" placeholder="House Number" />
            </mat-form-field>
            <mat-form-field>
              <input id='il-edit-street' matInput formControlName="street" maxlength="100" placeholder="Street" required />
            </mat-form-field>
            <mat-form-field>
              <input id='il-edit-street2' matInput formControlName="street2" maxlength="100" placeholder="Street 2" />
            </mat-form-field>
            <mat-form-field>
              <input id='il-edit-city' matInput formControlName="city" maxlength="100" placeholder="City" />
            </mat-form-field>
            <mat-form-field>
              <input id='il-edit-region' matInput formControlName="region" maxlength="100" placeholder="Region" />
            </mat-form-field>
            <mat-form-field>
              <input id='il-edit-country' matInput formControlName="country" maxlength="100" placeholder="Country" required />
            </mat-form-field>
            <mat-form-field>
              <input id='il-edit-postal' matInput formControlName="postal" maxlength="100" placeholder="Postal Code" required />
            </mat-form-field>
          </ng-container>
        </mat-card-content>
        <mat-card-actions align="end">
          <button id='il-edit-cancelbutton' type="button" mat-stroked-button adsBackbutton>
            Cancel
          </button>
          <button id='il-edit-createbutton' mat-flat-button color="primary" [disabled]="editInstallerLocationForm.invalid
                || !editInstallerLocationForm.dirty" type="submit">
            Save
          </button>
        </mat-card-actions>
      </ng-container>
    </form>
  </mat-card>
</div>
