import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { InstallerLocationsService, NewInstallerLocation } from '../../../services/accounts.service';

@Component({
  selector: 'ads-app-add-installer-locations',
  templateUrl: './add-installer-locations.component.html',
  styleUrls: ['./add-installer-locations.component.scss']
})
export class AddInstallerLocationsComponent implements OnInit, OnDestroy {
  _onDestroy = new Subject<void>();
  newInstallerLocationForm: FormGroup;

  constructor(private fb: FormBuilder, private installerLocationsService: InstallerLocationsService,
    private router: Router, private toasterService: ToasterNotificationService) {
  }

  ngOnInit() {
    this.setUpForm();
  }

  setUpForm() {
    this.newInstallerLocationForm = this.fb.group({
      customerId: ['0', Validators.required],
      companyName: ['', Validators.required],
      companyCode: ['', Validators.required],
      houseNo: [''],
      street: ['', Validators.required],
      street2: [''],
      city: [''],
      region: [''],
      country: ['', Validators.required],
      postal: ['', Validators.required]
    });
  }

  onSubmit() {
    const newInstallerLocation: NewInstallerLocation = {
      customerId: this.newInstallerLocationForm.controls.customerId.value,
      companyName: this.newInstallerLocationForm.controls.companyName.value,
      companyCode: this.newInstallerLocationForm.controls.companyCode.value,
      houseNo: this.newInstallerLocationForm.controls.houseNo.value,
      street: this.newInstallerLocationForm.controls.street.value,
      street2: this.newInstallerLocationForm.controls.street2.value,
      city: this.newInstallerLocationForm.controls.city.value,
      region: this.newInstallerLocationForm.controls.region.value,
      country: this.newInstallerLocationForm.controls.country.value,
      postal: this.newInstallerLocationForm.controls.postal.value,
      init: null,
      toJSON: null
    };

    this.installerLocationsService.createInstallerLocation(newInstallerLocation).subscribe(() => {
      this.router.navigate([`/accounts/installer-location`]);
      this.toasterService.showInfoToaster('Installer location has been successfully created.');
    },
      error => {
        this.toasterService.showWarnToaster('ERROR: Unable to create installer location. Please use a unique company code.');
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
