<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">
    <mat-nav-list>
      <ng-container *ngFor="let link of links">
        <a id='nav-{{link.displayText.replace("/\s/g", "")}}' mat-list-item *ngIf="!link.hidden" routerLinkActive="list-item-active" [routerLink]="[link.path]">
          <mat-icon class="material-icons">{{ link.icon }}</mat-icon>
          {{ link.displayText }}
        </a>

      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button id='nav-dropdownbutton' type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">alarm</mat-icon>
        </button>
        <span>ADS Operational Portal</span>
        <span class="welcome-spacer"></span>
        <span class="welcome-message">{{ welcomeMessage }}</span>
        <mat-icon id='usr-logouticon' class="welcome-icon" matTooltip="logout" aria-hidden="false" aria-label="sign out" (click)="logout()">
          exit_to_app</mat-icon>
      </mat-toolbar-row>
    </mat-toolbar>
    <br />
    <breadcrumb #parent>
      <div class="breadcrumb-container">
        <span class="breadcrumb" *ngFor="let route of parent.breadcrumbs">
          <ng-container *ngIf="route.terminal">
            <span>{{ route.displayName }}</span>
          </ng-container>
          <ng-container *ngIf="!route.terminal">
            <a id='bc-{{route.displayName.replace("/\s/g", "")}}' href="" [routerLink]="[route.url]">{{ route.displayName }}</a>
          </ng-container>
        </span>
      </div>
    </breadcrumb>
    <div class="content-pane">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
