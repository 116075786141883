/* eslint-disable guard-for-in */
import { Injectable } from '@angular/core';
import { IMapItemFactory } from '../../shared/models/map-factory.interface';
import { MapItem } from '../../shared/models/map-item.model';

// eslint-disable-next-line no-var
declare var google: any;

interface ILastKnownLocation {
  Latitude?: number;
  Longitude?: number;
  DateTime?: string;
  City?: string;
  State?: string;
  Source?: string;
  DwellState?: string;
  DwellStateStartedAt?: Date;
  DwellStateEndedAt?: Date;
  DwellDuration?: number; // Computed in ActiveTripsView
  DwellLocationName?: string;
  DwellLocationType?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MapService implements IMapItemFactory {
  markerIconPath =
    'M 15.00,8.45 C 11.38,8.45 8.45,11.38 8.45,15.00 8.45,18.62 11.38,21.55 15.00,21.55 18.62,21.55 21.55,18.62 21.55,15.00 21.55,11.38 18.62,8.45 15.00,8.45 Z M 15.00,4.78 C 9.36,4.78 4.78,9.36 4.78,15.00 4.78,20.64 9.36,25.22 15.00,25.22 20.64,25.22 25.22,20.64 25.22,15.00 25.22,9.36 20.64,4.78 15.00,4.78M 30.00,15.00 C 30.00,23.29 23.29,30.00 15.00,30.00 6.71,30.00 0.00,23.29 0.00,15.00 0.00,6.71 6.71,0.00 15.00,0.00 23.29,0.00 30.00,6.71 30.00,15.00 Z';
  // 'M 15.00,7.68 C 10.96,7.68 7.68,10.96 7.68,15.00 7.68,19.04 10.96,22.32 15.00,22.32 19.04,22.32 22.32,19.04 22.32,15.00 22.32,10.96 19.04,7.68 15.00,7.68 Z M 15.00,2.90 C 8.32,2.91 2.91,8.32 2.90,15.00 2.90,21.68 8.32,27.10 15.00,27.10 21.68,27.10 27.10,21.68 27.10,15.00 27.10,8.32 21.68,2.90 15.00,2.90M 15.00,0.00 C 23.28,-0.00 30.00,6.72 30.00,15.00 30.00,23.28 23.28,30.00 15.00,30.00 6.72,30.00 0.00,23.28 0.00,15.00 0.00,6.72 6.72,0.00 15.00,0.00 15.00,0.00 15.00,0.00 15.00,0.00 Z'

  markerDefaultColor = ['#38BEC4', '#F3B32B'];
  markerActiveColor = '#fff';
  markerIcon = {
    path: this.markerIconPath,
    fillColor: this.markerDefaultColor[0],
    fillOpacity: 2,
    strokeWeight: 1,
    scale: 0.8,
    anchor: new google.maps.Point(10, 10),
  };

  style: any = {
    url: 'assets/images/cluster.png',
    height: 40,
    width: 40,
    textColor: '#FFF',
    textSize: 12,
    backgroundPosition: 'center center',
  };
  options: any = {
    imagePath: 'assets/images/cluster.png',
    gridSize: 40,
    styles: [this.style, this.style, this.style],
    maxZoom: 9,
  };
  PolygonPathOptions = {
    paths: [],
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillOpacity: 0.35,
    editable: true,
    draggable: true,
  };

  constructor() {}

  transformData(events: any[]): MapItem[] {
    let filterGroup = events;
    let index = 0;
    let data: MapItem[] = [];

    for (let i = 0; i < filterGroup.length; i++) {
      let lastKnownLocation: ILastKnownLocation = this.getLastKnownLocation(
        filterGroup[i]
      );

      data.push({
        TripID: filterGroup[i].tripId,
        Asset: filterGroup[i].assetName,
        AssetId: filterGroup[i].assetId,
        AssetGroup: filterGroup[i].fleet,
        AssetGroupId: filterGroup[i].AssetGroupId, //
        LoadStatus: filterGroup[i].loadStatus,
        Origin: filterGroup[i].originName,
        OriginState: filterGroup[i].originState,
        Destination: filterGroup[i].destinationName,
        DestinationState: filterGroup[i].destinationState,
        LocationName: filterGroup[i].locationName,
        LocationSource: filterGroup[i].locationSource,
        LocationState: filterGroup[i].locationState,
        TripStatus: filterGroup[i].TripStatus, //
        DepartureDate: filterGroup[i].departureDate,
        Duration: filterGroup[i].Duration, //
        EtaDate: filterGroup[i].EtaDate, //
        EtaRailroadDate: filterGroup[i].EtaRailroadDate, //
        ArrivalStatus: filterGroup[i].ArrivalStatus, //
        Latitude: lastKnownLocation.Latitude ?? filterGroup[i].locationLatitude,
        Longitude:
          lastKnownLocation.Longitude ?? filterGroup[i].locationLongitude,
        Source: lastKnownLocation?.Source,
        DeviceDateTime: lastKnownLocation?.DateTime,
        DeviceClosestLocale:
          lastKnownLocation.City && lastKnownLocation.State
            ? `${lastKnownLocation.City}, ${lastKnownLocation.State}` // TODO: Add Country when implemented
            : null,
        WaybillIdentifier: filterGroup[i].WaybillIdentifier, //
        WaybillDestination: filterGroup[i].WaybillDestination, //
        WaybillDestinationState: filterGroup[i].WaybillDestinationState, //
        TrainID: filterGroup[i].trainId,
        ClmCode: filterGroup[i].ClmCode, //
        ClmSightingDate: filterGroup[i].ClmSightingDate, //
        ClmCodeDescription: filterGroup[i].ClmCodeDescription, //
        CareOfParty: filterGroup[i].careOfParty,
        RailRoad: filterGroup[i].RailRoad, //
        BOL: filterGroup[i].bolNumber,
        SID: filterGroup[i].sidNumber,
        Customer: filterGroup[i].customerName,
        CustPO: filterGroup[i].customerPoNumber,
        Product: filterGroup[i].product,
        NetWeight: filterGroup[i].netWeight,
        ModifiedUser: filterGroup[i].ModifiedUser, //
        ModifiedDate: filterGroup[i].ModifiedDate, //
        GeoJson: filterGroup[i].GeoJson, //
        GeoMetrixETA: filterGroup[i].GeoMetrixETA, //
      });
    }
    return data;
  }

  /**
   * Use GPS, CLM & Maritime Last Known Location DateTimes to build a Last Known Location Object
   *
   * @memberof MapService
   */
  getLastKnownLocation(event: any): ILastKnownLocation {
    let source = this.getLatestSource(
      event.assetLastKnownLocationGPSDateTimeUtc,
      event.assetLastKnownLocationCLMDateTimeUtc,
      event.assetLastKnownLocationMaritimeDateTimeUtc
    );

    const date = event[`assetLastKnownLocation${source}DateTimeUtc`] ?? null;

    return {
      Latitude: event[`assetLastKnownLocation${source}Latitude`],
      Longitude: event[`assetLastKnownLocation${source}Longitude`],
      DateTime: date,
      City: event[`assetLastKnownLocation${source}City`],
      State: event[`assetLastKnownLocation${source}State`],
      Source: event.locationSource,
    };
  }

  /**
   * Get the Most Recent Source to build the Last Known location
   *
   * @memberof MapService
   */
  getLatestSource(
    gps?: string,
    clm?: string,
    maritime?: string
  ): string | null {
    let source = null;

    const gpsDate = gps ? new Date(gps) : null;
    const clmDate = clm ? new Date(clm) : null;
    const maritimeDate = maritime ? new Date(maritime) : null;

    if (gpsDate > clmDate && gpsDate > maritimeDate) {
      source = 'GPS';
    } else if (clmDate > gpsDate && clmDate > maritimeDate) {
      source = 'CLM';
    } else if (maritimeDate > gpsDate && maritimeDate > clmDate) {
      source = 'Maritime';
    }

    return source;
  }

  groupEvents(events: any[]) {
    let index = 0;
    let data = [];

    // eslint-disable-next-line arrow-body-style
    const sortBy = (key: string) => {
      return (a: { [x: string]: number }, b: { [x: string]: number }) =>
        a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
    };

    const filterGroup: any[] = events
      .concat()
      .sort(sortBy('item.AssetGroup'))
      .reduce(
        (
          r: { [x: string]: any[] },
          v: { AssetGroup: any },
          i: any,
          a: any,
          k: any = v.AssetGroup
        ) => ((r[k] || (r[k] = [])).push(v), r),
        {}
      );

    for (let event in filterGroup) {
      for (let i = 0; i < filterGroup[event].length; i++) {
        data.push({
          position: {
            lat: filterGroup[event][i].Latitude,
            lng: filterGroup[event][i].Longitude,
          },
          Asset: filterGroup[event][i].Asset,
          Source: filterGroup[event][i]?.Source,
          DeviceDateTime: filterGroup[event][i]?.DeviceDateTime,
          DeviceClosestLocale: filterGroup[event][i]?.DeviceClosestLocale,
          Location: filterGroup[event][i].Location,
          LocationState: filterGroup[event][i].CurrentLocationState,
          AssetGroup: filterGroup[event][i].AssetGroup,
          AssetID: filterGroup[event][i].AssetId,
          EventID: filterGroup[event][i].EventID,
          PlusEventID: filterGroup[event][i].CID,
          ImpactID: filterGroup[event][i].MessageID,
          MessageNumber: filterGroup[event][i].Geofence,
          Date: filterGroup[event][i].EventDateUTC,
          LastMessageDate: filterGroup[event][i].LastMessageDate,
          Origin: filterGroup[event][i].Origin,
          Destination: filterGroup[event][i].Destination,
          City: filterGroup[event][i].City,
          LocationDays: filterGroup[event][i].LocationDays,
          DwellDays: filterGroup[event][i].DwellDays,
          TotalMileage: filterGroup[event][i].TotalDistanceKm,
          Temperature: filterGroup[event][i].TemperatureCelsius,
          StartLatitude: filterGroup[event][i].StartLatitude,
          StartLongitude: filterGroup[event][i].StartLongitude,
          StopLatitude: filterGroup[event][i].StopLatitude,
          StopLongitude: filterGroup[event][i].StopLongitude,
          CompletedLine: filterGroup[event][i].CompletedLine,
          IncompleteLine: filterGroup[event][i].IncompleteLine,
          LoadStatus: filterGroup[event][i].LoadStatus,
          DueBy: filterGroup[event][i].DueBy,
          AssetGroupID: index,
        });
      }
      index++;
    }

    return data;
  }

  // set marker icon colors based on fleets
  setMarkerIcon(loadStatus: string = null) {
    if (loadStatus === null || loadStatus === 'Empty' || loadStatus === '?') {
      this.markerIcon.fillColor = this.markerDefaultColor[0];
    } else {
      this.markerIcon.fillColor = this.markerDefaultColor[1];
    }
    return this.markerIcon;
  }

  setActiveMarkerIcon() {
    this.markerIcon.fillColor = this.markerActiveColor;
    return this.markerIcon;
  }

  // apply the styles to infowindow dynamically
  showInfoWindow() {
    let iwOuter = document.getElementsByClassName('gm-style-iw')[0];
    let iwArrow = null;
    if (iwOuter) {
      let iwBackground = iwOuter.previousSibling;
      let iwClose = iwOuter['children'][1];
      if (iwBackground) {
        iwArrow = iwBackground['children'][2];
      }

      if (iwBackground) {
        iwBackground['children'][3].style.backgroundColor = 'transparent';
        iwBackground['children'][1].style.backgroundColor = 'transparent';

        iwBackground['children'][3].style.width = 'auto';
        iwBackground['children'][3].style.height = 'auto';

        iwBackground['children'][1].style.width = 'auto';
        iwBackground['children'][1].style.height = 'auto';

        iwBackground['children'][1].style.display = 'none';
        iwBackground['children'][3].style.display = 'none';
      }
      // iwClose['style'].display = 'block';
      iwClose['style'].display = 'none';

      if (iwArrow) {
        if (iwArrow.children) {
          iwArrow.children[0].firstElementChild.style.backgroundColor = '#000';
          iwArrow.children[1].firstElementChild.style.backgroundColor = '#000';
          iwArrow.children[0].firstElementChild.style.display = 'block';
          iwArrow.children[1].firstElementChild.style.display = 'block';
        }
      }
    }
  }

  // closes infowindow
  closeInfowindow(infowindow) {
    infowindow.close();
    infowindow.setContent('');
  }

  // Set Geofence Styles
  setStyle(map, color: string) {
    map.data.setStyle({
      fillColor: color,
      border: 1,
      fillOpacity: 0.1,
      strokeWeight: 1,
    });
  }
}
