<button id="jd-close=button" mat-icon-button class="raw-message-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title>Account Asset Trace Details</h1>
<mat-dialog-content class="asset-trace-dialog">
	<ng-container>
    <mat-card-content>
      <pre *ngIf="assetTraceDetails">{{assetTraceDetails | json}}</pre>
    </mat-card-content>
  </ng-container>
</mat-dialog-content>
