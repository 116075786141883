import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'ads-json-dialog',
  templateUrl: './json-dialog.component.html',
  styleUrls: ['./json-dialog.component.scss']
})
export class JsonDialogComponent {
  message = 'Default message';
  buttonText = 'Ok';
  constructor(
      @Inject(MAT_DIALOG_DATA) private data: any,
      private dialogRef: MatDialogRef<JsonDialogComponent>) {
      if (data) {
          this.message = JSON.parse(data.message) || this.message;
          if (data.buttonText) {
              this.buttonText = data.buttonText.ok || this.buttonText;
          }
      }
  }
}
