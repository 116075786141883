import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LocationGeoJsonDTO, LocationList, LocationService, SimpleLocationDTO } from 'src/app/services/geo.service';
import { LocationCacheService } from 'src/app/services/location-cache.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'ads-add-location-dialog',
  templateUrl: './add-location-dialog.component.html',
  styleUrls: ['./add-location-dialog.component.scss']
})
export class AddLocationDialogComponent implements OnInit {
  customLocationFormController: CustomLocationFormController;
  searchLocationFormController: SearchLocationFormController;

  constructor(
    public dialogRef: MatDialogRef<AddLocationDialogComponent>,
    private locationService: LocationService,
    private locationCacheService: LocationCacheService
  ) {}

  ngOnInit(): void {
    this.customLocationFormController = new CustomLocationFormController();
    this.searchLocationFormController = new SearchLocationFormController();

    this.seedLocations();

    this.searchLocationFormController.filteredLocations = this.searchLocationFormController.searchInput.valueChanges.pipe(
      startWith(''),
      map((value) => this.searchLocationFormController.filter(value))
    );
  }

  seedLocations() {
    this.locationCacheService.getLocations().subscribe((result) => {
      for (const ele of result) {
        this.searchLocationFormController.locations.push(ele.locationId + ' - ' + ele.name);
      }
    });
  }

  // adding a location from dropdown
  onAddLocation(): void {
    const inputString: string = this.searchLocationFormController.searchInput.value;
    if (inputString === '' || inputString === null) {
      alert('Please select a location!');
    } else {
      const id: string = inputString.substring(0, inputString.indexOf('-') - 1);
      const selectedLocationId: number = Number(id);
      if (selectedLocationId == null || isNaN(selectedLocationId)) {
        alert('Please select a location!');
      } else {
        const idList = new LocationList();
        idList.ids = [];
        idList.ids.push(selectedLocationId);
        this.locationService.locationByID(idList).subscribe((r) => {
          this.dialogRef.close(r[0]);
        });
      }
    }
  }

  // adding a location from custom tab
  onAddCustomLocation(): void {
    const err =
      this.customLocationFormController.ValidateName() +
      this.customLocationFormController.ValidateLat() +
      this.customLocationFormController.ValidateLong() +
      this.customLocationFormController.ValidateGeoJson();
    if (err === '') {
      const location: LocationGeoJsonDTO = new LocationGeoJsonDTO();
      location.name = this.customLocationFormController.nameField;
      location.center = [Number(this.customLocationFormController.longField), Number(this.customLocationFormController.latField)];
      location.geoJson = this.customLocationFormController.geoJsonField;
      this.dialogRef.close(location);
    } else {
      alert(err);
    }
  }

  onClose(): void {
    this.dialogRef.close(null);
  }
}

class SearchLocationFormController {
  searchInput = new FormControl();
  locations: string[];
  filteredLocations: Observable<string[]>;

  constructor() {
    this.locations = [];
  }

  public filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.locations.filter((option) => {
      return (
        option
          .substring(option.indexOf('-') + 2, option.length)
          .toLowerCase()
          .indexOf(filterValue) === 0 || // search after id in string "id - name"
        option.toLowerCase().indexOf(filterValue) === 0 // search by id
      );
    });
  }
}

class CustomLocationFormController {
  nameField: string;
  latField: string;
  longField: string;
  geoJsonField: string;

  constructor() {
    this.nameField = '';
    this.latField = '';
    this.longField = '';
    this.geoJsonField = '';
  }

  ValidateName() {
    return this.nameField === '' ? 'name cannot be empty\n' : '';
  }

  ValidateLat() {
    const latitude = Number(this.latField);
    return isNaN(latitude) ? 'latitude is not a number\n' : latitude > 90 && latitude < -90 ? 'latitude is not in range [-90, 90]\n' : '';
  }

  ValidateLong() {
    const longitude = Number(this.longField);
    return isNaN(longitude)
      ? 'longitude is not a number\n'
      : longitude > 180 && longitude < -180
      ? 'longitude is not in range [-180, 180]\n'
      : '';
  }

  ValidateGeoJson() {
    return this.geoJsonField === '' ? 'geoJson cannot be empty' : '';
  }
}
