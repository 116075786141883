import { CheckboxSelectionCallbackParams } from 'ag-grid-community';

export const defaultColDefDS = {
  sortable: true,
  filter: true,
  resizable: true,
  headerCheckboxSelection: (e) => isFirstColumn(e),
  menuTabs: ['filterMenuTab'],
  headerComponentParams: {
    template:
      '<div class="ag-cell-label-container" role="presentation">' +
      '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
      '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
      '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
      '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
      '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
      '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
      '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
      '  </div>' +
      '</div>'
  },
  icons: {
    menu: '<span class="ag-icon ag-icon-filter"></span>',
  },
};

export const columnDefsDS = [
  {
    headerName: 'Serial Number',
    field: 'serialNumber',
    valueFormatter: (e) => valueFormatter(e),
    width: 185,
    checkboxSelection: true,
    cellRenderer: (e) => checkSelection(e),
    filter: 'agTextColumnFilter',
  },
  { headerName: 'Asset Number', field: '', valueFormatter: (e) => valueFormatter(e), width: 120, hide: true },
  { headerName: 'Current Group', field: '', valueFormatter: (e) => valueFormatter(e), width: 120, hide: true }
];

const valueFormatter = (params) => {
  return params.value ? params.value : '-';
};

const isFirstColumn = (params: CheckboxSelectionCallbackParams) => {
  var displayedColumns = params.columnApi.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
};

const checkSelection = (params) => {
  if (_devicesSelected.includes(params.value)) {
    params.node.setSelected(true);
  }
  return params.value;
};

let _devicesSelected: string[] = [];

export const setDevicesSelected = (devicesSelected: string[]) => {
  _devicesSelected = devicesSelected;
};
