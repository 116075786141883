import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'angular-crumbs';
import { AdsAccountDto, AccountsService } from '../../services/accounts.service';
import {
  AssetsService,
  AssetDto,
  AssetGroupsService,
  AssetGroupDto,
  AssetGroupDtoPagedListDto,
  AssetBasicDto,
} from '../../services/assets.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { ToasterNotificationService } from '../../services/toasterNotification.service';
import { Location } from '@angular/common';
import { MatTableDataSource, MatTable } from '@angular/material/table';

@Component({
  selector: 'ads-asset-groups',
  templateUrl: './asset-groups-unassigned.component.html',
  styleUrls: ['./asset-groups-unassigned.component.scss']
})
export class AssetGroupUnassignedComponent implements OnInit {
  assetGroupForm: FormGroup;
  private accountId: number;
  assetGroupsSearchTerm = '';
  assetGroupsEmptyData = true;
  assetGroupsBaseMessage = 'To select a group, please begin typing the group\'s name';
  assetGroupsEmptyMessage: string = this.assetGroupsBaseMessage;
  public account: AdsAccountDto;
  public assets: AssetDto[];
  public assetGroup: AssetGroupDto = new AssetGroupDto();
  groupAssetsData: MatTableDataSource<{}>;
  assetsToAdd: MatTableDataSource<{}> = new MatTableDataSource();
  assetGroupsAutoCompleteInput = new FormControl();
  allAssetGroups: AssetGroupDto[];
  filteredAssetGroups: AssetGroupDto[];
  filterValue = '';

  unassignedAssetColumnsToDisplay: string[] = ['assetName', 'unitNumber', 'selected'];
  assetsToAddColumnsToDisplay: string[] = ['assetToAddNames', 'assetToAddUnits', 'action'];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private assetService: AssetsService,
    private toasterService: ToasterNotificationService,
    private assetGroupsService: AssetGroupsService,
    private accountService: AccountsService,
    private formBuilder: FormBuilder,
    private location: Location) {

    this.accountId = Number.parseInt(this.route.parent.snapshot.params.id, 10);

    this.assetGroupForm = this.formBuilder.group({
      assetGroupName: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.accountService.account(this.accountId).subscribe(
      ((accountResponse: AdsAccountDto) => {
        this.account = accountResponse;
        if (this.account === undefined) {
          this.router.navigateByUrl('/accounts');
        }
        this.breadcrumbService.changeBreadcrumb(this.route.snapshot, this.account.accountName);
      }),
      error => {
        this.router.navigateByUrl('/accounts');
      }
    );

    if (this.accountId !== undefined) {
      const assetGroupRequest = this.assetService.unassignedAssets(this.accountId, 'all', true);
      assetGroupRequest.subscribe(
        ((assetResponse: AssetDto[]) => {
          this.assets = assetResponse;
          this.groupAssetsData = new MatTableDataSource(this.assets);
        }),
        error => {
          this.location.back();
        });
      const allAssetGroupRequest = this.assetGroupsService.assetGroups(this.accountId, 'all', 1, 20, undefined, undefined);
      allAssetGroupRequest.subscribe(
        ((assetGroupResponse: AssetGroupDtoPagedListDto) => {
          this.allAssetGroups = assetGroupResponse.items;
        }),
        error => {
          this.toasterService.showWarnToaster(error);
        }
      );
    } else {
      this.assets = [];
      this.groupAssetsData = new MatTableDataSource();
      this.allAssetGroups = [];
    }
  }

  setAssetToBeAdded(asset: AssetDto) {
    const indexToRemove = this.assets.indexOf(asset);
    if (indexToRemove > -1) {
      const unaddedData = this.assets;
      unaddedData.splice(indexToRemove, 1);
      this.groupAssetsData.data = unaddedData;
    }

    const data = this.assetsToAdd.data;
    data.push(asset);
    this.assetsToAdd.data = data;
    this.searchAssets(this.filterValue);
  }

  removeAssetToBeAdded(asset: AssetDto) {
    const indexToRemove = this.assetsToAdd.data.indexOf(asset, 0);
    if (indexToRemove > -1) {
      const toAddData = this.assetsToAdd.data;
      toAddData.splice(indexToRemove, 1);
      this.assetsToAdd.data = toAddData;
    }

    const data = this.assets;
    data.push(asset);
    this.groupAssetsData.data = data.sort((x, y) => x.assetName.localeCompare(y.assetName));
    this.searchAssets(this.filterValue);
  }

  searchAssets(filterValue: string) {
    this.filterValue = filterValue;
    if (this.filterValue !== '') {
      this.groupAssetsData.data = this.assets.filter(x => x.assetName.toLowerCase().includes(this.filterValue.toLowerCase()));
    } else {
      this.groupAssetsData.data = this.assets;
    }
  }

  searchTriggered(filterValue: any) {
    this.searchAssets(filterValue);
  }

  onSubmit() {
    this.assetGroupForm.markAllAsTouched();

    if (this.assetGroupForm.invalid) {
      this.toasterService.showInfoToaster('Please select an asset group to add the assets to.');
      return;
    }

    if (this.assetsToAdd.data.length > 0) {
      const assetGroupToUpdateId  = this.allAssetGroups.find(x => x.name === this.assetGroupForm.value.assetGroupName).assetGroupId;
      // query in case someone else updated the group in the time this page was initialized
      this.assetGroupsService.assetGroup(this.accountId, 'all', assetGroupToUpdateId).subscribe((assetGroupToUpdate: AssetGroupDto) => {
        if (assetGroupToUpdate !== undefined) {
          assetGroupToUpdate.assets  = assetGroupToUpdate.assets.concat(this.assetsToAdd.data.map(x => x as AssetBasicDto));
          this.assetGroupsService.updateAssetGroup(this.accountId, 'all').subscribe(x => {
            this.toasterService.showInfoToaster(`Asset group ${assetGroupToUpdate.name} updated!`);
            this.navigateToAccount();
          });
        }
      });
    }
  }

  navigateToAccount() {
    this.router.navigateByUrl(`/accounts/view/${this.accountId}/account-details`);
  }
}

