<mat-card *ngIf="resourcesLoaded">
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    User Details
    <div>
      <button id='usr-edituserbutton' mat-button color="primary" (click)="onEditUserClick()">
        Edit User
      </button>
      <button *ngIf='resetPasswordTreatment' id='usr-resetpassword' mat-button color="primary" (click)="resetUserPassword()">
        Reset Password
      </button>
      <button *ngIf='sendWelcomeEmailTreatment' id='usr-resendemailbutton' mat-button color="primary" (click)="onResendClick()">
        Resend Welcome Email
      </button>
      <button id='usr-cloneuserbutton'
              [routerLink]="['/users/add']"
              [queryParams]="{accountId: user.accountId, features: featuresValues, pages: pagesValues, 
                dateDisplayFormat: user.dateDisplayFormat, dateTimeDisplayFormat: user.dateTimeDisplayFormat, lengthUnitOfMeasure: user.lengthUnitOfMeasure,
                tankVolumeUnitOfMeasure: user.tankVolumeUnitOfMeasure, loadingDimensionsUnitOfMeasure: user.loadingDimensionsUnitOfMeasure,
                weightUnitOfMeasure: user.weightUnitOfMeasure}" mat-button
              color="primary">
        Clone User
      </button>
      <button id='usr-enableuserbutton' mat-button color="warn"
              (click)="onChangeAccountActiveClick()">
        {{ user.accountEnabled == true ? 'Disable' : 'Enable' }} User
      </button>
    </div>
  </mat-card-title>
  <mat-card-subtitle>SCV and User Information</mat-card-subtitle>

  <mat-divider></mat-divider>
  <mat-card-content>
    <dl>
      <dt>Name</dt>
      <dd>{{ user.displayName }}</dd>

      <dt>Email</dt>
      <dd>{{ user.emailAddress }}</dd>

      <dt>Account Name</dt>
      <dd>
        <span *ngIf="user.accountId === null">-</span>
        <ads-account-name *ngIf="user.accountId !== null" [accountId]="user.accountId"></ads-account-name>
      </dd>

      <dt>Account Id</dt>
      <dd>{{ user.accountId == null ? "" : (user.accountId) }}</dd>

      <dt>Features</dt>
      <dd>{{ user.features == "" ? "-" : user.features}}</dd>

      <dt>Pages</dt>
      <dd>{{ user.pages == "" ? "-" : user.pages }}</dd>

      <dt>Mobile Phone</dt>
      <dd>{{user.mobilePhone == null ? "-" : user.mobilePhone}}</dd>

      <dt>Date Display Format</dt>
      <dd>{{user.dateDisplayFormat == null ? "-" : user.dateDisplayFormat}}</dd>

      <dt>Date Time Display Format</dt>
      <dd>{{user.dateTimeDisplayFormat == null ? "-" : user.dateTimeDisplayFormat}}</dd>

      <dt>Length Unit of Measure</dt>
      <dd>{{user.lengthUnitOfMeasure == null ? "-" : user.lengthUnitOfMeasure}}</dd>

      <dt>Tank Volume Unit Of Measure</dt>
      <dd>{{user.tankVolumeUnitOfMeasure == null ? "-" : user.tankVolumeUnitOfMeasure}}</dd>

      <dt>Loading Dimensions Unit of Measure</dt>
      <dd>{{user.loadingDimensionsUnitOfMeasure == null ? "-" : user.loadingDimensionsUnitOfMeasure}}</dd>

      <dt>Weight Unit of Measure</dt>
      <dd>{{user.weightUnitOfMeasure == null ? "-" : user.weightUnitOfMeasure}}</dd>

      <dt>Job Title</dt>
      <dd>{{user.jobTitle == null ? "-" : user.jobTitle}}</dd>

      <dt>Bypass MFA</dt>
      <dd>{{user.bypassMfa == null ? "-" : user.bypassMfa}}</dd>

      <dt>User Account Status</dt>
      <dd>{{ user.accountEnabled == true ? "Enabled" : "Disabled" }}</dd>

      <dt>
        <mat-icon inline="true" #tooltip="matTooltip" matTooltip="Recent logins can take up to 10 minutes to be processed. Logins are only updated once per hour while users have active sessions.">info</mat-icon>
        Last Logged In
      </dt>
      <dd>
        <span matTooltip="{{ user.lastSignInDate }}" matTooltipPosition="right">{{ user.lastSignInDate | dateAgo: '--' }}</span>
      </dd>
      <dt>Policies</dt>
      <dd><ads-user-permissions userId="{{this.userId}}" accountId="{{this.user.accountId}}"></ads-user-permissions></dd>
    </dl>
  </mat-card-content>
</mat-card>
