<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    Account Asset Details
    <div>
      <a mat-button color="primary" (click)="onTraceAssetClick()">Trace Asset</a>
      <button mat-button color="primary" [matMenuTriggerFor]="viewAsMenu" [hidden]='!showDeviceActions'>Device Actions</button>
      <mat-menu #viewAsMenu="matMenu">
        <a mat-menu-item (click)="onInstallDeviceClick()">
          Install
        </a>
        <a mat-menu-item (click)="onReplaceDeviceClick()">
          Replace
        </a>
        <a mat-menu-item (click)="onUninstallDeviceClick()">
          Uninstall
        </a>
      </mat-menu>
      <button [disabled]='!asset?.canShareADSDataFeed' mat-button color="primary" (click)="onShareAssetClick()">Share Asset</button>
      <button [disabled]='!asset?.canShareADSDataFeed || asset?.sharedToAccountIds.length == 0' mat-button color="primary" (click)="onAssetEndShareClick()">End Asset Share</button>
      <button mat-button color="primary" [matMenuTriggerFor]="viewAsMenu2">Edit Asset</button>
      <mat-menu #viewAsMenu2="matMenu">
        <a mat-menu-item (click)="updateProperties()">
          Properties
        </a>
        <a mat-menu-item (click)="updateType()">
          Type
        </a>
      </mat-menu>
      <a mat-button color="primary" routerLink="/assets-paas/assets/{{assetId}}">View Full Asset</a>
    </div>
  </mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div *ngIf="asset" class="asset-details">
      <div class="asset-info-row">
        <div class="asset-info">
          <strong>Name:</strong>
          <span class="asset-value">{{asset.name}}</span>
          <button mat-icon-button (click)="copyToClipboard(asset.name, 'Asset Name')" matTooltip="Copy Asset Name">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
        <div class="asset-info">
          <strong>ID:</strong>
          <span class="asset-value">{{asset.id}}</span>
          <button mat-icon-button (click)="copyToClipboard(asset.id, 'Asset ID')" matTooltip="Copy Asset ID">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-content>
    <div *ngIf="asset" class="asset-details">
      <ngx-json-viewer [json]="asset" [expanded]="true"></ngx-json-viewer>
    </div>
  </mat-card-content>
</mat-card>


