import { ChangeDetectorRef, Component, OnInit, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { RailSightService, WaybillDateDtoWaybillDatePagedListDto,
         WaybillDateDto, AccountFleetDto, FleetTypeDto, Operation, FleetType, TabLabel} from 'src/app/services/railsight.service';
import { AssetRailcarService, GeoMetrixFtpDto } from 'src/app/services/assets.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { forkJoin, Subject } from 'rxjs';
import { ProblemDetails } from 'src/app/services/railsight.service';
import { ViewChildren } from '@angular/core';
import { debounceTime, catchError } from 'rxjs/operators';
import * as Highcharts from 'highcharts';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'ads-account-railsight-tab',
  templateUrl: './account-railsight-tab.component.html',
  styleUrls: ['./account-railsight-tab.component.scss']
})
export class AccountRailSightTabComponent implements OnInit, OnDestroy {
  @ViewChildren('searchText') searchInput;
  @ViewChild('paginator', { read: MatPaginator, static: true }) paginator: MatPaginator;

  private accountId: number;
  private fleetNameLabelText = 'Fleet Name';
  private fleetNameIsaLabelText = 'Sender ISA Fleet';
  private fleetNameGsLabelText = 'Sender GS Fleet';

  // True after all account assets have been retrieved
  public accountAssetsReady: boolean;
  // True when a fleet has been selected and getAccountFleet, getAccountReceiverFleet have not completed
  public fleetAssetsLoading: boolean;
  // True when a fleet has been selected and fleet assets have been retrieved
  public fleetAssetsReady: boolean;
  // True when account fleets have been retrieved
  public fleetsReady: boolean;
  // List of account fleets
  public fleets;
  // Selected account fleet type
  public fleetType = '';
  // Selected account fleet name
  public fleetName = '';
  // Selected account Waybill ISA_06 segment receiver fleet name
  public fleetNameIsa = '';
  // Selected account Waybill GS_02 segment receiver fleet name
  public fleetNameGs = '';
  // Fleet name label text
  public fleetNameLabel = this.fleetNameLabelText;
  // Fleet name ISA label text
  public fleetNameIsaLabel = this.fleetNameIsaLabelText;
  // Fleet name GS label text
  public fleetNameGsLabel = this.fleetNameGsLabelText;
  // Receiver inputs disabled if fleetType is not Sender
  public receiversDisabled = true;
  // Fleet selector selected fleet name value
  public fleetOption = '';
  // Fleet type selector fleet type values
  public fleetTypes;
  public fleetTypeTooltip: string;
  // Fleet type selector selected fleet type value
  public fleetTypeOption;
  // Asset display selector: all, selected, unselected
  public assetOption = 'all';

  // CLM event selector: latest, historical
  public clmEventOption = 'latest';
  // CLM historical date range
  startDate: Date;
  endDate: Date;
  minDate = new Date(1900, 1, 1);
  // CLM last event days ago
  lastEventDays: string;

  // Waybill table
  columnsToDisplay = ['asset', 'billOfLadingNumber', 'waybillDate', 'waybillDateUtc'];
  search: Subject<string> = new Subject();
  waybillDateData = new MatTableDataSource();
  emptyMessage = 'No assets currently added';

  // Show all Waybill fleets
  public showAllWaybillFleets: boolean;
  // Show or hide selected Waybill json
  public showWaybill: boolean;
  // Selected Waybill json
  waybillObject: ArrayBuffer = new ArrayBuffer(0);

  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  editAccountFleetForm: FormGroup;

  // Ag-Grid
  gridDataEvents = { header: null, body: null };
  gridDataAssets = { header: null, body: null };

  // Pagination
  length = 100;
  pageSize = 25;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 100];
  filterValue = '';

  // Status tab
  latestClmDate: Date;
  latestWaybillDate: Date;

  // Highcharts
  weekClmData = [];
  weekClmLabels = [];
  weekWaybillData = [];
  weekWaybillLabels = [];

  weekClmChartOptions: Highcharts.Options = {
    chart: {
      renderTo: 'container',
      type: 'line'
    },
    yAxis: [{
      title: { text: 'Number of CLM Events' }
    }],
    series: [{
      type: 'line',
      data: this.weekClmData
    }],
    xAxis: { categories: this.weekClmLabels }
  };
  weekWaybillChartOptions: Highcharts.Options = {
    chart: {
      type: 'line'
    },
    yAxis: [{
      title: { text: 'Number of Waybills' }
    }],
    series: [{
      type: 'line',
      data: this.weekWaybillData
    }],
    xAxis: { categories: this.weekWaybillLabels }
  };

  updateClmChart = false;
  updateWaybillChart = false;
  Highcharts: typeof Highcharts = Highcharts;
  subscriptionValueChanges;

  public fleetComparison = (option, value): boolean => {
    return option.fleetName === value.fleetName;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public assetRailcarService: AssetRailcarService,
    public railsightService: RailSightService,
    private toasterService: ToasterNotificationService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    this.route.parent.url.subscribe(
      (urlPath) => {
        let accountId = 0;
        if (urlPath.length > 1) {
          accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
        }

        if (isNaN(accountId)) {
          this.router.navigateByUrl('[/accounts]');
        }

        this.accountId = accountId;
        this.accountAssetsReady = false;
        this.fleetAssetsReady = false;
        this.fleetAssetsLoading = false;
        this.fleetsReady = false;
        this.showWaybill = false;
        this.fleets = [];

        this.fleetTypeTooltip = 'Add: Add a new fleet if the fleet name doesn\'t already exist.\r\n \
        Update: Update a fleet type for an existing fleet.\r\n';
      },
      async (error) => {
        this.toasterService.showWarnToaster(await this.getErrorMessage(error));
        throw error;
      }
    );
  }

  ngOnInit() {
    this.editAccountFleetForm = new FormGroup({
      fleetTypeCtrl: new FormControl(),
      fleetNameCtrl: new FormControl(this.fleetName, [Validators.required]),
      fleetNameIsaCtrl: new FormControl(this.fleetNameIsa, [
        this.requiredIfValidator(() =>
          this.editAccountFleetForm.get('fleetTypeCtrl').value === FleetType.Sender)
      ]),
      fleetNameGsCtrl: new FormControl(this.fleetNameGs, [
        this.requiredIfValidator(() =>
          this.editAccountFleetForm.get('fleetTypeCtrl').value === FleetType.Sender)
      ])
    });

    this.subscriptionValueChanges = this.editAccountFleetForm.get('fleetTypeCtrl').valueChanges
      .subscribe(value => {
        this.editAccountFleetForm.get('fleetNameIsaCtrl').updateValueAndValidity();
        this.editAccountFleetForm.get('fleetNameGsCtrl').updateValueAndValidity();

        // Clear fleet if changing between account fleet and account receiver fleet
        if (this.fleetType === '' ||
          (this.fleetType === FleetType.Sender && value !== FleetType.Sender) ||
          (this.fleetType !== FleetType.Sender && value === FleetType.Sender)) {
          this.editAccountFleetForm.get('fleetNameCtrl').reset();
          this.editAccountFleetForm.get('fleetNameIsaCtrl').reset();
          this.editAccountFleetForm.get('fleetNameGsCtrl').reset();
          this.fleetOption = '';
        }

        this.enableDisableReceiverFleets(value);
      });

    this.fleetsReady = false;

    const fleetTasks$ = [];
    fleetTasks$.push(this.getFleetTypes());
    fleetTasks$.push(this.getAccountFleets());
    fleetTasks$.push(this.getAccountReceiverFleets());

    forkJoin(fleetTasks$).subscribe(results => {
      const fleetTypes: any = results[0];
      const accountFleets: any = results[1];
      const accountReceiverFleets: any = results[2];

      if (fleetTypes !== null && fleetTypes.total > 0) {
        this.fleetTypes = fleetTypes.items;
        this.fleetTypeOption = this.fleetTypes[0].type;

        fleetTypes.items.forEach((fleetType: FleetTypeDto) => {
          this.fleetTypeTooltip += fleetType.type + ' - ' + fleetType.description + '\r\n';
        });
      }

      if (accountFleets !== null && accountFleets.total > 0) {
        this.fleets = accountFleets.items;
      }

      if (accountReceiverFleets !== null && accountReceiverFleets.total > 0) {
        // Convert AccountReceiverFleetDto
        accountReceiverFleets.items.forEach(item => {
          const accountFleet = {
            accountId: item.accountId,
            fleetName: item.receiver,
            fleetNameIsa: item.senderISA06,
            fleetNameGs: item.senderGS,
            fleetType: item.fleetType,
            clu: ''
          };
          this.fleets.push(accountFleet);
        });
      }

      this.fleetsReady = true;
    });

    this.accountAssetsReady = false;

    this.getAccountAssets()
      .subscribe(result => {
        this.gridDataAssets = result as any;
        this.accountAssetsReady = true;
      });
  }

  ngOnDestroy() {
    if (this.subscriptionValueChanges !== null && this.subscriptionValueChanges !== undefined
      && !this.subscriptionValueChanges.closed) {
      this.subscriptionValueChanges.unsubscribe();
    }
  }

  requiredIfValidator(predicate) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    });
  }

  enableDisableReceiverFleets(value) {
    if (value !== FleetType.Sender) {
      this.editAccountFleetForm.get('fleetNameIsaCtrl').disable();
      this.editAccountFleetForm.get('fleetNameGsCtrl').disable();
    }
    else {
      this.editAccountFleetForm.get('fleetNameIsaCtrl').enable();
      this.editAccountFleetForm.get('fleetNameGsCtrl').enable();
    }
  }

  onTabClick(event) {
    if (event.tab.textLabel === TabLabel.Waybill) {
      this.search.pipe(debounceTime(500)).subscribe((searchTextValue) => {
        this.filterValue = searchTextValue;
        this.searchAssets();
      });

      const element = this.renderer.selectRootElement('#ac-asset-searchtext');
      element.focus();
      this.changeDetectorRef.detectChanges();
      this.searchAssets();
    } else if (event.tab.textLabel === TabLabel.CLM) {
      this.getLatestRailSightCLMEvents();
    }
    if (event.tab.textLabel === TabLabel.Status) {
      this.getDateLastClmEvent();
      this.chartClmEventCount();
      this.chartWaybillCount();
    }
  }

  // Tabs should be disabled until CLM Account assets are retrieved
  // or if the fleet type is Geo, fleet assets do not apply (Geo fleet is for company codes only)
  // any other fleets do not maintain fleet assets, but should show their fleet events
  disableTabs() {
    return this.accountAssetsReady && this.fleetOption
      && ((this.fleetType === FleetType.CLM && this.fleetAssetsReady) ||
      (this.fleetType !== FleetType.CLM && this.fleetType !== FleetType.Geo));
  }

  getLatestRailSightCLMEvents() {
    this.gridDataEvents.body = null;
    this.railsightService
      .getLatestRailSightCLMEvents(this.accountId, this.fleetName)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe(
        (res: any) => {
          if (res !== null && res !== undefined) {
            this.gridDataEvents = res;

            if (this.gridDataEvents.body !== null && this.gridDataEvents.body.length > 0) {
              const lastEvent: Date = new Date(this.gridDataEvents.body[this.gridDataEvents.body.length - 1].CreatedDate);
              this.lastEventDays = Math.max(0, Math.floor((new Date().getTime() - lastEvent.getTime()) / (1000 * 3600 * 24))).toString();
              this.lastEventDays = this.lastEventDays + (this.lastEventDays === '1' ? ' day ago' : ' days ago');
            }
          }
        });
  }

  getHistoricalRailSightCLMEvents() {
    this.railsightService
      .getHistoricalRailSightCLMEvents(this.accountId, this.fleetName, this.startDate, this.endDate)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe(
        (res: any) => {
          if (res !== null && res !== undefined) {
            this.gridDataEvents = res;
          }
          else {
            this.gridDataEvents.body = null;
          }
        });
  }

  getDateLastClmEvent() {
    this.latestClmDate = null;
    this.railsightService
      .getLatestRailSightCLMEvents(this.accountId, this.fleetName)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe((resp: any) => {
        if (resp !== null && resp !== undefined) {
          this.latestClmDate = new Date(resp.body[resp.body.length - 1].CreatedDate);
        }
      });
  }

  chartClmEventCount() {
    const now = new Date();
    const wk = new Date(now.valueOf() - (7 * 24 * 60 * 60 * 1000));
    // console.log(now);
    // console.log(wk);
    this.weekClmData.length = 0;
    this.weekClmLabels.length = 0;
    this.railsightService
      .getHistoricalRailSightCLMEvents(this.accountId, this.fleetName, wk, now)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe((resp: any) => {
        let dates = [];
        let index = 0;
        if (resp !== null && resp !== undefined && resp.body.length > 0) {
          resp.body.forEach((val) => { dates.push(val.SightingDate.split('T')[0]); });
          dates = dates.sort().filter(value => (new Date(value) >= wk && new Date(value) <= now));
          do {
            const label = dates[index];
            const count = (dates.lastIndexOf(label) - dates.indexOf(label)) + 1;
            this.weekClmData.push(count);
            this.weekClmLabels.push(label);
            index = index + count;
          } while (index < dates.length);
        }

        this.weekClmChartOptions.title = { text: 'CLM Events' };
        this.updateClmChart = true;
      });
  }

  chartWaybillCount() {
    this.latestWaybillDate = null;
    this.weekWaybillData.length = 0;
    this.weekWaybillLabels.length = 0;
    const now = new Date();
    const wk = new Date(now.valueOf() - (7 * 24 * 60 * 60 * 1000));
    this.railsightService
      .getHistoricalRailSightWaybillEvents(this.accountId, this.fleetName, wk, now)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe((resp: any) => {
        if (resp !== null && resp !== undefined && resp.items.length > 0) {
          let dates = [];
          let index = 0;
          resp.items.forEach((val) => {
            dates.push(val.CreatedDate.split('T')[0]);
          });
          this.latestWaybillDate = new Date(resp.items[resp.items.length - 1].CreatedDate);
          dates = dates.sort().filter(value => (new Date(value) >= wk && new Date(value) <= now));
          do {
            const label = dates[index];
            const count = (dates.lastIndexOf(label) - dates.indexOf(label)) + 1;
            this.weekWaybillData.push(count);
            this.weekWaybillLabels.push(label);
            index = count + index;
          } while (index < dates.length);
          this.weekWaybillChartOptions.title = { text: 'Waybill Events' };
          this.updateWaybillChart = true;
        }
      });
  }

  getAccountAssets() {
    return this.railsightService
      .getAccountAssets(this.accountId)
      .pipe(catchError(async err => await this.handleError(err)));
  }

  getFleetTypes() {
    return this.railsightService.getFleetTypes()
      .pipe(catchError(async err => await this.handleError(err)));
  }

  getAccountFleets() {
    return this.railsightService.getAccountFleets(this.accountId)
      .pipe(catchError(async err => await this.handleError(err)));
  }

  getAccountReceiverFleets() {
    return this.railsightService.getAccountReceiverFleets(this.accountId)
      .pipe(catchError(async err => await this.handleError(err)));
  }

  getAccountFleet() {
    this.fleetAssetsReady = false;
    this.fleetAssetsLoading = true;
    this.railsightService.getAccountFleet(this.accountId, this.fleetName)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe((res: any) => {
        if (res !== null && res !== undefined) {
          this.railsightService.accountFleet = res;
          this.fleetName = this.railsightService.accountFleet.fleetName;
          this.fleetNameIsa = '';
          this.fleetNameGs = '';
          this.fleetTypeOption = this.railsightService.accountFleet.fleetType;
          this.editAccountFleetForm.get('fleetNameIsaCtrl').updateValueAndValidity();
          this.editAccountFleetForm.get('fleetNameGsCtrl').updateValueAndValidity();
          this.editAccountFleetForm.controls.fleetNameCtrl.setValue(this.fleetName);
          this.editAccountFleetForm.controls.fleetNameIsaCtrl.setValue(this.fleetNameIsa);
          this.editAccountFleetForm.controls.fleetNameGsCtrl.setValue(this.fleetNameGs);
          // CLM fleets will maintain their fleet assets
          if (this.fleetTypeOption === FleetType.CLM) {
            this.getAccountFleetAssets();
          }
          else {
            this.railsightService.accountFleetAssetNames = [];
            this.fleetAssetsLoading = false;
          }
        } else {
          this.fleetAssetsLoading = false;
        }

        this.enableDisableReceiverFleets(this.fleetType);
      });
  }

  getAccountReceiverFleet() {
    this.fleetAssetsReady = false;
    this.fleetAssetsLoading = true;

    this.railsightService.getAccountReceiverFleet(this.accountId, this.fleetName, this.fleetNameIsa, this.fleetNameGs)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe((res: any) => {
        if (res !== null && res !== undefined) {
          this.railsightService.accountFleet = new AccountFleetDto();
          this.fleetName = this.railsightService.accountFleet.fleetName = res.receiver;
          this.fleetNameIsa = this.railsightService.accountFleet.fleetNameIsa = res.senderISA06;
          this.fleetNameGs = this.railsightService.accountFleet.fleetNameGs = res.senderGS;
          this.fleetTypeOption = this.railsightService.accountFleet.fleetType = res.fleetType;
          this.editAccountFleetForm.get('fleetNameIsaCtrl').updateValueAndValidity();
          this.editAccountFleetForm.get('fleetNameGsCtrl').updateValueAndValidity();
          this.editAccountFleetForm.controls.fleetNameCtrl.setValue(this.fleetName);
          this.editAccountFleetForm.controls.fleetNameIsaCtrl.setValue(this.fleetNameIsa);
          this.editAccountFleetForm.controls.fleetNameGsCtrl.setValue(this.fleetNameGs);
          this.railsightService.accountFleetAssetNames = [];
          this.fleetAssetsLoading = false;
        } else {
          this.fleetAssetsLoading = false;
        }

        this.enableDisableReceiverFleets(this.fleetTypeOption);
      });
  }

  getAccountFleetAssets() {
    this.railsightService
      .getAccountFleetAssets(this.accountId, this.fleetName)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe(
        (res: any) => {
          if (res !== null && res !== undefined && res.total > 0) {

            if (this.railsightService.accountFleetAssetNames !== null && this.railsightService.accountFleetAssetNames !== undefined) {
              this.railsightService.accountFleetAssetNames.length = 0;
            }

            this.railsightService.accountFleetAssetNames = [];
            res.items.forEach((fleetAsset) => {
              this.railsightService.accountFleetAssetNames.push(fleetAsset.asset);
            });
          }

          this.fleetAssetsReady = true;
          this.fleetAssetsLoading = false;
        });
  }

  updateAccountFleetCLU() {
    this.railsightService
      .updateAccountFleetCLU(this.accountId, this.fleetName)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe(
        (res: any) => {
          this.uploadAccountFleetCLU();
        });
  }

  uploadAccountFleetCLU() {
    this.railsightService
      .uploadAccountFleetCLU(this.accountId)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe(
        (res: any) => {
          this.toasterService.showInfoToaster('Account fleet updated!');
          this.getAccountFleet();
        });
  }

  onAddAccountFleet(fleetName: string, fleetNameIsa: string, fleetNameGs: string) {
    if (this.editAccountFleetForm.invalid) {
      this.toasterService.showWarnToaster('Invalid form');
      return;
    }

    this.fleetName = fleetName;
    this.fleetNameIsa = fleetNameIsa;
    this.fleetNameGs = fleetNameGs;

    let fleet: AccountFleetDto;

    if (this.fleets !== null && this.fleets !== undefined) {
      // Lookup fleet by all names. Only Sender fleets will include ISA and GS fleet names
      fleet = this.fleets.find(x => x.fleetName === this.fleetName
        && x.fleetNameIsa === this.fleetNameIsa && x.fleetNameGs === this.fleetNameGs);
    }

    if (fleet !== null && fleet !== undefined) {
      this.toasterService.showWarnToaster('Account fleet name already exists');
      return;
    }

    if (this.fleetTypeOption === FleetType.Sender) {
      this.addAccountReceiverFleet();
    }
    else {
      this.addAccountFleet();
    }
  }

  addAccountFleet() {
    // Allow only one Geo fleet
    if (this.fleetTypeOption === FleetType.Geo) {
      const geoFleet = this.fleets.find(x => x.fleetType === FleetType.Geo);
      if (geoFleet) {
        this.toasterService.showWarnToaster('Geo fleet type already exists');
        return;
      }
    }

    this.railsightService
      .addAccountFleet(this.accountId, this.fleetName, this.fleetTypeOption)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe(
        (res: any) => {
          this.toasterService.showInfoToaster('Account fleet added!');

          // Add fleet to selector and make active
          const fleet = {
            accountId: this.accountId,
            fleetName: this.fleetName,
            fleetType: this.fleetTypeOption,
            clu: ''
          };

          this.fleets.push(fleet);

          const newFleet = this.fleets.find(x => x.fleetName === this.fleetName);

          // If a new Geo fleet, add it to the assets GeoMetrixFTP table
          if (newFleet.fleetType === FleetType.Geo) {
            this.saveGeoMetrixFtpName(newFleet.accountId, newFleet.fleetName);
          }

          this.fleetOption = newFleet;

          this.getAccountFleet();
        });
  }

  addAccountReceiverFleet() {
    this.railsightService
      .addAccountReceiverFleet(this.accountId, this.fleetName, this.fleetNameIsa, this.fleetNameGs, this.fleetTypeOption)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe(
        (res: any) => {
          this.toasterService.showInfoToaster('Account receiver fleet added!');

          // Add fleet to selector and make active
          const fleet = {
            accountId: this.accountId,
            fleetName: this.fleetName,
            fleetNameIsa: this.fleetNameIsa,
            fleetNameGs: this.fleetNameGs,
            fleetType: this.fleetTypeOption
          };

          this.fleets.push(fleet);

          const newFleet = this.fleets.find(x => x.fleetName === this.fleetName
            && x.fleetNameIsa === this.fleetNameIsa && x.fleetNameGs === this.fleetNameGs);

          this.fleetOption = newFleet;

          this.getAccountReceiverFleet();
        });
  }

  onUpdateAccountFleet() {
    if (this.editAccountFleetForm.invalid) {
      this.toasterService.showWarnToaster('Invalid form');
      return;
    }

    if (!this.fleets) {
      this.toasterService.showWarnToaster('No fleets found');
      return;
    }

    // AccountReceiverFleet is not currently updated. Only Sender fleet type exists.
    const fleet = this.fleets.find(x => x.fleetName === this.fleetName);

    if (fleet === undefined) {
      this.toasterService.showWarnToaster('Account fleet name not found');
      return;
    }

    if (fleet.fleetType === this.fleetTypeOption) {
      this.toasterService.showInfoToaster('Fleet Type not changed. Nothing to update.');
      return;
    }

    const accountFleetPatch: Operation[] = [
      new Operation({ op: 'replace', path: '/FleetType', value: this.fleetTypeOption })
    ];

    this.updateAccountFleet(fleet, accountFleetPatch);
  }

  updateAccountFleet(fleet, accountFleetPatch: Operation[]) {
    this.railsightService
      .updateAccountFleet(this.accountId, this.fleetName, accountFleetPatch)
      .pipe(catchError(async err => { await this.handleError(err); }))
      .subscribe(
        (res: any) => {
          this.toasterService.showInfoToaster('Account fleet updated!');

          // Update fleet selector
          fleet.fleetType = this.fleetTypeOption;

          // If changing to Geo fleet, add it to the assets GeoMetrixFTP table
          // If changing to another fleet type, remove it from the assets GeoMetrixFTP table
          if (fleet.fleetType === FleetType.Geo) {
            this.saveGeoMetrixFtpName(fleet.accountId, fleet.fleetName);
          }
          else {
            this.deleteGeoMetrixFtpName(fleet.accountId, fleet.fleetName);
          }

          this.fleetOption = fleet;

          this.getAccountFleet();
        });
  }

  saveGeoMetrixFtpName(accountId: number, fleetName: string) {

    this.assetRailcarService
      .saveGeoMetrixFtpLocation(new GeoMetrixFtpDto({ accountId, name: fleetName }))
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe(
        (res: any) => {
          this.toasterService.showInfoToaster('GeoMetrix FTP folder name added!');
        });
  }

  deleteGeoMetrixFtpName(accountId: number, fleetName: string) {

    this.assetRailcarService
      .deleteGeoMetrixFtpLocation(new GeoMetrixFtpDto({ accountId, name: fleetName }))
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe(
        (res: any) => {
          this.toasterService.showInfoToaster('GeoMetrix FTP folder name removed!');
        });
  }

  onUploadCLU() {
    this.railsightService.emitOnUploadCLUButtonSelected();
  }

  downloadToExcel() {
    this.railsightService.emitOnDownloadButtonSelected();
  }

  resetAssetsGrid() {
    this.railsightService.emitOnResetAssetsGridSelected();
  }

  onRowSelectionChangedFleets(opt) {
    this.fleetName = opt.value.fleetName;
    this.fleetNameIsa = opt.value.fleetNameIsa;
    this.fleetNameGs = opt.value.fleetNameGs;
    this.fleetType = opt.value.fleetType;
    if (this.fleetType !== FleetType.Sender) {
      this.getAccountFleet();
    }
    else {
      this.getAccountReceiverFleet();
    }

    this.updateLabels(opt.value.fleetType);
  }

  onRowSelectionChangedFleetAssets(opt) {
    switch (opt.value) {
      case 'all':
        this.railsightService.emitOnAllRowsSelected();
        break;
      case 'selected':
        this.railsightService.emitOnSelectedRowsSelected();
        break;
      case 'unselected':
        this.railsightService.emitOnUnselectedRowsSelected();
        break;
    }
  }

  onRowSelectionChangedFleetTypes(opt) {
    this.updateLabels(opt.value);
  }

  updateLabels(fleetType) {
    this.fleetNameLabel = fleetType === FleetType.Sender ? 'Receiver Fleet Name' : this.fleetNameLabelText;
    this.fleetNameIsaLabel = fleetType === FleetType.Sender ? this.fleetNameIsaLabelText : '';
    this.fleetNameGsLabel = fleetType === FleetType.Sender ? this.fleetNameGsLabelText : '';
  }

  toggleSelectedRows() {
    this.railsightService.emitOnSelectedRowsSelected();
  }

  toggleUnselectedRows() {
    this.railsightService.emitOnUnselectedRowsSelected();
  }

  toggleAllRows() {
    this.railsightService.emitOnAllRowsSelected();
  }

  onRowSelectionChangedCLMEvents(opt) {
    switch (opt.value) {
      case 'latest':
        this.getLatestRailSightCLMEvents();
        break;
      case 'historical':
        break;
    }
  }

  dateChanged(type: string, event: MatDatepickerInputEvent<any>) {
    // Set time to 0 in UTC
    if (type === 'start') {
      this.startDate = event.value;
    } else if (type === 'end') {
      this.endDate = event.value;
    }
  }

  ////////////////////////////////////
  // Waybill
  ////////////////////////////////////

  triggerJSONChangeDetection() {
    this.changeDetectorRef.detectChanges();
  }

  updatePaginator(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.railsightService
      .waybillDates(this.filterValue, this.accountId, this.fleetName, this.pageIndex, this.pageSize)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe(
        (resp: WaybillDateDtoWaybillDatePagedListDto) => {
          this.populateWaybillDates(resp);
        });
  }

  populateWaybillDates(resp: WaybillDateDtoWaybillDatePagedListDto) {
    const waybillDateData = resp.items.map((u: WaybillDateDto) => ({ ...u }));
    waybillDateData.sort((a, b) => a.asset.localeCompare(b.asset));
    this.waybillDateData = new MatTableDataSource(waybillDateData);
    if (this.waybillDateData.filteredData.length === 0) {
      this.emptyMessage = 'No results match search term ' + this.filterValue;
    }
    this.length = resp.total;
  }

  searchTriggered(filterValue: any) {
    this.filterValue = filterValue;
    this.search.next(filterValue);
  }

  searchAssets() {
    const fleetName = this.showAllWaybillFleets ? null : this.fleetName;
    this.paginator.firstPage();
    if (this.filterValue !== '') {
      this.pageIndex = 0;
      this.railsightService
        .waybillDates(this.filterValue, this.accountId, fleetName, this.pageIndex, this.pageSize)
        .pipe(catchError(async err => await this.handleError(err)))
        .subscribe(
          (resp: WaybillDateDtoWaybillDatePagedListDto) => {
            this.populateWaybillDates(resp);
          });
    } else {
      // no filter value, load all
      this.railsightService
        .waybillDates('', this.accountId, fleetName, this.pageIndex, this.pageSize)
        .pipe(catchError(async err => await this.handleError(err)))
        .subscribe(
          (resp: WaybillDateDtoWaybillDatePagedListDto) => {
            this.populateWaybillDates(resp);
          });
    }
  }

  onChangeShowAllWaybillFleets($event) {
    this.searchAssets();
  }

  onRowClick(asset: WaybillDateDto) {
    this.railsightService
      .getWaybill(this.accountId, asset.asset, asset.waybillDate, asset.createdDate)
      .pipe(catchError(async err => await this.handleError(err)))
      .subscribe(
        (res: ArrayBuffer) => {
          this.waybillObject = res;
          this.showWaybill = true;

          setTimeout(() => {
            document.getElementById('assetmgmt').scrollIntoView();
          }, 100);
        });
  }

  async handleError(error) {
    const err = await this.getErrorMessage(error);
    this.toasterService.showWarnToaster(err);
    throw err;
  }

  async getErrorMessage(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else if (error.response !== undefined && error.response.includes('detail')) {
      // server-side ProblemDetails error
      const resultData = JSON.parse(error.response, this.jsonParseReviver);
      const result = ProblemDetails.fromJS(resultData);
      errorMessage = result.detail;
    } else if (error.error !== undefined && error.error.detail !== undefined) {
      // server-side ProblemDetails error
      errorMessage = error.error.detail;
    }
    else if (error.error instanceof Blob) {
      // client-side ProblemDetails error
      await error.error.text().then(text => {
        const resultData = JSON.parse(text, this.jsonParseReviver);
        const result = ProblemDetails.fromJS(resultData);
        errorMessage = result.detail;
      });
    }
    else if (error.message === undefined) {
      // service error
      errorMessage = error;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return errorMessage;
  }
}
