import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountWorkspaceAssetsV3Service, ShareAdsDataFeedBulkRequest, ShareAdsDataFeedBulkRequestAssetShare, ShareAdsDataFeedBulkResult, AccountAssetResponse } from '../../../../../../services/assets-v3.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ColDef, GridApi, GridOptions, GridReadyEvent, RowSelectedEvent, ValueFormatterParams } from 'ag-grid-community';
import { ODataServerSideDataSource } from '../../../../../../services/ODataServerSideDataSource';
import { ToasterNotificationService } from '../../../../../../services/toasterNotification.service';

@Component({
  selector: 'ads-message-dialog',
  templateUrl: 'share-assets-dialog.component.html',
  styleUrls: ['./share-assets-dialog.component.scss']
})
export class ShareAssetsDialogComponent implements OnInit {
  public canReshare: boolean = false;
  pageSize: number = 100;
  sourceAccountId: number;
  sourceAccountName: string;
  targetAccountId: number;
  targetAccountName: number;
  selectAssets: boolean =  false;
  enableSelectedAssets: boolean = false;
  searchODataFilter: string = undefined;
  searchODataOrderBy: string = undefined;
  assetsSelected: any[] = [];
  searchSubject: Subject<string> = new Subject();
  columnDefs: ColDef[] = [
    { headerName: '', field: 'share', width: 50, checkboxSelection: this.checkboxSelection },
    { headerName: 'Name', field: 'name', sortable: true }
  ];
  gridOptions: GridOptions = {
    rowModelType: 'infinite',
    context: {
      nextPageContinuationToken: null
    },
    datasource: new ODataServerSideDataSource(this.getData.bind(this)),
  };
  gridApi: GridApi;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private accountWorkspaceAssetsV3Service: AccountWorkspaceAssetsV3Service,
    private toasterService: ToasterNotificationService,
    private dialogRef: MatDialogRef<ShareAssetsDialogComponent>) {
      if (data) {
        this.sourceAccountId = data.sourceAccountId;
        this.sourceAccountName = data.sourceAccountName;
        this.targetAccountId = data.targetAccountId;
        this.targetAccountName = data.targetAccountName;
      }
  }

  checkboxSelection(params: ValueFormatterParams) {
    if (!params.data) return;
    const asset: AccountAssetResponse = params.data;
    return asset.canShareADSDataFeed;
  }

  ngOnInit() {
    this.searchSubject
    .pipe(debounceTime(500))
    .subscribe(x => {
        if (x.indexOf(',') > -1) {
          var nameArr = x.split(',');
          var queryString = "name eq '" + nameArr[0] + "'";
          nameArr.forEach(x => {
            queryString = queryString + "or name eq '" + x + "'";
          })

          this.searchODataFilter = queryString;
        } else if (x) {
          const normalizedX = x.replace(/[^a-zA-Z0-9]/g, '');
          this.searchODataFilter = `contains(nameNormalized, '${normalizedX}')`;
        } else {
          this.searchODataFilter = undefined;
        }

        this.gridApi.setDatasource(this.gridOptions.datasource);
      });
  }

  getData(odataFilter: string, odataOrderBy: string, continuationToken: string): Promise<any> {
    return this.accountWorkspaceAssetsV3Service.getAssetsInWorkspace(this.sourceAccountId, "all",
      this.pageSize, this.searchODataFilter, odataOrderBy, undefined, undefined, continuationToken)
      .toPromise();
  }

  onSearchKeyup(event: KeyboardEvent) {
    this.searchSubject.next((event.target as HTMLInputElement).value);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  onRowSelected(event: RowSelectedEvent) {
    this.enableSelectedAssets = this.gridApi.getSelectedNodes().length > 0;
  }

  viewAssetsClick() {
    this.selectAssets = true;
  }

  selectAssetsClick() {
    this.assetsSelected = [];
    this.gridApi.getSelectedNodes().forEach((node) => {
      if(this.assetsSelected.find(x => x.id == node.data.id) == null) {
        this.assetsSelected.push(node.data);
      }
    });
    this.selectAssets = false;
  }

  shareAssetsClick() {
    var shareBulkRequest = new ShareAdsDataFeedBulkRequest();
    var shareBulkRequestShares: ShareAdsDataFeedBulkRequestAssetShare[] = [];

    shareBulkRequest.targetAccountId = this.targetAccountId;

    this.assetsSelected.forEach(x => shareBulkRequestShares.push(new ShareAdsDataFeedBulkRequestAssetShare({
      id: x.id,
      canReshare: this.canReshare
    })));

    shareBulkRequest.assetShares = shareBulkRequestShares;
    this.accountWorkspaceAssetsV3Service.shareAdsDataFeedBulk(this.sourceAccountId, "all", "Ops Portal", shareBulkRequest)
      .subscribe((x: any) => {
        this.handleErrorResponse(x);
        this.dialogRef.close(true);
      }
    );
  }

  handleErrorResponse(response: [ShareAdsDataFeedBulkResult]) {
    var errorDisplay = "";
    response.forEach(x => {
      if(x.notFound) {
        errorDisplay += "Asset not found: " + x.assetId + ". ";
      }
      if(!x.success && x.errors != null) {
        errorDisplay += "Error for asset " + x.assetId + ": " + JSON.stringify(x.errors) + ". ";
      }
    })
    if(errorDisplay != "") {
      this.toasterService.showWarnToaster(JSON.stringify(errorDisplay));
    }
  }

  removeSelectedAsset(asset: AccountAssetResponse) {
    this.assetsSelected = this.assetsSelected.filter(x => x.id != asset.id);
    this.gridApi.getSelectedNodes().forEach(node => {
      if(node.data.id == asset.id) {
        node.setSelected(false);
      }
    });
  }

  cancel(): void {
      this.dialogRef.close(false);
  }
}
