<mat-card *ngIf="!rsrd2Service.isConnected">
    <mat-card-title>RSRD2 Data Service</mat-card-title>
    <mat-card-subtitle>Manage RSRD2 Access</mat-card-subtitle>
    <mat-card-content>
        <mat-divider></mat-divider>
        <br>
        Login to setup RSRD2 data service
    </mat-card-content>
    <form [formGroup]="RSRD2Login"
        (ngSubmit)="connectAccount(RSRD2Login.controls.username.value, RSRD2Login.controls.password.value)">
        <mat-card-content>
            <mat-error *ngIf="invalidCredentials">
                The Username and/or Password entered is incorrect!
            </mat-error>
            Username <span style="color:red">*</span>
            <mat-form-field>
                <input formControlName="username" [errorStateMatcher]="matcher" (keyup)="validateCredentials()"
                    matInput>
                <ads-control-messages [control]='RSRD2Login.get("username")'></ads-control-messages>
            </mat-form-field>
            Password <span style="color:red">*</span>
            <mat-form-field>
                <input formControlName="password" [errorStateMatcher]="matcher" (keyup)="validateCredentials()"
                    type="password" matInput>
                <ads-control-messages [control]='RSRD2Login.get("password")'></ads-control-messages>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
            <button [disabled]="!submitEnabled" class="button" type="submit">Connect Datafeed</button>
        </mat-card-actions>
    </form>
</mat-card>
<mat-card *ngIf="rsrd2Service.isConnected">
    <mat-card-title>Asset Management</mat-card-title>
    <mat-card-subtitle class="event-subtitle">RSRD2 Wagon Details</mat-card-subtitle>
    <mat-card-action align="start" class="right">
        <div class="action-wrapper">
            <button [disabled]="initializing" class="button-lg" (click)="disconnectAccount()">Disconnect Datafeed</button>
        </div>
        <br>
        <div class="action-wrapper">
            <div *ngIf="rsrd2Service.isRefreshing || initializing" class="refresh">
                <span class="refresh-text">Retrieving data please wait&nbsp;&nbsp;</span>
                <mat-spinner class="mat-spinner-color" diameter="20"></mat-spinner>
            </div>
            <div *ngIf="!rsrd2Service.isRefreshing" class="refresh">
                <span class="refresh-text">Last Refresh: {{lastRefreshDays}}&nbsp;&nbsp;</span>
            </div>
            <button [disabled]="rsrd2Service.isRefreshing || initializing" class="button-sm" (click)="getRollingStockDataset()">Refresh</button>
            <button [disabled]="!rsrd2Service.exportEnabled || initializing" class="button-sm" (click)="downloadToExcel()">Export</button>
        </div>
    </mat-card-action>
    <mat-divider></mat-divider>
    <mat-card-content *ngIf="!initializing" class="aggrid-height">
        <ads-ag-grid-rsrd2 class="aggrid-height" [body]="gridData.body" [header]="gridData.header"></ads-ag-grid-rsrd2>
    </mat-card-content>
</mat-card>
<ads-alert-dialog *ngIf="refreshError"></ads-alert-dialog>