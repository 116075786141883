<div class="card-layout">
  <mat-card class="form-card mat-card">
    <mat-card-title id="ae-title">
      Edit Account
    </mat-card-title>
    <form (ngSubmit)="onSave()">
      <ng-container [formGroup]="editAccountForm">
        <mat-card-content>
          <mat-form-field>
            <mat-label class="form-field-label">Account Name</mat-label>
            <input id="ae-accountname" matInput formControlName="accountName" maxlength="100" required>
            <ads-control-messages [control]='editAccountForm.get("accountName")'></ads-control-messages>
          </mat-form-field>
          <mat-form-field>
            <mat-label class="form-field-label">SAP Account Number</mat-label>
            <input id="ae-sapaccountnumber" matInput type="text" formControlName="sapAccountNumber" mask="0999999999"
            placeholder="Enter an account number between 1 and 999999999" [specialCharacters]="[]" max="999999999" required>
            <ads-control-messages [control]='editAccountForm.get("sapAccountNumber")'></ads-control-messages>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions align="end">
          <button id="ae-cancelbutton" type="button" mat-stroked-button adsBackbutton>Cancel</button>
          <button id="ae-submitbutton" [disabled]="editAccountForm.invalid" mat-flat-button color="primary" type="submit">Save</button>
        </mat-card-actions>
      </ng-container>
    </form>
  </mat-card>
</div>
