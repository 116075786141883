<div class="my-3">
  <mat-card>
    <mat-card-title fxLayout="row" fxLayoutAlign="space-between"> Location Visualizer </mat-card-title>
    <mat-card-subtitle>Location Visualizer </mat-card-subtitle>

    <mat-divider></mat-divider>

    <mat-card-content>
      <div style="display: flex; flex-direction: row">
        <div *ngIf="apiLoaded" style="flex-grow: 1">
          <google-map #map1 width="100%" height="550px" [options]="defaultMapOptions"> </google-map>
        </div>

        <mat-accordion style="width: 350px; height: 550px; padding-left: 10px">
          <div style="width: 100%">
            <button mat-button color="primary" (click)="newLocationBtnClicked()">Add Location</button>

            <button mat-button color="warn" style="float: right" (click)="removeAllBtnClicked()">Remove All</button>
          </div>

          <div style="overflow-y: scroll; height: 500px">
            <mat-expansion-panel *ngFor="let location of locationListController.locations" (opened)="locationItemClicked(location)">
              <mat-expansion-panel-header>
                <div>
                  <b>{{ location.name }}</b>
                </div>
              </mat-expansion-panel-header>
              <div>
                <mat-panel-description *ngIf="location.center"><b>Latitude:&nbsp;</b> {{ location.center[1] }} </mat-panel-description>
                <mat-panel-description *ngIf="location.center"><b>Longitude:&nbsp;</b> {{ location.center[0] }} </mat-panel-description>
                <mat-panel-description *ngIf="location.locationType">
                  <b>Location Type:&nbsp;</b> {{ location.locationType }}
                </mat-panel-description>
                <mat-panel-description *ngIf="location.referenceCode">
                  <b>SPLC:&nbsp;</b> {{ location.referenceCode }}
                </mat-panel-description>
                <mat-panel-description *ngIf="location.Country"><b>Country:&nbsp;</b> {{ location.Country }} </mat-panel-description>
                <mat-panel-description *ngIf="location.State"><b>State:&nbsp;</b> {{ location.State }} </mat-panel-description>
                <mat-panel-description *ngIf="location.City"><b>City:&nbsp;</b> {{ location.City }} </mat-panel-description>
              </div>
            </mat-expansion-panel>
          </div>
        </mat-accordion>
      </div>
    </mat-card-content>

    <mat-divider></mat-divider>

    <label class="sm-note">Locations Visualizer</label>
  </mat-card>
</div>
