import { Component, OnInit } from '@angular/core';
import { AccountSummaryModel, ActiveTripViewModel, TripsService } from 'src/app/services/shipments-v2.service';

@Component({
  templateUrl: './trips.page.html',
  styleUrls: ['./trips.page.scss']
})
export class TripsPage implements OnInit {
  tripsViewModel: ActiveTripViewModel | undefined;
  accounts: AccountSummaryModel[] = [];

  constructor(
    private tripService: TripsService,
  ) {
  }

  ngOnInit(): void {
    this.onInitAsync();
  }

  async onInitAsync() {
    const tripsViewModel = await this.tripService.getActiveTrips().toPromise();
    const accountSummaries = await this.tripService.getActiveTripAccountSummaries().toPromise();

    this.tripsViewModel = tripsViewModel;
    this.accounts = accountSummaries
      .sort((a, b) => (b.activeTripCount as any) - (a.activeTripCount as any));
  }
}
