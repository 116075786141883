<div fxFlex>
  <mat-form-field>
    <mat-label>Workspace: </mat-label>
    <mat-select id="as-workspacedropdown" [(value)]="workspaceSelected" (selectionChange)="onSelectionChanged($event)" placeholder={{placeholder}} disableOptionCentering #singleSelect>
        <mat-option *ngFor="let workspace of workspaces" id="{{workspace.id}}" [value]="workspace.id">
          {{workspace.displayName}}
        </mat-option>
    </mat-select>
    <ads-control-messages *ngIf="this.control != null" [control]="this.control"></ads-control-messages>
  </mat-form-field>
</div>