import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-changes-confirmation-popup',
  templateUrl: './changes-confirmation-popup.component.html',
  styleUrls: ['./changes-confirmation-popup.component.scss']
})
export class ChangesConfirmationPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { popUpTitle: number; popUpDescription: string },
    public dialogRef: MatDialogRef<ChangesConfirmationPopupComponent>
  ) {}

  ngOnInit(): void {}

  close(params?) {
    this.dialogRef.close(params);
  }
}
