import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { UserGroupsService, UserGroupResponse } from '../../../../../services/users.service';
import { ToasterNotificationService } from '../../../../../services/toasterNotification.service';

@Component({
  selector: 'ads-user-groups-table',
  templateUrl: './user-groups-table.component.html',
  styleUrls: ['./user-groups-table.component.scss']
})
export class UserGroupsTableComponent implements OnInit {

  private accountId: number;

  userGroupsTableData = new MatTableDataSource();
  emptyUserGroupsData: boolean;
  baseUserGroupsMessage: string;
  emptyUserGroupsMessage: string;

  columnsToDisplay: string[] = ['name', 'userCount', 'action'];

  constructor(private router: Router,
    private route: ActivatedRoute,
    // private assetGroupService: AssetGroupsService,
    private userGroupService: UserGroupsService,
    private toasterService: ToasterNotificationService) {
    this.route.parent.url.subscribe((urlPath) => {
      let accountId = 0;
      if (urlPath.length > 1) {
        accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
      }

      if (isNaN(accountId)) {
        this.router.navigateByUrl('[/accounts]');
      }

      this.accountId = accountId;
    });
  }

  ngOnInit() {
    this.emptyUserGroupsData = true;
    this.baseUserGroupsMessage = '';
    this.emptyUserGroupsMessage = this.baseUserGroupsMessage;

    this.searchUserGroups('');
  }

  searchUserGroups(filterValue: string) {
    this.emptyUserGroupsMessage = 'No results';
    this.userGroupService.userGroups(this.accountId, filterValue).subscribe(
      ((userGroups: UserGroupResponse[]) => {
        const userGroupsData = userGroups;
        userGroupsData.sort((a, b) => a.groupName.localeCompare(b.groupName));
        this.userGroupsTableData = new MatTableDataSource(userGroupsData);
        this.emptyUserGroupsData = this.userGroupsTableData.filteredData.length === 0;
        this.emptyUserGroupsMessage = 'No results';
      }),
      error => {
        this.toasterService.showWarnToaster(error.detail);
      });
  }

  onRowClick(event): void {
    this.router.navigate(['user-group', `${ event.userGroupId }`], { relativeTo: this.route.parent });
  }

  createUserGroup(): void {
    this.router.navigate(['user-group'], { relativeTo: this.route.parent });
  }
}
