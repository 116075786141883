import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  config: any;

  constructor() {
  }

  getAllTags() {
    // gets all the tags from the backend
    return {
      tags: [
        { tagId: 1, tagName: 'apple' },
        { tagId: 2, tagName: 'banana' },
        { tagId: 3, tagName: 'durian' },
        { tagId: 4, tagName: 'pineapple' },
        { tagId: 5, tagName: 'blackberry' },
        { tagId: 6, tagName: 'mango' },
        { tagId: 7, tagName: 'watermelon' },
        { tagId: 8, tagName: 'payaya' },
        { tagId: 9, tagName: 'dragonfruit' },
        { tagId: 10, tagName: 'fig' },
        { tagId: 11, tagName: 'date' },
        { tagId: 12, tagName: 'melon' }
      ]
    }; // add this.httpOptions when you have the api
  }
  getItemTags(id: string, tagType: string) {
    // gets all the tags from the backend //case swict statement based on type -- device/assets etc..
    return { tags: [{ tagId: 1, tagName: 'apple' }] };
  } // add this.httpOptions when you have the api
  addTag() {
    // should add tags to list
  }
  removeAssociation() {
    // should remove tags that are associated with certain properties
  }
}
