import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { InstallerLocationResponse, InstallerLocationsService, UpdateInstallerLocation } from '../../../services/accounts.service';

@Component({
  selector: 'ads-app-edit-installer-locations',
  templateUrl: './edit-installer-locations.component.html',
  styleUrls: ['./edit-installer-locations.component.scss']
})
export class EditInstallerLocationsComponent implements OnInit, OnDestroy {
  _onDestroy = new Subject<void>();
  editInstallerLocationForm: FormGroup;
  originalInstallerLocationValues: InstallerLocationResponse;

  constructor(private activatedRoute: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder, private installerLocationsService: InstallerLocationsService,
    private router: Router, private toasterService: ToasterNotificationService) { }

  ngOnInit() {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.originalInstallerLocationValues = this.router.getCurrentNavigation().extras.state.installerLocation;
      this.setUpForm();
    }
    else {
      this.getInstaller();
    }
  }

  getInstaller() {
    const companyCode = this.activatedRoute.snapshot.paramMap.get('companyCode');

    this.installerLocationsService.installerLocation(companyCode).subscribe(installerLocation => {
      if (installerLocation) {
        this.originalInstallerLocationValues = installerLocation;
        this.setUpForm();
      }
    },
      error => {
        this.toasterService.showWarnToaster(error.detail);
      });
  }

  setUpForm() {
    this.editInstallerLocationForm = this.fb.group({
      customerId: [this.originalInstallerLocationValues.customerId, Validators.required],
      companyName: [this.originalInstallerLocationValues.companyName, Validators.required],
      companyCode: [this.originalInstallerLocationValues.companyCode, Validators.required],
      houseNo: [this.originalInstallerLocationValues.houseNo],
      street: [this.originalInstallerLocationValues.street, Validators.required],
      street2: [this.originalInstallerLocationValues.street2],
      city: [this.originalInstallerLocationValues.city],
      region: [this.originalInstallerLocationValues.region],
      country: [this.originalInstallerLocationValues.country, Validators.required],
      postal: [this.originalInstallerLocationValues.postal, Validators.required]
    });

    this.editInstallerLocationForm.get('companyCode').disable();
  }

  onSubmit() {
    const updateInstallerLocation: UpdateInstallerLocation = {
      customerId: this.editInstallerLocationForm.controls.customerId.value,
      companyName: this.editInstallerLocationForm.controls.companyName.value,
      companyCode: this.editInstallerLocationForm.controls.companyCode.value,
      houseNo: this.editInstallerLocationForm.controls.houseNo.value,
      street: this.editInstallerLocationForm.controls.street.value,
      street2: this.editInstallerLocationForm.controls.street2.value,
      city: this.editInstallerLocationForm.controls.city.value,
      region: this.editInstallerLocationForm.controls.region.value,
      country: this.editInstallerLocationForm.controls.country.value,
      postal: this.editInstallerLocationForm.controls.postal.value,
      init: null,
      toJSON: null
    };

    this.installerLocationsService.updateInstallerLocation(updateInstallerLocation.companyCode, updateInstallerLocation).subscribe(() => {
      this.router.navigate([`/accounts/installer-location`]);
      this.toasterService.showInfoToaster('Installer location has been updated.');
    },
      error => {
        this.toasterService.showWarnToaster('ERROR: Unable to update installer location.');
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

