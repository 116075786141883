import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountAssetEventFactoryEvent } from 'src/app/services/assets-v3.service';

@Component({
  selector: 'app-asset-trace-details-dialog',
  templateUrl: './asset-trace-details-dialog.component.html',
  styleUrls: [],
})
export class AssetTraceDetailsDialogComponent implements OnInit {

  assetTraceDetails: AccountAssetEventFactoryEvent;
  constructor(
    @Inject(MAT_DIALOG_DATA) assetRouteData: AccountAssetEventFactoryEvent,
    private dialogRef: MatDialogRef<AssetTraceDetailsDialogComponent>) {
      if (assetRouteData) {
        this.assetTraceDetails = assetRouteData;
      }
  }

  ngOnInit(): void {
  }

  cancel(): void {
      this.dialogRef.close(false);
  }
}
