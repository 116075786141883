import { NgModule, } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { EmptyLoadComponent } from './empty-load.component';
import { EmptyLoadRoutingModule } from './empty-load-routing.module';
import { EmptyLoadConfigListComponent } from './empty-load-config-list/empty-load-config-list.component';
import { AssetsService as EmptyLoadAssetsService, EMPTY_LOAD_API_BASE_URL } from '../services/empty-load.service';
import { environment } from '@env/environment';
import { AgGridModule } from 'ag-grid-angular';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';
import { EmptyLoadEmptyComponent } from './empty-load-empty/empty-load-empty.component';
import { EmptyLoadEditComponent } from './empty-load-edit/empty-load-edit.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    EmptyLoadComponent,
    EmptyLoadConfigListComponent,
    EmptyLoadEmptyComponent,
    EmptyLoadEditComponent
  ],
  imports: [
    CommonModule,
    EmptyLoadRoutingModule,
    AgGridModule,
    CommonUiSharedModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    
  ],
  providers: [
    DecimalPipe,
    EmptyLoadAssetsService,
    { provide: EMPTY_LOAD_API_BASE_URL, useValue: environment.emptyLoadBaseUrl}
  ]
})
export class EmptyLoadModule { }
