import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { LocationResponse, LocationsService } from 'src/app/services/geo-v2.service';
import { DefaultMapOptions } from '../../constants';
import { IHasLocation, TripEventViewModel } from '../../types';

type EventMarkerOptionsMap = { [type: string]: google.maps.MarkerOptions };

type GeoJsonLineStringFeature = {
  type: "Feature",
  properties?: undefined | {
    style?: google.maps.Data.StyleOptions | undefined,
    [name: string]: any
  }
  geometry: {
    type: "LineString",
    coordinates: number[][]
  }
}

type TripEventMarker = TripEventViewModel & {
  options: google.maps.MarkerOptions
};

@Component({
  selector: 'events-map',
  templateUrl: './events-map.component.html',
  styleUrls: ['./events-map.component.scss']
})
export class EventsMapComponent implements OnInit {
  @Input("events") eventsInput: TripEventViewModel[] = [];
  @ViewChild(GoogleMap) map: GoogleMap;
  @ViewChild(MapInfoWindow) mapInfoWindow: MapInfoWindow;
  markers: TripEventMarker[] = [];
  infoWindowMarker: TripEventViewModel | null = null;
  infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();
  eventOptionsMap: EventMarkerOptionsMap = {};
  eventOptionsArray: { type: string, options: google.maps.MarkerOptions }[] = [];
  eventTypes: string[] = [];
  mapOptions: google.maps.MapOptions = DefaultMapOptions;
  mapReady: Promise<void>;
  lois: LocationResponse[] | null = null;
  loiPolygons: google.maps.Polygon[] | null = null;
  ready: boolean = false;

  constructor(
    private loiService: LocationsService
  ) {
    this.mapReady = new Promise((resolve) => {
      var intervalToken = setInterval(() => {
        if (this.map) {
          resolve();
          clearInterval(intervalToken);
        }
      }, 500);
    });
  }

  ngOnInit(): void {
    this.eventOptionsMap = this.getMarkerTypeMap(this.eventsInput);
    this.eventTypes = Object.keys(this.eventOptionsMap);
    this.eventOptionsArray = this.eventTypes.map(type => ({ type, options: this.eventOptionsMap[type] }));

    this.markers = this.eventsInput
      .filter(x => x.latitude && x.longitude)
      .map(x => ({
        ...x,
        options: this.eventOptionsMap[x.eventType]
      }));

    this.mapReady.then(() => {
      const now = new Date();
      this.drawPath(this.markers.filter(x => x.time <= now));
      this.setDefaultMapBounds();

      this.map.data.setStyle(function(feature) {
        return feature.getProperty('style') ?? {};
      });

      this.ready = true;
    });
  }

  updateMarkers() {
    console.log(this.eventOptionsArray);
    console.log(this.eventOptionsMap);
  }

  onMarkerClick($event: any, marker: MapMarker, event: TripEventMarker) {
    this.infoWindowMarker = event;
    this.mapInfoWindow.open(marker);
  }

  getMarkerTypeMap(events: TripEventViewModel[]): EventMarkerOptionsMap {
    const markerTypeMap: EventMarkerOptionsMap = {};
    const colors = ['red', 'yellow', 'blue', 'green'];
    let colorIndex = 0;

    const eventTypes = events.map(x => x.eventType).filter(onlyUnique);
    eventTypes.sort();

    for (const type of eventTypes) {
      let icon = '';

      switch (type) {
        case 'Trip Start':
          icon = 'http://www.google.com/mapfiles/dd-start.png';
          break;

          case 'Trip End':
            icon = 'http://www.google.com/mapfiles/dd-end.png';
            break;

          case 'Asset Location':
            icon = 'assets/img/train.png';
            break;

        default:
          icon = `http://labs.google.com/ridefinder/images/mm_20_${colors[colorIndex++ % (colors.length - 1)]}.png`
          break;
      }

      const options = {
        icon: icon,
        visible: true
      };

      markerTypeMap[type] = options;
    }

    return markerTypeMap;
  }

  drawPath(events: TripEventViewModel[]) {
    const aboutFivePercentOfEvents = Math.ceil(events.length * 0.05);
    const reversedEvents = events.reverse();
    const features: GeoJsonLineStringFeature[] = [];

    const colorCount = 10;
    const startColor = [0,0,255];
    const endColor = [150,150,150];
    let colors: string[] = [];
    for (let i = 0; i < colorCount; i++) {
      const weight = i / colorCount;

      colors.push(rgbToHex(getColorFromGradient(endColor, startColor, weight)));
    }

    features.push({
      type: "Feature",
      properties: {
        style: {
          strokeColor: colors[0],
        }
      },
      geometry: {
        type: "LineString",
        coordinates: []
      }
    });


    for (const event of reversedEvents) {
      let currentFeature = features[features.length - 1];
      if (currentFeature.geometry.coordinates.length > aboutFivePercentOfEvents && features.length < colors.length) {
        const lastPoint = currentFeature.geometry.coordinates[currentFeature.geometry.coordinates.length - 1];
        currentFeature = {
          type: "Feature",
          properties: {
            style: {
              strokeColor: colors[features.length]
            }
          },
          geometry: {
            type: "LineString",
            coordinates: [lastPoint]
          }
        };
        features.push(currentFeature);
      }

      currentFeature.geometry.coordinates.push([event.longitude, event.latitude])
    }

    this.map.data.addGeoJson({
      type: "FeatureCollection",
      features: features
    });
  }

  setDefaultMapBounds() {
    var bounds = new google.maps.LatLngBounds();

    for (let e of this.markers) {
      const position: google.maps.LatLngLiteral = { lat: e.latitude, lng: e.longitude };

      bounds.extend(position);
    }

    this.map.fitBounds(bounds);
  }

  async showLOIs(show: boolean) {
    if (!this.lois) {
      this.lois = await this.loiService.getLois().toPromise();
      this.loiPolygons = [];
      for (const loi of this.lois) {
        const locationPolygon = new google.maps.Polygon({
          paths: loi.coordinates.map(function(x) {
            return {
              "lat": x.latitude,
              "lng": x.longitude
            }
          }),
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });

        google.maps.event.addListener(locationPolygon, 'click', event => this.onLocationClick(event, loi, locationPolygon));

        this.loiPolygons.push(locationPolygon);
      }
    }

    let map = this.map.googleMap;
    if (!show) map = null;

    for (const loiPolygon of this.loiPolygons) {
      loiPolygon.setMap(map);
    }
  }

  onLocationClick(event, location: LocationResponse, polygon: google.maps.Polygon) {
    const content = "Hi";

    this.infoWindow.setContent(`<pre>${htmlEncode(JSON.stringify(location, null, 2))}</pre>`);
    this.infoWindow.setPosition(new google.maps.LatLng(location.latitude, location.longitude));
    this.infoWindow.open(this.map.googleMap);
  }
}

function htmlEncode(input: string): string {
  const textArea = document.createElement("textarea");
  textArea.innerText = input;
  return textArea.innerHTML.split("<br>").join("\n");
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function rgbToHex(rgb: number[]): string {
  return '#' + rgb.map(x => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }).join('')
}

function getColorFromGradient(color1: number[], color2: number[], weight: number): number[] {
  var w1 = weight;
  var w2 = 1 - w1;
  var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2)];
  return rgb;
}
