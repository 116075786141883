import { TagsComponent } from './tags.component';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TagsRoutingModule } from './tags-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';

@NgModule({
    declarations: [TagsComponent],
    imports: [
        CommonModule,
        TagsRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        CommonUiSharedModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA],
    exports: [TagsComponent],
})
export class TagsModule { }
