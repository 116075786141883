import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssetsComponent } from './assets.component';
import { AssetDetailsComponent } from './asset-details/asset-details.component';
import { AssetEditComponent } from './asset-edit/asset-edit.component';

const routes: Routes = [
    {
      path: '',
      component: AssetsComponent,
    },
    {
      path: ':id',
      component: AssetDetailsComponent,
    },
    {
      path: ':id/edit',
      component: AssetEditComponent,
    }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AssetsRoutingModule { }
