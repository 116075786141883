import { AfterViewInit, ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AssetDto, AssetDtoAssetPagedListDto, AssetsService } from '../../../../services/assets.service';
import { ToasterNotificationService } from '../../../../services/toasterNotification.service';
import { WorkspaceSelectComponent } from '../../../../shared/workspace-select/workspace-select.component';
import { MatDialog } from '@angular/material/dialog';
import { PermissionsDialogComponent } from '../../../../shared/dialog/permissions-dialog/permissions-dialog.component';

@Component({
  selector: 'ads-account-assets-tab',
  templateUrl: './account-assets-tab.component.html',
  styleUrls: ['./account-assets-tab.component.scss']
})
export class AccountAssetsTabComponent implements OnInit, AfterViewInit {
  accountId: number;
  assetData = new MatTableDataSource();
  columnsToDisplay = ['assetName', 'unitName', 'unitNumber', 'assetGroupings', 'assetOwner'];
  emptyMessage = 'No assets currently added';
  filterValue = '';
  length = 100;
  pageSize = 25;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 100];
  search: Subject<string> = new Subject();
  totalOperatorAssets = 0;
  totalOwnerAssets = 0;

  @ViewChildren('searchText') searchInput;
  @ViewChild('paginator', { read: MatPaginator, static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('workspaceSelect', { static: true }) workspaceSelect: WorkspaceSelectComponent;

  constructor(private assetsService: AssetsService, private router: Router, private route: ActivatedRoute, private dialog: MatDialog,
    private toasterService: ToasterNotificationService, private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    this.route.parent.url.subscribe((urlPath) => {
      let accountId = 0;
      if (urlPath.length > 1) {
        accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
      }

      if (isNaN(accountId)) {
        this.router.navigateByUrl('[/accounts]');
      }

      this.accountId = accountId;
    });
  }

  ngOnInit() {
    this.search.pipe(debounceTime(500)).subscribe(searchTextValue => {
      this.filterValue = searchTextValue;
      this.searchAssets();
    });

    this.workspaceSelect.registerOnChange(() => this.onWorkspaceChange());

    this.workspaceSelect.workspacesLoaded.subscribe(loaded => {
      if(loaded)
        this.searchAssets();
    });

  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#ac-asset-searchtext');
    element.focus();
    this.cdr.detectChanges();
  }

  onWorkspaceChange() {
    this.searchAssets();
  }

  updatePaginator(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.assetsService.assets(this.accountId, 'all', this.filterValue, false,
      this.pageIndex, this.pageSize)
      .subscribe((resp: AssetDtoAssetPagedListDto) => {
        this.populateAssets(resp);
      },
      error => {
        this.toasterService.showWarnToaster(error.detail);
      });
  }

  searchAssets() {
    this.paginator.firstPage();
    this.pageIndex = 0;
    this.assetsService.assets(this.accountId, this.workspaceSelect.workspaceSelected, this.filterValue, false,
      this.pageIndex, this.pageSize)
      .subscribe((resp: AssetDtoAssetPagedListDto) => {
        this.populateAssets(resp);
      },
      error => {
        this.toasterService.showWarnToaster(error.detail);
      });
  }

  onRowClick(asset: AssetDto) {
    this.router.navigate([`/assets/${asset.assetId}`]);
  }

  searchTriggered(filterValue: any) {
    this.filterValue = filterValue;
    this.search.next(filterValue);
  }

  populateAssets(resp: AssetDtoAssetPagedListDto) {
    const assetData = resp.items.map((u: AssetDto) => ({ ...u }));
    assetData.sort((a, b) => a.assetName.localeCompare(b.assetName));
    this.assetData = new MatTableDataSource(assetData);
    this.assetData.sort = this.sort;

    if (this.assetData.filteredData.length === 0) {
      this.emptyMessage = 'No results match search term ' + this.filterValue;
    }
    this.length = resp.total;
    this.totalOperatorAssets = resp.totalOperatorAssets;
    this.totalOwnerAssets = resp.totalOwnerAssets;
  }

  manageAssetPermissions() {
    var resourcePath = `/accounts/${this.accountId}/workspaces/all/assets/**`;
    const dialogRef = this.dialog.open(PermissionsDialogComponent, {
      data: {
        resourceType: 'Asset',
        resourcePath: resourcePath
      }
    });
  }
}
