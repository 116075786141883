<mat-card *ngIf="account">
  <mat-card-content>
    <dl>
      <dt>Name</dt>
      <dd id="account-details-account-name">{{account.accountName}}</dd>

      <dt>SAP Account Number</dt>
      <dd id="account-details-sap-account-number">{{account.sapAccountNumber}}</dd>
    </dl>
    <div class="workspace-dropdown">
      <ads-workspace-select #workspaceDropdown accountId="{{this.account.accountId}}"></ads-workspace-select>
    </div>
    <div class="source-radio">
      <label class="source-label">Fleets & Assignments Administration:</label>
      <mat-radio-group [(ngModel)]="selectedValue" (change)="onButtonChange($event)">
        <mat-radio-button value="GeoMetrix">
          GeoMetrix
        </mat-radio-button>
        <mat-radio-button value="AssetManagement">
          SCV
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-card-content>
  <div class="card-layout m-t-50">
    <mat-card class="three-card account-detail-group">
      <ads-device-groups-table #deviceGroupsTable workspace={{this.workspaceDropdown.workspaceSelected}}></ads-device-groups-table>
    </mat-card>
    <mat-card class="three-card account-detail-group">
      <ads-assets-groups-table #assetGroupsTable workspace="{{this.workspaceDropdown.workspaceSelected}}"></ads-assets-groups-table>
    </mat-card>
    <mat-card class="three-card account-detail-group">
      <ads-user-groups-table></ads-user-groups-table>
    </mat-card>
  </div>
</mat-card>
