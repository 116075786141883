<mat-form-field>
  <mat-select id='as-accountdropdown' (selectionChange)="onSelectionChanged($event)" placeholder={{placeholder}} [required]="isRequired" disableOptionCentering #singleSelect>
    <mat-option id='op-noitem'>
      <ngx-mat-select-search [formControl]="accountSearchControl" placeholderLabel="Please begin typing account name." noEntriesFoundLabel="no results found">
      </ngx-mat-select-search>
    </mat-option>
    <ng-container *ngFor="let account of filteredAccounts | async">
      <mat-option *ngIf="account.accountName == '*'" [value]="'*'">*</mat-option>
      <mat-option *ngIf="account.accountName != '*'" id='op-{{account.accountId}}' [value]="account.accountId" [disabled]="account.isNotEnabled">
        {{account.accountName}}
      </mat-option>
    </ng-container>
  </mat-select>
  <ads-control-messages *ngIf="this.control != null" [control]="this.control"></ads-control-messages>
</mat-form-field>
