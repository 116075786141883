<!--<ads-tags></ads-tags>-->
<mat-card>
  <mat-card-title>
    SIMs
  </mat-card-title>
  <mat-card-subtitle>
    <p>Upload delimited SIM cards data for manufacturing</p>
    CSV Fields: Notes, Quantity, Date, Type, Model, Vendor, Carrier, APN, Primary Server, Secondary Server, Region, Number
  </mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-card-content>
    <form #simModelForm="ngForm" (ngSubmit)="uploadSimList(simModelForm)">
      <div>
        <mat-form-field>
          <textarea matInput rows="20" name="simInput" ngModel maxlength="200"></textarea>
        </mat-form-field>
      </div>
        <mat-card-actions align="end">
      <button id="sims-submit-btn" mat-flat-button color="primary" shape="semi-round" status="success" size="small">Submit</button>
        </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>
