import { Component, OnInit, AfterViewInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Operation, AccountsService, AdsAccountDto } from '../../services/accounts.service';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'angular-crumbs';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';

@Component({
  selector: 'ads-app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent implements OnInit, AfterViewInit {
  private accountId: number;
  editAccountForm: FormGroup;

  constructor(private fb: FormBuilder, private router: Router,
              private route: ActivatedRoute, private breadcrumbService: BreadcrumbService,
              private toasterService: ToasterNotificationService,
              private accountService: AccountsService, private renderer: Renderer2,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.editAccountForm = this.fb.group({
      accountName: [{value: '', disabled: true}, Validators.required],
      sapAccountNumber: ['', [
        Validators.required,
        Validators.max(999999999),
        Validators.min(1)
      ]]
    });

    this.accountId = Number.parseInt(this.route.snapshot.params.id, 10);

    this.accountService.account(this.accountId).subscribe((acc: AdsAccountDto) => {
      this.editAccountForm.patchValue({ ...acc });
      this.breadcrumbService.changeBreadcrumb(this.route.snapshot, acc === undefined ? '' : acc.accountName);
    });
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#ae-accountname');
    element.focus();
    this.cdr.detectChanges();
  }

  onSave() {
    if (this.editAccountForm.invalid) {
      return;
    }

    const accountPatch: Operation[] = [
      new Operation({ op: 'replace', path: '/sapAccountNumber', value: this.editAccountForm.controls.sapAccountNumber.value }),
      // new Operation({ op: 'replace', path: '/accountName', value: this.editAccountForm.controls.accountName.value })
    ];

    this.accountService.update(this.accountId, accountPatch).subscribe((acc: AdsAccountDto) => {
      this.editAccountForm.patchValue({ ...acc });
      this.breadcrumbService.changeBreadcrumb(this.route.snapshot, acc === undefined ? '' : acc.accountName);
      this.router.navigate([`/accounts/view/${this.accountId}`]);
    },
      err => {
        this.toasterService.showWarnToaster(err.detail);
      }
    );
  }
}
