<button id="jd-close=button" mat-icon-button class="raw-message-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title>Asset End Share</h1>
<mat-dialog-content class="endshare-asset-dialog">
	<form (ngSubmit)="onSubmit()">
    <ng-container [formGroup]="assetEndShare">
      <mat-card-content>
        <ads-account-select-search #targetAccountId [isRequired]="true"></ads-account-select-search>
      </mat-card-content>
      <mat-card-actions align="end">
        <button id='ad-cancelbutton' type="button" [mat-dialog-close]="true">Cancel</button>
        <button id='ad-updatebutton' mat-flat-button color="primary" type="submit">Ok</button>
      </mat-card-actions>
    </ng-container>
  </form>
</mat-dialog-content>


