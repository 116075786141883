<div class="my-3">
  <mat-card>
    <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
      Manage Assets
      <!--<button [routerLink]="['/assets/add']" mat-button color="primary">
        Add New Asset
      </button>-->
      <button id='as-assetsauditlogs' [routerLink]="['/auditing']" [queryParams]="{resourceType: 'ADSAssetsAsset'}" mat-button color="secondary">
        View Audit Logs
      </button>
    </mat-card-title>
    <mat-card-subtitle>
      Manage assets
    </mat-card-subtitle>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div id="leftcolumn">
        <label>Search Assets</label>
        <mat-form-field>
          <input id='ad-searchtext' name="searchText" matInput (keyup)="searchTriggered($event.target.value)" maxlength="50" #searchText />
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-content class="table-container">
      <table mat-table [dataSource]="assetData" matSort [hidden]="!searchText.value || assetData.filteredData.length < 1">
        <ng-container matColumnDef="assetName">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let asset">{{ asset.assetName }}</td>
        </ng-container>
        <ng-container matColumnDef="unitName">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Unit Name</th>
          <td mat-cell *matCellDef="let asset">{{ asset.unitName }}</td>
        </ng-container>
        <ng-container matColumnDef="unitNumber">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Unit Number</th>
          <td mat-cell *matCellDef="let asset">{{ asset.unitNumber }}</td>
        </ng-container>
          <ng-container matColumnDef="assetTypeName">
            <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let asset">{{ asset.assetTypeName }}</td>
          </ng-container>
        <ng-container matColumnDef="assetGroupings">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Asset Groups</th>
          <td mat-cell *matCellDef="let asset">{{ asset.assetGroupings }}</td>
        </ng-container>
        <ng-container matColumnDef="ownerAccount">
          <th mat-header-cell class="table-header" *matHeaderCellDef>Asset Owner</th>
          <td mat-cell *matCellDef="let asset">{{ asset.ownerAccount !== undefined ? asset.ownerAccount.name : '-' }}</td>
        </ng-container>
        <ng-container matColumnDef="operatorAccount">
          <th mat-header-cell class="table-header" *matHeaderCellDef>Asset Operator</th>
          <td mat-cell *matCellDef="let asset">{{ asset.operatorAccount !== undefined ? asset.operatorAccount.name : '-' }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row class="hover" (click)="onRowClick(row)" *matRowDef="let row; columns: columnsToDisplay">
        </tr>
      </table>
        <div *ngIf="assetData.filteredData.length < 1">
          <label class="sm-note">{{searchMessage}}</label>
        </div>
    </mat-card-content>
  </mat-card>
</div>
