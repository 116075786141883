import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountWorkspaceAssetsV3Service } from '../../services/assets-v3.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToasterNotificationService } from '../../services/toasterNotification.service';
import { AccountsService } from 'src/app/services/accounts.service';
import { AccountSelectSearchComponent } from 'src/app/shared/account-select-search/account-select-search.component';
import { Router } from '@angular/router';

@Component({
  selector: 'ads-message-dialog',
  templateUrl: 'account-asset-endshare-dialog.component.html',
  styleUrls: ['./account-asset-endshare-dialog.component.scss']
})
export class AccountAssetEndShareDialogComponent implements OnInit {
  assetEndShare: FormGroup;
  accountId: number;
  assetId: string;
  sharedToAccountIds: number[];
  @ViewChild('targetAccountId', { static: true }) targetAccountId: AccountSelectSearchComponent;

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data: any,
    private accountWorkspaceAssetsV3Service: AccountWorkspaceAssetsV3Service,
    private formBuilder: FormBuilder,
    private toasterService: ToasterNotificationService,
    accountService: AccountsService,
    private dialogRef: MatDialogRef<AccountAssetEndShareDialogComponent>) {
      this.targetAccountId = new AccountSelectSearchComponent(accountService, toasterService);
      if (data) {
        this.accountId = data.accountId;
        this.assetId = data.assetId;
        this.sharedToAccountIds = data.sharedToAccountIds;
      }
  }

  ngOnInit() {
    this.targetAccountId.prefilteredAccounts = this.sharedToAccountIds;
    this.assetEndShare = this.formBuilder.group({
      targetAccountId: null,
    });
  }

  onSubmit() {
    this.accountWorkspaceAssetsV3Service.deactivateAdsDataFeed(this.accountId, "all", this.assetId, this.targetAccountId.singleSelect.value, "Ops Portal")
      .subscribe(() => {
        this.dialogRef.close(true);
      },
      error => {
        this.toasterService.showWarnToaster(error.detail);
      }
    );
  }

  cancel(): void {
      this.dialogRef.close(false);
  }
}
