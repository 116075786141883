import { Component, OnInit, AfterViewInit, Input, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { UsersService } from 'src/app/services/users.service';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ads-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit, AfterViewInit {
  @Input() users: Array<any>;
  @ViewChild(MatTable) table;
  usersDataSource: MatTableDataSource<Array<any>>;
  columnsToDisplay = ['Name', 'Email', 'ActionButtons'];
  userForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private userService: UsersService,
    private toasterService: ToasterNotificationService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute) {

    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobilePhone: [''],
      dateDisplayFormat: [''],
      dateTimeDisplayFormat: [''],
      lengthUnitOfMeasure: [''],
      tankVolumeUnitOfMeasure: [''],
      loadingDimensionsUnitOfMeasure: [''],
      weightUnitOfMeasure: [''],
      jobTitle: ['']
    });

    this.usersDataSource = new MatTableDataSource<Array<any>>(this.users);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if(params.dateDisplayFormat) {
        this.userForm.get('dateDisplayFormat').setValue(params.dateDisplayFormat);
      }
      if(params.dateTimeDisplayFormat) {
        this.userForm.get('dateTimeDisplayFormat').setValue(params.dateTimeDisplayFormat);
      }
      if(params.lengthUnitOfMeasure) {
        this.userForm.get('lengthUnitOfMeasure').setValue(params.lengthUnitOfMeasure);
      }
      if(params.tankVolumeUnitOfMeasure) {
        this.userForm.get('tankVolumeUnitOfMeasure').setValue(params.tankVolumeUnitOfMeasure);
      }
      if(params.loadingDimensionsUnitOfMeasure) {
        this.userForm.get('loadingDimensionsUnitOfMeasure').setValue(params.loadingDimensionsUnitOfMeasure);
      }
      if(params.weightUnitOfMeasure) {
        this.userForm.get('weightUnitOfMeasure').setValue(params.weightUnitOfMeasure);
      }
    });
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#usr-tb-firstname');
    element.focus();
    this.cdr.detectChanges();
  }

  delete(user: any) {
    const deletedUserIndex = this.users.indexOf(user);
    this.users.splice(deletedUserIndex, 1);
    this.table.renderRows();
  }

  onSubmit() {
    this.userForm.markAllAsTouched();
    if (this.userForm.invalid) {
      return;
    }

    const emailToTest = this.userForm.value.email;
    if (this.users.find(a => a.email.toLowerCase() === emailToTest.toLowerCase())) {
      this.toasterService.showInfoToaster(`The email ${emailToTest} is already in the list to add below`);
    } else {
      this.userService.emailTaken(emailToTest).subscribe((resp: boolean) => {
        if (resp) {
          this.toasterService.showInfoToaster(`The email ${emailToTest} is already in use`);
        } else {
          this.users.push({
            firstName: this.userForm.value.firstName,
            lastName: this.userForm.value.lastName,
            email: this.userForm.value.email,
            mobilePhone: this.userForm.value.mobilePhone,
            dateDisplayFormat: this.userForm.value.dateDisplayFormat,
            dateTimeDisplayFormat: this.userForm.value.dateTimeDisplayFormat,
            lengthUnitOfMeasure: this.userForm.value.lengthUnitOfMeasure,
            tankVolumeUnitOfMeasure: this.userForm.value.tankVolumeUnitOfMeasure,
            loadingDimensionsUnitOfMeasure: this.userForm.value.loadingDimensionsUnitOfMeasure,
            weightUnitOfMeasure: this.userForm.value.weightUnitOfMeasure,
            jobTitle: this.userForm.value.jobTitle,
          });

          this.userForm.reset();
          if (this.table) {
            this.table.renderRows();
          }
        }
      },
        error => {
          this.toasterService.showInfoToaster(error.detail);
        });
    }
  }
}
