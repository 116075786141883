export const settingTooltipUp = () => {
  const icon: any = document.querySelector('#tooltip-icon');
  const tooltip: any = document.querySelector('#tooltip');
  if (icon && tooltip) {
    calculatePositionTooltip();
    window.addEventListener('resize', () => calculatePositionTooltip());
    tooltip.addEventListener('mouseenter', () => clearTimeout(timer));
    tooltip.addEventListener('mouseleave', () => tooltip.classList.remove('active'));
    icon.addEventListener('mouseenter', () => {
      calculatePositionTooltip();
      tooltip.classList.add('active');
    });
    let timer;
    icon.addEventListener('mouseleave', () => {
      timer = setTimeout(() => {
        tooltip.classList.remove('active');
      }, 500);
    });
  }
};

const calculatePositionTooltip = () => {
  const icon: any = document.querySelector('#tooltip-icon');
  const tooltip: any = document.querySelector('#tooltip');
  // Calculate the  icon's  coordinates.
  const x = icon.offsetLeft;
  const y = icon.offsetTop;

  // Calculate the  icon's size.
  const heightTooltip = tooltip.clientHeight;

  // Calculate where the tooltip are going to be.
  const left = icon.getBoundingClientRect().left - x - 100;
  const top = y + heightTooltip + 70;

  tooltip.style.left = `${left}px`;
  tooltip.style.top = `${top}px`;
};
