import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-asset-trace-line-map',
  templateUrl: './asset-trace-line-map.component.html',
  styleUrls: [],
})
export class AssetTraceLineMapComponent implements OnInit {

  @Input() locationArray: { lng: any; lat: any; date: string; city: string; }[] = [];
  mapData: [{ CompletedLine: any; }];
  selectedOverlay: string = 'ETA';
  
  constructor(
    ) {
  }

  ngOnInit(): void {
    this.mapData = [
      {
        CompletedLine: this.locationArray,
      },
    ];
  }
  
}
