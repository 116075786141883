import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';

if (environment.production) {
  enableProdMode();
}
LicenseManager.setLicenseKey('CompanyName=IONX LLC,LicensedApplication=ADS Operations Portal,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-012383,ExpiryDate=14_January_2022_[v2]_MTY0MjExODQwMDAwMA==aa47b31955f92b4f2bc5b9303608da66');
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
