import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IHasLocation, TripEventViewModel } from '../../types';
import { JsonDialogComponent } from '../json-dialog/json-dialog.component';
import { computeDistanceBetween } from 'spherical-geometry-js';
import { FormControl } from '@angular/forms';

type Column = {
  label: string,
  name: string,
  visible: boolean
};

@Component({
  selector: 'events-table',
  templateUrl: './events-table.component.html',
  styleUrls: ['./events-table.component.scss']
})
export class EventsTableComponent implements OnInit {
  @Input("events") eventsInput: TripEventViewModel[] = [];
  events: (TripEventViewModel & {
    distanceFromLastLocation?: number | undefined
  })[] = [];
  inspecificFutureDate = new Date(9999, 0, 1);
  columns: Column[] = [
    { label: 'Date/Time', name: 'dateTime', visible: true },
    { label: 'Received Date/Time', name: 'receivedTime', visible: false },
    { label: 'Sequentiality', name: 'sequentiality', visible: false },
    { label: 'Accounts', name: 'accounts', visible: true },
    { label: 'Event Type', name: 'eventType', visible: true },
    { label: 'Code', name: 'sightingCode', visible: false },
    { label: 'E/L', name: 'emptyLoadStatus', visible: true },
    { label: 'Description', name: 'description', visible: true },
    { label: 'Location', name: 'location', visible: true },
    { label: 'SPLC', name: 'splc', visible: false },
    { label: 'Dest. Splc', name: 'destinationSplc', visible: false },
    { label: 'KMs Δ', name: 'distanceFromLastLocation', visible: false },
    { label: 'Links', name: 'links', visible: true },
  ];
  columnsToDisplay: string[] = [];

  constructor(
    public dialog: MatDialog
  ) {
    this.updateColumnsToDisplay();
  }

  ngOnInit(): void {
    const events = this.eventsInput.map(x => ({ ...x })); // shallow clone

    let lastLocation: IHasLocation;
    for (const e of events) {
      if (e.latitude && e.longitude) {
        if (!lastLocation) {
          lastLocation = e as IHasLocation;
          continue;
        }

        const distance = computeDistanceBetween(
          e as IHasLocation,
          lastLocation
        ) / 1000;

        if (distance > 0) {
          e.distanceFromLastLocation = distance;
        }

        lastLocation = e as IHasLocation;
      }
    }

    this.events = events;
  }

  updateColumnsToDisplay() {
    this.columnsToDisplay = this.columns.filter(x =>  x.visible).map(x => x.name);
  }

  dateToString(date: Date) {
    const zeroPad = (x: number) => x.toString().padStart(2, '0');
    const datePart = `${date.getUTCFullYear()}-${zeroPad(date.getUTCMonth() + 1)}-${zeroPad(date.getUTCDate())}`;
    const timePart = `${zeroPad(date.getUTCHours())}:${zeroPad(date.getUTCMinutes())}`;
    return `${datePart} ${timePart}`;
  }

  openRawEventDialog(title: string, resource?: any, resourceGetter?: () => Promise<any> ) {
    this.dialog.open(JsonDialogComponent, {
      data: {
        title,
        object: resource,
        objectGetter: resourceGetter
      }
    })
  }
}
