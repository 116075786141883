import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActiveTripViewModel, TripsService } from 'src/app/services/shipments-v2.service';

@Component({
  templateUrl: './account-trips.page.html',
  styleUrls: ['./account-trips.page.scss']
})
export class AccountTripsPage implements OnInit {
  accountId: number;
  tripsViewModel: ActiveTripViewModel | undefined;

  constructor(
    private tripService: TripsService,
    private route: ActivatedRoute,
  ) {
    this.accountId = this.route.snapshot.params.accountId;
  }

  ngOnInit(): void {
    this.tripService.getActiveTrips(this.accountId)
      .toPromise()
      .then(x => this.tripsViewModel = x);
  }

}
