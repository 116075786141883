import { AssetResponse } from "src/app/services/assets-v3.service";
import { AccountTripSummaryModel, TripCLMEventModel, TripDeviceLocationEventModel, TripEventModel, TripGeoMetrixTripEventModel, TripSummaryModel, TripWaybillEventModel } from "src/app/services/shipments-v2.service";
import { IRailSightService } from "../services/railsight.service";
import { IHasLocation, TripEventBase, TripEventViewModel } from "./types";


export function createTripEventViewModels(asset: AssetResponse | null, events: TripEventBase[], trips: TripSummaryModel[] | AccountTripSummaryModel[], railsightService: IRailSightService): TripEventViewModel[] {
  const response: TripEventViewModel[] = [];

  // asset location
  if (asset?.lastKnownLocation) {
    response.push({
      id: asset.id,
      accountIds: asset.accounts.map(x => x.accountId),
      eventType: "Asset Location",
      source: asset,
      time: asset.lastKnownLocation.dateTimeUtc,
      receivedTime: asset.lastKnownLocation.dateTimeUtc,
      latitude: asset.lastKnownLocation.latitude,
      longitude: asset.lastKnownLocation.longitude,
    })
  }

  // events
  for (const event of events) {
    let eventLocation: IHasLocation = null;
    if ((event as any).latitude) {
      eventLocation = event as unknown as IHasLocation;
    }

    const accountIds = (event as TripEventModel).accountIds
      ?? [];

    const sequentiality = (event as TripEventModel).sequentiality;

    const eventViewModel: TripEventViewModel = {
      id: event.id,
      accountIds: accountIds,
      eventType: event.eventType,
      source: event,
      time: event.eventDateTime,
      receivedTime: event.eventReceivedDateTime,
      latitude: eventLocation?.latitude,
      longitude: eventLocation?.longitude,
      sequentiality: sequentiality
    };

    switch (event.eventType) {
      case "CLM":
        const clmEvent = event as TripCLMEventModel;
        const clmEventId = clmEvent._raw.CLMEventId;
        const clmEventAccountId = clmEvent._raw.AccountId;
        eventViewModel.splc = normalizeSplc(clmEvent.sightingSPLC);
        eventViewModel.destinationSplc = normalizeSplc(clmEvent._raw.DestinationSPLC);
        eventViewModel.sightingCode = clmEvent._raw.SightingCode;
        eventViewModel.description = clmEvent._raw.SightingDescription;
        eventViewModel.emptyLoadStatus = clmEvent.emptyLoadStatus;
        if (clmEvent._raw.SightingCity) {
          eventViewModel.location = `${clmEvent._raw.SightingCity}, ${clmEvent._raw.SightingState}`;
        }

        eventViewModel.resourceLinks = [
          { text: "Raw CLM", resourceGetter: () => railsightService.getCLMById(clmEventAccountId, clmEventId).toPromise() }
        ]
        break;

      case "Waybill":
        const waybillEvent = event as TripWaybillEventModel;
        const waybillId = waybillEvent._raw.WaybillId;
        const waybillAccountId = waybillEvent._raw.AccountId;

        if (waybillEvent._raw.OriginSPLC) {
          eventViewModel.description = `${waybillEvent._raw.OriginSPLC} --> ${waybillEvent._raw.DestinationSPLC ?? ''}`
        }
        eventViewModel.splc = normalizeSplc(waybillEvent._raw.OriginSPLC);
        eventViewModel.sightingCode = waybillEvent._raw.SightingCode;
        eventViewModel.emptyLoadStatus = waybillEvent.emptyLoadStatus;
        eventViewModel.destinationSplc = normalizeSplc(waybillEvent._raw.DestinationSPLC);
        if (waybillEvent._raw.OriginName) {
          eventViewModel.location = `${waybillEvent._raw.OriginName}, ${waybillEvent._raw.OriginState}`;
        }

        eventViewModel.resourceLinks = [
          { text: "Raw Waybill", resourceGetter: () => railsightService.getWaybillById(waybillAccountId, waybillId).toPromise() }
        ]
        break;

      case 'GeoMetrix Trip':
        const gmTripEvent = event as TripGeoMetrixTripEventModel;
        eventViewModel.description = gmTripEvent._raw.LocationSightingCodeDescription;
        eventViewModel.splc = normalizeSplc(gmTripEvent._raw.LocationSPLC);
        eventViewModel.destinationSplc = normalizeSplc(gmTripEvent._raw.DestinationSPLC);
        eventViewModel.sightingCode = gmTripEvent._raw.LocationSightingCode;
        eventViewModel.emptyLoadStatus = gmTripEvent._raw.LoadStatus;
        eventViewModel.location = [gmTripEvent._raw.LocationCity, gmTripEvent._raw.LocationState, gmTripEvent._raw.LocationCountry].join(' ');
        break;

      case "Device Location":
        const gpsEvent = event as TripDeviceLocationEventModel;
        if ((gpsEvent._raw.hydrationData?.locations?.length ?? []) > 0 ) {
          const location = gpsEvent._raw.hydrationData.locations[0];
          eventViewModel.location = location.Name;
        }
    }

    response.push(eventViewModel);
  }

  // trips
  for (const trip of trips) {
    const detailsUri = `/shipments-v2/trips/${trip.id}`;

    response.push({
      id: trip.id + "Start",
      accountIds: [trip.accountId],
      eventType: "Trip Start",
      source: trip,
      time: trip.startDateTime,
      receivedTime: null,
      splc: normalizeSplc(trip.origin.splc),
      destinationSplc: normalizeSplc(trip.destination?.splc),
      latitude: trip.origin.latitude,
      longitude: trip.origin.longitude,
      location: [trip.origin.description, trip.origin.city, trip.origin.state].join(' '),
      resourceLinks: [
        { text: 'Trip details...', link: detailsUri }
      ]
    });

    if (trip.destination && (trip.endDateTime || trip.isActive)) {
      response.push({
        id: trip.id + "End",
        accountIds: [trip.accountId],
        eventType: "Trip End",
        source: trip,
        time: trip.endDateTime ?? new Date(Date.UTC(9999, 11, 31)),
        receivedTime: null,
        splc: normalizeSplc(trip.destination?.splc),
        destinationSplc: normalizeSplc(trip.destination?.splc),
        latitude: trip.destination?.latitude,
        longitude: trip.destination?.longitude,
        location: [trip.destination?.description, trip.destination?.city, trip.destination?.state].join(' '),
        resourceLinks: [
          { text: 'Trip details...', link: detailsUri }
        ]
      })
    }
  }

  response.sort((a, b) => a.time.getTime() - b.time.getTime());

  return response;
}

function normalizeSplc(inputSplc: string | number | null | undefined): string | null {
  if (!inputSplc) return null;

  let splcString = parseInt(inputSplc.toString()).toString();

  if (splcString.length > 6) {
    splcString = splcString.substring(0, splcString.length - 3);
  }

  return splcString;
}
