import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { LastConnectedWidgetComponent } from './last-connected-widget/last-connected-widget.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DevicesService, DEVICES_API_BASE_URL } from '../services/devices.service';
import { environment } from '@env/environment';
import { ToasterNotificationService } from '../services/toasterNotification.service';
import { CommonUiSharedModule } from '../shared/common-ui-shared/common-ui-shared.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonUiSharedModule,
    SharedModule,
  ],
  declarations: [
    DashboardComponent,
    LastConnectedWidgetComponent,
  ],
  entryComponents: [
  ],
  providers: [
    DevicesService,
    ToasterNotificationService,
    { provide: DEVICES_API_BASE_URL, useValue: environment.devicesBaseUrl },
  ]
})
export class DashboardModule { }
