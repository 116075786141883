import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class ToasterNotificationService {

  private snackBarConfig: MatSnackBarConfig;
  private actionButtonText: string;

  constructor(private snackBar: MatSnackBar) {
    this.snackBarConfig = new MatSnackBarConfig();
    this.snackBarConfig.horizontalPosition = 'center';
    this.snackBarConfig.verticalPosition = 'top';
    this.snackBarConfig.duration = 2500;
    this.actionButtonText = '';
  }

  showWarnToaster(message: string) {
    this.showMessage(message, ToasterType.Warn);
  }

  showInfoToaster(message) {
    this.showMessage(message, ToasterType.Info);
  }

  private showMessage(message: string, style: ToasterType) {
    if (!message || message === 'undefined') {
      message = 'There was an error processing your request.';
    }

    this.snackBarConfig.panelClass = style;
    this.snackBar.open(message, this.actionButtonText, this.snackBarConfig);
  }
}

enum ToasterType {
  Warn = 'danger',
  Info = 'info'
}
