import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Spinkit } from 'ng-http-loader';
import { Logger, CryptoUtils } from 'msal';
import { Subscription } from 'rxjs';
import { PortalAppInsights } from './logging.service';

@Component({
  selector: 'ads-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  public spinkit = Spinkit;
  private subscription: Subscription;
  loggedIn = false;

  ngOnInit(): void {

    this.checkAccount();

    this.subscription = this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkAccount();
    });

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect error : ', authError.errorMessage);
        return;
      }
      console.log('Redirect success');
    });
  }

  checkAccount() {
    this.loggedIn = !!this.authService.getAccount();
  }

  constructor(private authService: MsalService, private broadcastService: BroadcastService, private portalAppInsights: PortalAppInsights) {
  }

  logout() {
    this.authService.logout();
  }

  login() {
    this.authService.loginRedirect({
      extraScopesToConsent: ['user.read', 'openid', 'profile']
    });
  }

  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
