import { Directive, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DeviceSearchDto } from 'src/app/services/devices.service';

@Directive({
  selector: '[appDeviceValidator]'
})
export class DeviceValidatorDirective implements OnChanges {
  @Input('existingDevices') existingDevices: DeviceSearchDto[] = [];
  @Input('deviceEntered') deviceEntered: string;
  @Output() settingValidFlag = new EventEmitter();
  styleRules = {
    valid: '#afe5e8',
    invalid: '#faa0a0'
  };

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(): void {
    if (this.existingDevices.findIndex((device) => device && device.serialNumber && device.serialNumber == this.deviceEntered) !== -1) {
      this.elementRef.nativeElement.style.backgroundColor = this.styleRules.valid;
      this.settingValidFlag.emit(true);
    } else {
      this.elementRef.nativeElement.style.backgroundColor = this.styleRules.invalid;
      this.settingValidFlag.emit(false);
    }
  }
}
