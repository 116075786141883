import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'angular-crumbs';
import { AssetWorkspaceChipsComponent } from 'src/app/shared/asset-workspace-chips/asset-workspace-chips.component';
import { AdsAccountDto } from '../../services/accounts.service';
import { AssetsService, AssetTypeDto, AssetTypeService, Operation } from '../../services/assets.service';
import { AssetsV3Service } from '../../services/assets-v3.service';
import { ToasterNotificationService } from '../../services/toasterNotification.service';

@Component({
  selector: 'ads-asset-edit',
  templateUrl: './asset-edit.component.html',
  styleUrls: ['./asset-edit.component.scss']
})
export class AssetEditComponent implements OnInit, AfterViewInit {
  assetEdit: FormGroup;
  assetId: number;
  accountId: number;
  assetTypes: AssetTypeDto[];
  assetTypeCtrl = new FormControl();
  deviceCtrl = new FormControl();
  ownerAccountCtrl = new FormControl();
  operatorAccountCtrl = new FormControl();

  loaded = false;

  @ViewChild('assetName') assetName: ElementRef;
  @ViewChild('chips') chips: AssetWorkspaceChipsComponent;

  constructor(private formBuilder: FormBuilder,
              private assetsService: AssetsService,
              private assetsV3Service: AssetsV3Service,
              private assetTypeService: AssetTypeService,
              private router: Router,
              private route: ActivatedRoute,
              private breadcrumbService: BreadcrumbService,
              private toasterService: ToasterNotificationService,
              private renderer: Renderer2,
              private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.assetId = Number.parseInt(this.route.snapshot.url[this.route.snapshot.url.length - 2].path, 10);

    this.assetEdit = this.formBuilder.group({
      unitName: [''],
      unitNumber: [''],
      assetName: ['', Validators.required]
    });
    this.populateForm();

    this.assetTypeService.assetTypes().subscribe(a => {
      this.assetTypes = a;
    });
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#ad-assetname');
    element.focus();
    this.cdr.detectChanges();
  }

  populateForm() {
    this.assetsService.getAsset(this.assetId).subscribe(a => {
      const asset = a;
      this.assetEdit.patchValue({ ...asset });
      this.ownerAccountCtrl.setValue(asset.ownerAccountId);
      this.operatorAccountCtrl.setValue(asset.operatorAccountId);
      this.assetTypeCtrl.setValue(asset.assetTypeId);
      this.deviceCtrl.setValue(asset.deviceSerial);
      this.breadcrumbService.changeBreadcrumb(this.route.snapshot, this.assetEdit.controls.assetName.value);
      this.accountId = asset.ownerAccountId;

      this.loaded = true;
    });
  }

  onSubmit() {
    this.assetEdit.markAllAsTouched();

    if (this.assetEdit.invalid) {
      this.assetName.nativeElement.focus();
      return;
    }

    const patch: Operation[] = [
      /*new Operation({
        op: 'replace', path: '/assetTypeId',
        value: this.
      }),*/
      new Operation({
        op: 'replace', path: '/ownerAccountId',
        value: this.ownerAccountCtrl.value == null ? null : this.ownerAccountCtrl.value
      }),
      new Operation({
        op: 'replace', path: '/operatorAccountId',
        value: this.operatorAccountCtrl.value == null ? null : this.operatorAccountCtrl.value
      }),
      new Operation({
        op: 'replace', path: '/deviceSerial',
        value: this.deviceCtrl.value == null ? null : this.deviceCtrl.value
      }),
      new Operation({
        op: 'replace', path: '/assetName',
        value: this.assetEdit.controls.assetName.value
      }),
      new Operation({
        op: 'replace', path: '/unitName',
        value: this.assetEdit.controls.unitName.value
      }),
      new Operation({
        op: 'replace', path: '/unitNumber',
        value: this.assetEdit.controls.unitNumber.value
      }),
      new Operation({
        op: 'replace', path: '/assetTypeId',
        value: this.assetTypeCtrl.value
      })
    ];
    this.assetsService.patchAsset(this.ownerAccountCtrl.value, 'all', this.assetId, patch).subscribe(() => {

    },
      error => {
        this.toasterService.showWarnToaster(error.detail);
      }
    );

    this.assetsService.updateWorkspaces(this.accountId, this.assetId, 'all', this.chips.workspaces).subscribe(() => {
      this.router.navigate([`/assets/${this.assetId}`]);
      }, error => {
        this.toasterService.showWarnToaster(error.detail);
      });
    }

  updateAccount(accountType: string, newValue: AdsAccountDto) {
    if (!newValue) {
      return;
    }

    const accountPropertyName = `${accountType}AccountId`;
    this.assetEdit.value[accountPropertyName] = newValue.accountId;
  }
}
