import { Component, OnInit, AfterViewInit, Renderer2, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Subject } from 'rxjs';
import { DevicesService, Status, DeviceSearchDtoPagedListDto, DeviceGroupResponse, DeviceGroupResponseResultSet, DeviceGroupsService } from '../../../../services/devices.service';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime } from 'rxjs/operators';
import { ToasterNotificationService } from '../../../../services/toasterNotification.service';
import { MatSort } from '@angular/material/sort';
import { WorkspaceSelectComponent } from '../../../../shared/workspace-select/workspace-select.component';
import { MatDialog } from '@angular/material/dialog';
import { PermissionsDialogComponent } from '../../../../shared/dialog/permissions-dialog/permissions-dialog.component';

@Component({
  selector: 'ads-account-devices-tab',
  templateUrl: './account-devices-tab.component.html',
  styleUrls: ['./account-devices-tab.component.scss']
})
export class AccountDevicesTabComponent implements OnInit, AfterViewInit {
  accountId: number;
  columnsToDisplay: string[] = [
    'serialNumber',
    'imei',
    'createdDate',
    'deviceGroup',
    'isActive'
  ];
  deviceData: MatTableDataSource<{}>;
  emptyData: boolean;
  emptyMessage: string;
  filterValue = '';
  length = 100;
  pageIndex = 0;
  pageSize = 25;
  pageSizeOptions = [5, 10, 25, 100];
  private search: Subject<string> = new Subject();
  serialNumberFilter: string;
  deviceGroups: DeviceGroupResponse[];
  deviceGroupSelected: string;

  onChange: any = () => { };
  onTouched: any = () => { };

  @ViewChild('paginator', { read: MatPaginator, static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('workspaceSelect', { static: true }) workspaceSelect: WorkspaceSelectComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DevicesService,
    private deviceGroupService: DeviceGroupsService,
    private toasterService: ToasterNotificationService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    this.route.parent.url.subscribe((urlPath) => {
      let accountId = 0;
      if (urlPath.length > 1) {
        accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
      }

      if (isNaN(accountId)) {
        this.router.navigateByUrl('[/accounts]');
      }

      this.accountId = accountId;
    });
  }

  updatePaginator(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    const skipNumber = this.pageSize * this.pageIndex;
    this.deviceService.devices(skipNumber, this.pageSize, undefined, Status.All, this.accountId, this.filterValue)
      .subscribe((resp: DeviceSearchDtoPagedListDto) => {
        const deviceData = resp.items;
        deviceData.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber));
        this.deviceData = new MatTableDataSource(deviceData);
        this.deviceData.sort = this.sort;
        this.emptyData = this.deviceData.filteredData.length === 0;
        this.emptyMessage = 'No results match search term ' + this.filterValue;
        this.length = resp.total;
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
  }

  ngOnInit() {
    this.search.pipe(debounceTime(500)).subscribe(searchTextValue => {
      this.filterValue = searchTextValue;
      this.searchDevices();
    });

    this.emptyData = true;
    this.emptyMessage = 'No Devices in account';

    this.deviceGroupService.deviceGroups(this.accountId, 'all', '', 20, '').subscribe(
      ((deviceGroups: DeviceGroupResponseResultSet) => {
        const deviceGroupsData = deviceGroups;
        deviceGroupsData.results.sort((a, b) => a.name?.localeCompare(b.name));
        this.deviceGroups = deviceGroupsData.results
      }),
      error => {
        this.toasterService.showWarnToaster(error.detail);
      });

    this.workspaceSelect.registerOnChange(() => this.onWorkspaceChange());

    this.workspaceSelect.workspacesLoaded.subscribe(loaded => {
      if(loaded)
        this.searchDevices();
    });
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#ac-devices-searchtext');
    element.focus();
    this.cdr.detectChanges();
  }

  onWorkspaceChange() {
    this.searchDevices();
  }

  searchTriggered(filterValue: any) {
    this.filterValue = filterValue;
    this.search.next(filterValue);
  }

  searchDevices() {
    this.paginator.firstPage();
    const skipNumber = this.pageSize * this.pageIndex;
    if (this.filterValue !== '') {
      this.emptyMessage = 'No results match search term ' + this.filterValue;
    } else {
      this.emptyMessage = 'No Devices in account';
    }

    this.deviceService.devices(skipNumber, this.pageSize, undefined, Status.All, this.accountId, this.filterValue, null, false, this.deviceGroupSelected, null, false, true, this.workspaceSelect.workspaceSelected)
      .subscribe((resp: DeviceSearchDtoPagedListDto) => {
        const deviceData = resp.items;
        deviceData.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber));

        this.deviceData = new MatTableDataSource(deviceData);
        this.emptyData = this.deviceData.filteredData.length === 0;
        this.emptyMessage = 'No results match search term ' + this.filterValue;
        this.length = resp.total;
      },
        error => {
          this.toasterService.showWarnToaster(error.detail);
        });
  }

  onUserRowSelect(event): void {
    this.router.navigate([`/devices/${event.serialNumber}`]);
  }

  onDeviceGroupChanged(event: MatSelectChange) {
    this.deviceGroupSelected = event.value;
    
    this.searchDevices();
  }

  getDeviceGroupName(groupId: string)
  {
    return groupId ? this.deviceGroups.find(g => g.id === groupId).name : '';
  }

  manageDevicePermissions() {
    var resourcePath = `/accounts/${this.accountId}/workspaces/all/devices/**`;
    const dialogRef = this.dialog.open(PermissionsDialogComponent, {
      data: {
        resourceType: 'Device',
        resourcePath: resourcePath
      }
    });
  }
}
