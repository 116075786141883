import { Component, OnInit, ViewChildren, AfterViewInit, ChangeDetectorRef, Renderer2, ViewChild } from '@angular/core';
import { UsersService, AppUserDto, AppUserDtoPagedListDto } from '../../../../services/users.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ToasterNotificationService } from '../../../../services/toasterNotification.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'ads-account-users-tab',
  templateUrl: './account-users-tab.component.html',
  styleUrls: ['./account-users-tab.component.scss']
})
export class AccountUsersTabComponent implements OnInit, AfterViewInit {
  accountId: number;
  columnsToDisplay = ['accountName', 'email', 'displayName', 'lastSignInDate'];
  emptyMessage = 'No users currently in account';
  filterValue: string;
  search: Subject<string> = new Subject();
  skipToken: string;
  userData = new MatTableDataSource();
  usrsDataSource: MatTableDataSource<{}>;

  @ViewChildren('searchText') searchInput;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterNotificationService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
    this.route.parent.url.subscribe((urlPath) => {
      let accountId = 0;
      if (urlPath.length > 1) {
        accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
      }

      if (isNaN(accountId)) {
        this.router.navigateByUrl('[/accounts]');
      }

      this.accountId = accountId;
    });
    this.skipToken = null;
    this.usersService.getUsersByAccount(this.accountId, '').subscribe(
      (resp: AppUserDtoPagedListDto) => {
        const assetData = resp.items.map((u: AppUserDto) => ({
          id: u.userId,
          accountId: u.accountId,
          accountName: u.accountName,
          givenName: u.firstName,
          surname: u.lastName,
          email: u.emailAddress,
          displayName: u.displayName,
          lastSignInDate: u.lastSignInDate
        }));
        assetData.sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.userData = new MatTableDataSource(assetData);
        this.userData.sort = this.sort;

        if (this.userData.filteredData.length === 0) {
          this.emptyMessage = 'No users in account';
        }
        this.skipToken = resp.skipToken;
      },
      (err) => {
        this.toasterService.showWarnToaster(err.detail);
      }
    );
  }

  ngOnInit() {
    this.search.pipe(debounceTime(500)).subscribe((searchTextValue) => {
      this.filterValue = searchTextValue;
      this.searchAssets();
    });
  }

  ngAfterViewInit() {
    const element = this.renderer.selectRootElement('#ac-users-searchtext');
    element.focus();
    this.cdr.detectChanges();
  }

  searchAssets() {
    this.usersService.getUsersByAccount(this.accountId, this.filterValue).subscribe(
      (resp: AppUserDtoPagedListDto) => {
        const assetData = resp.items.map((u: AppUserDto) => ({
          id: u.userId,
          accountId: u.accountId,
          accountName: u.accountName,
          givenName: u.firstName,
          surname: u.lastName,
          email: u.emailAddress,
          displayName: u.displayName,
          lastSignInDate: u.lastSignInDate
        }));
        assetData.sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.userData = new MatTableDataSource(assetData);
        this.userData.sort = this.sort;

        if (this.userData.filteredData.length === 0) {
          this.emptyMessage = 'No results match search term ' + this.filterValue;
        }
        this.skipToken = resp.skipToken;
      },
      (err) => {
        this.toasterService.showWarnToaster(err.detail);
      }
    );
  }

  loadMoreResults() {
    if (this.skipToken !== null) {
      this.usersService.getUsersByAccount(this.accountId, this.filterValue, this.skipToken).subscribe(
        (resp: AppUserDtoPagedListDto) => {
          const assetData = resp.items.map((u: AppUserDto) => ({
            id: u.userId,
            accountId: u.accountId,
            accountName: u.accountName,
            givenName: u.firstName,
            surname: u.lastName,
            email: u.emailAddress,
            displayName: u.displayName
          }));
          assetData.sort((a, b) => a.displayName.localeCompare(b.displayName));
          const combinedData = this.userData.data.concat(assetData);
          this.userData = new MatTableDataSource(combinedData);
          this.userData.sort = this.sort;
          this.skipToken = resp.skipToken;
        },
        (err) => {
          this.toasterService.showWarnToaster(err.detail);
        }
      );
    }
  }

  onRowClick(user) {
    this.router.navigate([`accounts/${this.accountId}/users/${user.id}`]);
  }

  searchTriggered(filterValue: any) {
    this.filterValue = filterValue;
    this.search.next(filterValue);
  }

  addUsers() {
    this.router.navigate([`/users/add`], { queryParams: { accountId: this.accountId } });
  }
}
