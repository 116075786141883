import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetsService as EmptyLoadAssetsService } from '../../services/empty-load.service';
import { ToasterNotificationService } from '../../services/toasterNotification.service';


@Component({
  selector: 'app-empty-load-edit',
  templateUrl: './empty-load-edit.component.html',
  styleUrls: ['./empty-load-edit.component.scss']
})
export class EmptyLoadEditComponent implements OnInit {
  selectedRowAssetId: number;
  constructor(@Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<EmptyLoadEditComponent>,
    private emptyLoadAssetsService: EmptyLoadAssetsService,
    private toasterService: ToasterNotificationService) {
    if (data) {
      this.selectedRowAssetId = data.assetId;
    }
  }

  assetConfigData: any;

  ngOnInit(): void {
    this.emptyLoadAssetsService.getAssetConfig(this.selectedRowAssetId)
      .subscribe(x => {
        this.assetConfigData = x;
        //Converting Two elements to string to show up in frontend html page.
        this.assetConfigData.visible = this.assetConfigData.visible.toString();
        this.assetConfigData.wsn415_FlipDeterminationOverride = this.assetConfigData.wsn415_FlipDeterminationOverride.toString();
      }
      );
  }

  editEmptyLoad() 
  {
    this.emptyLoadAssetsService.saveAssetConfig(this.assetConfigData.assetId, this.assetConfigData)
      .subscribe(res => {
        if (res.assetId != null) {
          this.toasterService.showInfoToaster("Updated Sucessfully");
        }
      },
        response => {
          this.toasterService.showWarnToaster(response);
        })
    this.dialogRef.close({ res: '' })
  }


}






