import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetGroupDto, AssetGroupDtoPagedListDto, AssetGroupsService } from '../../../../../services/assets.service';
import { ToasterNotificationService } from '../../../../../services/toasterNotification.service';
import { AssetGroupDtoAssignable } from './asset-group-dto-assignable';

@Component({
  selector: 'ads-assets-groups-table',
  templateUrl: './assets-groups-table.component.html',
  styleUrls: ['./assets-groups-table.component.scss']
})
export class AssetsGroupsTableComponent implements OnInit {
  @Input() workspace: string;
  private accountId: number;
  assetGroupsTableData = new MatTableDataSource();
  baseAssetGroupsMessage: string;
  emptyAssetGroupsData: boolean;
  emptyAssetGroupsMessage: string;

  columnsToDisplay: string[] = ['name', 'assetCount', 'isActive', 'action'];

  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private route: ActivatedRoute,
    private assetGroupService: AssetGroupsService, private toasterService: ToasterNotificationService) {
    this.route.parent.url.subscribe((urlPath) => {
      let accountId = 0;
      if (urlPath.length > 1) {
        accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
      }

      if (isNaN(accountId)) {
        this.router.navigateByUrl('[/accounts]');
      }

      this.accountId = accountId;
    });
  }

  ngOnInit() {
    this.emptyAssetGroupsData = true;
    this.baseAssetGroupsMessage = '';
    this.emptyAssetGroupsMessage = this.baseAssetGroupsMessage;

    this.searchAssetGroups('');
  }

  public searchAssetGroups(filterValue: string) {
    if(!this.workspace) {
      this.workspace = 'all';
    }

    this.assetGroupService.assetGroups(this.accountId, this.workspace, 1, 20, undefined, false).subscribe(
      ((assetGroups: AssetGroupDtoPagedListDto) => {
        const assetGroupsData = assetGroups;
        assetGroupsData.items.map(x => x as AssetGroupDtoAssignable);
        assetGroupsData.items.sort((a, b) => a.name.localeCompare(b.name));
        this.assetGroupService.unassignedAssetGroup(this.accountId, 'all', undefined).subscribe(
          ((unassignedAssetGroup: AssetGroupDto) => {
            const unassignedGroup = unassignedAssetGroup as AssetGroupDtoAssignable;
            unassignedGroup.isUnassigned = true;
            assetGroupsData.items.push(unassignedGroup);
            this.assetGroupsTableData = new MatTableDataSource(assetGroupsData.items);
            this.assetGroupsTableData.sort = this.sort;

            this.emptyAssetGroupsData = this.assetGroupsTableData.filteredData.length === 0;
            this.emptyAssetGroupsMessage = 'No results';
          }),
          error => {
            this.toasterService.showWarnToaster(error.detail);
          }
        );
      }),
      error => {
        this.toasterService.showWarnToaster(error.detail);
      });
  }

  onRowClick(event): void {
    if (event.isUnassigned) {
      this.router.navigate(['asset-group-unassigned'], {relativeTo: this.route.parent });
    } else {
      this.router.navigate(['asset-group', `${ event.assetGroupId }`], { relativeTo: this.route.parent });
    }
  }

  createAssetGroup(): void {
    this.router.navigate(['asset-group'], { relativeTo: this.route.parent });
  }
}
