import { Component, OnInit, ViewChild } from '@angular/core';
import { DevicesService } from '../../services/devices.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'ads-last-connected-widget',
  templateUrl: './last-connected-widget.component.html',
  styleUrls: ['./last-connected-widget.component.scss'],
})
export class LastConnectedWidgetComponent implements OnInit {

  lastConnectedSource = new MatTableDataSource<{}>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  lastConnectedSourceCopy = [];
  columnsToDisplay: string[] = [
    'id',
    'lastActiveTime',
    'status'
  ];

  constructor(private deviceService: DevicesService) {}

  ngOnInit() {
    /*
    this.deviceService.iotDevicesByLastConnected(30)
      .subscribe((devices: any) => {
        const devicesData = devices.result.map(u => ({
          id: u.id,
          lastActiveTime: new Date(u.lastActive),
          status: u.status,
        }));
        this.lastConnectedSource = new MatTableDataSource(devicesData);
        this.lastConnectedSource.sort = this.sort;
      }, error => {
        throw error;
      });
  }
   public doFilter = (filterValue: any) => {
    if (filterValue.value) {
      this.lastConnectedSource = new MatTableDataSource(
        this.lastConnectedSourceCopy.filter(
          record =>
            record[filterValue.name] &&
            record[filterValue.name]
              .trim()
              .toLocaleLowerCase()
              .includes(
                filterValue.value &&
                  filterValue.value.trim().toLocaleLowerCase()
              )
        )
      );
    } else {
      this.lastConnectedSource = new MatTableDataSource(this.lastConnectedSourceCopy);
    }
    */
  }
}
