import { ValueGetterParams } from 'ag-grid-community';

export const defaultColDef = {
  sortable: true,
  suppressMenu: true,
  resizable: true
};
export const columnDefs = [
  { headerName: 'Group Name', field: 'name', width: 150 },
  { headerName: 'Devices', field: 'deviceCount', width: 90 },
  {
    headerName: 'Configuration',
    field: '',
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '    <span ref="eHeaderIcon" class="ag-header-icon info-icon tooltip" id="tooltip-icon"><i class="material-icons">info</i>' +
        ' </span>' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>'
    },
    valueFormatter: (e) => valueFormatter(e),
    width: 160,
    hide: true
  },
  { headerName: 'Arena Number', valueGetter: (e) => arenaNumberGetter(e), width: 130 },
  { headerName: 'Fleets', field: '', valueFormatter: (e) => valueFormatter(e), width: 150, hide: true },
  {
    headerName: 'Group Updated',
    field: '',
    valueFormatter: (e) => valueFormatter(e),
    comparator: (e, a) => dateComparator(e, a),
    width: 150,
    hide: true
  },
  { headerName: 'Updated By', field: '', valueFormatter: (e) => valueFormatter(e), width: 150, hide: true },
  {
    headerName: 'Sync Updated',
    field: '',
    valueFormatter: (e) => valueFormatter(e),
    comparator: (e, a) => dateComparator(e, a),
    width: 150,
    hide: true
  },
  { headerName: 'Sync Status', field: '', valueFormatter: (e) => valueFormatter(e), width: 125, hide: true },
  {
    headerName: 'Sync Progress',
    field: 'syncProgress',
    valueGetter: (e) => syncProgressGetter(e),
    width: 125,
    cellClass: 'grid-cell-centered'
  }
];

const dateComparator = (date1: string, date2: string) => {
  const date1Number = date1 && new Date(date1).getTime();
  const date2Number = date2 && new Date(date2).getTime();

  if (date1Number == null && date2Number == null) {
    return 0;
  }

  if (date1Number == null) {
    return -1;
  } else if (date2Number == null) {
    return 1;
  }

  return date1Number - date2Number;
};

const syncProgressGetter = (params: ValueGetterParams) => {
  const devices = params.data.devices || [];
  const syncedDevices = params.data.syncingDevices || [];
  return `${syncedDevices.length}/${devices.length}`;
};

const arenaNumberGetter = (params: ValueGetterParams) => {
  const devices = params.data.arenaConfigurationPackage;
  return `${devices ? devices.number : ''}`;
};

const valueFormatter = (params) => {
  return params.value ? params.value : '-';
};
