<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <mat-select id='ds-dropdown' (selectionChange)="onSelectionChanged($event)" placeholder={{placeholder}} disableOptionCentering required #singleSelect [disabled]="disableSelect">
    <mat-option id='op-noitem'>
      <ngx-mat-select-search [formControl]="deviceSearchControl" placeholderLabel="search"
        noEntriesFoundLabel="no results found">
      </ngx-mat-select-search>
    </mat-option>
    <ng-container *ngFor="let device of filteredDevices | async">
      <mat-option id='op-{{device.serialNumber}}' [value]="device.serialNumber">
        {{device.serialNumber}}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
