<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    Manage Accounts
    <div>
      <button id='ac-accountauditlogs' [routerLink]="['/auditing']" [queryParams]="{resourceType: 'ADSAccountsAccount'}" mat-button color="secondary">
        View Audit Logs
      </button>
      <button id='ac-manageinstallerlocations' [routerLink]="['/accounts/installer-location']" mat-button color="secondary">
        Manage Installer Locations
      </button>
      <button id='ac-createaccountbutton' [routerLink]="['/accounts/new']" mat-button color="primary">
        Create new Account
      </button>
    </div>
  </mat-card-title>
  <mat-card-subtitle>
    Manage customer accounts
  </mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div id="leftcolumn">
      <label>Search Accounts</label>
      <mat-form-field>
        <input id='ac-searchtext' name="searchText" matInput (keyup)="searchTriggered($event.target.value)" maxlength="50" #searchText />
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-content class="table-container">
    <table mat-table [dataSource]="accountData" matSort class="m-t-20" [hidden]="!searchText.value || accountData.data.length < 1">
      <ng-container matColumnDef="sapAccountNumber">
        <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>SAP Account Number</th>
        <td mat-cell *matCellDef="let account">{{ account.sapAccountNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="accountName">
        <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let account">{{ account.accountName }}</td>
      </ng-container>
      <ng-container matColumnDef="isNotEnabled">
        <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Account Enabled</th>
        <td mat-cell *matCellDef="let account">{{!account.isNotEnabled}}</td>
      </ng-container>     
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr mat-row class="hover" *matRowDef="let account; columns: columnsToDisplay" (click)="onRowClick(account)"></tr>
    </table>
    <div>
      <label class="sm-note" *ngIf="accountData.data.length < 1">{{searchMessage}}</label>
    </div>
  </mat-card-content>
</mat-card>
