<button id="jd-close=button" mat-icon-button class="raw-message-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title>Add Account</h1>
<mat-dialog-content class="share-to-account-dialog">
	<form (ngSubmit)="onSubmit()">
    <ng-container [formGroup]="accountShare">
      Before adding a new account, make sure that {{ sourceAccountName }} has submitted a signed agreement authorizing ADS to share data with the account selected below.
      <mat-card-content>
        <ads-account-select-search [control]="accountShare.get('targetAccountId')" placeholder="Select the Authorized Account"
        formControlName="targetAccountId" [isRequired]="true" #accountSelect></ads-account-select-search>
      </mat-card-content>
      <mat-card-actions align="end">
        <button id='ad-cancelbutton' type="button" [mat-dialog-close]="true">Cancel</button>
        <button id='ad-updatebutton' mat-flat-button color="primary" type="submit">Save</button>
      </mat-card-actions>
    </ng-container>
  </form>
</mat-dialog-content>


