<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    Trip Details
  </mat-card-title>
  <mat-card-subtitle>
    <div *ngIf="account">{{ account.accountName }}</div>
    <div *ngIf="asset">{{ asset.name }} - {{asset.class}}</div>
    <a [routerLink]="['/shipments-v2', 'assets', trip.assetId]">Asset History...</a>
  </mat-card-subtitle>
  <ng-container *ngIf="events">
    <mat-card-content>
      <events-map [events]="events"></events-map>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-content>
      <nav mat-tab-nav-bar mat-tab-group mat-align-tabs="left">
        <a *ngFor="let tab of ['Origin', 'Destination', 'Events', 'Raw']" mat-tab-link [active]="selectedTab == tab" (click)="this.selectedTab = tab">{{ tab }}</a>
      </nav>

      <ng-container [ngSwitch]="selectedTab">
        <mat-card *ngSwitchCase="'Origin'">
          <mat-card-content>
            <dl>
              <dt>Origin</dt>
              <dd>{{ [trip.origin.description, trip.origin.city, trip.origin.state].join(' ') }}</dd>
              <dt>SPLC</dt>
              <dd>{{ trip.origin.splc }} </dd>
              <dt>Departure Date</dt>
              <dd>{{ trip.startDateTime.toISOString() }} </dd>
            </dl>
          </mat-card-content>
        </mat-card>

        <mat-card *ngSwitchCase="'Destination'">
          <mat-card-content>
            <dl>
              <dt>Destination</dt>
              <dd>{{ [trip.destination?.description, trip.destination?.city, trip.destination?.state].join(' ') }}</dd>
              <dt>SPLC</dt>
              <dd>{{ trip.destination?.splc }} </dd>
              <dt>Arrival Date</dt>
              <dd>{{ trip.endDateTime?.toISOString() }} </dd>
            </dl>
          </mat-card-content>
        </mat-card>

        <mat-card *ngSwitchCase="'Events'">
          <mat-card-content>
            <events-table [events]="events"></events-table>
          </mat-card-content>
        </mat-card>

        <mat-card *ngSwitchCase="'Raw'">
          <mat-card-content>
            <pre>{{ trip | json }}</pre>
          </mat-card-content>
        </mat-card>
      </ng-container>

    </mat-card-content>
  </ng-container>
</mat-card>
