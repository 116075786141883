<ads-nav>
  <router-outlet></router-outlet>

  <ng-http-loader
    [debounceDelay]="100"
    [extraDuration]="100"
    [minDuration]="100"
    [opacity]="0.6"
    [spinner]="spinkit.skWave"
    [filteredUrlPatterns]="[
      '/api/v[0-9]+/Users/[0-9a-z\-]+/audits',
      '/api/v[0-9]+/Installers',
      '/api/v[0-9]+/rsrd2/GetRefreshStatusByAccount',
      '/api/v[0-9]+/CDATool/ProcessingLog',
      'dc\.services\.visualstudio\.com/v2/track'
    ]"
  >
  </ng-http-loader>
</ads-nav>
