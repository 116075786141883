<div fxLayout="row" fxLayoutAlign="space-between center" mat-dialog-title>
  Devices Selection <strong class="close-icon" (click)="close()">&times;</strong>
</div>
<mat-dialog-content>
  <p>
    Add Devices (or Fleets) into Group Name <strong>{{ data?.groupName }}</strong
    ><br />
    Seleted configuration : {{ data?.configuration }}
  </p>
  <div class="aggrid-height">
    <ag-grid-angular
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [animateRows]="true"
      [rowData]="rowData"
      [rowSelection]="'multiple'"
      [suppressRowClickSelection]="true"
      [suppressMenuHide]="true"
      (rowSelected)="onRowSelected($event)"
      (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
    <div fxLayout="center center" class="go-back-devices-selection" (click)="close()">
      <mat-icon>keyboard_arrow_left</mat-icon> <span>Back to Previous</span>
    </div>
    <button mat-button class="save-btn" [disabled]="devicesSelected.length == 0" (click)="close(devicesSelected)">Add Devices</button>
  </div>
</mat-dialog-actions>
