<mat-card>
  <mat-card-title>
    Create User<ng-container *ngIf="isBulkCreate">s</ng-container>
  </mat-card-title>
  <mat-slide-toggle id='usr-multipleslider' [checked]="isBulkCreate" (toggleChange)="toggleBulkCreate($event)">
    Create multiple
  </mat-slide-toggle>
  <form (ngSubmit)="onSubmit()">
    <ng-container [formGroup]="newUserForm">
      <mat-card-content>
        <mat-card *ngIf="isBulkCreate" class="mat-elevation-z2">
          <ads-users-table [users]="newUsers"></ads-users-table>
        </mat-card>

        <ng-container *ngIf="!isBulkCreate">
          <mat-form-field>
            <input id='usr-new-firstname' matInput formControlName="firstName" maxlength="100" required placeholder="First Name" />
            <ads-control-messages [control]="newUserForm.get('firstName')"></ads-control-messages>
          </mat-form-field>

          <mat-form-field>
            <input id='usr-new-lastname' matInput formControlName="lastName" maxlength="100" required placeholder="Last Name" />
            <ads-control-messages [control]="newUserForm.get('lastName')"></ads-control-messages>
          </mat-form-field>

          <mat-form-field>
            <input id='usr-new-email' matInput formControlName="email" maxlength="100" required placeholder="Email" />
            <ads-control-messages [control]="newUserForm.get('email')"></ads-control-messages>
          </mat-form-field>

          <mat-form-field>
            <input id='usr-mobilePhone' matInput formControlName="mobilePhone" placeholder="Mobile Phone">
          </mat-form-field>
  
          <mat-form-field>
            <input id='usr-dateDisplayFormat' matInput formControlName="dateDisplayFormat" placeholder="Date Display Format">
          </mat-form-field>
  
          <mat-form-field>
            <input id='usr-dateTimeDisplayFormat' matInput formControlName="dateTimeDisplayFormat" placeholder="Date Time Display Format">
          </mat-form-field>
  
          <mat-form-field>
            <input id='usr-lengthUnitOfMeasure' matInput formControlName="lengthUnitOfMeasure" placeholder="Length Unit of Measure">
          </mat-form-field>
  
          <mat-form-field>
            <input id='usr-tankVolumeUnitOfMeasure' matInput formControlName="tankVolumeUnitOfMeasure" placeholder="Tank Volume Unit of Measure">
          </mat-form-field>
  
          <mat-form-field>
            <input id='usr-loadingDimensionsUnitOfMeasure' matInput formControlName="loadingDimensionsUnitOfMeasure" placeholder="Loading Dimensions Unit of Measure">
          </mat-form-field>
  
          <mat-form-field>
            <input id='usr-weightUnitOfMeasure' matInput formControlName="weightUnitOfMeasure" placeholder="Weight Unit of Measure">
          </mat-form-field>
  
          <mat-form-field>
            <input id='usr-jobTitle' matInput formControlName="jobTitle" placeholder="Job Title">
          </mat-form-field>
        </ng-container>

        <ads-account-select-search id='usr-new-account' [control]="newUserForm.get('accountControl')" ngDefaultControl
          formControlName="accountControl" [allowEmptySelection]="false" placeholder="Account">
        </ads-account-select-search>

        <ads-pages-and-features id='usr-new-features' ngDefaultControl [pagesFeaturesControl]="featuresControl" [roleType]="1"
          placeholder="Features">
        </ads-pages-and-features>

        <ads-pages-and-features id='usr-new-pages' ngDefaultControl [pagesFeaturesControl]="pagesControl" [roleType]="2"
          placeholder="Pages">
        </ads-pages-and-features>



        <mat-slide-toggle *ngIf="allowSendWelcomeEmail" class="usr-sendemailslider" [checked]=sendWelcomeEmail (change)="sendWelcomeEmail = $event.source.checked">Send welcome email</mat-slide-toggle>
        <br />
        <mat-slide-toggle class="usr-bypassMfaSlider" [checked]=bypassMfa (change)="bypassMfa = $event.source.checked">Bypass MFA</mat-slide-toggle>
      </mat-card-content>
      <mat-card-actions align="end">
        <button id='usr-new-cancelbutton' type="button" mat-stroked-button adsBackbutton>Cancel</button>
        <button id='usr-new-createbutton' mat-flat-button color="primary" [disabled]="(isBulkCreate && newUsers.length == 0)" type="submit">
          Create
        </button>
      </mat-card-actions>
    </ng-container>
  </form>
</mat-card>
