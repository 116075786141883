<ag-grid-angular 
    style="width: 100%; height: 100%;" 
    class="ag-theme-alpine"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (rowDataChanged)="onRowDataChanged()"
    (rowSelected)="onRowSelected($event)"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [overlayLoadingTemplate]="overlayLoadingTemplate"
    [rowData]="body" 
    [columnDefs]="header">
</ag-grid-angular>