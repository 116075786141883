<!-- <div *ngIf="isLoading" style="margin-top: 15px; display: flex; justify-content: center;">
  <mat-spinner [diameter]="20"></mat-spinner>
</div> -->


<div>
  <app-map-line
    [InOverlay]="true"
    [events]="mapData"
    [selectedOverlay]="selectedOverlay"
  >
  </app-map-line>
</div>
