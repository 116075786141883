<div class="card-layout">
  <mat-card class="form-card mat-card">
    <mat-card-title>
      Edit Asset
    </mat-card-title>
    <form (ngSubmit)="onSubmit()">
      <ng-container [formGroup]="assetEdit">
        <mat-card-content>
          <mat-form-field>
            <mat-label>Asset Name</mat-label>
            <input id='ad-assetname' matInput #assetName name="assetName" formControlName="assetName" maxlength="100" required>
            <ads-control-messages [control]='assetEdit.get("assetName")'></ads-control-messages>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Unit Name</mat-label>
            <input id='ad-unitname' matInput formControlName="unitName" maxlength="5">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select id='ad-assettype' ngDefaultControl [formControl]="assetTypeCtrl" placeholder = "Asset Type" disableOptionCentering>
              <mat-option *ngFor = "let item of assetTypes"
                  [value] = "item.assetTypeId">
                  {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Unit Number</mat-label>
            <input id='ad-unitnumber' matInput type="text" formControlName="unitNumber" mask="999999" [specialCharacters]="[]">
          </mat-form-field>
          <ads-account-select-search id='ad-owneraccount' ngDefaultControl placeholder="Owner"  [formControl]="ownerAccountCtrl">
          </ads-account-select-search>
          <ads-account-select-search id='ad-operatoraccount' ngDefaultControl placeholder="Operator"  [formControl]="operatorAccountCtrl">
          </ads-account-select-search>
          <ads-device-select-search id='ad-installeddevice' label='Installed Device' placeholder="Choose Installed Device" ngDefaultControl accountId="{{ownerAccountCtrl.value}}" [formControl]="deviceCtrl">
          </ads-device-select-search>
          <div *ngIf="this.loaded">
            <ads-asset-workspace-chips #chips searchable="true" assetId="{{this.assetId}}" accountId="{{this.accountId}}"></ads-asset-workspace-chips>
          </div>
        </mat-card-content>
        <mat-card-actions align="end">
          <button id='ad-cancelbutton' type="button" mat-stroked-button adsBackbutton>Cancel</button>
          <button id='ad-updatebutton' mat-flat-button color="primary" type="submit">Save</button>
        </mat-card-actions>
      </ng-container>
    </form>
  </mat-card>
</div>
