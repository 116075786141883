<button mat-icon-button class="raw-message-close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title>Manage Permissions</h1>
<mat-dialog-content class="manage-permissions-dialog">
  <p class="mat-card-subtitle">
    Resource Type: {{resourceType}} | Resource ID: {{resourcePath}}
  </p>
  <mat-card-content class="table-container">
    <div class="add-new-permission-container">
      <form>
        <ng-container [formGroup]="newPolicyForm">
          <div class="manage-permissions-dialog-add-title">
            <h4>Add New Permission</h4>
            <mat-icon #tooltip="matTooltip" matTooltipPosition="above" matTooltip="Policy: {{resourcePath}}">info</mat-icon>
          </div>
          <div class="manage-permissions-dialog-add-content">
            <mat-form-field class="small" floatLabel="never">
              <mat-select id='policy-type' disableOptionCentering formControlName="typeControl" required
                placeholder="Type">
                <mat-option [value]="'contributor'">User</mat-option>
                <mat-option [value]="'owner'">Group</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="large" *ngIf="userTypeActive" floatLabel="never">
              <mat-select id='policy-new-access-select' disableOptionCentering formControlName="accessControl"
                [compareWith]="compareUsers" ngmodel="user" placeholder="Search" required
                panelClass="disable-outer-scroll" #assignUserAccess>
                <mat-option id='policy-new-access-search'>
                  <ngx-mat-select-search [formControl]="accessSearchControl" placeholderLabel="search">
                  </ngx-mat-select-search>
                </mat-option>

                <mat-option *ngIf="users?.length < 1" disabled>No users match the search.</mat-option>
                <mat-option matTooltip="{{user.emailAddress}}" matTooltipDisabled="false" *ngFor="let user of users" [value]="user">
                  {{user.displayName}}
                  <span class="account-color">[{{user.accountName}}]</span>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="large" *ngIf="!userTypeActive" floatLabel="never">
              <mat-select id='policy-new-access-select' disableOptionCentering formControlName="accessControl"
                [compareWith]="compareGroups" ngmodel="group" placeholder="Search" required
                panelClass="disable-outer-scroll" #assignGroupAccess>
                <mat-option id='policy-new-access-search'>
                  <ngx-mat-select-search [formControl]="accessSearchControl" placeholderLabel="search">
                  </ngx-mat-select-search>
                </mat-option>

                <mat-option *ngIf="groups?.length < 1" disabled>No groups match the search.</mat-option>
                <mat-option *ngFor="let group of groups" [value]="group">
                  {{group.groupName}}
                  <span class="account-color">[{{group.accountName}}]</span>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="small" floatLabel="never">
              <mat-select id='policy-new-role' disableOptionCentering formControlName="roleControl" placeholder="Role"
                required>
                <mat-option [value]="'contributor'">Contributor</mat-option>
                <mat-option [value]="'owner'">Owner</mat-option>
                <mat-option [value]="'reader'">Reader</mat-option>
              </mat-select>
            </mat-form-field>

            <button mat-flat-button color="primary" [disabled]="newPolicyForm.invalid" type="submit"
              (click)="onSubmit($event)">
              Add
            </button>
          </div>
        </ng-container>
      </form>
    </div>
    <div class="permissions-table-container">
      <table mat-table [dataSource]="dataSource" matSort class="m-t-20" [hidden]="dataSource?.data?.length < 1">
        <ng-container matColumnDef="type">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let policy">{{policy.type}}</td>
        </ng-container>
        <ng-container matColumnDef="matchedUserName">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Name</th>
          <td class="name" mat-cell *matCellDef="let policy" #tooltip="matTooltip" matTooltipPosition="above" matTooltip="{{policy.userNameGuid | async}}">{{policy.userNameSubject | async}} {{getUserEmail(policy.userNameSubject | async)}}</td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Role</th>
          <td mat-cell *matCellDef="let policy">{{policy.role}}</td>
        </ng-container>
        <ng-container matColumnDef="update">
          <th mat-header-cell *matHeaderCellDef class="table-header"></th>
          <td mat-cell *matCellDef="let policy">
            <button *ngIf="policy.matchedPolicy" mat-button (click)="deletePolicy(policy)">
              <mat-icon class="red-icon">clear</mat-icon>
            </button>
            <button disabled="true" *ngIf="!policy.matchedPolicy" mat-button (click)="openPolicy(policy)">
              <mat-icon #tooltip="matTooltip" matTooltipPosition="above" matTooltip="{{policy.path}}">info</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headers; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: headers;">
        </tr>
      </table>
    </div>
    <div *ngIf="dataSource?.data?.length < 1">
      <label class="sm-note">{{emptyMessage}}</label>
    </div>
  </mat-card-content>
</mat-dialog-content>
