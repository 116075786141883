import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';

import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment-timezone';
import { Rsrd2Service } from '../../../../../services/rsrd2.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ads-ag-grid-rsrd2',
  templateUrl: './ag-grid.component.html',
  styleUrls: ['./ag-grid.component.scss']
})
export class AgGridRSRD2Component implements OnInit, OnChanges, OnDestroy {

  @Input() body: any = null;
  @Input() header: any = null;
  gridOptions: GridOptions;
  gridApi: any;
  gridColumnApi: any;
  overlayNoRowsTemplate: string;
  overlayLoadingTemplate: string;

  downloadSub: Subscription;

  constructor(private rsrd2: Rsrd2Service) {
    this.gridOptions = {} as GridOptions;
  }

  ngOnInit() {

    this.downloadSub = this.rsrd2.triggerDownload$.subscribe((res: any) => {
      this.downloadExcel();
    });
    this.overlayNoRowsTemplate = 'No Rows Found';
    this.overlayLoadingTemplate = 'No Rows Found';

    this.gridOptions.defaultColDef = {
      sortable: true,
      resizable: true,
      suppressMenu: true,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true, clearButton: true, headerHeight: 20 }
    };

    this.gridOptions.columnTypes = {
      'System.DateTime': {
        valueFormatter: this.dateFormatter.bind(this),
        filter: 'agDateColumnFilter',
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator(filterValue, cellValue) {
            let dateAsString = moment(cellValue).format('DD/MM/YYYY');

            if (dateAsString == null) {
              return 0;
            }

            let dateParts = dateAsString.split('/');
            const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

            dateAsString = moment(filterValue).format('DD/MM/YYYY');
            dateParts = dateAsString.split('/');
            const filterDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterDate) {
              return -1;
            } else if (cellDate > filterDate) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      },
      'System.String': {
        filter: 'agTextColumnFilter'
      },
      'System.String[]': {
        filter: 'agTextColumnFilter'
      },
      'System.Boolean': {
        filter: 'agTextColumnFilter'
      },
      'System.Nullable`1[System.Int16]': {
        filter: 'agTextColumnFilter'
      },
      'System.Nullable`1[System.Int16][]': {
        filter: 'agTextColumnFilter'
      },
      'System.Nullable`1[System.Int32]': {
        filter: 'agTextColumnFilter'
      },
      'System.Decimal': {
        filter: 'agTextColumnFilter'
      },
      'System.Decimal[]': {
        filter: 'agTextColumnFilter'
      },
      'System.Nullable`1[System.Decimal]': {
        filter: 'agTextColumnFilter'
      },
      'AmstedDigital.RSRD2.Core.Enums.BufferType': {
        filter: 'agTextColumnFilter'
      },
      'AmstedDigital.RSRD2.Core.Enums.RouteClass': {
        filter: 'agTextColumnFilter'
      },
      'AmstedDigital.RSRD2.Core.Enums.WagonGauge': {
        filter: 'agTextColumnFilter'
      }
    };

    this.gridOptions.excelStyles = [
      {
        id: 'stringType',
        dataType: 'String',
      }
    ];
  }

  onNewColumnsLoaded() {
    if (this.gridOptions.columnDefs !== null) {
      const col = this.gridOptions.columnApi.getColumn('WagonNumberFreight');
      const def = col.getColDef();
      const userDef = def;
      userDef.cellClass = ['stringType'];
      col.setColDef(def, userDef);
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  dateFormatter(params) {
    // const currentTimeZone = this.userPreferenceService.Settings.Timezone;
    if (params.value === null || params.value === undefined) {
      return null;
    } else {
      return moment(params.value).tz('UTC').format('YYYY/MM/DD H:mm ');
    }
  }

  ngOnChanges() {
    this.showLoader();
  }

  showLoader() {
    if (this.gridOptions.api) {
      if (this.header && this.header === null) {
        this.gridOptions.api.showNoRowsOverlay();
      } else {
        this.gridOptions.api.hideOverlay();
        this.gridOptions.api.setRowData(this.body);
      }
    }
  }

  // download the data with excel
  exportToExcel() {
    // this.rsrd2.exportEnabled = false;
    setTimeout(() => {
      const excelParams = {
        fileName: 'Grid Report',
        processCellCallback(x) {
          if (x.value && x.column.colDef.field.match(/(Date|Time)/gi)) {
            return moment(x.value).tz('UTC').format('YYYY/MM/DD H:mm ');
          } else {
            return x.value;
          }
        }
      };
      this.gridApi.exportDataAsExcel(excelParams);
    }, 100);
  }

  // Fired when click download to excel icon on grid header
  downloadExcel() {
    this.exportToExcel();
    // this.showDownloadDialog = true;
  }

  ngOnDestroy() {
    if (this.downloadSub) {
      this.downloadSub.unsubscribe();
    }
  }
}
