import { Injectable } from '@angular/core';
import { BlockBlobClient, ContainerClient } from '@azure/storage-blob';

// https://javascript.plainenglish.io/upload-to-azure-blob-storage-with-angular-8-2ed80dfc6672
// https://github.com/stottle-uk/stottle-angular-blob-storage

@Injectable({
  providedIn: 'root'
})
export class BlobStorageService {
  constructor(
  ) { }

  sasTokenUrl$: string;

  // Container Client

  /**
   * A valid container sas token url must be set before communicating with the blob container
   * @param sasTokenUrl (Container sas token)
   */
  setSasTokenUrl(sasTokenUrl: string) {
    this.sasTokenUrl$ = sasTokenUrl;
  }

  /**
   * Upload a file to a blob container
   * @param file (File)
   */
  uploadToContainer(file: any) {
    const blockBlobClient = this.getBlockBlobClient(file.name);
    return this.uploadFileToContainer(blockBlobClient, file);
  }

  /**
   * Get a block blob client instance for the file to upload
   * @param fileName (File name)
   */
  private getBlockBlobClient(fileName) {
    const containerClient = this.buildContainerClient();
    return containerClient.getBlockBlobClient(fileName);
  }

  /**
   * Create a container client using a valid sas token url for the container
   */
  private buildContainerClient() {
    return new ContainerClient(this.sasTokenUrl$);
  }

  /**
   * Return a list of existing blob files in the container
   */
  listContainerBlobs() {
    return this.buildContainerClient().listBlobsFlat();
  }

  /**
   * Upload a file to the container using a block blob client.
   * Periodically updates the file upload progress.
   * @param blockBlobClient (Container block blob client for the file)
   * @param file (File)
   */
  private async uploadFileToContainer(blockBlobClient: BlockBlobClient, file: any) {
    const response = await blockBlobClient.uploadData(file, {
      blockSize: 4 * 1024 * 1024, // 4MB block size,
      concurrency: 10,
      onProgress: (ev) => {
        // console.log(ev.loadedBytes + ' bytes uploaded');
        file.progress = (ev.loadedBytes / file.size) * 100;
      },
      blobHTTPHeaders: { blobContentType: file.type }
    });

    return response;
  }
}

export class ProblemDetails implements IProblemDetails {

  constructor(data?: IProblemDetails) {
      if (data) {
          for (const property in data) {
              if (data.hasOwnProperty(property)) {
                  (this as any)[property] = (data as any)[property];
              }
          }
      }
  }
  type?: string;
  title?: string;
  status?: number | undefined;
  detail?: string;
  instance?: string;

  static fromJS(data: any): ProblemDetails {
      data = typeof data === 'object' ? data : {};
      const result = new ProblemDetails();
      result.init(data);
      return result;
  }

  init(data?: any) {
      if (data) {
          this.type = data.type;
          this.title = data.title;
          this.status = data.status;
          this.detail = data.detail;
          this.instance = data.instance;
      }
  }

  toJSON(data?: any) {
      data = typeof data === 'object' ? data : {};
      data.type = this.type;
      data.title = this.title;
      data.status = this.status;
      data.detail = this.detail;
      data.instance = this.instance;
      return data;
  }
}

export interface IProblemDetails {
  type?: string;
  title?: string;
  status?: number | undefined;
  detail?: string;
  instance?: string;
}
