<form (ngSubmit)="onSubmit()">
    <!-- define formGroup separate from the form to prevent submitted being set. This is a hack to account for
    bug https://github.com/angular/components/issues/13240. Without this, the validation on the inputs wouldn't be
    set on reset. -->
    <ng-container [formGroup]="userForm">
        <mat-form-field>
            <input id='usr-tb-firstname' matInput placeholder="First Name" required formControlName="firstName" maxlength="100">
            <ads-control-messages [control]='userForm.get("firstName")'></ads-control-messages>
        </mat-form-field>
        <mat-form-field>
            <input id='usr-tb-lastname' matInput placeholder="Last Name" required formControlName="lastName" maxlength="100">
            <ads-control-messages [control]='userForm.get("lastName")'></ads-control-messages>
        </mat-form-field>
        <mat-form-field>
            <input id='usr-tb-email' matInput placeholder="Email" required formControlName="email" maxlength="100">
            <ads-control-messages [control]='userForm.get("email")'></ads-control-messages>
        </mat-form-field>
        <mat-form-field>
            <input id='usr-mobilePhone' matInput formControlName="mobilePhone" placeholder="Mobile Phone">
        </mat-form-field>

        <mat-form-field>
            <input id='usr-dateDisplayFormat' matInput formControlName="dateDisplayFormat" placeholder="Date Display Format">
        </mat-form-field>

        <mat-form-field>
            <input id='usr-dateTimeDisplayFormat' matInput formControlName="dateTimeDisplayFormat" placeholder="Date Time Display Format">
        </mat-form-field>

        <mat-form-field>
            <input id='usr-lengthUnitOfMeasure' matInput formControlName="lengthUnitOfMeasure" placeholder="Length Unit of Measure">
        </mat-form-field>

        <mat-form-field>
            <input id='usr-tankVolumeUnitOfMeasure' matInput formControlName="tankVolumeUnitOfMeasure" placeholder="Tank Volume Unit of Measure">
        </mat-form-field>

        <mat-form-field>
            <input id='usr-loadingDimensionsUnitOfMeasure' matInput formControlName="loadingDimensionsUnitOfMeasure" placeholder="Loading Dimensions Unit of Measure">
        </mat-form-field>

        <mat-form-field>
            <input id='usr-weightUnitOfMeasure' matInput formControlName="weightUnitOfMeasure" placeholder="Weight Unit of Measure">
        </mat-form-field>

        <mat-form-field>
            <input id='usr-jobTitle' matInput formControlName="jobTitle" placeholder="Job Title">
        </mat-form-field>
        <button id='usr-tb-addbutton' mat-stroked-button type="submit">Add</button>
    </ng-container>
</form>

<table *ngIf="this.users.length > 0" mat-table [dataSource]="users" class="new-users-table">
    <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let user">{{user.firstName}} {{user.lastName}}</td>
    </ng-container>
    <ng-container matColumnDef="Email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let user">{{user.email}}</td>
    </ng-container>
    <ng-container matColumnDef="ActionButtons">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
            <button mat-stroked-button (click)="delete(user)">Remove</button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let newUsers; columns: columnsToDisplay"></tr>
</table>
