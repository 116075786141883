import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AccountWorkspaceAssetsV3Service, AssetClassesV3Service, IntermodalContainerClassification } from '../../services/assets-v3.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToasterNotificationService } from '../../services/toasterNotification.service';
import { AccountSelectSearchComponent } from 'src/app/shared/account-select-search/account-select-search.component';
import { Router } from '@angular/router';

@Component({
  selector: 'ads-message-dialog',
  templateUrl: 'asset-get-or-create-dialog.component.html',
  styleUrls: ['./asset-get-or-create-dialog.component.scss']
})
export class AssetGetOrCreateDialogComponent implements OnInit {
  getOrCreate: FormGroup;
  deviceCtrl = new FormControl();
  getOrCreateByName = false;
  getOrCreateByIonxId = false;
  classificationIds = "";
  regionIds = "";
  @ViewChild('accountId') accountId: AccountSelectSearchComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private accountWorkspaceAssetsV3Service: AccountWorkspaceAssetsV3Service,
    private assetClassesV3Service: AssetClassesV3Service,
    private formBuilder: FormBuilder,
    private router: Router,
    private toasterService: ToasterNotificationService,
    private dialogRef: MatDialogRef<AssetGetOrCreateDialogComponent>) {
      if (data) {
      }
  }

  ngOnInit() {
    this.getOrCreate = this.formBuilder.group({
      ionxId: null,
      name: null,
      regionId: null,
      classId: null,
      accountId: null
    });

    this.assetClassesV3Service.getClassifications()
    .subscribe(classifications => {
      this.formatIdTooltips(classifications);
    });
  }

  formatIdTooltips(classifications: IntermodalContainerClassification[]) {
    classifications.forEach(x => this.classificationIds = this.classificationIds + x.name + ": " + x.id + `, `)
    classifications.forEach(x => x.regions.forEach(y => this.regionIds = this.regionIds + y.name + " " + x.name + ": " + y.id + `, `))
  }

  onByNameClick() {
    this.getOrCreateByName = true;
  }

  onByIonxIdClick() {
    this.getOrCreateByIonxId = true;
  }

  onBackClick() {
    this.getOrCreateByName = false;
    this.getOrCreateByIonxId = false;
  }

  onSubmit() {
    if(this.getOrCreateByName) {
      this.getOrCreateByNameSubmit();
    } else {
      this.getOrCreateByIonxIdSubmit();
    }
  }

  getOrCreateByNameSubmit() {
    var classId = this.getOrCreate.value.classId ?? undefined;
    var regionId = this.getOrCreate.value.regionId ?? undefined;
    this.accountWorkspaceAssetsV3Service.getOrCreateInWorkspace(this.getOrCreate.value.accountId, "all", this.getOrCreate.value.name, "Ops Portal", classId, regionId)
      .subscribe((accountAssetResponse) => {
        this.router.navigate([this.router.url, `${accountAssetResponse.id}`]);
        this.dialogRef.close(true);
      },
      error => {
        this.handleErrorResponse(error);
      }
    );
  }

  getOrCreateByIonxIdSubmit() {
    this.accountWorkspaceAssetsV3Service.getOrCreateByIonxIdInWorkspace(this.getOrCreate.value.accountId, "all", this.getOrCreate.value.ionxId, "Ops Portal")
      .subscribe((accountAssetResponse) => {
        this.router.navigate([this.router.url, `${accountAssetResponse.id}`]);
        this.dialogRef.close(true);
      },
      error => {
        this.handleErrorResponse(error);
      }
    );
  }

  handleErrorResponse(error) {
    var errorResponse = JSON.parse(error.response);
    var errorResponseToDisplay = "";
    if(errorResponse.errors != null) {
      errorResponseToDisplay = errorResponse.errors;
    } else{
      errorResponseToDisplay = errorResponse == null || errorResponse == undefined ? error.detail : errorResponse;
    }
    this.toasterService.showWarnToaster(JSON.stringify(errorResponseToDisplay));
  }

  cancel(): void {
      this.dialogRef.close(false);
  }
}
