import { Component, OnInit } from '@angular/core';
// import { SIMService } from '../services/devices.service';
import { NgForm } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { ToasterNotificationService } from '../services/toasterNotification.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'ads-manage-sims',
  templateUrl: './sims.component.html',
  styleUrls: ['./sims.component.scss']
})
export class SimsComponent implements OnInit {
  simInput: any = '';
  account: any;
  simInputErrors: string[] = [];

  constructor(
    // private simService: SIMService,
    private authService: MsalService,
    private toasterService: ToasterNotificationService,
  ) {
  }

  ngOnInit() { }

  createEscapedHttpBodyString(retval: string): string {
    return '"' + retval.toString().replace(new RegExp('"', 'g'), '\\"') + '"';
  }

  uploadSimList(simModelForm: NgForm) {
    this.account = this.authService.getAccount();
    return null;
    /*
    return this.simService.import(this.account.name,
      this.createEscapedHttpBodyString(simModelForm.value.simInput)
    )
      .subscribe(
        (e: any) => {
          simModelForm.reset();
          this.toasterService.showInfoToaster('Sims Succesfully Imported');
        },
        error => {
          this.toasterService.showWarnToaster(`${error.title}`);
        }
      );
      */
  }
}
