import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  title: string,
  object?: any,
  objectGetter?: () => Promise<any>
}

@Component({
  selector: 'app-json-dialog',
  templateUrl: './json-dialog.component.html',
  styleUrls: ['./json-dialog.component.scss']
})
export class JsonDialogComponent implements OnInit {
  title: string;
  data: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public input: DialogData,
  ) {
    this.title = input.title;
    if (input.object) {
      this.data = input.object;
    }
    else if (input.objectGetter) {
      this.data = "Loading...";
      input.objectGetter().then(x => {
        this.data = x;
      });
    }
  }

  ngOnInit(): void {
  }

}
