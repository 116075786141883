import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountWorkspaceAssetsV3Service, ShareAdsDataFeedBulkRequest, ShareAdsDataFeedBulkRequestAssetShare, ShareAdsDataFeedBulkByWorkspaceRequest, ShareAdsDataFeedBulkResult } from '../../services/assets-v3.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToasterNotificationService } from '../../services/toasterNotification.service';
import { AccountSelectSearchComponent } from 'src/app/shared/account-select-search/account-select-search.component';
import { WorkspaceSelectComponent } from '../../shared/workspace-select/workspace-select.component';
import { Router } from '@angular/router';
import { I } from '@angular/cdk/keycodes';

@Component({
  selector: 'ads-message-dialog',
  templateUrl: 'asset-bulk-share-dialog.component.html',
  styleUrls: ['./asset-bulk-share-dialog.component.scss']
})
export class AssetBulkShareDialogComponent implements OnInit {
  assetsShare: FormGroup;
  sourceAccountId: number;
  targetAccountId: number;
  assetIds: [];
  byWorkspace: boolean;
  shareBulkRequest: ShareAdsDataFeedBulkRequest = new ShareAdsDataFeedBulkRequest();
  shareBulkByWorkspaceRequest: ShareAdsDataFeedBulkByWorkspaceRequest = new ShareAdsDataFeedBulkByWorkspaceRequest();
  shareBulkRequestShares: ShareAdsDataFeedBulkRequestAssetShare[] = [];
  @ViewChild('targetAccountSelectBulk', { static: true }) targetAccountSelectBulk: AccountSelectSearchComponent;
  @ViewChild('workspaceSelect', { static: true }) workspaceSelect: WorkspaceSelectComponent;

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data: any,
    private accountWorkspaceAssetsV3Service: AccountWorkspaceAssetsV3Service,
    private formBuilder: FormBuilder,
    private toasterService: ToasterNotificationService,
    private dialogRef: MatDialogRef<AssetBulkShareDialogComponent>) {
      if (data) {
        this.sourceAccountId = data.sourceAccountId;
        this.assetIds = data.assetIds;
        this.byWorkspace = data.byWorkspace;
      }
  }

  ngOnInit() {
    this.targetAccountId = 0;
    this.assetsShare = this.formBuilder.group({
      targetAccountId: null,
      canReshare: [false],
      workspace: null
    });

    this.targetAccountSelectBulk.registerOnChange(() => this.onTargetAccountChange());
  }

  onTargetAccountChange() {
    this.targetAccountId = this.targetAccountSelectBulk.singleSelect.value;
    this.workspaceSelect.accountId = this.targetAccountId;
    this.workspaceSelect.setupWorkspaces();
  }


  onSubmit() {
    if(this.byWorkspace) {
      this.submitBulkByWorkspace();
    } else{
      this.submitBulk();
    }
  }

  submitBulk() {
    this.shareBulkRequest.targetAccountId = this.assetsShare.value.targetAccountId;
    this.assetIds.forEach(x => this.shareBulkRequestShares.push(new ShareAdsDataFeedBulkRequestAssetShare({
      id: x,
      canReshare: this.assetsShare.value.canReshare
    })));

    this.shareBulkRequest.assetShares = this.shareBulkRequestShares;
    this.accountWorkspaceAssetsV3Service.shareAdsDataFeedBulk(this.sourceAccountId, "all", "Ops Portal", this.shareBulkRequest)
      .subscribe((x: any) => {
        this.handleErrorResponse(x);
        this.dialogRef.close(true);
      }
    );
  }

  submitBulkByWorkspace() {
    this.shareBulkByWorkspaceRequest.targetAccountId = this.assetsShare.value.targetAccountId;
    this.shareBulkByWorkspaceRequest.canReshare = this.assetsShare.value.canReshare;
    this.shareBulkByWorkspaceRequest.workspace = this.workspaceSelect.workspaceSelected;//this.assetsShare.value.workspace;

    this.shareBulkRequest.assetShares = this.shareBulkRequestShares;
    this.accountWorkspaceAssetsV3Service.shareAdsDataFeedBulkByWorkspace(this.sourceAccountId, "all", "Ops Portal", this.shareBulkByWorkspaceRequest)
      .subscribe((x: any) => {
        this.handleErrorResponse(x);
        this.dialogRef.close(true);
      }
    );
  }

  handleErrorResponse(response: [ShareAdsDataFeedBulkResult]) {
    var errorDisplay = "";
    response.forEach(x => {
      if(x.notFound) {
        errorDisplay += "Asset not found: " + x.assetId + ". ";
      }
      if(!x.success && x.errors != null) {
        errorDisplay += "Error for asset " + x.assetId + ": " + JSON.stringify(x.errors) + ". ";
      }
    })
    if(errorDisplay != "") {
      this.toasterService.showWarnToaster(JSON.stringify(errorDisplay));
    }
  }

  cancel(): void {
      this.dialogRef.close(false);
  }
}
