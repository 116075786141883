import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import Debug from 'debug';

const debug = Debug('BreadcrumbResolver');

// This is dumb, but it appears that if you use a resolver, then angular router oveerwrites all children
// with the resolved value - so this lets me re-overwrite it back to the original value. :facepalm:
@Injectable()
export class BreadcrumbResolver implements Resolve<string> {

  constructor() {}

  resolve(route: ActivatedRouteSnapshot) {
    const breadcrumb = route.data.breadcrumb;
    debug(breadcrumb);

    return breadcrumb;
  }
}
