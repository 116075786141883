<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between">
    Asset Details
    <div>
      <button mat-button color="primary" [matMenuTriggerFor]="viewAsMenu">View As Account...</button>
      <mat-menu #viewAsMenu="matMenu">
        <a mat-menu-item
           *ngFor="let account of asset?.accounts"
           routerLink="/assets-paas/accounts/{{account.accountId}}/assets/{{assetId}}"
        >
          {{account.accountId}}
        </a>
      </mat-menu>
      <a mat-button color="primary" routerLink="events">View Event Stream</a>
      <button mat-button color="warn" (click)="onReprojectClick()">Reproject</button>
    </div>
  </mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div *ngIf="asset" class="asset-details">
      <div class="asset-info-row">
        <div class="asset-info">
          <strong>Name:</strong>
          <span class="asset-value">{{asset.name}}</span>
          <button mat-icon-button (click)="copyToClipboard(asset.name, 'Asset Name')" matTooltip="Copy Asset Name">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
        <div class="asset-info">
          <strong>ID:</strong>
          <span class="asset-value">{{asset.id}}</span>
          <button mat-icon-button (click)="copyToClipboard(asset.id, 'Asset ID')" matTooltip="Copy Asset ID">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-content>
    <div *ngIf="asset" class="asset-details">
      <ngx-json-viewer [json]="asset" [expanded]="true"></ngx-json-viewer>
    </div>
  </mat-card-content>
</mat-card>
