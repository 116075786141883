import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterNotificationService } from 'src/app/services/toasterNotification.service';
import { MatDialog } from '@angular/material/dialog';
import { AccountsService } from '../../../../../services/accounts.service';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ValueFormatterParams, RowSelectedEvent } from 'ag-grid-community';
import { AssetResponse, AssetsV3Service, AccountWorkspaceAssetsV3Service, AccountAssetResponse, DeactivateAdsDataFeedBulkRequest, DeactivateAdsDataFeedBulkResult } from '../../../../../services/assets-v3.service';
import { ODataServerSideDataSource } from '../../../../../services/ODataServerSideDataSource';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../../../../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { ShareAssetsDialogComponent } from './share-assets-dialog-component/share-assets-dialog.component'

@Component({
  selector: 'ads-app-sharing-view',
  templateUrl: './sharing-view.component.html',
  styleUrls: ['./sharing-view.component.scss']
})
export class SharingViewComponent implements OnInit {
  accountId: number;
  sharedAccountId: number;
  deviceCount: number;
  deviceGroupCount: number;
  assetCount: number;
  assetGroupCount: number;
  accountName: string;
  sharedAccountName: string;
  gridApi: GridApi;
  enableSelectedAssets: boolean = false;
  searchODataFilter: string = undefined;
  assetsSelected: any[] = [];
  searchSubject: Subject<string> = new Subject();
  columnDefs: ColDef[] = [
    { headerName: '', field: 'share', width: 50, checkboxSelection: true },
    { headerName: 'Name', field: 'name', sortable: true },
    { headerName: 'Class', field: 'class', sortable: true },
    { headerName: 'Region', field: 'region', sortable: true },
    { headerName: 'Devices', field: 'devices', valueFormatter: this.formatDevices },
    { headerName: 'Shared To Accounts', field: 'sharedToAccountIds', valueFormatter: this.formatSharedToAccounts },
    { headerName: 'Resharable', field: 'canShareADSDataFeed', sortable: true }
  ];
  gridOptions: GridOptions = {
    rowModelType: 'infinite',
    context: {
      nextPageContinuationToken: null
    },
    datasource: new ODataServerSideDataSource(this.getData.bind(this)),
  };

  constructor(private activatedRouter: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute,
    private accountsService: AccountsService,
    private toasterService: ToasterNotificationService,
    private dialog: MatDialog,
    private assetsV3Service: AssetsV3Service,
    private accountWorkspacesAssetsV3Service: AccountWorkspaceAssetsV3Service) {
      this.route.parent.url.subscribe((urlPath) => {
        let accountId = 0;
        if (urlPath.length > 1) {
          accountId = Number.parseInt(urlPath[urlPath.length - 1].path, 10);
        }

        if (isNaN(accountId)) {
          this.router.navigateByUrl('[/accounts]');
        }

        this.accountId = accountId;

      });
      this.route.params.subscribe(param => {
        this.sharedAccountId = param.sharedAccountId;
      });
    }

  ngOnInit() {
    this.accountsService.account(this.accountId).subscribe(account => {
      this.accountName = account.accountName
    });
    this.accountsService.account(this.sharedAccountId).subscribe(account => {
      this.sharedAccountName = account.accountName
    });

    this.searchSubject
      .pipe(debounceTime(500))
      .subscribe(x => {
          if (x) {
            const normalizedX = x.replace(/[^a-zA-Z0-9]/g, '');
            this.searchODataFilter = `contains(nameNormalized, '${normalizedX}')`;
          } else {
            this.searchODataFilter = undefined;
          }

          this.gridApi.setDatasource(this.gridOptions.datasource);
        });
  }

  getData(odataFilter: string, odataOrderBy: string, continuationToken: string): Promise<any> {
    return this.accountWorkspacesAssetsV3Service.getAccountAssetsBySharedFromAccount(this.sharedAccountId,
      "all", this.accountId, this.searchODataFilter, odataOrderBy, 10000, continuationToken)
      .toPromise();
  }

  shareAssets() {
    var dialogRef = this.dialog.open(ShareAssetsDialogComponent, {
      data: {
        targetAccountId: this.sharedAccountId,
        targetAccountName: this.sharedAccountName,
        sourceAccountId: this.accountId,
        sourceAccountName: this.accountName
      }
    });

    dialogRef.afterClosed().subscribe((success) => {
      if (success) { this.searchSubject.next(""); }
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  onRowSelected(event: RowSelectedEvent) {
    this.enableSelectedAssets = this.gridApi.getSelectedNodes().length > 0;
  }

  onSearchKeyup(event: KeyboardEvent) {
    this.searchSubject.next((event.target as HTMLInputElement).value);
  }

  deactivateAssetsClick() {
    this.assetsSelected = [];
    this.gridApi.getSelectedNodes().forEach((node) => {
      this.assetsSelected.push(node.data.id);
    });
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure you want to deactivate the selected assets?',
        message: 'This action is irreversible.',
        buttonText: {
          ok: 'Deactivate',
          cancel: 'Cancel'
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      var request = new DeactivateAdsDataFeedBulkRequest ({
        targetAccountId: this.sharedAccountId,
        targetAssetIds: this.assetsSelected
      });

      if (result) {
        this.accountWorkspacesAssetsV3Service.deactivateAdsDataFeedBulk(this.accountId, "all", "Ops Portal", request)
          .subscribe((x: any) => {
            this.handleErrorResponse(x);
            this.searchSubject.next("");
            this.enableSelectedAssets = false;
          }
        );
      }
    });
  }

  handleErrorResponse(response: [DeactivateAdsDataFeedBulkResult]) {
    var errorDisplay = "";
    response.forEach(x => {
      if(x.notFound) {
        errorDisplay += "Asset not found: " + x.assetId + ". ";
      }
      if(!x.success && x.errors != null) {
        errorDisplay += "Error for asset " + x.assetId + ": " + JSON.stringify(x.errors) + ". ";
      }
    })
    if(errorDisplay != "") {
      this.toasterService.showWarnToaster(JSON.stringify(errorDisplay));
    }
  }

  formatDevices(params: ValueFormatterParams) {
    if (!params.value) return;

    const asset: AssetResponse = params.data;
    return asset.devices.length.toString();
  }

  formatSharedToAccounts(params: ValueFormatterParams) {
    if (!params.value) return;

    const asset: AccountAssetResponse = params.data;
    return asset.sharedToAccountIds.length.toString();
  }
}
