<div fxLayout="row" fxLayoutAlign="space-between center" mat-dialog-title>
  Move Devices <strong class="close-icon" (click)="close()">&times;</strong>
</div>
<mat-dialog-content [formGroup]="moveGroupForm" (ngSubmit)="submitMove()">
  <label class="bold section-title"> Choose Target:</label>
  <mat-form-field class="device-group-select">
    <mat-label>Group Name</mat-label>
    <mat-select
      id="as-devicesgroup-configuration-dropdown"
      formControlName="groupId"
      panelClass="as-devicesgroupconfigdropdown-panel"
      disableOptionCentering
    >
      <mat-option disabled value="">Select a group</mat-option>
      <mat-option *ngFor="let group of groups" id="{{ group.id }}" value="{{ group.id }}">
        {{ group.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <label class="bold section-title"> Move Devices into Group:</label>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-radio-group
      aria-label="Select an option"
      #radioGroup="matRadioGroup"
      formControlName="typeItemsGrouped"
      [disabled]="itemsSelected && itemsSelected.length > 0"
    >
      <mat-radio-button value="{{ GroupTypes.DEVICES }}" checked="true">Devices</mat-radio-button>
      <mat-radio-button value="{{ GroupTypes.FLEETS }}" *ngIf="false">Fleets</mat-radio-button>
    </mat-radio-group>
    <div fxLayout="center center" class="view-devices-fleets" (click)="openSelectionSection(radioGroup.value)">
      <span>View all devices</span><mat-icon>keyboard_arrow_right</mat-icon>
    </div>
  </div>
  <div class="devices-fleets-list" (click)="chipListInput.focus()">
    <mat-form-field class="example-chip-list">
      <mat-label></mat-label>
      <mat-chip-list #chipList aria-label="items selection">
        <mat-chip
          *ngFor="let item of itemsSelected"
          [removable]="true"
          (removed)="remove(item)"
          appDeviceValidator
          [existingDevices]="devices"
          [deviceEntered]="item.serialNumber"
          (settingValidFlag)="item.isValid = $event"
          (click)="$event.stopPropagation()"
        >
          {{ item.serialNumber }}
          <mat-icon matChipRemove (click)="$event.stopPropagation()">cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Type or Copy & Paste"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          (paste)="paste($event)"
          autocomplete="off"
          #chipListInput
        />
      </mat-chip-list>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="save-btn" [disabled]="!moveGroupForm.valid || invalidItemsSelected()" (click)="submitMove()">Move</button>
</mat-dialog-actions>
